import React from "react";
import {
    // Button,
    // Card,
    // CardBody,

    // Row,
} from "reactstrap";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import { getStreamerStatistics } from "src/helpers/backend_helper";
// import { ApiCore } from "src/helpers/api_helper";
// import { COLORS } from "src/utils/COLORS";
import { useSelector } from "react-redux";
// import Icon from "@ailibs/feather-react-ts";
import { NewWidgetsCampaignCard } from "./NewWidgetCampaignCard";
import { WidgetStatusCard } from "./WidgetStatusCard";
import {
    CampanasActivasIcon,
    CampanasDisponiblesIcon,
    CampanasSuscritasIcon,
} from "src/assets/icons/Icons";
import GetData from "../Home/GetData";

const WidgetsCampaign = (props: any) => {

    const { activeCampaigns, subscribed_campaigns, availableCampaigns } = GetData()
    const { status } = useSelector((state: any) => ({
        status: state.profile.status,
    }));

    return (
        <React.Fragment>
            <div style={{ marginTop: "1rem" }} className="container-widgets-campaing campaigns1">
                <NewWidgetsCampaignCard
                    icon={<CampanasActivasIcon size={70} />}
                    WidgetTitle={"Campañas activas"}
                    WidgetData={activeCampaigns}
                    color="rgb(99, 58, 162)"
                />
                <NewWidgetsCampaignCard
                    icon={<CampanasSuscritasIcon size={70} />}
                    WidgetTitle={"Campañas suscritas"}
                    WidgetData={subscribed_campaigns}
                    color="rgb(246, 107, 78)"
                />
                <NewWidgetsCampaignCard
                    icon={<CampanasDisponiblesIcon size={70} />}
                    WidgetTitle={"Campañas disponibles"}
                    WidgetData={availableCampaigns}
                />
                <WidgetStatusCard status={status.status} />
            </div>
        </React.Fragment>
    );
};

export default WidgetsCampaign;
