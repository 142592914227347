import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Button, Card, CardBody, Container, Input, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
// import TwitchSync from "src/components/Twitch/TwitchSync";
// import YoutubeSync from "src/components/Youtube/YoutubeSync";
import RangeSlider from "react-bootstrap-range-slider";
import { toNumber } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EmissionConfigurationDraggable from "src/components/Draggables/EmissionConfigurationDraggable";
import { useDispatch, useSelector } from "react-redux";
import { putStreamerProfile, updateStreamerPlayout } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import AcceptCampaignSelect from "src/components/EditProfile/AcceptCampaignSelect";
import { getProfile } from "src/slices/thunks";
import { useHistory } from "react-router";
import AllowLinksChat from "src/components/Campaigns/AllowLinksChat";
import ReactPlayer from "react-player";

const buttonStates = {
    editing: "Guardar configuración",
    loading: "Guardando configuración",
    success: "Configuración guardada",
};



const EmissionConfiguration = (props: any) => {
    const [streamerAdPositionX, setStreamerAdPositionX] = useState<number>();
    const [streamerAdPositionY, setStreamerAdPositionY] = useState<number>();
    const [streamerAdCadenceMinutes, setStreamerAdCadenceMinutes] = useState(10);
    const [accept_subscriptions_automatically, setAccept_subscriptions_automatically] = useState(0)
    const [streamerId, setStreamerId] = useState();
    const [urlButtonLabel, setUrlButtonLabel] = useState("Copiar URL");
    const [buttonState, setButtonState] = useState(buttonStates.editing);
    const profileData = localStorage.getItem("profile") ?? "";
    const dispatch = useDispatch()
    const history = useHistory()
    const possibleValues: number[] = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];


    const { coordinates } = useSelector((state: any) => ({
        coordinates: state.emission.coordinates,
    }));

    const getBodyToUpdateProfile = () => {
        return {
            streamer_id: streamerId,
            ad_cadence_secs: streamerAdCadenceMinutes * 60,
            ad_position_x: coordinates.x,
            ad_position_y: coordinates.y,
        };
    };

    useEffect(() => {
        setButtonState(buttonStates.editing);
    }, [coordinates]);

    const updateAccept = () => {
        if (profileData) {
            const obj = JSON.parse(profileData);
            const streamer = obj.streamers?.[0];
            const data = {
                streamer_id: localStorage.getItem('streamer_id'),
                birthdate: streamer.birthdate,
                gender: streamer.gender,
                nationality: streamer.nationality,
                language_iso: streamer.language_iso,
                currency_iso: streamer.currency_iso,
                notes: streamer.notes,
                channel_topic: streamer.channel_topic,
                accept_subscriptions_automatically
            }
            putStreamerProfile(new ApiCore(localStorage.getItem("token")), data).then(res =>
                dispatch(getProfile(history))
            ).catch(err => {
                console.log(err)
            })
        }
    }


    const handleValidSubmit = (event: any) => {
        event.preventDefault();

        if (profileData) updateAccept()

        setButtonState(buttonStates.loading);
        const updatePlayout = updateStreamerPlayout(
            new ApiCore(localStorage.getItem("token")),
            getBodyToUpdateProfile()
        );
        updatePlayout.then((data: any) => {
            setButtonState(buttonStates.success);
        });

    };


    useEffect(() => {

        if (profileData) {
            const obj = JSON.parse(profileData);
            const streamer = obj.streamers?.[0];
            setAccept_subscriptions_automatically(streamer.accept_subscriptions_automatically)
            setStreamerAdCadenceMinutes(
                streamer?.ad_cadence_secs == null ? 5 : streamer?.ad_cadence_secs / 60
            );
            setStreamerId(streamer?.id);
            setStreamerAdPositionX(streamer?.ad_position_x);
            setStreamerAdPositionY(streamer?.ad_position_y);
        }
    }, [profileData]);

    // useEffect(() => {
    //     console.log(streamerAdPositionX, streamerAdPositionY)
    // }, [streamerAdPositionX, streamerAdPositionY])

    return (
        <div className="page-content">
            <MetaTags>
                <title>{props.t("Configurate emission")}</title>
            </MetaTags>
            <Container fluid>
                <AvForm
                    onValidSubmit={(e: any, v: any) => {
                        handleValidSubmit(e);
                    }}>
                    {/* Render Breadcrumbs */}
                    <Card>
                        <CardBody>

                            <Row className="mt-3 mb-3">
                                <div className="mb-3">
                                    <div>
                                        <h5 className="font-size-16">Configuración de la URL de emisión</h5>
                                        <span style={{ display: "flex", marginBottom: "1.2rem" }}>
                                            Haz clic en el botón, copia la url de emisión de anuncios de 014 LIVE!, pégala en tu OBS, y
                                            listo, <br /> ¡empieza a emitir nuestros anuncios! En esta guía paso a paso te explicamos cómo o
                                            mírate el vídeo que te hemos preparado:
                                        </span>
                                        <ol style={{ paddingLeft: "1rem" }}>
                                            <li>
                                                Abre el OBS o el software de streaming que utilices. Clica en “Añadir nueva fuente” y elige la fuente “Navegador”
                                            </li>
                                            <li>
                                                Copia y pega este código personal de streaming en el OBS
                                                <br />
                                                <CopyToClipboard

                                                    text={
                                                        process.env.REACT_APP_STREAM_URL +
                                                        `${profileData
                                                            ? JSON.parse(profileData).streamers[0]?.id
                                                            : null
                                                        }`
                                                    }>
                                                    <div className="my-3 justify-content-center justify-content-md-start d-flex flex-md-row flex-column gap-2">
                                                        <Input
                                                            type="text"
                                                            name="stream_url"
                                                            id="stream_url"
                                                            placeholder={`${process.env.REACT_APP_STREAM_URL}${profileData
                                                                ? JSON.parse(profileData).streamers[0]?.id
                                                                : null
                                                                }`}
                                                            style={{ maxWidth: "min(100%,35rem)" }}
                                                            readOnly={true}
                                                        />

                                                        <Button
                                                            color="primary"
                                                            style={{ maxWidth: "120px" }}
                                                            className="w-50 align-self-center"
                                                            onClick={(e: any) =>
                                                                setUrlButtonLabel("URL copiada")
                                                            }>
                                                            {urlButtonLabel}
                                                        </Button>
                                                        {"  "}
                                                    </div>
                                                </CopyToClipboard>
                                            </li>
                                            <li>
                                                Fija las dimensiones de ancho y alto en 1920 x 1080, y el FPS en 30
                                            </li>
                                            <li>
                                                Activa “Apagar fuente cuando no sea visible”
                                            </li>
                                            <li>
                                                Activa “Actualizar el navegador cuando la escena se active”
                                            </li>
                                            <li>
                                                Bloquea la capa
                                            </li>
                                            <li>
                                                Coloca la fuente encima de todas las demás capas que configuran tu escena
                                            </li>
                                            <li>
                                                Asegúrate de añadir la fuente a todas tus escenas.
                                                <br /> <br />
                                                A continuación te dejamos el vídeo explicando estos pasos:
                                            </li>
                                        </ol>
                                    </div>

                                    <div style={{ position: 'relative', width: 'min(100%,44rem)', height: "auto", aspectRatio: "16/9" }}>
                                        <ReactPlayer
                                            width={"100%"}
                                            height={"auto"}
                                            style={{ position: 'absolute', top: 0, left: 0, }}
                                            controls={true}
                                            // playing={isPlaying}
                                            url={process.env.REACT_APP_VIDEO_URL}
                                        // src={campaignOnModal?.campaign_image}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row className="mt-5 mb-3">
                                <AllowLinksChat />
                            </Row>
                            {/* <Row>
                                <label>Sincronización con plataformas</label>
                                <h5 className="font-size-16">Sincronización con plataformas</h5>
                                <Col xl={3}>
                                    <div className="mb-3">
                                        <div className="mb-3">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </div>
                                        <div>
                                            <TwitchSync/>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl={3}>
                                    <div className="mb-3">
                                        <div className="mb-3">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </div>
                                        <div>
                                            <YoutubeSync/>
                                        </div>
                                    </div>
                                </Col>
                            </Row> */}
                            <Row className="mt-5 mb-3">
                                <AcceptCampaignSelect setValue={setAccept_subscriptions_automatically} value={accept_subscriptions_automatically === 1? 1 : 0} />
                            </Row>
                            <Row className="mt-5 mb-3" style={{ justifyContent: "left" }}>
                                <h5 className="font-size-16">Posición de la publicidad</h5>
                                <br></br>
                                <span>
                                    Indícanos en qué posición deseas que se emita la publicidad por defecto. Ten en cuenta que nunca ocupará más de un 15% del espacio total.
                                </span>
                                <EmissionConfigurationDraggable
                                    x={
                                        typeof streamerAdPositionX == "number"
                                            ? streamerAdPositionX
                                            : 0
                                    }
                                    y={
                                        typeof streamerAdPositionY == "number"
                                            ? streamerAdPositionY
                                            : 0
                                    }
                                />
                            </Row>
                            <Row className="mt-5 mb-3">
                                <div className="mb-3" style={{ marginTop: "1rem" }}>
                                    <h5 className="font-size-16">
                                        Cadencia de publicidad por minutos
                                    </h5>
                                    {/* <label>Cadencia de publicidad por minutos</label> */}
                                    <br></br>
                                    <span>
                                        Define el tiempo mínimo que quieres entre patrocinios. Recomendamos 30 minutos. Nunca emitiremos patrocinios a frecuencia mayor de la indicada.
                                    </span>
                                    <RangeSlider
                                        min={10}
                                        max={60}
                                        step={5}
                                        tooltip="off"
                                        tooltipStyle={{ border: "1px solid red" }}
                                        value={toNumber(streamerAdCadenceMinutes)}
                                        onChange={(e: any) =>
                                            setStreamerAdCadenceMinutes(e.target.value)
                                        }
                                    />
                                    <ul className="d-flex w-100 p-0 justify-content-between list-unstyled">
                                        {possibleValues.map((possibleValue: number) => (
                                            <li key={possibleValue}>{possibleValue}</li>
                                        ))}
                                    </ul>
                                </div>
                            </Row>
                            <Row>
                                <div className="text-end mt-5">
                                    <Button color="primary" type="submit">
                                        {buttonState}
                                    </Button>
                                    {"  "}
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
                </AvForm>
            </Container>
        </div>
    );
};

export default withTranslation()(withRouter(EmissionConfiguration));
