import React, { useEffect, useRef, useState } from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardText,
    CardTitle,
    Col,
    Input,
    Label,
    Row,
    Modal, ModalHeader, ModalFooter, ModalBody, Spinner
} from "reactstrap";
import moment from "moment";
import * as statuses from "src/helpers/status_helper";
import { useTrail, animated } from "react-spring";
import { COLORS } from "src/utils/COLORS";
import "../../assets/scss/custom/components/_home_campaigns.scss";
import { statusData } from "../UserCard/UserCard";
import { validCampaignImage } from "src/pages/Campaigns";
import DetailCampaign from "src/components/Campaigns/DetailCampaign";
import StatusCampaign from "src/components/Campaigns/StatusCampaign";
import { getSubscriptions, postSubscriptionStatus } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import { useDispatch } from "react-redux";
import { updateStatus } from "src/slices/Campaigns/reducer";
import "../../assets/scss/custom/pages/_detail-campaign.scss";
import SubscribeButton from "./SubscribeButton";


interface Props {
    img?: any;
    campaign: any;
    subscription?: any
}

function CampaignCardFunction(props: any) {
    const dispatch = useDispatch()

    const [isVisible, setIsVisible] = useState(false);
    const [modal, setModal] = useState(false);
    const [susbcribeModal, setSusbcribeModal] = useState(false);
    const [subscriptionOnModal, setSubscriptionOnModal] = useState<any>();
    const [campaignOnModal, setCampaignOnModal] = useState<any>();
    const [updating, setUpdating] = useState(false);


    const getSubscriptionByCampaign = (campaign: any, subscriptions: any) => {
        const subscriptionIdToFind = campaign?.subscription_id;
        const subscription = props.subscriptions.find((sub: any) => sub.id == subscriptionIdToFind)
        return subscriptions
            ? props.subscriptions.find((sub: any) => sub.id == subscriptionIdToFind)
            : null;
    };


    const [subscription, setSubscription] = useState(getSubscriptionByCampaign(props.campaign, props.subscriptions))


    const trail = useTrail(1, {
        config: { duration: 1000 },
        from: { opacity: 0 },
        to: { opacity: isVisible ? 1 : 0 },
        delay: isVisible ? 0 : 1000,
    });

    useEffect(() => {
        setIsVisible(true);
        // setSubscription(getSubscriptionByCampaign(props.campaign, props.subscriptions))
    }, []);

    const updateStatusSubscription = (status: any, subscription_id: any) => {
        setUpdating(true);
        postSubscriptionStatus(new ApiCore(localStorage.getItem("token")), subscription_id, status)
            .then((data: any) => {
                if (data.message == "ok") {
                    setSusbcribeModal(false);
                    setModal(false);
                    props.setSuccessAlert(true);
                    setUpdating(false);
                    dispatch(updateStatus(Math.random().toString(36).slice(2)));
                } else {
                    setSusbcribeModal(false);
                    setModal(false);
                    props.setDangerAlert(true);
                    setUpdating(false);
                }
            })
            .catch(() => {
                setSusbcribeModal(false);
                setModal(false);
                props.setDangerAlert(true);
                setUpdating(false);
            });
    };

    const subscribeConfirmationModal = (subscription: any, campaign: any) => {
        setSusbcribeModal(true);
        setSubscriptionOnModal(subscription);
        setCampaignOnModal(campaign);
    };
    const handleDetails = (campaign: any, subscription: any) => {
        setModal(true);
        setCampaignOnModal(campaign);
        setSubscriptionOnModal(subscription);
    };


    // luego cuando conecten el api solo poner el estado para que la funcion cambie los colores
    const status = statuses.getStatusByCode(props.campaign?.subscription_status);
    return (
        <>
            {trail.map((prop: any, index) => (
                <animated.div key={index} style={prop} className=" p-2">
                    <Card
                        className="card-h-100 home-campaign card-h-100 border"
                        style={{
                            border: `1px solid ${COLORS.bootstrapGreen}`, maxWidth: "300px", height: "auto", minHeight: "100%"
                            // boxShadow: `0px 0px 7px 5px ${COLORS.bootstrapGreen}`,
                        }}>
                        <>
                            {/* <span className="move-lines"></span>
                            <span className="move-lines"></span>
                            <span className="move-lines"></span>
                            <span className="move-lines"></span> */}
                        </>

                        <div style={{ width: "100%", height: "auto", aspectRatio: "1.51/1", backgroundImage: `url(${props.campaign?.campaign_image ? validCampaignImage(props.campaign.campaign_image) : "https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>

                        </div>
                        <CardBody>
                            <CardTitle className="homeCardTitle" tag="h4">
                                <p style={{ cursor: "pointer", fontSize: "1.1rem" }}>{props.campaign?.name}</p>
                                <Badge className={status?.color}>{status?.translation}</Badge>
                            </CardTitle>
                            <Row className="mt-4 d-flex" style={{ flexWrap: "wrap" }}>
                                <Col
                                    xs={12}
                                    sm={6}
                                    className="justify-content-end mb-3"
                                    style={{ textAlign: "center" }}>
                                    <Label className="light">CPM</Label>
                                    <Input
                                        disabled={true}
                                        value={props.campaign?.cpm_streamer.toLocaleString("es-AR") + " €"}
                                        style={{
                                            textAlign: "center",
                                            display: "block",
                                            minWidth: "auto",
                                            height: "80%",
                                            fontSize:
                                                "min(max(24px, calc(1.5rem + ((1vw - 12px) * 1.9444))), 38px)",
                                            margin: "auto",
                                        }}
                                    />
                                </Col>

                                <Col
                                    sm={6}
                                    className="justify-content-end mb-3"
                                    style={{ textAlign: "center" }}>
                                    <div className="mb-1">
                                        <Label className="light">Fecha inicio</Label>
                                        <Input
                                            disabled={true}
                                            value={moment(props.campaign?.start_date)
                                                .startOf("day")
                                                .format("DD/MM/YYYY")}
                                            style={{
                                                textAlign: "center",
                                                display: "block",
                                                width: "120px",
                                                margin: "auto",
                                            }}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        <Label className="light">Fecha fin</Label>
                                        <Input
                                            disabled={true}
                                            value={moment(props.campaign?.end_date)
                                                .startOf("day")
                                                .format("DD/MM/YYYY")}
                                            style={{
                                                textAlign: "center",
                                                display: "block",
                                                width: "120px",
                                                margin: "auto",
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter style={{ border: "none", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                            <Button
                                outline
                                color="primary m-1"
                                style={{ float: "left", fontWeight: "600" }}
                                onClick={() => handleDetails(props.campaign, subscription)}>
                                Detalles
                            </Button>
                            {/* <Button
                                color="green-light m-1"

                                onClick={() =>
                                    subscribeConfirmationModal(subscription, props.campaign)
                                }>
                                Suscribirse
                            </Button> */}
                            <SubscribeButton color='green-light' style={{
                                float: "right",
                                borderColor: COLORS.bootstrapGreen,
                                fontWeight: "600",
                                // boxShadow: `0px 0px 7px 5px #ff0bf9`,
                            }} subscribeConfirmationModal={subscribeConfirmationModal} subscriptionOnModal={subscriptionOnModal} campaign={props.campaign} />


                            {"   "}
                        </CardFooter>
                    </Card>
                </animated.div>
            ))}
            <Modal
                className="custom"
                centered={true}
                isOpen={modal}
                toggle={() => {
                    if (!updating) setModal(false);
                }}>
                <ModalHeader style={{ display: "block", verticalAlign: "middle" }}>
                    <Row>
                        <Col sm={8}>
                            <img
                                width="24px"
                                src={campaignOnModal?.creativities[0]?.thumbnail_img_url ?
                                    validCampaignImage(campaignOnModal?.creativities[0]?.thumbnail_img_url)
                                    : ''}

                            ></img>{" "}
                            {campaignOnModal?.name}{" "}
                        </Col>
                        <Col
                            sm={4}
                            style={{
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                            }}>

                            <StatusCampaign code={subscriptionOnModal?.status?.name}></StatusCampaign>
                        </Col>
                    </Row>
                </ModalHeader>
                <DetailCampaign campaign={campaignOnModal} subscription={subscriptionOnModal} />
                <ModalFooter style={{ display: "block" }}>
                    <Button
                        color="secondary"
                        onClick={() => setModal(false)}
                        style={{ float: "right" }}>
                        Cerrar
                    </Button>
                    <SubscribeButton style={{ float: "right" }} subscribeConfirmationModal={subscribeConfirmationModal} subscriptionOnModal={subscriptionOnModal} campaign={props.campaign} />
                    {/* <Button
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() =>
                            subscribeConfirmationModal(subscriptionOnModal, props.campaign)
                        }>
                        Suscribirse
                    </Button> */}
                </ModalFooter>
            </Modal>
            <Modal
                className="custom"
                isOpen={susbcribeModal}
                centered={true}
                toggle={() => {
                    if (!updating) setSusbcribeModal(false);
                }}>
                <ModalHeader>{campaignOnModal?.name}</ModalHeader>
                <ModalBody>
                    {"Vas a suscribirte a la campaña " + campaignOnModal?.name + ". ¿Estas seguro?"}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={updating}
                        onClick={() => {
                            updateStatusSubscription(
                                statuses.STREAMER_AGREED.original,
                                campaignOnModal.subscription_id
                            )
                        }
                        }>
                        {updating ? <Spinner size={"sm"} animation="border" /> : "Si"}
                    </Button>{" "}
                    <Button
                        color="secondary"
                        disabled={updating}
                        onClick={() => setSusbcribeModal(false)}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CampaignCardFunction;
