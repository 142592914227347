import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import { dateFilter, setEndDate, setStartDate } from "src/slices/Statistics/reducer";
import "../../assets/scss/custom/pages/_statistics.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
registerLocale("es", es);

const FiltersStatistics = () => {
    const dispatch = useDispatch();
    const { startDate, endDate } = useSelector((state: any) => ({
        startDate: state.statistics.startDate,
        endDate: state.statistics.endDate,
    }));
    const [daysAgo, setDaysAgo] = useState(7);
    const { daysFilter } = useSelector((state: any) => ({
        daysFilter: state.statistics.dateFilter,
    }));
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setTime(today.getTime() - daysAgo * 24 * 60 * 60 * 1000);

    const [showSinceUntilDate, setShowSinceUntilDate] = useState(false);

    useEffect(() => {
        if (daysFilter === "custom") {
            setShowSinceUntilDate(true);
        } else {
            setShowSinceUntilDate(false);
        }
    }, [daysFilter]);

    return (
        <React.Fragment>
            <Row className="mb-4 " style={{ display: "flex", justifyContent: "right" }}>
                <Col className="statistics1" sm={4}>
                    <Input
                        type="select"
                        className="form-select"
                        onChange={e => dispatch(dateFilter(e.currentTarget.value))}
                        defaultValue={daysFilter}>
                        <option value={6}>7 días</option>
                        <option value={14}>15 días</option>
                        <option value={29}>30 días</option>
                        <option value={"custom"}>Personalizado</option>
                    </Input>
                </Col>
                {showSinceUntilDate ? (
                    // <div className="custom-date-picker">
                    <>
                        <Col sm={2} className="custom-date-picker">
                            <DatePicker
                                className="form-control d-block"
                                placeholderText="Desde"
                                locale="es"
                                name="since"
                                dateFormat="dd/MM/yyyy"
                                selected={new Date(startDate)}
                                onChange={(newValue: any) => {

                                    dispatch(setStartDate(newValue));
                                }}
                            />
                        </Col>
                        <Col sm={2} className="custom-date-picker">
                            <DatePicker
                                className="form-control d-block"
                                placeholderText="Hasta"
                                locale="es"
                                name="until"
                                dateFormat="dd/MM/yyyy"
                                selected={new Date(endDate)}
                                minDate={new Date(startDate)}
                                onChange={(newValue: any) => {
                                    dispatch(setEndDate(newValue));
                                }}
                            />
                        </Col>
                    </>
                ) : (
                    // </div>
                    <></>
                )}
            </Row>
        </React.Fragment>
    );
};

export default FiltersStatistics;
