import React, { useState, useEffect } from "react";
import { Card, CardBody, Input, Label } from "reactstrap";
import "src/assets/scss/custom/components/_acceptCard.scss";
import AllowLinksChat from "../Campaigns/AllowLinksChat";

type Props = { value?: any; setValue?: any; tour?: boolean };

const AcceptCampaignSelect = (props: Props) => {
    const setAutomatic = () => props.setValue(1);
    const setManual = () => props.setValue(0);


    return (
        <div>
            {props.tour ? (
                <h4 style={{ color: "#fff", marginBottom: "20px" }}>Modo de aceptación</h4>
            ) : (
                <h5 className={`font-size-16 `}>Campañas: Modo de aceptación</h5>
            )}
            <div className={`option-cards-container ${props.tour && "justify-content-center"}`}>
                <Card
                    style={{
                        minWidth: "fit-content",
                        cursor: "pointer",
                    }}
                    className={`${props.value === 0 ? "activeSelection" : ""}`}>
                    <CardBody className="ft" onClick={setManual}>
                        <div className="ft">
                            <div className="d-flex gap-2 align-items-center">
                                <Input
                                    type="radio"
                                    name="campaignMode"
                                    className="m-0"
                                    readOnly
                                    checked={props.value === 0}></Input>
                                <Label className="m-0" style={{ cursor: "pointer" }}>
                                    Modo manual
                                </Label>
                            </div>
                            {props.tour ? (
                                <div
                                    style={{ marginLeft: "25px", textAlign: "left" }}
                                    className="accept-text">
                                    Tu decides que campañas se visualizaran en el streaming
                                </div>
                            ) : (
                                <span className="accept-text">
                                    Tu decides que campañas se visualizaran en el streaming
                                </span>
                            )}
                        </div>
                    </CardBody>
                </Card>
                <Card
                    style={{
                        minWidth: "fit-content",
                        cursor: "pointer",
                    }}
                    className={`${props.value === 1 ? "activeSelection" : ""}`}
                    onClick={setAutomatic}>
                    <CardBody className="ft">
                        <div className="ft">
                            <div className="d-flex gap-2 align-items-center">
                                <Input
                                    readOnly
                                    type="radio"
                                    name="campaignMode"
                                    className="m-0"
                                    checked={props.value === 1}></Input>
                                <Label style={{ cursor: "pointer" }} className="m-0">
                                    Modo automatico
                                </Label>
                            </div>
                            {props.tour ? (
                                <div
                                    style={{ marginLeft: "25px", textAlign: "left" }}
                                    className="accept-text">
                                    Todas las campañas propuestas se visualizarán en tu streaming
                                    sin necesidad de que te suscribas
                                </div>
                            ) : (
                                <span className="accept-text">
                                    Todas las campañas propuestas se visualizarán en tu streaming
                                    sin necesidad de que te suscribas
                                </span>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default AcceptCampaignSelect;
