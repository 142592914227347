import { createSlice } from "@reduxjs/toolkit";

interface WalletStateType {
    tableFilterMode: any;
    dateFilter: any;
    status: any;
    validated: string
}
export const initialState: WalletStateType = {
    tableFilterMode: "Campañas",
    dateFilter: 365,
    status: "",
    validated: ""
};

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        walletTableFilterMode(state, action) {
            state.tableFilterMode = action.payload;
        },
        dateFilter(state, action) {
            state.dateFilter = action.payload;
        },
        statusUpdate(state, action) {
            state.status = action.payload;
        },
        validate(state,action){
            state.validated = action.payload
        }
    },
});

export const { walletTableFilterMode, dateFilter, statusUpdate,validate } = walletSlice.actions;

export default walletSlice.reducer;
