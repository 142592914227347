import { ceil } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,

    Spinner,
    Badge,
} from "reactstrap";
import { COLORS } from "src/utils/COLORS";
import NoInfoCard from "../NoInfoCard/NoInfoCard";


const getType = (status: string) => {
    switch (status) {
        case "streamer_cpm":
            return "Ganancia";
        case "streamer_withdraw":
            return "Retiro";
        default:
            return ""
    }
}

const WalletActivityTable = (props: any) => {
    const [firstPage, setFirstPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [activitiesCurrentPage, setActivitiesCurrentPage] = useState(false);
    const [noActivities, setNoActivities] = useState(false)

    useEffect(() => {
        const numberActivities = props.activities ? props.activities.length : 0;
        const sortedArray = props.activities?.sort((a: any, b: any) => {
            const A: any = new Date(a.date)
            const B: any = new Date(b.date)
            return A - B
        })
        setNoActivities(!numberActivities)
        const quantityPages = ceil(numberActivities / recordsPerPage);
        setLastPage(quantityPages === 0 ? 1 : quantityPages);
        const offset = (page - 1) * recordsPerPage;
        const limit = offset + recordsPerPage;

        setActivitiesCurrentPage(props.activities ? sortedArray.slice(offset, limit) : []);
    }, [props.activities, page]);
    const isWithdraw = (type: string) => type === "streamer_cpm"
    const table = () => {
        return (
            <>
                <Table responsive className="table table-hover mb-4 mt-4" color="white">
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th >Campaña</th>
                            <th >Views</th>
                            <th style={{ minWidth: "100px" }}>Tipo</th>
                            {/* <th style={{ minWidth: "180px" }}>Descripción</th> */}
                            <th style={{ minWidth: "120px" }} >Importe</th>

                            {/* <th>PDF</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(activitiesCurrentPage) ? (
                            activitiesCurrentPage.map((activity: any, i: any) => {
                                return (
                                    <tr key={i} style={{ verticalAlign: "baseline" }}>
                                        <td>
                                            {moment(activity.date).format("DD/MM/YYYY HH:mm")}
                                        </td>
                                        <td>
                                            {isWithdraw(activity.type) && activity.campaign_name}
                                        </td>
                                        <td >
                                            {activity.views?.toLocaleString("es-AR")}
                                        </td>
                                        <td >
                                            <Badge
                                                className={`badge-soft-${isWithdraw(activity.type) ? "success" : "danger"} h-100`}
                                                style={{
                                                    fontSize: "medium",
                                                    boxShadow: `${isWithdraw(activity.type) ? " 0 0 5px 3px " + COLORS.badgeSuccess : ""} `,
                                                }}>
                                                {getType(activity.type)}
                                            </Badge>
                                        </td>
                                        <td>{activity.amount?.toLocaleString("es-AR")} €</td>
                                        {/* <td>
                                        ordenar por fecha 
                                        brillo rojo
                                                <i className="fas fa-arrow-down" />
                                            </td> */}
                                    </tr>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </tbody>
                </Table>
                <nav style={{ float: "right", margin: "1.5rem 0" }}>
                    <Pagination>
                        <PaginationItem
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{"<"}</PaginationLink>
                        </PaginationItem>
                        {firstPage == page ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(firstPage);
                                }}>
                                <PaginationLink>{firstPage}</PaginationLink>
                            </PaginationItem>
                        )}

                        {page - 1 <= firstPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page - 1);
                                }}>
                                <PaginationLink>{page - 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        <PaginationItem
                            active
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{page}</PaginationLink>
                        </PaginationItem>

                        {page + 1 >= lastPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page + 1);
                                }}>
                                <PaginationLink>{page + 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        {lastPage == page ? (
                            <></>
                        ) : (
                            <>
                                <PaginationItem
                                    onClick={() => {
                                        setPage(lastPage);
                                    }}>
                                    <PaginationLink>{lastPage}</PaginationLink>
                                </PaginationItem>
                            </>
                        )}

                        <PaginationItem
                            onClick={() => {
                                if (page < lastPage) {
                                    setPage(page + 1);
                                }
                            }}>
                            <PaginationLink next tag="button">
                                {">"}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem></PaginationItem>
                    </Pagination>
                </nav>
            </>
        );
    };

    return (
        <React.Fragment>
            {activitiesCurrentPage !== false ? (
                noActivities ? <NoInfoCard
                    WidgetTitle={"Todavía no tienes transacciones!"}
                    WidgetData={<>Anímate a suscribirte a alguno de nuestros patrocinios.<br />Tú decides el cuando, como y con quien!</>}
                ></NoInfoCard> :
                    table()
            ) : (
                <div className="m-4" style={{ textAlign: "center" }}>
                    <Spinner animation="border" />
                </div>
            )}
        </React.Fragment>
    );
};

export default WalletActivityTable;
