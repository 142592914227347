import Icon from "@ailibs/feather-react-ts/dist/Icon";
import React from "react";
import CountUp from "react-countup";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";


interface Props {
  WidgetData?: any;
  WidgetTitle?: any;
  color?: string;
  suffix?: string;
  icon?: any;
  decimals?: number
}

export const NewWidgetsCampaignCard = (props: Props) => {
  const { color } = props;

  const {
    layoutType,
    layoutMode
  } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
    layoutMode: state.Layout.layoutMode
  }));

  return (
    <Card style={{ minHeight: "5em", minWidth: 200 }} className=" on-hover shadow-none">
      <CardBody style={{ padding: "0.4rem", display: "flex", gap: "2rem", alignItems: "center", justifyContent: "center" }}>
        <Row style={{ width: "100%", display: "flex", flexWrap: "nowrap" }}>
          <div style={{
            // background: `${color || "rgb(29, 161, 212)"}`,
            width: "70px",
            // height: "4em",
            alignSelf: "center",
            minWidth: "4em",
            display: "flex", justifyContent: "center", alignItems: "center",
            borderRadius: "5%",
            aspectRatio: "1/1",
            position: "relative",
            overflow: "hidden",
            flexShrink: 0
          }}>

            {/* Circulos */}
            {/* <section>
              <div style={{ position: "absolute", right: "-25%", bottom: "-45%", zIndex: 0 }}>
                <div style={{
                  background: "transparent", width: "4em", height: "4em", borderRadius: "50%", position: "relative", boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)"
                }}>
                  <div style={{
                    background: "transparent",
                    width: "2.6em",
                    height: "2.6em",
                    borderRadius: "50%",
                    position: "absolute",
                    boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)"
                  }}>
                    <div style={{
                      background: "transparent",
                      width: "1em",
                      height: "1em",
                      borderRadius: "50%",
                      position: "absolute",
                      boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)"
                    }}>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ position: "absolute", right: "-50%", top: "0", zIndex: 0 }}>
                <div style={{
                  background: "transparent", width: "4em", height: "4em", borderRadius: "50%", position: "relative", boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)"
                }}>
                  <div style={{
                    background: "transparent",
                    width: "2.6em",
                    height: "2.6em",
                    borderRadius: "50%",
                    position: "absolute",
                    boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)"
                  }}>
                    <div style={{
                      background: "transparent",
                      width: "1em",
                      height: "1em",
                      borderRadius: "50%",
                      position: "absolute",
                      boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)"
                    }}>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/*  */}
            {/* <div> */}
            {props.icon ? props.icon : <Icon name="airplay" color="white"></Icon>}
            {/* </div> */}
          </div>
          <section style={{ width: 0, justifyContent: "center", alignItems: "center", flexDirection: "column", display: "flex", flexGrow: 1 }}>
            <p style={{ display: "inline-block", fontSize: "1.2rem", color: layoutMode === 'dark' ? "rgb(226, 248, 249)" : "#495057", marginBottom: 0, fontWeight: 300 }}>
              {props.WidgetTitle || "Titulo"}
            </p>
            <h3 className="font-weight-bold mb-2 d-flex align-items-center">
              <CountUp
                formattingFn={(number) => `${number.toLocaleString('es-AR')}${props.suffix || ""}`}
                start={0}
                end={props.WidgetData || 0}
                decimals={props.decimals || 0}
                duration={1}
                suffix={props.suffix || ""}
                style={{ fontSize: "1.8rem", fontWeight: "300" }}
              />
            </h3>
          </section>

        </Row>

      </CardBody>
    </Card>




    // <Card style={{ height: 120 }} className=" on-hover shadow-none">
    //   <CardBody style={{ padding: "1em 1em" }}>
    //     <Row>
    //       <Col sm={4} style={{
    //         display: "grid",
    //         padding: 0,
    //         placeContent: "center",
    //         gridTemplate:""
    //       }}>
    //         <div style={{
    //           background: "red",
    //           width: "5em",
    //           height: "5em",
    //           display: "grid", placeContent: "center",
    //           borderRadius: "5%",
    //           aspectRatio: "1/1"
    //         }}>
    //           <Icon name="airplay" color="white"></Icon>
    //         </div>
    //       </Col>
    //       <Col sm={8} style={{ paddingLeft: "1rem", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
    //         <div style={{ display: "inline-block", fontSize: "16px", color: "#e2f8f9" }}>
    //           Total disponible
    //         </div>
    //         <h3 className="font-weight-bold mb-2 d-flex align-items-center">
    //           <CountUp
    //             start={0}
    //             end={50}
    //             decimals={3}
    //             duration={1}
    //             suffix={"€"}
    //             style={{ fontSize: "1.6rem" }}
    //           />
    //         </h3>
    //       </Col>
    //     </Row>
    //   </CardBody>
    // </Card>
  );
};
