import { ceil } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
    ModalHeader,
    ModalFooter,
    Spinner,
    Row,
    Col,
} from "reactstrap";
// import * as statuses from "../../helpers/status_helper";
import DetailCampaign from "../Campaigns/DetailCampaign";
import StatusCampaign from "../Campaigns/StatusCampaign";
import { validURL } from "src/pages/Home";
import { validCampaignImage } from "src/helpers/functions_helper";
import { getWalletByCampaigns } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import NoInfoCard from "../NoInfoCard/NoInfoCard";

function shapeLocal(data: number) {
    return `${data.toFixed(2)}`.replace(".", ",");
}

const WithdrawedTable = (props: any) => {
    const [modal, setModal] = useState(false);
    const [campaignOnModal, setCampaignOnModal] = useState<any>();
    const [subscriptionOnModal, setSubscriptionOnModal] = useState<any>();

    const [firstPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [noSubscriptions, setNoSubscriptions] = useState<any>(false);

    const [subscriptionsCurrentPage, setSubscriptionsCurrentPage] = useState(false);

    const getCampaingBySubscription = (subscription: any) => {
        const campaingIdToFind = subscription.campaign_id;
        return props.campaigns
            ? props.campaigns.find((campaign: any) => campaign.campaign_id === campaingIdToFind)
            : null;
    };

    const getVirtualStatusSubscription = (statusSuscription: any, campaign: any) => {

        if (campaign?.campaign_situation === "finished"){

            return "finished"
        }

        if (new Date(campaign?.start_date) > new Date() && statusSuscription === "assigned"  ){

            return "assigned"
        }

        return 'assigned'
    };

    useEffect(() => {
        // const numberSubscriptions = props.subscriptions ? props.subscriptions.length : 0;
        getWalletByCampaigns(new ApiCore(localStorage.getItem("token"))).then(({ subscriptions }) => {
            const actives = props?.subscriptions?.filter((el: any) => {
                const earnings: Array<number> = Object.values(subscriptions[el.id][0].retired_earnings)
                return !earnings.every((el) => el === 0)
            })

            const filteredList = props.subscriptions ? actives.filter((el: any) => {
                const campaign = getCampaingBySubscription(el);
                if (campaign) { return campaign }
                else return false
            }) : false
            const arr = props.subscriptions ? props.subscriptions.filter((el: any) => {
                const campaign = getCampaingBySubscription(el);
                if (campaign) { return campaign }
                else return false

            }).length : 0
            setNoSubscriptions(!actives.length)

            const quantityPages = ceil(arr / recordsPerPage);

            setLastPage(quantityPages === 0 ? 1 : quantityPages);
            const offset = (page - 1) * recordsPerPage;
            const limit = offset + recordsPerPage;

            setSubscriptionsCurrentPage(
                props.subscriptions ? filteredList.slice(offset, limit) : false
            );
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.subscriptions, page]);

    const handleDetails = (campaign: any, subscription: any) => {
        setModal(true);
        setSubscriptionOnModal(subscription);
        setCampaignOnModal(campaign);
    };

    const table = () => {
        return (
            <>
                <Table responsive className="table table-hover mb-4 mt-4" color="white">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Campaña</th>
                            <th>Fecha fin</th>
                            <th className="text-center">Emisiones</th>
                            <th className="text-center">Views</th>
                            <th className="text-center">Ganancia</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(subscriptionsCurrentPage) && props.campaigns ? (
                            subscriptionsCurrentPage.map((subscription: any, i: any) => {

                                const campaign = getCampaingBySubscription(subscription);
                                // const status = statuses.getStatusByCode(subscription.status.name);
                                if (campaign) {
                                    return (
                                        <tr key={i} style={{ verticalAlign: "baseline" }}>
                                            <td
                                                onClick={() =>
                                                    handleDetails(campaign, subscription)
                                                }
                                                style={{ cursor: "pointer" }}>
                                                {campaign.campaign_image ? (
                                                    <img
                                                        alt="campaignimg"
                                                        style={{ width: "6vw", height: "auto", minWidth: "75px", maxHeight: 80 }}

                                                        src={validURL(campaign.campaign_image) ? campaign.campaign_image : `${process.env.REACT_APP_BASE_URL}${campaign.campaign_image}`}
                                                    >
                                                    </img>

                                                ) : (
                                                    <img
                                                        alt="campaignimg"
                                                        style={{ width: "6vw", height: "auto", minWidth: "75px" }}
                                                        src="https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"></img>
                                                )}
                                            </td>
                                            <td
                                                onClick={() =>
                                                    handleDetails(campaign, subscription)
                                                }
                                                style={{
                                                    cursor: "pointer",
                                                    minWidth: "120px",
                                                }}>
                                                {subscription.campaign.campaign_name}
                                            </td>

                                            <td>
                                                {moment(campaign.end_date)
                                                    .startOf("day")
                                                    .format("DD/MM/YYYY")}
                                            </td>
                                            <td className="text-center">
                                                {subscription.emissions}
                                            </td>
                                            <td className="text-center">{subscription.views}</td>

                                            <td
                                                className="text-center"
                                                style={{ whiteSpace: "nowrap" }}>
                                                {shapeLocal(subscription.earnings) + " €"}
                                            </td>
                                            <td></td>
                                            <td>
                                                <Button
                                                    outline
                                                    color="primary m-1"
                                                    // style={{
                                                    //     boxShadow: `0 0 7px 5px #00bfff`,
                                                    // }}
                                                    onClick={() =>
                                                        handleDetails(campaign, subscription)
                                                    }>
                                                    Detalles
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                } else {
                                    return <></>
                                }
                            })
                        ) : (
                            <></>
                        )}
                    </tbody>
                </Table>
                <nav style={{ float: "right", margin: "1.5rem 0" }}>
                    <Pagination>
                        <PaginationItem
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{"<"}</PaginationLink>
                        </PaginationItem>
                        {firstPage === page ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(firstPage);
                                }}>
                                <PaginationLink>{firstPage}</PaginationLink>
                            </PaginationItem>
                        )}

                        {page - 1 <= firstPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page - 1);
                                }}>
                                <PaginationLink>{page - 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        <PaginationItem
                            active
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{page}</PaginationLink>
                        </PaginationItem>

                        {page + 1 >= lastPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page + 1);
                                }}>
                                <PaginationLink>{page + 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        {lastPage === page ? (
                            <></>
                        ) : (
                            <>
                                <PaginationItem
                                    onClick={() => {
                                        setPage(lastPage);
                                    }}>
                                    <PaginationLink>{lastPage}</PaginationLink>
                                </PaginationItem>
                            </>
                        )}

                        <PaginationItem
                            onClick={() => {
                                if (page < lastPage) {
                                    setPage(page + 1);
                                }
                            }}>
                            <PaginationLink next tag="button">
                                {">"}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem></PaginationItem>
                    </Pagination>
                </nav>
                <Modal isOpen={modal} toggle={() => setModal(false)} className="custom">
                    <ModalHeader style={{ display: "block", verticalAlign: "middle" }}>
                        <Row>
                            <Col sm={8}>
                                <img
                                    alt={"thumb"}
                                    width="70px"
                                    src={
                                        validCampaignImage(campaignOnModal?.creativities[0]?.thumbnail_img_url)
                                    }></img>{" "}
                                {campaignOnModal?.name}
                            </Col>
                            <Col
                                style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    alignItems: "center",
                                }}
                                sm={4}>
                                { <StatusCampaign code={getVirtualStatusSubscription(subscriptionOnModal?.status, campaignOnModal)}></StatusCampaign> }
                            </Col>
                        </Row>

                        <span style={{ float: "right" }}></span>
                    </ModalHeader>
                    <DetailCampaign campaign={campaignOnModal} subscription={subscriptionOnModal} />
                    <ModalFooter style={{ display: "block" }}>
                        <Button
                            color="secondary"
                            onClick={() => setModal(false)}
                            style={{ float: "right" }}>
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    };

    return (
        <React.Fragment>
            {subscriptionsCurrentPage !== false ? (
                <>{
                    noSubscriptions ? (
                        <NoInfoCard WidgetTitle={"No tienes ninguna campaña con retiros!"} WidgetData={<><>Anímate a suscribirte a alguno de nuestros patrocinios.</><br />Tú decides el cuando, como y con quien!</>}></NoInfoCard>
                    ) : <>
                        {table()}
                    </>
                }

                </>
            ) : (
                <div className="m-4" style={{ textAlign: "center" }}>
                    <Spinner animation="border" />
                </div>
            )}
        </React.Fragment>
    );
};

export default WithdrawedTable;
