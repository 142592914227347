import React, { useEffect, useState } from "react";
// import { Button, CardBody, Col, Input, Modal, ModalFooter, Row, Spinner } from "reactstrap";
// import { AvField, AvForm } from "availity-reactstrap-validation";
import CountUp from "react-countup";
import {
    // getStreamerProfile,
    // getWalletBallance,
    // jsonResponseFromAxiosResponse,
    postWithdrawBallance,
    // putStreamerProfile,
    // updateBillingData,
} from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import WidgetBoxWallet from "./WidgetBoxWallet";
import { COLORS } from "src/utils/COLORS";
// import { badgeColor } from "../Campaigns/MyCampaignsTable";
// import { countries } from "src/common/data/countries";
// import { WidgetStatusCard } from "../Campaigns/WidgetStatusCard";
import { WidgetButtonCard } from "../Campaigns/WidgetCardButton";
import Icon from "@ailibs/feather-react-ts/dist/Icon";
import {
    DatosFacturacionIcon,
    SaldoAcumuladoIcon,
    SaldoDispIcon,
    SaldoEnProcesoDePagoIcon,
    // TipoEntidadFiscalIcon,
} from "src/assets/icons/Icons";
import { useDispatch, useSelector } from "react-redux";
// import PdfUpload from "../Inputs/PdfUpload";
import BillingDataModal from "./BillingDataModal";
import NotEnoughModal from "./NotEnoughModal";
import WithDrawModal from "./WithdrawModal";
import { statusUpdate } from "src/slices/Wallet/reducer";

// interface vatTypes {
//     company: string;
//     freelance: string;
//     natural_person: string;
// }

const vatIdentifiers: any = {
    company: "CIF/NIF",
    freelance: "NIF",
    natural_person: "DNI",
};
// const types: any = {
//     company: "Empresa",
//     freelance: "Autonomo",
//     natural_person: "Particular",
// };

const WidgetsWallet = (props: any) => {

    const [modalEditBillingData, setModalEditBillingData] = useState(false);
    const dispatch = useDispatch();

    //Streamer Billing data
    const [streamerBillingName, setStreamerBillingName] = useState<string>("");
    const [streamerBillingEmail, setStreamerBillingEmail] = useState<string>("");
    const [streamerBillingCountry, setStreamerBillingCountry] = useState<string>("");
    const [streamerBillingCity, setStreamerBillingCity] = useState<string>("");
    const [streamerBillingState, setStreamerBillingState] = useState<string>("");
    const [streamerBillingAddress, setStreamerBillingAddress] = useState<string>("");
    const [streamerBillingAddressExtra, setStreamerBillingAddressExtra] = useState<string>("");
    const [streamerBillingZipCode, setStreamerBillingZipCode] = useState<string>("");
    const [streamerBillingPhone, setStreamerBillingPhone] = useState<string>("");
    const [streamerBillingVat, setStreamerBillingVat] = useState<string>("");
    const [streamerBillingBankAccount, setStreamerBillingBankAccount] = useState<string>("");
    const [streamerBicCode, setStreamerBicCode] = useState<string>("");
    const [streamerId, setStreamerId] = useState<string>("");
    const [notEnoughisOpen, setNotEnoughIsOpen] = useState(false);
    const [whithdrawIsOpen, setWithdrawIsOpen] = useState(false);
    const [withdrawIsDisableButton, setWithdrawIsDisableButton] = useState(false);
    const [fiscalEntity, setFiscalEntity] = useState<any>("natural_person");

    const handleEditBillingData = () => {
        setModalEditBillingData(true);
    };

    const validateBillingFields = () => {
        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            const billing = streamer?.invoice;
            const streamerBillingName = billing?.legal_name;
            const streamerBillingEmail = billing?.email;
            const streamerBillingCountry = billing?.country;
            const streamerBillingCity = billing?.city;
            const streamerBillingState = billing?.state;
            const streamerBillingAddress = billing?.address;
            const streamerBillingZipCode = billing?.zip_code;
            const streamerBillingPhone = billing?.phone;
            const streamerBillingVat = billing?.vat_identifier;
            const streamerBillingBankAccount = streamer?.withdraw_method?.account_number;
            const sFiscalEntity = streamer?.invoice?.organization_type;

            const billingData = [
                streamerBillingName,
                streamerBillingEmail,
                streamerBillingCountry,
                streamerBillingCity,
                streamerBillingState,
                streamerBillingAddress,
                streamerBillingZipCode,
                streamerBillingPhone,
                streamerBillingVat,
                streamerBillingBankAccount,

                sFiscalEntity,
            ];
            return billingData.every(variable => variable !== "" && variable);
        }
        return false;
    };

    useEffect(() => {
        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            const billing = streamer?.invoice;
            setStreamerBillingName(billing?.legal_name);
            setStreamerBillingEmail(billing?.email);
            setStreamerBillingCountry(billing?.country);
            setStreamerBillingCity(billing?.city);
            setStreamerBillingState(billing?.state);
            setStreamerBillingAddress(billing?.address);
            setStreamerBillingAddressExtra(billing?.address_extra);
            setStreamerBillingZipCode(billing?.zip_code);
            setStreamerBillingPhone(billing?.phone);
            setStreamerBillingVat(billing?.vat_identifier);
            setStreamerId(streamer?.id);
            setStreamerBillingBankAccount(streamer?.withdraw_method?.account_number);
            setStreamerBicCode(streamer?.withdraw_method?.bic_code);

            setFiscalEntity(streamer?.invoice?.organization_type);
        }
    }, []);

    const [formData, setFormData] = useState({
        streamer_id: streamerId,
        legal_name: streamerBillingName,
        beneficiary: streamerBillingName,
        email: streamerBillingEmail,
        legal_identifier: streamerBillingVat,
        vat_identifier: streamerBillingVat,
        country: streamerBillingCountry,
        city: streamerBillingCity,
        state: streamerBillingState,
        address_extra: streamerBillingAddressExtra,
        address: streamerBillingAddress,
        zip_code: streamerBillingZipCode,
        organization_type: fiscalEntity,
        phone: streamerBillingPhone,
        account_number: streamerBillingBankAccount,
    });

    useEffect(() => {
        if (modalEditBillingData) {
            setFormData({
                streamer_id: streamerId,
                legal_name: streamerBillingName,
                beneficiary: streamerBillingName,
                email: streamerBillingEmail,
                legal_identifier: streamerBillingVat,
                vat_identifier: streamerBillingVat,
                country: streamerBillingCountry,
                city: streamerBillingCity,
                state: streamerBillingState,
                address_extra: streamerBillingAddressExtra,
                address: streamerBillingAddress,
                zip_code: streamerBillingZipCode,
                phone: streamerBillingPhone,
                account_number: streamerBillingBankAccount,
                organization_type: fiscalEntity,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalEditBillingData]);

    const confirmarRetiro = async () => {
        // Bloqueamos el boton para que no se pueda seguir pulsando.
         setWithdrawIsDisableButton(true);
         const getProfile = localStorage.getItem("profile");
         if (getProfile) {
             const obj = JSON.parse(getProfile);
             const streamer = obj.streamers?.[0];
             const currency = streamer.currency_iso;
             try {
                 await postWithdrawBallance(new ApiCore(localStorage.getItem("token")), {
                     streamer_id: localStorage.getItem("streamer_id"),
                     balance: `${props.availableBalance}`,
                     currency,
                 });
                 dispatch(statusUpdate(Math.random().toString(36).slice(2)));
                 props.success(true);
             } catch (error) {
                 props.error(true);
             }
         }

        toggle();
    };


    const toggle = () => setWithdrawIsOpen(!whithdrawIsOpen);
    const toggleNotEnough = () => setNotEnoughIsOpen(!notEnoughisOpen);

    const handleWhitdraw = () => {
        if (props.availableBalance < 20) {
            return toggleNotEnough();
        }
        if (!validateBillingFields()) {
            handleEditBillingData();
            return;
        }
        return toggle();
    };

    const { layoutMode } = useSelector((state: any) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    return (
        <React.Fragment>
            <NotEnoughModal isOpen={notEnoughisOpen} toggle={toggleNotEnough} />
            <WithDrawModal
                isOpen={whithdrawIsOpen}
                isDisableConfirm={withdrawIsDisableButton}
                toggle={toggle}
                onConfirm={confirmarRetiro}
                availableAmount={props.availableBalance}
            />
            <div
                style={{ marginTop: "1rem", gap: "0.6rem" }}
                className="container-widgets-wallet wallet1">
                <WidgetButtonCard
                    className="wallet-box"
                    color="#668A03"
                    icon={<SaldoDispIcon />}
                    buttonLabel="Cobrar"
                    buttonIcon={
                        <svg
                            onClick={() => handleWhitdraw()}
                            style={{
                                color: layoutMode === "dark" ? "white" : "#038edc",
                                display: "grid",
                                placeContent: "center",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-currency-euro"
                            viewBox="0 0 16 16">
                            <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                        </svg>
                    }
                    WidgetData={
                        <CountUp
                            start={0}
                            end={props.availableBalance}
                            decimals={2}
                            suffix={"€"}
                            formattingFn={number => `${number.toLocaleString("es-AR")}€`}
                            duration={1}
                            style={{ fontSize: "1.7rem", fontWeight: "300" }}
                        />
                    }
                />
                <WidgetBoxWallet
                    icon={<SaldoAcumuladoIcon />}
                    style={{
                        border: `1px solid ${COLORS.bootstrapBlue}`,
                    }}
                    title={"Saldo acumulado"}
                    color="#405601 ">
                    <h5
                        className="font-weight-bold align-items-end d-flex align-items-end  flex-row-reverse"
                        style={{ justifyContent: "left" }}>
                        <CountUp
                            start={0}
                            end={props.accumulatedBalance}
                            decimals={2}
                            suffix={"€"}
                            duration={1}
                            formattingFn={number => `${number.toLocaleString("es-AR")}€`}
                            style={{ fontSize: "1.7rem", fontWeight: "300" }}
                        />
                    </h5>
                </WidgetBoxWallet>
                <WidgetBoxWallet
                    icon={<SaldoEnProcesoDePagoIcon />}
                    style={{
                        border: `1px solid ${COLORS.bootstrapBlue}`,
                    }}
                    title={"Saldo en proceso de pago"}
                    color="rgb(246, 107, 78) "
                    titleFontSize="1.1rem">
                    <h5
                        className="font-weight-bold align-items-end d-flex align-items-end  flex-row-reverse"
                        style={{ justifyContent: "left" }}>
                        <CountUp
                            start={0}
                            end={props.pendingBalance}
                            decimals={2}
                            suffix={"€"}
                            duration={1}
                            formattingFn={number => `${number.toLocaleString("es-AR")}€`}
                            style={{ fontSize: "1.8rem", fontWeight: "300" }}
                        />
                    </h5>
                </WidgetBoxWallet>

                <WidgetButtonCard
                    icon={<DatosFacturacionIcon />}
                    color="#d7e907"
                    WidgetTitle={"Datos de facturacion"}
                    buttonLabel="Editar"
                    buttonIcon={
                        <Icon
                            onClick={() => handleEditBillingData()}
                            name="edit"
                            color={layoutMode === "dark" ? "white" : "#038edc"}
                        />
                    }>
                    {" "}
                </WidgetButtonCard>
            </div>
            {/* {modalBillingData()} */}
            <BillingDataModal open={modalEditBillingData} setOpen={setModalEditBillingData} />
        </React.Fragment>
    );
};

export default WidgetsWallet;
