import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Alert, Container, } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { registerUser } from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import logoDark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { useHistory } from "react-router";
import TermsAndConditions from "src/components/Modal/termsAndConds";

const Register = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [checked, setChecked] = useState(false)
    const [password, setPassword] = useState();
    const [termsOpen, setTermsOpen] = useState(false)
    const toggleTerms = () => {
        setTermsOpen(!termsOpen)
    }

    const { user, registrationError } = useSelector((state: any) => ({
        user: state.register.user,
        registrationError: state.register.registrationError,
        loading: state.register.loading,
    }));

    // handleValidSubmit
    const handleValidSubmit = (values: any) => {
        dispatch(registerUser(values, history));
    };

    const equalToPassword = (value: any, ctx: any) => {
        if (ctx.password !== ctx.confirmPassword) {
            return "No coinciden las contraseña";
        }
        return true;
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Registro</title>
            </MetaTags>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay bg-white"></div>
                <Container>
                    <TermsAndConditions open={termsOpen} toggleModal={toggleTerms} />
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={4}>
                                <div className="py-5">
                                    <div className="mb-4 mb-md-5">
                                        <Link to="/" className="d-block auth-logo text-center">
                                            <img
                                                src={logoDark}
                                                alt=""
                                                height="40"
                                                className="auth-logo-dark"
                                            />
                                            <img
                                                src={logolight}
                                                alt=""
                                                height="40"
                                                className="auth-logo-light"
                                            />
                                        </Link>
                                    </div>
                                    <div className="text-center mb-4">
                                        <h5 className="">Registro</h5>
                                    </div>
                                    <AvForm
                                        className="needs-validation custom-form mt-4 pt-2"
                                        onValidSubmit={(e: any, v: any) => {
                                            handleValidSubmit(v);
                                        }}>
                                        {user && user ? (
                                            <Alert color="success">
                                                Registro de usuario exitoso
                                            </Alert>
                                        ) : null}

                                        {registrationError && registrationError ? (
                                            <Alert color="danger">{registrationError}</Alert>
                                        ) : null}

                                        <div className="form-floating form-floating-custom mb-3 light">
                                            <AvField
                                                name="name"
                                                label="Nombre de usuario"
                                                type="text"
                                                required
                                                errorMessage="Completar información"
                                                placeholder="Ingresar nombre de usuario"
                                            />
                                        </div>

                                        <div className="form-floating form-floating-custom mb-3 light">
                                            <AvField
                                                id="email"
                                                name="email"
                                                label="Mail"
                                                className="form-control"
                                                placeholder="Ingresar mail"
                                                type="email"
                                                required
                                                errorMessage="Completar información"
                                            />
                                        </div>

                                        <div className="form-floating form-floating-custom mb-3 light">
                                            <AvField
                                                name="password"
                                                label="Contraseña"
                                                type="password"
                                                required
                                                errorMessage="Completar información"
                                                onChange={(e: any) => {
                                                    setPassword(e.target.value);
                                                }}
                                                placeholder="Ingresar contraseña"
                                            />
                                        </div>

                                        <div className="form-floating form-floating-custom mb-3 light">
                                            <AvField
                                                name="confirmPassword"
                                                label="Confirmar contraseña"
                                                type="password"
                                                required
                                                validate={{ myValidation: equalToPassword }}
                                                errorMessage="Completar información"
                                                placeholder="Confirmar contraseña"
                                            />
                                        </div>
                                        <div className="form-check form-check-info font-size-16">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="accept-terms"
                                                checked={checked}
                                                onClick={() => setChecked(!checked)}
                                            />
                                            <label
                                                className="form-check-label font-size-14 light"
                                                htmlFor="accept-terms"
                                            >

                                                <Link
                                                    style={{
                                                        color: "white",
                                                        borderBottom: "1px solid white",
                                                    }}
                                                    to="/terms-conditions-">
                                                    Acepto los términos
                                                </Link>{" "}
                                                y{" "}
                                                <Link
                                                    style={{
                                                        color: "white",
                                                        borderBottom: "1px solid white",
                                                    }}
                                                    to="#">
                                                    condiciones legales
                                                </Link>{" "}
                                                de 014 Live!
                                            </label>
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-info w-100" disabled={!checked} type="submit">
                                                Registrar
                                            </button>
                                        </div>
                                    </AvForm>

                                    <div className="mt-5 text-center">
                                        <p>
                                            {" "}
                                            Ya tenes cuenta?{" "}
                                            <Link
                                                to="/login"
                                                className="fw-medium text-decoration-underline">
                                                {" "}
                                                Login{" "}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Register;
