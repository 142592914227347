import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


interface ModalDarBajaProps {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
}

function UnsubscribeModal({ isOpen, toggle, onConfirm }: ModalDarBajaProps) {

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Baja</ModalHeader>
      <ModalBody>
        ¿Estás seguro que deseas darte de baja?
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}
        <Button color="primary" onClick={onConfirm}>Confirmar</Button>
      </ModalFooter>
    </Modal>
  );
}

export default UnsubscribeModal;