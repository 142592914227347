import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Row, Col } from "reactstrap";
import Dots from "./Dots";
import NextButton from "./NextButton";
import PrevButton from "./PrevButton";
import "./slider.css";

type Props = {
    slides: any[];
    children: (props: any) => JSX.Element;
    visibleItemsNumber?: number;
};

const Slider = React.memo<Props>(({ slides, children, visibleItemsNumber = 3 }) => {
    const [start, setStart] = useState(0);
    const maxWidthPaddingResponsive = window.innerWidth > 1100;
    const isControlsVisible = slides.length > visibleItemsNumber;
    const [timeoutId, setTimeoutId] = useState<any>(null);
    const [fadeInProps, setFadeInProps] = useSpring(() => ({ opacity: 1, minWidth: "100%" }));

    const maxWidthResponsiveCol = 12 / visibleItemsNumber;
    const startTimeout = () => {
        const id = setTimeout(() => {
            onNextClick();
        }, 3000);
        setTimeoutId(id);
    };

    const stopTimeout = () => {
        clearTimeout(timeoutId);
        setTimeoutId(null);
    };

    const onNextClick = () => {
        setStart(start + 1 >= slides.length ? 0 : start + 1);
    };

    const onPrevClick = () => {
        setStart(start - 1 >= 0 ? start - 1 : slides.length - 1);
    };

    useEffect(() => {
        startTimeout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFadeInProps({
            config: { duration: 1000 },
            from: { opacity: 0 },
            to: { opacity: 1 },
            delay: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onNextClick]);

    const visibleItems = isControlsVisible
        ? slides
            .concat(slides.slice(0, visibleItemsNumber))
            .slice(start, start + visibleItemsNumber)
        : slides;

    return (
        <div className="w-100 d-flex flex-column justify-content-center align-items-center ">
            <div
                style={{ position: "relative" }}
                className="d-flex w-100 align-items-center justify-content-center">
                <PrevButton
                    style={
                        maxWidthPaddingResponsive
                            ? { position: "absolute", left: "-80px", height: "70px" }
                            : {
                                position: "absolute",
                                left: "10px",
                                height: "40px",
                                width: "40px",
                                borderRadius: "100%",
                                backgroundColor: "#4f57f13d",
                                zIndex: 100,
                            }
                    }
                    color={"white"}
                    onBlur={() => stopTimeout()}
                    onClick={() => onPrevClick()}
                    className={"navButtons"}
                />

                <Row style={{}} className="d-flex  align-items-stretch justify-content-center">
                    {visibleItems.slice(0, visibleItemsNumber - 1).map((slide: any, index) => (
                        <Col className="d-flex" sm={maxWidthResponsiveCol} key={index}>
                            {children ? children(slide) : null}
                        </Col>
                    ))}
                    <Col className="d-flex align-items-stretch" sm={maxWidthResponsiveCol}>
                        <animated.div style={fadeInProps} className="d-flex align-items-stretch">
                            {children(visibleItems[visibleItemsNumber - 1])}
                        </animated.div>
                    </Col>
                </Row>

                <NextButton
                    style={
                        maxWidthPaddingResponsive
                            ? { position: "absolute", right: "-80px", height: "70px" }
                            : {
                                position: "absolute",
                                right: "10px",
                                height: "40px",
                                width: "40px",
                                borderRadius: "100%",
                                backgroundColor: "#4f57f13d",
                                zIndex: 100,
                            }
                    }
                    color={"white"}
                    onBlur={() => stopTimeout()}
                    onClick={() => onNextClick()}
                    className={"navButtons"}
                />
            </div>

            <div className={"dotsControls"}>
                <Dots
                    onBlur={() => stopTimeout()}
                    items={slides.length}
                    active={start}
                    onClick={(active: number) => setStart(active)}
                />
            </div>
        </div>
    );
});
export default Slider;
