export const dateFormatter = (date: Date = new Date()): string => {
    const fecha = date;
    const anio = fecha.getFullYear().toString().padStart(4, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const dia = fecha.getDate().toString().padStart(2, "0");
    const hora = fecha.getHours().toString().padStart(2, "0");
    const minuto = fecha.getMinutes().toString().padStart(2, "0");
    const segundo = fecha.getSeconds().toString().padStart(2, "0");
    const fechaFormateada = `${anio}-${mes}-${dia} ${hora}:${minuto}:${segundo}`;
    return fechaFormateada;
};

export const daysAgo = (daysAgo: number): Date => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setTime(today.getTime() - daysAgo * 24 * 60 * 60 * 1000);
    return sevenDaysAgo;
};

export const daysAgoFormatted = (daysAgoInt: number = 6): string => {
    return dateFormatter(daysAgo(daysAgoInt));
};

export const validCampaignImage = (image: string) => {
    return validURL(image)
        ? image
        : `${process.env.REACT_APP_BASE_URL}${image}`;
};
export function validURL(str: any) {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
}
