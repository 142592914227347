import { createSlice } from "@reduxjs/toolkit";

const altTourSlice = createSlice({
    name: "altTour",
    initialState: {
        runTour: false,
        currentStep: 0,
    },
    reducers: {
        setRunTour(state, action) {
            state.runTour = action.payload;
        },
        setStep(state, action) {
            state.currentStep = action.payload;
        },
    },
});

export const { setRunTour, setStep } = altTourSlice.actions;

export default altTourSlice.reducer;
