import axios from "axios";
import { use } from "i18next";
import * as statuses from "../../helpers/status_helper";
import { validURL } from "src/pages/Home";
import { ceil } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Table, Col, Card, Button, Row, Input, Container, FormGroup, Label, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Spinner } from "reactstrap";
import { validCampaignImage } from "src/helpers/functions_helper";
import { getStatusByCode } from "src/helpers/status_helper";
import { getAllCampaigns } from "src/slices/Campaigns/thunk";
import StatusCampaign from "./StatusCampaign";
import DetailCampaign from "./DetailCampaign";
import FilterHistorical from "./HistoricalDateFilter";
import NoInfoCard from "../NoInfoCard/NoInfoCard";

const HistoricalCampaignsTable = (props: any) => {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false);
    const [campaignOnModal, setCampaignOnModal] = useState<any>(false);
    const [subscriptionOnModal, setSubscriptionOnModal] = useState<any>(false);
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [modalConfirmationTitle, setModalConfirmationTitle] = useState('');
    const [modalConfirmationBody, setModalConfirmationBody] = useState('');
    const [subs, setSubs] = useState(false)

    const [page, setPage] = useState(1);
    const [numberPages, setNumberPages] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [campaignsCurrentPage, setCampaignsCurrentPage] = useState([])
    const [firstPage, setFirstPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [noCampaigns, setNoCampaigns] = useState(false);
    const [updating, setUpdating] = useState(false);



    const getSubscriptionByCampaign = (campaing: any) => {
        const subscriptionIdToFind = campaing.subscription_id;

        return props.subscriptions
            ? props.subscriptions.find((sub: any) => sub.id == subscriptionIdToFind)
            : null;
    };


    // useEffect(() => {
    //     const numberCampaigns = props.campaigns ? props.campaigns.length : 0;
    //     setNumberPages(ceil(numberCampaigns / recordsPerPage))
    //     const offset = (page - 1) * recordsPerPage;
    //     const limit = offset + (recordsPerPage)

    //     setCampaignsCurrentPage(props.campaigns ?
    //         props.campaigns.slice(offset, limit) :
    //         [])


    // }, [props.campaigns, page])
    useEffect(() => {
        dispatch(getAllCampaigns())

    }, [])
    useEffect(() => {
        const FilteredCampaigns = props.campaigns ? props.campaigns.map((campaign: any) => {
            const subscription = getSubscriptionByCampaign(campaign);
            if (subscription) {
                return subscription
            }
        }).filter((el: any) => el !== undefined) : false
        setNoCampaigns(!FilteredCampaigns.length)
        const numberCampaigns = FilteredCampaigns ? FilteredCampaigns.length : 0;
        const quantityPages = ceil(numberCampaigns / recordsPerPage);
        setLastPage(quantityPages);
        const offset = (page - 1) * recordsPerPage;
        const limit = offset + recordsPerPage;

        setCampaignsCurrentPage(props.campaigns ? props.campaigns.slice(offset, limit) : false);
    }, [props.campaigns, page, props.subscriptions]);



    const handleDetails = (campaign: any, subscription: any) => {
        setModal(true);
        setCampaignOnModal(campaign);
        setSubscriptionOnModal(subscription);
    }

    const table = () => {

        return (
            <>
                <Table className="table table-hover mb-4 mt-4 " responsive color="white" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th className="text-center"></th>
                            <th className="text-start"  >Nombre campaña</th>
                            <th className="text-center"  >Visitas</th>
                            <th className="text-center" >Ganancias</th>
                            <th className="text-center" >Emisiones</th>
                            <th className="text-center" >CTR %</th>
                            <th className="text-center" >Fecha fin</th>
                            <th ></th>
                        </tr>
                    </thead>
                    <tbody style={{ width: "100%" }}>
                        {Array.isArray(campaignsCurrentPage) ? (
                            campaignsCurrentPage.map((sub: any, i: any) => {
                                const subscription = getSubscriptionByCampaign(sub);
                                if (subscription && (sub.campaign_situation === "finished")) {
                                    const status = statuses.getStatusByCode(
                                        subscription.status.name
                                    );
                                    return (
                                        <tr key={i} style={{ verticalAlign: "baseline" }}>
                                            <td
                                                onClick={() =>
                                                    handleDetails(sub, subscription)
                                                }
                                                style={{ cursor: "pointer" }}>
                                                {sub.campaign_image ? (
                                                    <img
                                                        style={{ width: "6vw", height: "auto", objectFit: 'cover', minWidth: "75px", maxHeight: 80 }}
                                                        alt="campaignImg"
                                                        src={validURL(sub.campaign_image) ? sub.campaign_image : `${process.env.REACT_APP_BASE_URL}${sub.campaign_image}`}></img>
                                                ) : (
                                                    <img
                                                        style={{ width: "6vw", height: "auto", minWidth: "75px" }}
                                                        alt="campaignImg"
                                                        src="https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"></img>
                                                )}{" "}
                                            </td>
                                            <td
                                                className="text-start"
                                                onClick={() =>
                                                    handleDetails(sub, subscription)
                                                }
                                                style={{ cursor: "pointer" }}>
                                                {sub.name}
                                            </td>
                                            <td className="text-center">{subscription.views.toLocaleString("es-AR")}</td>
                                            <td className="text-center">
                                                {subscription.earnings.toLocaleString("es-AR") + " €"}
                                            </td>
                                            <td className="text-center">
                                                {subscription.emissions.toLocaleString("es-AR")}
                                            </td>
                                            <td className="text-center">
                                                {subscription?.ctr.toLocaleString("es-AR")}
                                            </td>
                                            <td className="text-center">
                                                {moment(sub.end_date)
                                                    .startOf("day")
                                                    .format("DD/MM/YYYY")}
                                            </td>
                                            <td>
                                                <Button
                                                    outline
                                                    color="primary m-1"
                                                    style={{
                                                        // boxShadow: `0 0 7px 5px #00bfff`,
                                                        fontWeight: 800,
                                                    }}
                                                    onClick={() =>
                                                        handleDetails(sub, subscription)
                                                    }>
                                                    Detalles
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                }
                            })
                        ) : (
                            <></>
                        )}
                    </tbody>
                </Table>
                <nav style={{ float: "right", margin: "1.5rem 0" }}>
                    <Pagination>
                        <PaginationItem
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{"<"}</PaginationLink>
                        </PaginationItem>
                        {firstPage == page ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(firstPage);
                                }}>
                                <PaginationLink>{firstPage}</PaginationLink>
                            </PaginationItem>
                        )}

                        {page - 1 <= firstPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page - 1);
                                }}>
                                <PaginationLink>{page - 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        <PaginationItem
                            active
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{page}</PaginationLink>
                        </PaginationItem>

                        {page + 1 >= lastPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page + 1);
                                }}>
                                <PaginationLink>{page + 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        {lastPage == page ? (
                            <></>
                        ) : (
                            <>
                                <PaginationItem
                                    onClick={() => {
                                        setPage(lastPage);
                                    }}>
                                    <PaginationLink>{lastPage}</PaginationLink>
                                </PaginationItem>
                            </>
                        )}

                        <PaginationItem
                            onClick={() => {
                                if (page < lastPage) {
                                    setPage(page + 1);
                                }
                            }}>
                            <PaginationLink next tag="button">
                                {">"}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem></PaginationItem>
                    </Pagination>
                </nav>
                <Modal
                    className="custom"
                    centered={true}
                    isOpen={modal}
                    toggle={() => {
                        // if (!updating) setModal(false);
                    }}>
                    <div>

                        <ModalHeader style={{ display: "block", verticalAlign: "middle" }}>
                            <Row>
                                <Col sm={8}>
                                    {campaignOnModal?.name}{" "}
                                </Col>
                                <Col
                                    sm={4}
                                    style={{
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                    }}>

                                    <StatusCampaign code={subscriptionOnModal?.status?.name}></StatusCampaign>
                                </Col>
                            </Row>
                        </ModalHeader>
                        <ModalBody>
                            <DetailCampaign campaign={campaignOnModal} subscription={subscriptionOnModal} />
                        </ModalBody>
                    </div>


                    <ModalFooter style={{ display: "block" }}>
                        <Button
                            color="secondary"
                            onClick={() => setModal(false)}
                            style={{ float: "right" }}>
                            Cerrar
                        </Button>
                        {/* {getButtonToTransitionState()} */}
                    </ModalFooter>
                </Modal>
            </>
        );
    };


    return (
        <React.Fragment>

            <FilterHistorical handleCustomDate={props.handleCustomDate} handleDate={props.handleDate} />
            {props.noCampaignsDates ? <NoInfoCard WidgetTitle={"No hay campañas disponibles"} WidgetData={"Prueba con otras fechas"}></NoInfoCard> : <>    {!noCampaigns ? (
                <>
                    {/* <Table responsive>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}></th>
                                <th style={{ textAlign: "center" }}>Nombre campaña</th>
                                <th style={{ textAlign: "center" }}>Estado</th>
                                <th style={{ textAlign: "center" }}>Visitas</th>
                                <th style={{ textAlign: "center" }}>Ganancias</th>
                                <th style={{ textAlign: "center" }}>Emisiones</th>
                                <th style={{ textAlign: "center" }}>CTR %</th>
                                <th style={{ textAlign: "center" }}>Fecha fin</th>
                                <th style={{ textAlign: "center" }}></th>
                            </tr>
                        </thead>
                        <tbody>

                            {Array.isArray(campaignsCurrentPage) ? campaignsCurrentPage.map((campaign: any, i: any) => {
                                const subscription = getSubscriptionByCampaign(campaign);
                                if (subscription) {
                                    const status = getStatusByCode(subscription.status.name)
                                    return (<tr key={i} style={{ verticalAlign: "baseline" }}>

                                        <td style={{ textAlign: "center" }} ><img width="50px" src={campaign?.campaign_image ? validCampaignImage(campaign.campaign_image) : "https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"}></img></td>
                                        <td style={{ textAlign: "center" }}>{campaign.name}</td>

                                        <td style={{ textAlign: "center" }}>{new Date(campaign.start_date) > new Date() && status?.original === "assigned" ? (
                                            <StatusCampaign code={"prepared"} name={campaign.name}></StatusCampaign>) : <StatusCampaign code={status?.original} name={campaign.name}></StatusCampaign>}</td>
                                        <td style={{ textAlign: "center" }}>{subscription.views}</td>
                                        <td style={{ textAlign: "center" }}>{subscription.earnings + " €"}</td>
                                        <td style={{ textAlign: "center" }}>{subscription.emissions}</td>
                                        <td style={{ textAlign: "center" }}>{subscription.ctr}</td>
                                        <td style={{ textAlign: "center" }}>{moment(campaign.end_date).startOf('day').format("DD/MM/YYYY")}</td>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            <Button outline color="primary m-1" onClick={() => handleDetails(campaign, subscription)} >Detalles</Button>{'   '}
                                        </td>
                                    </tr>)
                                }
                            }) : <></>}
                        </tbody>
                    </Table>
                    <nav style={{ float: "right", margin: "1.5rem 0" }}>
                        <Pagination>
                            <PaginationItem
                                onClick={() => {
                                    if (page > 1) {
                                        setPage(page - 1);
                                    }
                                }}>
                                <PaginationLink>{"<"}</PaginationLink>
                            </PaginationItem>
                            {firstPage == page ? (
                                <></>
                            ) : (
                                <PaginationItem
                                    onClick={() => {
                                        setPage(firstPage);
                                    }}>
                                    <PaginationLink>{firstPage}</PaginationLink>
                                </PaginationItem>
                            )}

                            {page - 1 <= firstPage ? (
                                <></>
                            ) : (
                                <PaginationItem
                                    onClick={() => {
                                        setPage(page - 1);
                                    }}>
                                    <PaginationLink>{page - 1}</PaginationLink>
                                </PaginationItem>
                            )}

                            <PaginationItem
                                active
                                onClick={() => {
                                    if (page > 1) {
                                        setPage(page - 1);
                                    }
                                }}>
                                <PaginationLink>{page}</PaginationLink>
                            </PaginationItem>

                            {page + 1 >= lastPage ? (
                                <></>
                            ) : (
                                <PaginationItem
                                    onClick={() => {
                                        setPage(page + 1);
                                    }}>
                                    <PaginationLink>{page + 1}</PaginationLink>
                                </PaginationItem>
                            )}

                            {lastPage == page ? (
                                <></>
                            ) : (
                                <>
                                    <PaginationItem
                                        onClick={() => {
                                            setPage(lastPage);
                                        }}>
                                        <PaginationLink>{lastPage}</PaginationLink>
                                    </PaginationItem>
                                </>
                            )}

                            <PaginationItem
                                onClick={() => {
                                    if (page < lastPage) {
                                        setPage(page + 1);
                                    }
                                }}>
                                <PaginationLink next tag="button">
                                    {">"}
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem></PaginationItem>
                        </Pagination>
                    </nav> */}
                    {table()}
                </>) : <section style={{ height: "4rem", width: "100%", display: "grid", placeItems: "center" }}><Spinner></Spinner></section>}</>}


            <Modal className="custom" isOpen={modal} toggle={() => setModal(!modal)} style={{
                // position:"absolute",
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, -50%)",
            }}
            >
                <ModalHeader style={{ justifyContent: "center" }} >
                    {campaignOnModal?.campaign?.campaign_name}
                </ModalHeader>
                <ModalBody>
                    <>
                        {/* <Row>
                        <Col lg={12} style={{ textAlign: "center" }}>
                            <img width="400px" src="https://cloudfront-us-east-1.images.arcpublishing.com/infobae/RCMPXYFXKFB5BFCCL7YFMLLICE.jpg"></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                            <div>
                                <Label>
                                    Moneda:
                                </Label>
                                <span>
                                    {' '}{campaignOnModal?.currency}
                                </span>
                            </div>

                            <div>
                                <Label>
                                    Fecha inicio:
                                </Label>
                                <span>
                                    {' '}{moment(campaignOnModal?.start_date).startOf('day').format("DD/MM/YYYY")}
                                </span>
                            </div>

                            <div>
                                <Label>
                                    Fecha fin:
                                </Label>
                                <span>
                                    {' '}{moment(campaignOnModal?.end_date).startOf('day').format("DD/MM/YYYY")}
                                </span>
                            </div>

                            <div>
                                <Label>
                                    Estado:
                                </Label>
                                <span>
                                    {' '}{subscriptionOnModal?.status?.name}
                                </span>
                            </div>
                        </Col>
                        <Col sm={6} style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                            <div>
                                <Label>
                                    Vistas:
                                </Label>
                                <span>
                                    {' '}{subscriptionOnModal?.views}
                                </span>
                            </div>


                            <div>
                                <Label>
                                    Ganancias:
                                </Label>
                                <span>
                                    {' '}{subscriptionOnModal?.earnings + " €"}
                                </span>
                            </div>


                            <div>
                                <Label>
                                    Emisiones:
                                </Label>
                                <span>
                                    {' '}{subscriptionOnModal?.emissions}
                                </span>
                            </div>

                            <div>
                                <Label>
                                    CTR %:
                                </Label>
                                <span>
                                    {' '}{subscriptionOnModal?.ctr}
                                </span>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ReactPlayer width="auto" controls={true} url='https://www.youtube.com/watch?v=6-EqlQMPmDI' />
                        </Col>
                    </Row> */}
                    </>
                    <Row>
                        {(campaignOnModal && subscriptionOnModal) ? <DetailCampaign campaign={campaignOnModal} subscription={subscriptionOnModal} /> : <></>}
                    </Row>
                </ModalBody>
                <ModalFooter style={{ display: "block" }}>
                    <Button color="secondary" onClick={() => setModal(false)} style={{ float: "right" }}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment >
    )
}

export default HistoricalCampaignsTable;