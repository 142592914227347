import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
//import images
import logolight from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";

const EmailVerification = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Verificación mail</title>
            </MetaTags>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay bg-white"></div>
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={8} xl={8}>
                                <div className="text-center py-5">
                                    <div className="mb-4 mb-md-5">
                                        <Link to="/" className="d-block auth-logo">
                                            <img
                                                src={logoDark}
                                                alt=""
                                                height="40"
                                                className="auth-logo-dark"
                                            />
                                            <img
                                                src={logolight}
                                                alt=""
                                                height="40"
                                                className="auth-logo-light"
                                            />
                                        </Link>
                                    </div>
                                    <div className="mb-4 mb-md-5">
                                        <div className="avatar-lg mx-auto">
                                            <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                                <i className="uil uil-envelope-alt"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-muted">
                                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                            <h4>
                                                Gracias por registrarte en 014 Live!.
                                            </h4>
                                            <h4>
                                                Para poder empezar a disfrutar de nuestras
                                                campañas, debes confirmar el registro. Revisa tu e-mail
                                                y sigue los pasos que te indicamos en el mismo.
                                                <br />
                                                ¡Bienvenido a tu plataforma de campañas online para tus
                                                streams!
                                            </h4>
                                        </div>
                                    </div>

                                    <div className="mt-5 text-center text-muted">
                                        <p>
                                            <Link
                                                to="/"
                                                className="fw-medium text-decoration-underline">
                                                Regresar al login
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default EmailVerification;
