import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import 'src/assets/scss/custom/components/_pdfUploadButton.scss'


const PdfUpload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile?.type === 'application/pdf') {
      setFile(selectedFile);
    } else {
      alert('Por favor, selecciona un archivo PDF.');
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Aquí puedes enviar el archivo a tu servidor
    console.log('Archivo subido:', file);
  };

  return (
    <>
      <label htmlFor="file" style={{ width: "auto" }}>
        <div className={`bootstrap-btn ${file ? "loaded" : ""}`}>{file ? file.name : "Adjuntar certificado de residencia fiscal"}</div>
        <Input type="file" name="file" id="file" accept="application/pdf" onChange={handleFileChange} style={{ display: 'none' }} />
      </label>
      {/* <Button type="submit" disabled={!file}>Subir archivo</Button> */}
    </>
  );
};

export default PdfUpload;
