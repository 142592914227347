import { Redirect } from "react-router-dom";

//Home
import Home from "../pages/Home";
//Campaigns
import Campaigns from "../pages/Campaigns";
//Wallet
import Wallet from "../pages/Wallet";
//Statistics
import Statistics from "../pages/Statistics";
//Affiliates
import Affiliates from "../pages/Affiliates";
//Help
import Help from "../pages/Help";
//Sales

import UsertSetting from "../pages/Contacts/UsertSetting";

import Terms from "../pages/Terms/index"
import PublicTerms from "../pages/PublicTerms/index"

import CookiesPolicy from "src/pages/CookiesPolicy";
import Privacy from "src/pages/Privacy";
//profile
import UserProfile from "../pages/Authentication/user-profile"

//  Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login1";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register1";
import Register2 from "../pages/AuthenticationInner/Register2";
import Signout1 from "../pages/AuthenticationInner/Signout1";
import Signout2 from "../pages/AuthenticationInner/Signout2";
import ForgotPassword1 from "../pages/AuthenticationInner/ForgotPassword1";
import ForgotPassword2 from "../pages/AuthenticationInner/ForgotPassword2";
import ResetPassword1 from "../pages/AuthenticationInner/ResetPassword1";
import ResetPassword2 from "../pages/AuthenticationInner/ResetPassword2";

import EmailVerification1 from "../pages/AuthenticationInner/EmailVerification1";
import EmailVerification2 from "../pages/AuthenticationInner/EmailVerification2";
import TwoStep1 from "../pages/AuthenticationInner/TwoStep1";
import TwoStep2 from "../pages/AuthenticationInner/TwoStep2";
import LockScreen1 from "../pages/AuthenticationInner/LockScreen1";
import LockScreen2 from "../pages/AuthenticationInner/LockScreen2";
import Thankyou1 from "../pages/AuthenticationInner/Thankyou1";
import Thankyou2 from "../pages/AuthenticationInner/Thankyou2";

//Error
import Error1 from "../pages/Error/Error1";
import Error2 from "../pages/Error/Error2";
import ErrorBasic from "../pages/Error/ErrorBasic";
import ErrorCover from "../pages/Error/ErrorCover";



//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import EmailVerification from "../pages/Authentication/EmailVerification";
import NewPassword from "../pages/Authentication/NewPassword";

//utility
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageMaintenance from "../pages/Utility/PageMaintenance";
import Profile from "../pages/Utility/Profile";





//Forms pages
import FormElements from "../pages/Forms/FormElements";
import EmissionConfiguration from "src/pages/EmissionConfiguration/EmissionConfiguration";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/", component: Home },
  { path: "/campaigns", component: Campaigns },
  // { path: "/available-campaigns", component: Campaigns },
  // { path: "/history-campaigns", component: Campaigns },
  { path: "/wallet", component: Wallet },
  { path: "/statistics", component: Statistics },
  { path: "/affiliates", component: Affiliates },
  { path: "/help", component: Help },
  { path: "/profile", component: Profile },
  { path: "/emission/edit", component: EmissionConfiguration },
  // { path: "/sales", component: Sales },
  // { path: "/dashboards-analytics", component: Analytics },
  // { path: "/calendar", component: Calendar },
  // { path: "/chat", component: Chat },
  // { path: "/kanban-board", component: KanbanBoard },
  // { path: "/file-manager", component: FileManager },
  // { path: "/inbox", component: Inbox },
  // { path: "/read-email", component: EmailRead },
  // { path: "/user-grid", component: UserGrid },
  // { path: "/user-list", component: UserList },
  // { path: "/user-settings", component: UsertSetting },
  // { path: "/gallery", component: Gallery },
  // { path: "/projects-grid", component: ProjectGrid },
  // { path: "/projects-list", component: ProjectList },
  // { path: "/projects-overview", component: ProjectOverview },
  // { path: "/projects-create", component: ProjectCreate },

  //Widgets
  // { path: "/widgets", component: Widgets },

  //UI Components
  // { path: "/ui-alerts", component: UiAlert },
  // { path: "/ui-buttons", component: UiButton },
  // { path: "/ui-cards", component: UiCard },
  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-dropdowns", component: UiDropdowns },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-modals", component: UiModal },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-offcanvas", component: UiDrawer },
  // { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-general", component: UiGeneral },
  // { path: "/ui-colors", component: UiColors },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-video", component: UiVideo },
  // { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  // { path: "/ui-utilities", component: Utilities },
  // { path: "/ui-placeholders", component: UiPlaceholders },

  //Extended pages
  // { path: "/extended-lightbox", component: UiLightbox },
  // { path: "/extended-rangeslider", component: RangeSlider },
  // { path: "/extended-sweet-alert", component: UiSweetAlert },
  // { path: "/extended-rating", component: UiRating },
  // { path: "/extended-notifications", component: Notifications },
  // { path: "/extended-swiperslider", component: Swiperslider },

  //Utility
  // { path: "/pages-starter", component: PageStarter },
  // { path: "/pages-faqs", component: PageFaqs },
  // { path: "/pages-profile", component: Profile },

  //pricing
  // { path: "/pricing-basic", component: PricingBasic },
  // { path: "/pricing-table", component: PricingCover },


  //Invoice
  // { path: "/invoices-list", component: InvoiceList },
  // { path: "/invoices-detail", component: InvoiceDetail },

  //Timeline
  // { path: "/timeline-center", component: TimeLineCenter },
  // { path: "/timeline-left", component: TimeLineLeft },
  // { path: "/timeline-horizontal", component: TimeLineHorizontal },

  // Forms pages
  { path: "/form-elements", component: FormElements },
  // { path: "/form-validation", component: FormValidation },
  // { path: "/form-advanced", component: AdvancedPlugins },
  // { path: "/form-editors", component: FormEditors },
  // { path: "/form-uploads", component: FormUpload },
  // { path: "/form-wizard", component: FormWizard },
  // { path: "/form-mask", component: FormMask },

  //tables
  // { path: "/tables-basic", component: BasicTable },
  // { path: "/tables-advanced", component: DatatableTables },

  //charts
  // { path: "/charts-area", component: ChartsArea },
  // { path: "/charts-bar", component: ChartsBar },
  // { path: "/charts-boxplot", component: ChartsBoxplot },
  // { path: "/charts-bubble", component: ChartsBubble },
  // { path: "/charts-candlestick", component: ChartsCandlestick },
  // { path: "/charts-column", component: ChartsColumn },
  // { path: "/charts-heatmap", component: ChartsHeatmap },
  // { path: "/charts-line", component: ChartsLine },
  // { path: "/charts-mixed", component: ChartsMixed },
  // { path: "/charts-pie", component: ChartsPie },
  // { path: "/charts-polararea", component: ChartsPolararea },
  // { path: "/charts-radar", component: Chartsradar },
  // { path: "/charts-radialbar", component: ChartsRadialbar },
  // { path: "/charts-scatter", component: ChartsScatter },
  // { path: "/charts-timeline", component: ChartsTimeline },
  // { path: "/charts-treemap", component: ChartsTreemap },


  //Icons
  // { path: "/icons-boxicons", component: IconBoxicons },
  // { path: "/icons-feathericons", component: Feathericons },
  // { path: "/icons-materialdesign", component: IconMaterialdesign },
  // { path: "/icons-fontawesome", component: IconFontawesome },
  // { path: "/icons-unicons", component: IconUnicon },


  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/profile/edit", component: UsertSetting },
  { path: "/cookies-policy", component: CookiesPolicy },
  { path: "/privacy", component: Privacy },
  { path: "/terms-conditions", component: Terms },
  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },
  // { path: "/maps-leaflet", component: MapsLeaflet },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/" /> }
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/verify-mail", component: EmailVerification },
  { path: "/new-password", component: NewPassword },



  // Authentication Inner
  { path: "/auth-signin-basic", component: Login1 },
  { path: "/auth-signin-cover", component: Login2 },
  { path: "/auth-signup-basic", component: Register1 },
  { path: "/auth-signup-cover", component: Register2 },
  { path: "/auth-signout-basic", component: Signout1 },
  { path: "/auth-signout-cover", component: Signout2 },
  { path: "/auth-lockscreen-basic", component: LockScreen1 },
  { path: "/auth-lockscreen-cover", component: LockScreen2 },
  { path: "/auth-forgotpassword-basic", component: ForgotPassword1 },
  { path: "/auth-forgotpassword-cover", component: ForgotPassword2 },
  { path: "/auth-resetpassword-basic", component: ResetPassword1 },
  { path: "/auth-resetpassword-cover", component: ResetPassword2 },
  { path: "/auth-emailverification-basic", component: EmailVerification1 },
  { path: "/auth-emailverification-cover", component: EmailVerification2 },
  { path: "/auth-2step-basic", component: TwoStep1 },
  { path: "/auth-2step-cover", component: TwoStep2 },
  { path: "/auth-thankyou-basic", component: Thankyou1 },
  { path: "/auth-thankyou-cover", component: Thankyou2 },

  { path: "/error-404-basic", component: Error1 },
  { path: "/error-404-cover", component: Error2 },
  { path: "/error-500-basic", component: ErrorBasic },
  { path: "/error-500-cover", component: ErrorCover },

  //utility page
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-maintenance", component: PageMaintenance },
  { path: "/terms-conditions-", component: PublicTerms },

];

export { userRoutes, authRoutes };
