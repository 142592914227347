import Icon from "@ailibs/feather-react-ts/dist/Icon";
import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "src/assets/styles/CardStatus.module.css";

interface Props {
    WidgetData?: any;
    WidgetTitle?: any;
    color?: string;
    status?: string;
}
const opacity = 0.2;
const profileData = localStorage.getItem("profile") ?? "";

export const WidgetStatusCard = (props: Props) => {
    const [copied, setCopied] = React.useState(false);
    const { color } = props;
    return (
        <Card
            className={styles.container + " campaigns2"}
            style={{
                maxHeight: "300px",
                background: `${props.status === "connected" ? "#008000" : "rgb(207, 77, 89)"}`,
                padding: 1,
            }}>
            <CardBody className={styles.card}>
                {/* <section className={styles.section}>
        </section> */}

                <div
                    className={styles.cube}
                    style={{
                        background: `${color || "white"}`,
                        width: "4.3em",
                        height: "auto",
                        maxWidth: "4em",
                        minWidth: "4em",
                        display: "grid",
                        placeContent: "center",
                        borderRadius: "5%",
                        aspectRatio: "1/1",
                        position: "relative",
                        overflow: "hidden",
                    }}>
                    {/* Circulos */}
                    <section>
                        <div
                            style={{
                                position: "absolute",
                                right: "-25%",
                                bottom: "-45%",
                                zIndex: 0,
                            }}>
                            <div
                                style={{
                                    background: "transparent",
                                    width: "4em",
                                    height: "4em",
                                    borderRadius: "50%",
                                    position: "relative",
                                    boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                                }}>
                                <div
                                    style={{
                                        background: "transparent",
                                        width: "2.6em",
                                        height: "2.6em",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)",
                                    }}>
                                    <div
                                        style={{
                                            background: "transparent",
                                            width: "1em",
                                            height: "1em",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)",
                                        }}></div>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "absolute", right: "-50%", top: "0", zIndex: 0 }}>
                            <div
                                style={{
                                    background: "transparent",
                                    width: "4em",
                                    height: "4em",
                                    borderRadius: "50%",
                                    position: "relative",
                                    boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                                }}>
                                <div
                                    style={{
                                        background: "transparent",
                                        width: "2.6em",
                                        height: "2.6em",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,

                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)",
                                    }}>
                                    <div
                                        style={{
                                            background: "transparent",
                                            width: "1em",
                                            height: "1em",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)",
                                        }}></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div style={{ zIndex: 1 }}>
                        <Icon
                            name="airplay"
                            color={`${props.status === "connected" ? "#008000" : "red"}`}></Icon>
                    </div>
                </div>

                <div className={styles.text} style={{ color: "#e2f8f9" }}>
                    <p
                        className="mb-0"
                        style={{
                            padding: 0,
                            fontSize: "clamp(13px,0.8vw,1.5rem)",
                            fontWeight: 600,
                            color: "#eff0f2",
                        }}>
                        URL EMISIÓN
                    </p>
                    <h5
                        className="font-weight-bold mb-0 d-flex align-items-center"
                        style={{ color: "#eff0f2" }}>
                        {props.status === "connected"
                            ? "Estás listo para emitir"
                            : "No estás listo para emitir"}
                    </h5>
                </div>

                <div className={styles.separator}></div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    <CopyToClipboard
                        text={
                            process.env.REACT_APP_STREAM_URL +
                            `${profileData ? JSON.parse(profileData).streamers[0]?.id : null}`
                        }>
                        <section
                            className={styles.button}
                            onClick={() => setCopied(true)}
                            style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <div
                                style={{
                                    border: "1px solid white",
                                    padding: "0.5em",
                                    borderRadius: "0.3em",
                                }}>
                                <Icon name="copy" color={copied ? "#eaeaea" : "white"} />
                            </div>
                            <p className="m-0" style={{ fontSize: "0.8em", color: "white" }}>
                                COPIAR URL
                            </p>
                        </section>
                    </CopyToClipboard>
                </div>
            </CardBody>
        </Card>
    );
};
