import React, { useEffect, useState } from "react";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";
import { withTranslation } from "react-i18next";

//Import Icons

import { Link } from "react-router-dom";

// reactstrap
import { Badge } from "reactstrap";

// Import menuDropdown
import RightSidebar from "../CommonForBoth/RightSidebar";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//import images
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
// import illustrator from "../../assets/images/illustrator/1.png";

// import slack from "../../assets/images/brands/slack.png";
// import behance from "../../assets/images/brands/behance.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import github from "../../assets/images/brands/github.png";
import { useDispatch, useSelector } from "react-redux";
import { streamerStatuses } from "src/common/data/streamerStatus";
import { getProfile, getStatus } from "src/slices/thunks";
import { useHistory } from "react-router";
import Slider from "../Slider/ToggleDarkMode";

const Header = (props: any) => {

  const history = useHistory();
  const dispatch = useDispatch();


  // const [isSearch, setSearch] = useState<boolean>(false);
  // const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [position,] = useState<string>();
  const [rightbarOpen, setrightbarOpen] = useState<boolean>(false);
  // const [megaMenu, setmegaMenu] = useState(false);
  // const [categoryMenu, setcategoryMenu] = useState(false);
  // const [profileMenu, setprofileMenu] = useState(false);
  // const [helpMenu, sethelpMenu] = useState(false);
  const [statusData, setStatusData] = useState({
    status: "",
    translate: "",
    classBadge: ""
  })



  const { status } = useSelector((state: any) => ({
    status: state.profile.status
  }));

  useEffect(() => {
    const op = streamerStatuses.find(function (e) {
      return e.status === status.status;
    })

    if (op)
      setStatusData(op);

  }, [status])

  useEffect(() => {
    dispatch(getProfile(history));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const toggleTopDrawer = () => {
  //   setPosition("right");
  //   setrightbarOpen(!rightbarOpen);
  // };

  const onDrawerClose = () => {
    setrightbarOpen(false);
  };

  const toggleLeftmenu = () => {
    var element = document.getElementById("topnav-menu-content");
    if (element) {
      element.classList.toggle("show");
    }
  };
  return (
    <React.Fragment>

      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <span className="BetaTag">Beta</span>
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="" height="28" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="" height="28" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
            onClick={() => {
              toggleLeftmenu()
            }}
          >
            <i className="fa fa-fw fa-bars" />
          </button>
        </div>

        <div className="d-flex" style={{ alignItems: "center" }}>
          <Slider />
          <div >
            <Badge className={statusData.classBadge}>
              {statusData.translate}
            </Badge>
          </div>

          <div>
            <ProfileMenu />
          </div>
        </div>
      </div>

      <ReactDrawer open={rightbarOpen} position={position} onClose={onDrawerClose}>
        <RightSidebar
          onClose={onDrawerClose}
        />
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withTranslation()(Header);