import { useEffect, useState } from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { Col, Row, Button, Spinner } from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";

import { AvField, AvForm } from "availity-reactstrap-validation";
import { CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
// import { withRouter } from "react-router";
// import { withTranslation } from "react-i18next";
// import { useHistory } from "react-router";

import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
// import moment from "moment";
import {
    getStreamerProfile,
    jsonResponseFromAxiosResponse,
    // putStreamerProfile,
    updateBillingData,
    // updateContactData,
} from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import { countries } from "src/common/data/countries";
import { profileModal } from "src/slices/Auth/Profile/reducer";
// import { validCampaignImage } from "src/pages/Campaigns";
import PdfUpload from "../Inputs/PdfUpload";
registerLocale("es", es);

const vatIdentifiers: any = {
    company: "CIF/NIF *",
    freelance: "NIF *",
    natural_person: "DNI *",
};

const TourBilling = (props: any) => {
    // const history = useHistory();
    const dispatch = useDispatch();
    // const { pfp } = useSelector((state: any) => state.profile);

    const [fiscalEntity, setFiscalEntity] = useState("Particular");

    // const [name, setname] = useState();
    // const [username, setusername] = useState();
    // const [email, setemail] = useState<string>();
    // const [photo, setphoto] = useState(pfp);

    const [saveButtonBillingData, setSaveButtonBillingData] = useState(true);
    const [updatingBillingData, setUpdatingBillingData] = useState(false);

    //Streamer Contact data
    const [streamerContactName, setStreamerContactName] = useState<string>("");
    const [streamerContactEmail, setStreamerContactEmail] = useState<string>("");
    const [streamerContactCountry, setStreamerContactCountry] = useState<string>("");
    const [streamerContactCity, setStreamerContactCity] = useState<string>("");
    const [streamerContactState, setStreamerContactState] = useState<string>("");
    const [streamerContactAddress, setStreamerContactAddress] = useState<string>("");
    const [streamerContactAddressExtra, setStreamerContactAddressExtra] = useState<string>("");
    const [streamerContactZipCode, setStreamerContactZipCode] = useState<string>("");
    const [streamerContactPhone, setStreamerContactPhone] = useState<string>("");

    //Streamer Billing data
    const [streamerBillingName, setStreamerBillingName] = useState<string>("");
    const [streamerBillingEmail, setStreamerBillingEmail] = useState<string>("");
    const [streamerBillingCountry, setStreamerBillingCountry] = useState<string>("");
    const [streamerBillingCity, setStreamerBillingCity] = useState<string>("");
    const [streamerBillingState, setStreamerBillingState] = useState<string>("");
    const [streamerBillingAddress, setStreamerBillingAddress] = useState<string>("");
    const [streamerBillingAddressExtra, setStreamerBillingAddressExtra] = useState<string>("");
    const [streamerBillingZipCode, setStreamerBillingZipCode] = useState<string>("");
    const [streamerBillingPhone, setStreamerBillingPhone] = useState<string>("");
    const [streamerBillingVat, setStreamerBillingVat] = useState<string>("");
    const [streamerBillingBankAccount, setStreamerBillingBankAccount] = useState<string>("");
    const [streamerBicCode, setStreamerBicCode] = useState<string>("");
    const [streamerId, setStreamerId] = useState<string>("");

    const [configurationTwitchModal, setConfigurationTwitchModal] = useState(false);
    const [pendingApprovalModal, setPendingApprovalModal] = useState(false);

    const { success } = useSelector((state: any) => ({
        success: state.profile.success,
    }));

    const handleValidSubmitBillingData = (event: any) => {
        event.preventDefault();
        setUpdatingBillingData(true);
        saveProfileBillingData();
    };

    const copyContactDataToBillingData = (checked: boolean) => {
        setStreamerBillingName(checked ? streamerContactName : "");
        setStreamerBillingEmail(checked ? streamerContactEmail : "");
        setStreamerBillingCountry(checked ? streamerContactCountry : "");
        setStreamerBillingCity(checked ? streamerContactCity : "");
        setStreamerBillingState(checked ? streamerContactState : "");
        setStreamerBillingAddress(checked ? streamerContactAddress : "");
        setStreamerBillingAddressExtra(checked ? streamerContactAddressExtra : "");
        setStreamerBillingZipCode(checked ? streamerContactZipCode : "");
        setStreamerBillingPhone(checked ? streamerContactPhone : "");
    };

     useEffect(() => {
        if (configurationTwitchModal || pendingApprovalModal) {
            dispatch(profileModal(true));
        } else {
            dispatch(profileModal(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationTwitchModal, pendingApprovalModal]);

    useEffect(() => {
        const getAuthUser = localStorage.getItem("authUser");
        if (getAuthUser) {
            const obj = JSON.parse(getAuthUser);
            if (obj) {
                // setname(obj.name);
                // setusername(obj.username);
                // setemail(obj.email);
                // setphoto(obj.photo);
            }
        }

        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            if (streamer) {
                const contact = streamer.contact;
                const billing = streamer.invoice;
                setStreamerContactName(contact?.fullname);
                setStreamerContactEmail(contact?.email);
                setStreamerContactCountry(contact?.country ?? "");
                setStreamerContactCity(contact?.city);
                setStreamerContactState(contact?.state);
                setStreamerContactAddress(contact?.address);
                setStreamerContactAddressExtra(contact?.address_extra);
                setStreamerContactZipCode(contact?.zip_code);
                setStreamerContactPhone(contact?.phone);
                setStreamerBillingName(billing?.legal_name);
                setStreamerBillingEmail(billing?.email);
                setStreamerBillingCountry(billing?.country);
                setStreamerBillingCity(billing?.city);
                setStreamerBillingState(billing?.state);
                setStreamerBillingAddress(billing?.address);
                setStreamerBillingAddressExtra(billing?.address_extra);
                setStreamerBillingZipCode(billing?.zip_code);
                setStreamerBillingPhone(billing?.phone);
                setStreamerBillingVat(billing?.vat_identifier);
                setStreamerBillingBankAccount(billing?.account_number);

                // setphoto(validCampaignImage(streamer.thumbnail_img_url));

                setStreamerId(streamer.id);
            } else {
                setConfigurationTwitchModal(true);
            }
        } else {
            setConfigurationTwitchModal(true);
        }
    }, [success]);

    const saveProfileBillingData = () => {
        updateBillingData(new ApiCore(localStorage.getItem("token")), {
            streamer_id: streamerId,
            legal_name: streamerBillingName,
            email: streamerBillingEmail,
            legal_identifier: streamerBillingVat,
            vat_identifier: streamerBillingVat,
            country: streamerBillingCountry,
            city: streamerBillingCity,
            state: streamerBillingState,
            address_extra: streamerBillingAddressExtra,
            address: streamerBillingAddress,
            zip_code: streamerBillingZipCode,
            phone: streamerBillingPhone,
            legal_residence_document: "",
            legal_residence_country: "",
            account_number: streamerBillingBankAccount,
            beneficiary:streamerBillingName,
            branch_address: "",
            branch_name: "",
            bic_code: "",
        }).then((data: any) => {
            if (data.message === "ok") {
                getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                    const profileJson = jsonResponseFromAxiosResponse(data);
                    localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
                    localStorage.setItem("profile", JSON.stringify(profileJson.payload));
                    setUpdatingBillingData(false);
                });
            } else {
            }
        });
    };

    return (
        <div className="card border shadow-none">
            <div className="card-header d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm">
                        <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                            03
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 d-flex justify-content-start mt-2">
                    <h4 style={{ color: "#fff" }}>Datos de Facturación</h4>
                </div>
            </div>
            <CardBody>
                <div className="form-check form-check-info font-size-16 mb-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="same-contact"
                        onClick={e => copyContactDataToBillingData(e.currentTarget.checked)}
                    />
                    <label className="form-check-label font-size-14" htmlFor="same-contact">
                        ¿Utilizar mismos datos que en contacto?
                    </label>
                </div>
                <AvForm
                    onValidSubmit={(e: any, v: any) => {
                        handleValidSubmitBillingData(e);
                    }}>
                    <Row>
                        <Col lg={6}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_legal_name"
                                    label="Nombre completo *"
                                    type="text"
                                    errorMessage="Este campo es obligatorio"
                                    required
                                    placeholder="Ingresar nombre completo"
                                    value={streamerBillingName}
                                    onChange={(e: any) => {
                                        setStreamerBillingName(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_email"
                                    label="Email *"
                                    type="text"
                                    errorMessage="Este campo es obligatorio"
                                    required
                                    placeholder="Ingresar email"
                                    value={streamerBillingEmail}
                                    onChange={(e: any) => {
                                        setStreamerBillingEmail(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    type="select"
                                    name="select"
                                    label="Tipo de entidad fiscal *"
                                    required
                                    errorMessage="Este campo es obligatorio"
                                    value={fiscalEntity?? "" }
                                    onChange={(e: any) => {
                                        setFiscalEntity(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}>
                                    <option value=""></option>
                                    <option value="natural_person">Particular</option>
                                    <option value="company">Empresa</option>
                                    <option value="freelance">Autónomo</option>
                                    onChange={(e: any) => {
                                  //  setStreamerBillingVat(e.target.value);
                                    setSaveButtonBillingData(false);
                                }}
                                </AvField>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_vat_identifier"
                                    label={
                                        vatIdentifiers[fiscalEntity] || "NIF *"
                                        // fiscalEntity === "Empresa"
                                        //     ? "CIF/NIF"
                                        //     : "DNI"
                                    }
                                    type="text"
                                    errorMessage="Este campo es obligatorio"
                                    required
                                    placeholder="Ingresar documento"
                                    value={streamerBillingVat}
                                    onChange={(e: any) => {
                                        setStreamerBillingVat(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_bank_account"
                                    label="IBAN *"
                                    errorMessage="Este campo es obligatorio"
                                    type="text"
                                    required
                                    placeholder="Ingresar código IBAN"
                                    value={streamerBillingBankAccount}
                                    onChange={(e: any) => {
                                        setStreamerBillingBankAccount(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="streamerBicCode"
                                    label={ "BIC/SWIFT "}
                                    type="text"
                                    placeholder=" "
                                    errorMessage="Este campo es obligatorio"
                                    value={streamerBicCode}
                                    onChange={(e: any) => {
                                        setStreamerBicCode(e.target.value);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_country"
                                    label="País *"
                                    type="select"
                                    required
                                    value={streamerBillingCountry}
                                    errorMessage="Este campo es obligatorio"
                                    onChange={(e: any) => {
                                        setStreamerBillingCountry(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}>
                                    <option value="">Ingresar país</option>
                                    {countries.map((country: any, index: number) => {
                                        return (
                                            <option key={country.name + index} value={country.name}>
                                                {country.name === 'Spain' ? 'España' : country.name}
                                            </option>
                                        );
                                    })}
                                </AvField>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_state"
                                    label="Provincia *"
                                    type="text"
                                    required
                                    errorMessage="Este campo es obligatorio"
                                    placeholder="Ingresar provincia"
                                    value={streamerBillingState}
                                    onChange={(e: any) => {
                                        setStreamerBillingState(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_city"
                                    label="Ciudad *"
                                    type="text"
                                    required
                                    errorMessage="Este campo es obligatorio"
                                    placeholder="Ingresar ciudad"
                                    value={streamerBillingCity}
                                    onChange={(e: any) => {
                                        setStreamerBillingCity(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_address"
                                    label="Dirección *"
                                    type="text"
                                    required
                                    errorMessage="Este campo es obligatorio"
                                    placeholder="Ingresar dirección"
                                    value={streamerBillingAddress}
                                    onChange={(e: any) => {
                                        setStreamerBillingAddress(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_address_extra"
                                    label="Información adicional"
                                    type="text"
                                    placeholder="Ingresar información adicional"
                                    value={streamerBillingAddressExtra}
                                    onChange={(e: any) => {
                                        setStreamerBillingAddressExtra(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_zip_code"
                                    label="Código postal *"
                                    type="text"
                                    required
                                    placeholder="Ingresar código postal"
                                    errorMessage="Completar información"
                                    value={streamerBillingZipCode}
                                    onChange={(e: any) => {
                                        setStreamerBillingZipCode(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="mb-3">
                                <AvField
                                    name="billing_phone"
                                    label="Teléfono *"
                                    type="text"
                                    required
                                    placeholder="Ingresar teléfono"
                                    value={streamerBillingPhone}
                                    onChange={(e: any) => {
                                        setStreamerBillingPhone(e.target.value);
                                        setSaveButtonBillingData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    {streamerBillingCountry !== "Spain" ? (
                        <Row>
                            <Col style={{ marginLeft: -12 }} lg={6}>
                                <PdfUpload />
                            </Col>
                            <Col lg={6}>
                                <Button
                                    color="primary"
                                    style={{ float: "right", marginRight: -12 }}
                                    type="submit"
                                    disabled={saveButtonBillingData || updatingBillingData}>
                                    {" "}
                                    {updatingBillingData ? (
                                        <Spinner size={"sm"} animation="border" />
                                    ) : (
                                        "Actualizar"
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Row>
                                <Col lg={6}>
                                    <Button
                                        color="primary"
                                        style={{ float: "right", marginRight: -12 }}
                                        type="submit"
                                        disabled={saveButtonBillingData || updatingBillingData}>
                                        {updatingBillingData ? (
                                            <Spinner size={"sm"} animation="border" />
                                        ) : (
                                            "Actualizar"
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </AvForm>
            </CardBody>
        </div>
    );
};

export default TourBilling;
