import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router";
import { Card, CardBody, Col, Collapse, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { BILLS_NAV } from "src/components/Wallet/WalletFiltersTable";


const Help = (props: any) => {

    const [tableMode, setTableMode] = useState("Consultas generales")
    const [generalQuestions, setGeneralQuestions] = useState(true)
    const [sponsors, setSponsors] = useState(false)
    const [revenuesAndViews, setRevenuesAndViews] = useState(false)
    const [, settwitchExtensions] = useState(false)
    const [, setGrowAndEarn] = useState(false)


    // Cada conjunto de cols corresponde a una pestaña

    //CONSULTAS GENERALES
    const [col1, setcol1] = useState(false);
    const [col2, setcol2] = useState(false);
    const [col3, setcol3] = useState(false);
    const [col4, setcol4] = useState(false);
    const [col5, setcol5] = useState(false);
    const [col6, setcol6] = useState(false);
    const [col7, setcol7] = useState(false);
    const [col8, setcol8] = useState(false);
    const [, setcol9] = useState(false);
    const [col10, setcol10] = useState(false);
    const [col11, setcol11] = useState(false);


    //Ingresos y Patrocinios
    const [col12, setcol12] = useState(false);
    const [col13, setcol13] = useState(false);
    const [col14, setcol14] = useState(false);
    const [col15, setcol15] = useState(false);
    const [col16, setcol16] = useState(false);
    const [col17, setcol17] = useState(false);
    const [col18, setcol18] = useState(false);
    const [col19, setcol19] = useState(false);
    const [col20, setcol20] = useState(false);
    const [col21, setcol21] = useState(false);
    const [col22, setcol22] = useState(false);
    const [col23, setcol23] = useState(false);
    const [col24, setcol24] = useState(false);
    const [col25, setcol25] = useState(false);


    //PAGOS Y FACTURACIÓN
    const [col26, setcol26] = useState(false);
    const [col27, setcol27] = useState(false);
    const [col28, setcol28] = useState(false);
    const [col29, setcol29] = useState(false)
    const [col30, setcol30] = useState(false)
    const [col31, setcol31] = useState(false)
    const [col32, setcol32] = useState(false)
    const [col33, setcol33] = useState(false)
    const [col34, setcol34] = useState(false)
    const [col35, setcol35] = useState(false)
    const [col36, setcol36] = useState(false)
    const [col37, setcol37] = useState(false)
    const [col38, setcol38] = useState(false)

    //EXTENSIÓN DE TWITCH
    const [col39, setcol39] = useState(false)
    const [col40, setcol40] = useState(false)
    const [col41, setcol41] = useState(false)
    const [col42, setcol42] = useState(false)
    const [col43, setcol43] = useState(false)



    //CRECE Y GANA
    const [col44, setcol44] = useState(false)
    const [col45, setcol45] = useState(false)
    const [col46, setcol46] = useState(false)
    const [col47, setcol47] = useState(false)
    const [col48, setcol48] = useState(false)
    const [col49, setcol49] = useState(false)
    const [col50, setcol50] = useState(false)
    const [col51, setcol51] = useState(false)
    const [col52, setcol52] = useState(false)
    const [col53, setcol53] = useState(false)
    const [col54, setcol54] = useState(false)


    //Arreglos para las funciones
    const generalQuestionsArray = [setcol1, setcol2, setcol3, setcol4, setcol5, setcol6, setcol7, setcol8, setcol9, setcol10, setcol11]
    const paymentsArray = [setcol12, setcol13, setcol14, setcol15, setcol16, setcol17, setcol18, setcol19, setcol20, setcol21, setcol22, setcol23, setcol24, setcol25]
    const billingAndPaymentsArray = [setcol26, setcol27, setcol28, setcol29, setcol30, setcol31, setcol32, setcol33, setcol34, setcol35, setcol36, setcol37, setcol38, setcol39, setcol40, setcol41, setcol42, setcol43]
    const twitchArray = [setcol39, setcol40, setcol41, setcol42, setcol43]
    const growAndEarnArray = [setcol44, setcol45, setcol46, setcol47, setcol48, setcol49, setcol50, setcol51, setcol52, setcol53, setcol54]

    //Recibe un array, el set de la posicion y el valor a cambiar
    const handleClick = (bulk: any, setter: any, value: boolean) => {
        bulk.forEach((element: any) => {
            element(false)
        });
        setter(!value);
    }

    const TwitchTab = () => {
        return (
            <div>
                <Row>
                    <Col xl={12} md={12}>
                        <Card className="card-h-100 border shadow-none mb-0 mt-0">
                            <CardBody className="p-4">
                                <div className="accordion accordion-flush" id="accordion-gen-ques">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingOne">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col39 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(twitchArray, setcol39, col39)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Qué es la extensión de Twitch “Tu comunidad en 014 Live!"?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col39} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                La extensión de Twitch “Tu comunidad en 014 Live!” se creó para brindarte más información sobre tus espectadores (que tienen 18 años o más) recopilando datos sobre ellos a través de Google Analytics.
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingTwo">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col40 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(twitchArray, setcol40, col40)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo puedo instalar " Tu comunidad en 014 Live!"?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col40} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                La extensión de Twitch “Tu comunidad en 014 Live!” está disponible <a href="#">aquí</a> como extensión de Twitch verificada
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingThree">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col41 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(twitchArray, setcol41, col41)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Que datos recopila "Tu comunidad en 014 Live!”?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col41} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Tu comunidad en 014 Live! recopila los siguientes datos sobre tus espectadores: país, edad, género e ID de Twitch.
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFour">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col42 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(twitchArray, setcol42, col42)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Es seguro usar la extensión de Twitch "Tu comunidad en 014 Live!”?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col42} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Si, la extensión de Twitch “Tu comunidad en 014 Live!” pasó el proceso de revisión de Twitch para aparecer en la tienda de extensiones. La extensión sigue todos los lineamientos de Twitch y es segura de usar en la plataforma.                                                </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col43 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(twitchArray, setcol43, col43)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Dónde puedo acceder a los datos recopilados?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col43} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Verás los datos recopilados por la extensión en el Panel de Control de 014Live! en la sección superior
                                                de la página bajo el apartado “Datos de transmisión”
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>



                </Row>

            </div>)
    }

    const GrowAndEarnTab = () => {
        return (
            <div>
                <Row>
                    <Col xl={12} md={12}>
                        <Card className="card-h-100 border shadow-none mb-0 mt-0">
                            <CardBody className="p-4">
                                <div className="accordion accordion-flush" id="accordion-gen-ques">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingOne">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col44 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol44, col44)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Que es Level Up?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col44} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Level Up! es un programa único de gamificación de 014Live! que introduce muchos beneficios y mayores ingresos pera los Streamers que son usuarios activos de nuestra plataforma. De esta forma, queremos recompensar a aquellos que promocionan constantemente muchas marcas diferentes disponibles en 014Live!
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingTwo">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col45 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol45, col45)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Con qué frecuencia obtengo XP?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col45} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Recompensaremos tu actividad y regularidad con puntos XP diarios.
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingThree">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col46 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol46, col46)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo y cuántos XP puedo ganar?

                                            </button>
                                        </h2>
                                        <Collapse isOpen={col46} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Hay cuatro formas de ganar puntos:
                                                <ol>
                                                    <li>
                                                        Mostrar una animación patrocinada: se te recompensa con 10 XP por cada día que muestres una animación patrocinada y consigas al menos una visualización

                                                    </li>
                                                    <li>
                                                        Rachas Calientes: completar Rachas Calientes te da 20 XP adicionales cada tres días

                                                    </li>
                                                    <li>
                                                        Bonificación por rendimiento puedes ganar entre 3 y 10 XP cada día
                                                    </li>
                                                    <li>
                                                        Actividades adicionales: participa en diversas actividades organizadas por 014Live! (por ejemplo, envíanos tus clips de patrocinios) y consigue puntos XP extra
                                                    </li>
                                                </ol>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFour">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col47 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol47, col47)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cuántos niveles hay y cuanto puedo ganar?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col47} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Cada nivel que alcances aumentará tus ganancias en un 1%. El nivel máximo será ahora 40, lo que significa que podrás aumentar tus ganancias hasta un 40% Después de alcanzarlo, podrás seguir ganando puntos de XP adicionales, que te pueden venir muy bien si te tomas un descanso del Streaming
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col48 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol48, col48)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Puedo perder XP o un nivel una vez alcanzado?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col48} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Sí. puede hacerlo si no transmite diariamente. Pierdes 4XP cada día por no hacer Streaming con 014Live!.
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col49 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol49, col49)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Qué son Rachas Calientes y cómo se consigue?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col49} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Para conseguir una Racha Caliente, debes mostrar animaciones patrocinadas durante 3 días seguidos (y conseguir al menos una visualización cada día). Cuando termines una Hot Streak, comenzará una nueva
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col50 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol50, col50)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo funciona la bonificación de rendimiento?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col50} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Cuanto más interactúe tu comunidad con el sitio web de los patrocinadores (consultando sus ofertas, haciendo clic), más cerca estarás de convertirte en un Streamer de primer nivel. Convertimos tu rango en dinero clasificando a todos los Streamers desde los que mejor rendimiento tienen hasta los que tienen las puntuaciones más bajas. Cuanto más alto sea tu rango, más puntos ganarás.
                                                <p>{"Bonificación por rendimiento [% / XP ):"}</p>
                                                <ul>
                                                    <li>Top 31-50%% de rendimiento = 3 XP.</li>
                                                    <li>Top 6-30% de rendimiento = 6 XP</li>
                                                    <li>Top 5% de rendimiento = 10 XP</li>
                                                </ul>
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col51 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol51, col51)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo puedo unirme a actividades adicionales para ganar XP extra?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col51} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                De vez en cuando, nuestro equipo puede pedirte que nos facilites alguna actividad especial interna o externa. Por ejemplo, podemos pedirte que nos envíes tus mejores clips de un patrocinio, que participes en una entrevista o que rellenes una encuesta. Cada actividad completada será recompensada con puntos adicionales, con un número de puntos adaptado al tipo de actividad
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col52 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol52, col52)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Y si no hay patrocinio disponible en mi región?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col52} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                No te preocupes por perder puntos, incluso cuando no haya patrocinios disponibles. Lo único que tienes que hacer es retransmitir todos los días con el enlace 014Live! activo en tu OBS
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col53 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol53, col53)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                Llevo mucho tiempo como Streamer, pero mantener un crecimiento constante de la audiencia es difícil, así que ¿por qué se tiene en cuenta para la bonificación de rendimiento?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col53} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Para encontrar el equilibrio en el sistema, que considera a toda la comunidad 014Live!, necesitábamos encontrar una forma de otorgar diferentes aspectos que sean beneficiosos para todos los grupos. Por lo tanto, continuaremos trabajando en el desarrollo de la bonificación por rendimiento y realizaremos los cambios adecuados en función de vuestros comentarios.
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col54 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(growAndEarnArray, setcol54, col54)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Necesito instalar la extensión de Twitch “Tu comunidad en 014Live!"?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col54} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                Necesitarás una extensión de Twitch para progresar más allá del nivel 10. El sistema detectará la extensión instalada después de que visites la sección “Sube de nivel” en el Panel de Streamer. Puede que tarde un par de horas en reflejarlo correctamente en una barra de progreso. Al instalar la extensión también obtienes acceso a estadísticas detalladas de tus espectadores y canal en la pestaña Stream Data
                                            </div>
                                        </Collapse>
                                    </div>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>



                </Row>

            </div>)
    }

    const GeneralQuestionsTab = () => {
        return (
            <div>
                <Row>
                    <Col xl={12} md={12}>
                        <Card className="card-h-100 border shadow-none mb-0 mt-0">
                            <CardBody className="p-4">
                                <div className="accordion accordion-flush" id="accordion-gen-ques">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingOne">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col1 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol1, col1)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Qué es 014 Live!?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col1} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                014 Live! es un plataforma que permite la emisión de anuncios publicitarios durante las retransmisiones en directo de pequeños, medianos y grandes streamers dándoles la oportunidad de monetizar su actividad</div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingTwo">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col2 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol2, col2)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo funciona?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col2} className="accordion-collapse">
                                            <div className="accordion-body text-muted">Ya formas parte de la plataforma 014 Live! Empezarás a recibir propuestas de patrocinios y eres libre de aceptar y de emitir anuncios de aquellos en los que quieras participar.
                                                El proceso de emisión de anuncios es un proceso totalmente automático. Para disfrutar de 014 Live! sólo necesitas <Link to='/emission/edit'>configurar tu OBS</Link> con el enlace que te proporcionamos.
                                            </div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingThree">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col3 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol3, col3)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo elijo las marcas y porqué?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col3} className="accordion-collapse">
                                            <div className="accordion-body text-muted">Nosotros nos encargamos de negociar y traer los patrocinios. Trabajamos con todas las agencias y marcas del mercado.
                                                <br />Te proponemos los patrocinios más afines a ti y tú eliges las marcas con las que quieres colaborar en función de tus intereses y tus gustos. Desde tu <Link to={"/campaigns"}>Dashboard </Link>podrás controlar y gestionar todas tus campañas.</div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFour">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col4 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol4, col4)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo se mostrará el anuncio?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col4} className="accordion-collapse">
                                            <div className="accordion-body text-muted">El anuncio se mostrará en tu directo sin interrumpir la emisión. Podrás colocarlo donde mejor te convenga y tu audiencia seguirá disfrutando de tu directo. Además aparecerá en el chat un link a la marca.</div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col5 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol5, col5)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo gano dinero?
                                            </button>
                                        </h2>
                                        <Collapse isOpen={col5} className="accordion-collapse">
                                            <div className="accordion-body text-muted">Escoges el patrocinador con el que quieras colaborar y te adhieres a su campaña. Posteriormente se mostrará su contenido en tus directos. Cada campaña tiene una tarifa determinada por cada 1000 visualizaciones del anuncio llamada CPV y cobras en función de las visualizaciones que has generado. En ocasiones, para algunas campañas se pagará por cada 100 clics en el link del chat, que en ese caso de llama CPC.<br />

                                                El streamer cobra por cada visualización. A más viewers, más visualizaciones y más ingresos, sin necesidad de llegar a un mínimo de visualizaciones o clicks, cobrarás desde el primero que generes.</div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col6 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol6, col6)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Quién puede darse de alta en 014 Live!?                                            </button>
                                        </h2>
                                        <Collapse isOpen={col6} className="accordion-collapse">
                                            <div className="accordion-body text-muted">Para asegurarnos de que eres atractivo para los patrocinadores tenemos unos criterios de aceptación basados en tu actividad y tu número de viewers.</div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col7 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol7, col7)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿En qué plataformas funciona 014 Live!?                                            </button>
                                        </h2>
                                        <Collapse isOpen={col7} className="accordion-collapse">
                                            <div className="accordion-body text-muted">Actualmente funciona en Twitch. Próximamente entrará en funcionamiento en YouTube y otras plataformas de Streaming emergentes.</div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col8 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol8, col8)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo se configura 014 Live!?                                            </button>
                                        </h2>
                                        <Collapse isOpen={col8} className="accordion-collapse">
                                            <div className="accordion-body text-muted">Para disfrutar de 014 Live! sólo necesitas configurar tu OBS con el enlace que te proporcionamos.<Link to="/emission/edit"> Aquí</Link>  te explicamos como.</div>
                                        </Collapse>
                                    </div>
                                    {/* <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col9 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol9, col9)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                Reclutar a un amigo: ¿cómo funciona?                                            </button>
                                        </h2>
                                        <Collapse isOpen={col9} className="accordion-collapse">
                                            <div className="accordion-body text-muted">
                                                <ol>
                                                    <li>Deja que tu amigo se registre a través de tu propio enlace de afiliación en tu <a href="#">página de referencia</a></li>
                                                    <li>Cuando empiecen a transmitir nuestros patrocinios y alcancen el umbral de views, tú ganarás 30 EUR.</li>
                                                    <li>Cada reclutamiento se contará después de que un Streamer reclutado haya alcanzado al menos 4000 views en sus patrocinios</li>
                                                    <li>Por cada umbral de reclutamiento que alcances, recibirás un rango especial en nuestro servidor de Discord</li>
                                                    <li>El dinero que ganes con esto se añadirá a tu próximo pago</li>
                                                    <li>Si tienes alguna pregunta, no dudes en ponerse en contacto con nosotros en nuestro <a href="#">Discord</a> o a través del correo electrónico streamers@014on.com </li>
                                                    <li>Cuando el Streamer ha llegado a 4000 vistas y no has recibido dinero por él en 7 días, por favor <a href="#">contáctanos</a></li>
                                                </ol></div>
                                        </Collapse>
                                    </div> */}
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col10 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol10, col10)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Cómo puedo contactaros?                                            </button>
                                        </h2>
                                        <Collapse isOpen={col10} className="accordion-collapse">
                                            <div className="accordion-body text-muted">Únete a nuestro <a href="#">Discord</a> o a través del correo electrónico <a href="#">streamers@014on.com</a></div>
                                        </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="gen-ques-headingFive">
                                            <button
                                                className={classnames(
                                                    "accordion-button",
                                                    { collapsed: !col11 }
                                                )}
                                                type="button"
                                                onClick={() => handleClick(generalQuestionsArray, setcol11, col11)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                ¿Los datos del Streamer están protegidos en 014 Live!?      </button>                                  </h2>
                                        <Collapse isOpen={col11} className="accordion-collapse">
                                            <div className="accordion-body text-muted">Por supuesto, todos los datos confidenciales del Streamer están seguros y protegidos por múltiples sistemas. No se utilizarán por ningún motivo.</div>
                                        </Collapse>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>



                </Row>

            </div>)
    }

    const RevenuesAndSponsorsTab = () => {
        return <>
            <Card className="card-h-100 border shadow-none mb-0">
                <CardBody className="p-4">
                    <div className="accordion accordion-flush" id="accordion-privacypolicy">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingOne">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col12 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol12, col12)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Como selecciono a un patrocinador?                                </button>
                            </h2>
                            <Collapse isOpen={col12} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Trabajamos con todas las agencias y marcas del mercado y nos encargamos de negociar y traer los patrocinios. Te propondremos los patrocinios más afines a ti y tú eres libre de seleccionar con los que quieres colaborar en función de tus intereses y tus gustos. El proceso es el siguiente:
                                    <ol>
                                        <li>Cuando haya un nuevo patrocinio disponible para ti, te avisaremos mediante un mail y te aparecerá también en tu dashboard en la sección <Link to={`/campaigns?tab=Campañas disponibles`}>“Campañas disponibles”</Link>. Ahí podrás ver los detalles de la campaña</li>
                                        <li>Escoge en el dashboard el patrocinio con el que quieras colaborar y haz clic en “Suscribirse”</li>
                                        <li>Asegúrate de tener configurado el OBS con 014 Live!</li>
                                        <li>Espera a que tu solicitud sea aprobada</li>
                                        <li>Empieza a emitir y a monetizar</li>
                                    </ol>
                                    <br />
                                    Algunos patrocinios no quieren asociarse con determinados juegos o categorías, por lo que te informaremos de la Black List en los detalles de la campaña.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingTwo">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col13 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol13, col13)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Con qué frecuencia hay nuevos patrocinios disponibles?
                                </button>
                            </h2>
                            <Collapse isOpen={col13} className="accordion-collapse">
                                <div className="accordion-body text-muted">Habrá nuevos patrocinios disponibles tan a menudo como sea posible. Estamos en contacto diario con todos los anunciantes negociando posibles patrocinios. Cuando se confirman, os lo comunicamos de inmediato.</div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col14 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol14, col14)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Cuánto tiempo se tarda en aprobar una solicitud de campaña?                                </button>
                            </h2>
                            <Collapse isOpen={col14} className="accordion-collapse">
                                <div className="accordion-body text-muted">La aprobación de participación de un Streamer en una campaña no debería tardar más de 24 horas. Te pediremos un poco de paciencia en aquellas solicitudes enviadas en fin de semana. </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col15 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol15, col15)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Cómo gano dinero?
                                </button>
                            </h2>
                            <Collapse isOpen={col15} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Escoges el patrocinador con el que quieras colaborar y te adhieres a su campaña. Casi todas las campañas tienen una tarifa determinada por cada 1000 visualizaciones del anuncio llamada CPV y cobras en función de las visualizaciones que has generado en tus Streams. En ocasiones, para algunas campañas se pagará por el número de clics que tus espectadores han generado en el link del chat,  que en ese caso de llama CPC.
                                    <br />
                                    <p>Algunas consideraciones importantes: </p>
                                    <ul style={{ listStyle: "none", marginLeft: "0" }}>
                                        <li>- En ningún caso necesitas llegar a un mínimo de visualizaciones o clicks, cobrarás desde el primero que generes.</li>
                                        <li>- Cobrarás por “Complete Views”, es decir, por aquellos espectadores que estén conectados durante el 100% del tiempo de emisión del anuncio</li>
                                        <li>- Asegúrate de que la capa de 014 Live! está encima de todas demás en el software de streaming para que no la cubran y sea totalmente visible pues sólo cobrarás por las views totalmente visibles</li>
                                        <li>- Si el anuncio no se visualiza correctamente en una emisión, los espectadores de esa emisión no computarán en el pago</li>
                                    </ul>
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col16 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol16, col16)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Cómo se calculan las visualizaciones de los patrocinios?                              </button>
                            </h2>
                            <Collapse isOpen={col16} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Es fácil! Las visualizaciones de la emisión de un anuncio de un patrocinio son iguales al número de viewers mirando el stream en el momento en que se muestra la creatividad de un patrocinio. Al streamer se le asignará la misma cantidad de visualizaciones que la cantidad de viewers que están viendo el directo. Una persona que ve el stream mientras se muestra la creatividad cuenta como una visualización. Si en el momento de emitir el patrocino hay 250 viewers, se contabilizarán 250 visualizaciones.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col17 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol17, col17)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Cobro por todos los espectadores independientemente del país en el que estén?                                </button>
                            </h2>
                            <Collapse isOpen={col17} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Los patrocinadores hacen campañas de ámbito nacional y sólo quieren impactar a los espectadores de su país de origen. Mediante la extensión de Twitch sabemos que porcentaje de tus espectadores están en el país de origen del patrocinador y te pagamos por esos espectadores.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col18 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol18, col18)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Qué es el CTR de una campaña?                                </button>
                            </h2>
                            <Collapse isOpen={col18} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    El CTR es lo que se llama el click-through ratio e indica el porcentaje de tus espectadores que ha hecho clic en el enlace mostrado en el chat. Es una métrica fundamental para el patrocinador. Cuanto más alto sea el CTR mejor resulta la campaña por lo que cuantos más clicks consigas generar más patrocinios tendrás.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col19 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol19, col19)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Qué tengo que hacer para poder emitir la campaña?                                </button>
                            </h2>
                            <Collapse isOpen={col19} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Para disfrutar de 014 Live! sólo necesitas configurar tu OBS con el enlace que te proporcionamos.<Link to="/emission/edit"> Aquí</Link> te explicamos como.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col20 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol20, col20)}
                                    style={{ cursor: "pointer" }}>
                                    He configurado el patrocinio pero no funciona. ¿Qué debo hacer?                               </button>
                            </h2>
                            <Collapse isOpen={col20} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    <p>
                                        Pueden haber varias razones. A continuación te explicamos las más comunes:
                                        <ul>
                                            <li>La fuente 014 Live! no es la capa superior de la escena en el OBS/ software de Streaming
                                                Asegúrate de que estamos en todas las escenas de tu software de Streaming y que nuestra fuente está encima de todas demás para que no la cubran y sea totalmente visible.
                                            </li>
                                            <li>
                                                Has hecho el Stream en una categoría o juego excluido de la campaña
                                                <br />
                                                Por razones de afinidad a la marca, algunos patrocinios tendrán Black List de categorías y/o juegos. La campaña no se emitirá si tu Streaming está dentro de una de esas categorías.
                                                <br />
                                                Por el mismo motivo algunos patrocinios tendrán White List de categorías y/o juegos. La campaña únicamente se emitirá si tu Streaming está dentro de una de esas categorías.
                                            </li>
                                            <li>
                                                Se han alcanzado todos los views de la campaña previstos para hoy
                                                Cada patrocinio tiene un número de views previstos diariamente, una vez alcanzada esa cifra se deja de emitir la campaña hasta el día siguiente.
                                            </li>
                                            <li>
                                                Tu creatividad se retrasa en el programa OBS / stream cuando se muestra
                                                Asegúrate de que la fuente de 014 Live! en el OBS está ajustada a los FPS correctos (sugerimos 30ps)
                                            </li>
                                            <li>
                                                La creatividad se está entrecortando{"Ves a OBS --> Configuración -> Avanzada --> Fuentes -->"} “Habilitar la aceleración del hardware de la fuente del navegador” marque esta opción si no estaba marcada antes, o desmarque si estaba marcada
                                            </li>
                                            <li>
                                                Utilizas un Mac
                                                OBS en Mac no tiene soporte para archivos de vídeo en este momento, por lo que 014 Live! no funcionará en tu stream
                                            </li>
                                            <li>
                                                La creatividad no se muestra en Streamlabs OBS
                                                De vez en cuando, las creatividades no se visualizan en el Streamiabs OBS aunque la fuente se haya instalado correctamente
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col21 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol21, col21)}
                                    style={{ cursor: "pointer" }}>
                                    ¿No hemos solucionado tu problema?
                                </button>
                            </h2>
                            <Collapse isOpen={col21} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Envíanos un mensaje a <a href="#">streamers@014on.com</a>  o contáctanos en el canal de soporte en <a href="#"> Discord</a>
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col22 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol22, col22)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Puedo suscribirme a varios patrocinios a la vez?
                                </button>
                            </h2>
                            <Collapse isOpen={col22} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Sí, puedes estar suscrito al mismo tiempo a tantos patrocinios como quieras. La única condición es que no sean productos que compitan entre sí.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col23 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol23, col23)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Por qué no hay campañas disponibles?
                                </button>
                            </h2>
                            <Collapse isOpen={col23} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Al tratarse de un formato de patrocinio novedoso, estamos trabajando para darlo a conocer y atraer cada vez un mayor número de patrocinadores. Habrá momentos que no habrá patrocinios para ti pero estate atento pues en los siguientes días aparecerá alguno nuevo. Cuando eso ocurra te enviaremos un correo electrónico y aparecerá en tu Dashboard particular.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col24 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol24, col24)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Por qué no se muestran campañas entre las 2 y las 6 de la mañana?
                                </button>
                            </h2>
                            <Collapse isOpen={col24} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Durante estas horas, hay una pausa programada en las pantallas de la campaña para que los Streamers puedan obtener más visualizaciones durante el día.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="privacypolicy-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col25 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(paymentsArray, setcol25, col25)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Se pueden mostrar las campañas si se transmite a través de Twitch Studio?
                                </button>
                            </h2>
                            <Collapse isOpen={col25} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Por el momento, no es posible. Estamos esperando a que Twitch desarrolle su app pera integrar la fuente de 014Live! dentro de su plataforma
                                </div>
                            </Collapse>
                        </div>

                    </div>
                </CardBody>
            </Card>
        </>
    }

    const BillingAndPayments = () => {
        return <>
            <Card className="card-h-100 border shadow-none mb-0">
                <CardBody className="p-4">
                    <div className="accordion accordion-flush" id="accordion-pricingplans">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingOne">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col26 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol26, col26)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Es necesario firmar un contrato para usar la plataforma de 014 Live!?
                                </button>
                            </h2>
                            <Collapse isOpen={col26} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    No es necesario. Los Streamers pueden usar la plataforma 014 Live! sin necesidad de firmar contratos y/o documentos análogos que los obliguen a retransmitir patrocinios que no quieren retransmitir. No obstante, sí que los Streamers se obligan a la aceptación expresa y voluntaria de los términos y condiciones legales de registro de la plataforma.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingTwo">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col27 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol27, col27)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Cómo puedo cobrar el dinero que he ganado?
                                </button>
                            </h2>
                            <Collapse isOpen={col27} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    <ol>
                                        <li>Rellena los <Link to={"/profile/edit"}>Datos de facturación</Link> en tu <Link to={"/profile/edit"}>Perfil</Link>  si no lo has hecho previamente.</li>
                                        <li>Ve a la <Link to={"/wallet"}>Billetera</Link> y haz clic en el botón “Cobrar” de la sección “Saldo Disponible”. </li>
                                    </ol>
                                    <p>En la sección <Link to={"/wallet"}>Billetera</Link> encontrará todo el detalle de los ingresos generados en los patrocinios en que has participado</p>
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingThree">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col28 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol28, col28)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Es necesario emitir facturas?
                                </button>
                            </h2>
                            <Collapse isOpen={col28} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    No es necesario. 014 Live! generará las facturas por el importe que quieras retirar y, en su caso, practicará la retención pertinente para cumplir con todos los requisitos legales.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col29 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol29, col29)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Dónde puedo encontrar las facturas?
                                </button>
                            </h2>
                            <Collapse isOpen={col29} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Las facturas que se emitan a tu favor te las enviaremos por mail y también las tendrás disponibles en la tabla de <Link to={`/wallet?tab=${BILLS_NAV}`}>Facturas</Link>  dentro de la sección <Link to={"/wallet"}>Billetera</Link> de tu dashboard
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">

                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col30 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol30, col30)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Si vivo fuera de España necesito acreditarlo de alguna forma?
                                </button>
                            </h2>
                            <Collapse isOpen={col30} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Sí, efectivamente necesitaremos que nos presentes el certificado (actualizado) de residencia fiscal del país correspondiente de residencia del Streamer. El certificado de residencia fiscal es un documento oficial que se proporciona a la Agencia Tributaria con información sobre tu lugar de residencia.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col31 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol31, col31)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Por qué solicita 014 Live! los certificados de residencia fiscal?
                                </button>
                            </h2>
                            <Collapse isOpen={col31} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Las oficinas fiscales exigen que, en el caso de la cooperación internacional (como la nuestra), los impuestos se paguen en el país en el que reside la entidad que obtiene los ingresos (el Streamer). Mientras la oficina no disponga de dicha información, puede obligarnos a pagar tus impuestos en España.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col32 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol32, col32)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Qué pasa si soy un Streamer que vive en la Unión Europea? ¿Qué voy a encontrarme en mi factura?
                                </button>
                            </h2>
                            <Collapse isOpen={col32} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Necesitamos que nos envíes tu certificado de residencia fiscal en el país correspondiente de residencia de la UE. En ese caso no tendrás ninguna retención.
                                    <br />
                                    En el caso de no presentarse el correspondiente certificado actualizado de residencia fiscal, se te retendrá el importe equivalente a un 15% para cumplir con sus obligaciones ante la Agencia Tributaria española.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col33 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol33, col33)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Qué pasa si soy un Streamer que vive en Andorra? ¿Qué voy a encontrarme en mi factura?
                                </button>
                            </h2>
                            <Collapse isOpen={col33} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Necesitamos que nos envíes tu certificado de residencia fiscal en el Principado de Andorra. En ese caso no tendrás ninguna retención. En el caso de no presentarse el correspondiente certificado actualizado de residencia fiscal, 014 Live! te retendrá un 15% para cumplir con sus obligaciones ante la Agencia Tributaria española.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col34 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol34, col34)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Qué pasa si soy un Streamer que vive fuera de la Unión Europea? ¿Qué voy a encontrarme en mi factura?
                                </button>
                            </h2>
                            <Collapse isOpen={col34} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Igual que en el caso anterior, necesitamos que nos envíes tu certificado de residencia fiscal del país extracomunitario (no UE) correspondiente. En ausencia de la presentación del correspondiente certificado, 014 Live! te retendrá un 15% para cumplir con sus obligaciones ante la Agencia Tributaria española.  Si quieres evitar la retención del pago del 15% de tu remuneración, recuerda subir el certificado. También debe recordar que el 15% de la remuneración se retiene y no se reduce.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col35 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol35, col35)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Cuándo puedo retirar mis ganancias?
                                </button>
                            </h2>
                            <Collapse isOpen={col35} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Cuando hayas acumulado un mínimo de 20€ de saldo disponible
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col36 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol36, col36)}
                                    style={{ cursor: "pointer" }}>
                                    ¿Qué diferencia hay entre “Saldo acumulado” y “Saldo disponible”?
                                </button>
                            </h2>
                            <Collapse isOpen={col36} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    En el “Saldo disponible” de tu cuenta encontrarás las ganancias acumuladas de los patrocinios que ya han finalizado. En el “Saldo acumulado” de tu cuenta encontrarás las ganancias acumuladas de los patrocinios que ya han finalizado más las ganancias acumuladas de los patrocinios que tengas activos en ese momento.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col37 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol37, col37)}
                                    style={{ cursor: "pointer" }}>
                                    He retirado mi dinero, ¿cuándo lo recibiré?
                                </button>
                            </h2>
                            <Collapse isOpen={col37} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Te lo enviaremos a más tardar en 10 días laborables. Recibirás información al respecto en tu dirección de correo electrónico.
                                </div>
                            </Collapse>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="pricingplans-headingFive">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        { collapsed: !col38 }
                                    )}
                                    type="button"
                                    onClick={() => handleClick(billingAndPaymentsArray, setcol38, col38)}
                                    style={{ cursor: "pointer" }}>
                                    Responsabilidad del Streamer en el proceso de emisión de la autofactura.
                                </button>
                            </h2>
                            <Collapse isOpen={col38} className="accordion-collapse">
                                <div className="accordion-body text-muted">
                                    Todo el proceso de emisión de la autofactura cuenta con el visto bueno y la previa revisión de la misma por parte de cada Streamer, de modo que esta último será responsable, en todo caso, del contenido de la autofactura que se genere. La responsabilidad por el contenido de la autofactura recae, en todo caso, en el Streamer, eximiendo de responsabilidad a 014 Live!.
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </CardBody>
            </Card>

        </>
    }


    const handleChangeCheck = (e: any) => {
        switch (e.currentTarget.attributes.name.value) {
            case "Consultas generales":
                setTableMode("Consultas generales")
                setGeneralQuestions(true);
                setRevenuesAndViews(false);
                settwitchExtensions(false);
                setSponsors(false);
                setGrowAndEarn(false)
                break;
            case "Ingresos y Patrocinios":
                setTableMode("Ingresos y Patrocinios")
                setGeneralQuestions(false);
                setRevenuesAndViews(false);
                settwitchExtensions(false)
                setSponsors(true);
                setGrowAndEarn(false)
                break;
            case "Pagos y Facturacion":
                setTableMode("Pagos y Facturacion")
                setRevenuesAndViews(true);
                settwitchExtensions(false);
                setGeneralQuestions(false);
                setSponsors(false);
                setGrowAndEarn(false)
                break;
            case "Extension de Twitch":
                setTableMode("Extension de Twitch")
                setRevenuesAndViews(false);
                setGeneralQuestions(false);
                settwitchExtensions(true)
                setSponsors(false);
                setGrowAndEarn(false)
                break;
            case "Crece y gana":
                setTableMode("Crece y gana");
                setRevenuesAndViews(false)
                setGeneralQuestions(false);
                settwitchExtensions(false)
                setSponsors(false);
                setGrowAndEarn(true)
                break;
        }
    };

    const renderSwitch = () => {
        switch (tableMode) {
            case "Consultas generales":
                return GeneralQuestionsTab()
            case "Ingresos y Patrocinios":
                return RevenuesAndSponsorsTab();
            case "Pagos y Facturacion":
                return BillingAndPayments();

            case "Extension de Twitch":
                return TwitchTab()
            case "Crece y gana":
                return GrowAndEarnTab()
            default:
                return console.log(tableMode)
        }
    };




    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Help")}</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="mx-n4 mt-n4">
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <div className="faq-bg position-relative p-5">
                                        <div className="bg-overlay bg-dark bg-gradient"></div>
                                        <div className="row justify-content-center">
                                            <Col lg={5}>
                                                <div className="text-center text-white-50">
                                                    <h3 className="text-white">¿Cómo podemos ayudarte?</h3>
                                                    <p style={{ color: "white" }}>Busca el tema sobre el que necesites información.</p>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>

                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-n5">
                        <Row>

                            <Container fluid>
                                <Card>

                                    <Row className="mt-3 mb-3 d-flex justify-content-center">
                                        <Col xs={12} md={4} style={{ display: "flex", alignItems: "center" }}>
                                            <Nav tabs justified className="nav-tabs-custom" style={{ width: "100%" }}>
                                                <NavItem>
                                                    <NavLink
                                                        name="Consultas generales"
                                                        className={classnames({ active: generalQuestions })}
                                                        onClick={handleChangeCheck}
                                                    >

                                                        <div className="d-flex align-items-center">
                                                            <div className="me-2">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title rounded-circle font-size-20">
                                                                        <i className="uil uil-question-circle d-flex justify-content-center align-items-center"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-14 mb-0">Consultas generales</h5>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>

                                        <Col xs={12} md={4} style={{ display: "flex", alignItems: "center" }}>
                                            <Nav tabs justified className="nav-tabs-custom" style={{ width: "100%" }}>
                                                <NavItem>
                                                    <NavLink
                                                        name="Ingresos y Patrocinios"
                                                        className={classnames({ active: sponsors })}
                                                        onClick={handleChangeCheck}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-2">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title rounded-circle font-size-20">
                                                                        <i className="uil uil-keyhole-circle d-flex justify-content-center align-items-center"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-14 mb-0">Ingresos y Patrocinios</h5>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>

                                        <Col xs={12} md={4} style={{ display: "flex", alignItems: "center" }}>
                                            <Nav tabs justified className="nav-tabs-custom" style={{ width: "100%" }}>
                                                <NavItem>
                                                    <NavLink
                                                        name="Pagos y Facturacion"
                                                        onClick={handleChangeCheck}
                                                        className={classnames({ active: revenuesAndViews })}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <div className="me-2">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title rounded-circle font-size-20">
                                                                        <i className="uil uil-usd-circle d-flex justify-content-center align-items-center"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-14 mb-0">Pagos y Facturacion</h5>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                        {/* <Col xs={12} md={2} style={{ display: "flex", alignItems: "center" }}>
                                            <Nav tabs justified className="nav-tabs-custom" style={{ width: "100%" }}>
                                                <NavItem>
                                                    <NavLink
                                                        name="Extension de Twitch"
                                                        className={classnames({ active: twitchExtensions })}
                                                        onClick={handleChangeCheck}
                                                    >

                                                        <div className="d-flex align-items-center">
                                                            <div className="me-2">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title rounded-circle font-size-20">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitch d-flex justify-content-center align-items-center" viewBox="0 0 16 16">
                                                                            <path d="M3.857 0 1 2.857v10.286h3.429V16l2.857-2.857H9.57L14.714 8V0H3.857zm9.714 7.429-2.285 2.285H9l-2 2v-2H4.429V1.143h9.142v6.286z" />
                                                                            <path d="M11.857 3.143h-1.143V6.57h1.143V3.143zm-3.143 0H7.571V6.57h1.143V3.143z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-14 mb-0">Extension de Twitch</h5>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                        <Col xs={12} md={2} style={{ display: "flex", alignItems: "center" }}>
                                            <Nav tabs justified className="nav-tabs-custom" style={{ width: "100%" }}>
                                                <NavItem>
                                                    <NavLink
                                                        name="Crece y gana"
                                                        className={classnames({ active: growAndEarn })}
                                                        onClick={handleChangeCheck}
                                                    >

                                                        <div className="d-flex align-items-center">
                                                            <div className="me-2">
                                                                <div className="avatar-sm">
                                                                    <div className="avatar-title rounded-circle font-size-20">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                                                            <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden">
                                                                <h5 className="font-size-14 mb-0">Crece y gana</h5>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Container fluid>
                                            {renderSwitch()}
                                        </Container>
                                    </Row>
                                </Card>
                            </Container>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(withRouter(Help));