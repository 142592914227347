import axios from "axios";
// import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
// const token = accessToken;

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
    baseURL: API_URL,
});

// content type
instance.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
instance.interceptors.response.use(
    function (response) {
        return response.data ? response.data : response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            case 404:
                message = "Sorry! the data you are looking for could not be found";
                break;
            default:
                message = error.message || error;
        }
        return Promise.reject(message);
    }
);

const API_ADXTREAM_URL = process.env.REACT_APP_API_ADXTREAM_URL;

const instanceAdxtrean = axios.create({
    baseURL: API_ADXTREAM_URL,
});

// content type
instanceAdxtrean.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
instanceAdxtrean.interceptors.response.use(
    function (response) {
        return response.data ? response.data : response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (error.status) {
            case 500:
                message = "Internal Server Error";
                break;
            case 401:
                message = "Invalid credentials";
                break;
            case 404:
                message = "Sorry! the data you are looking for could not be found";
                break;
            default:
                message = error.message || error;
        }
        return Promise.reject(message);
    }
);

class ApiCore {
    constructor(token?: any) {
        if (token) instance.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    /**
     * Fetches data from given url
     */
    get = (url: string, params?: {}) => {
        return instance.get(url, params);
    };

    /**
     * post given data to url
     */
    create = (url: string, data: {}) => {
        return instance.post(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: {}) => {
        return instance.put(url, data);
    };

    /**
     * Delete
     */
    delete = (url: string, data?: {}) => {
        return instance.delete(url, { ...data });
    };
}

class ApiAdxtream {
    /**
     * Fetches data from given url
     */
    get = (url: string, params?: {}) => {
        return instanceAdxtrean.get(url, params);
    };

    /**
     * post given data to url
     */
    create = (url: string, data: {}) => {
        return instanceAdxtrean.post(url, data);
    };

    /**
     * Updates data
     */
    update = (url: string, data: {}) => {
        return instanceAdxtrean.put(url, data);
    };

    /**
     * Delete
     */
    delete = (url: string, data?: {}) => {
        return instanceAdxtrean.delete(url, { ...data });
    };
}

export { ApiCore, ApiAdxtream };
