// import axios from "axios";
import { validURL } from "src/pages/Home";
import { validCampaignImage } from "src/pages/Campaigns";
// import { stat } from "fs";
// import { use } from "i18next";
import { ceil } from "lodash";

import moment from "moment";
import React, { useEffect, useState } from "react";
// import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import {
    // CardBody,
    Table,
    Col,
    // Card,
    Button,
    Row,
    // Input,
    // Container,
    // FormGroup,
    // Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    // Progress,
    Spinner,
    // UncontrolledAlert,
    // Alert
} from "reactstrap";
import { ApiCore } from "src/helpers/api_helper";
import { postSubscriptionStatus } from "src/helpers/backend_helper";
import { updateStatus } from "src/slices/Campaigns/reducer";
import * as statuses from "../../helpers/status_helper";
import DetailCampaign from "./DetailCampaign";
import { COLORS } from "src/utils/COLORS";
import StatusCampaign from "./StatusCampaign";
import Notifications from "../Notifications/Notifications";
import NoInfoCard from "../NoInfoCard/NoInfoCard";
// import AddPositioner from "./AdPosition/AddPositioner";




export interface NotificationsT {
    type: "success" | "error" | "suscribed" | "resume" | "paused" | "drop"
}

export const badgeColor = (status: string) => {
    switch (status) {
        case statuses.ASSIGNED.original:
            return COLORS.badgeSuccess;
        case statuses.STREAMER_AGREED.original:
            return COLORS.badgeWarning;
        case statuses.PAUSED.original:
            return COLORS.badgeStreamer;
        case statuses.STREAMER_PENDING.original:
            return COLORS.badgeSuccess;
        case statuses.STREAMER_REQUESTED.original:
            return COLORS.badgeWarning;
        case statuses.STREAMER_REFUSED.original:
            return COLORS.badgeDanger;
        case statuses.OPERATIONAL.original:
            return COLORS.badgeInfo;
    }
};

const MyCampaignsTable = (props: any) => {
    const dispatch = useDispatch();

    const [modal, setModal] = useState(false);
    const [campaignOnModal, setCampaignOnModal] = useState<any>();
    const [subscriptionOnModal, setSubscriptionOnModal] = useState<any>();
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [modalConfirmationTitle, setModalConfirmationTitle] = useState("");
    const [modalConfirmationBody, setModalConfirmationBody] = useState("");

    const [firstPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const [campaignsCurrentPage, setCampaignsCurrentPage] = useState(false);
    const [noCampaigns, setNoCampaigns] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [dangerAlert, setDangerAlert] = useState(false);
    const [alertType, setAlertType] = useState("success")


    const [statusToTransition, setStatusToTransition] = useState("");
    const [updating, setUpdating] = useState(false);

    const { statusUpdated } = useSelector((state: any) => ({
        statusUpdated: state.campaign.statusUpdated,
    }));

    useEffect(() => {
        if (successAlert) {
            setTimeout(() => {
                setSuccessAlert(false);
            }, 3000);
        }

        if (dangerAlert) {
            setTimeout(() => {
                setDangerAlert(false);
            }, 3000);
        }
    }, [successAlert, dangerAlert])

    useEffect(() => {

        setCampaignsCurrentPage(false);

    }, [statusUpdated])






    const handleDetails = (campaign: any, subscription: any) => {
        setModal(true);
        setCampaignOnModal(campaign);
        setSubscriptionOnModal(subscription);
    };

    const transitionStateSubscriptionTo = (status: any) => {
        setModalConfirmation(true);
        setModalConfirmationTitle(campaignOnModal?.name);
        setSuccessAlert(false)
        switch (status) {
            case statuses.PAUSED.original:
                setStatusToTransition(statuses.PAUSED.original);
                setAlertType(status)
                setModalConfirmationBody(
                    "Vas a pausar la campaña " + campaignOnModal?.name + ". ¿Estas seguro?"
                );
                break;
            case statuses.STREAMER_AGREED.original:
                setStatusToTransition(statuses.ASSIGNED.original);
                setAlertType("resume")
                setModalConfirmationBody(
                    "Vas a reactivar la campaña " + campaignOnModal?.name + ". ¿Estas seguro?"
                );
                break;
            case statuses.STREAMER_REFUSED.original:
                setStatusToTransition(statuses.STREAMER_REFUSED.original);
                setAlertType(status)
                setModalConfirmationBody(
                    "Vas a desuscribirte de la campaña " +
                    campaignOnModal?.name +
                    ". ¿Estas seguro?"
                );
                break;
        }
    };

    const getSubscriptionByCampaign = (campaing: any) => {
        const subscriptionIdToFind = campaing.subscription_id;

        return props.subscriptions
            ? props.subscriptions.find((sub: any) => sub.id === subscriptionIdToFind)
            : null;
    };

    const updateStatusSubscription = (status: any, subscription_id: any) => {
        setUpdating(true);
        postSubscriptionStatus(new ApiCore(localStorage.getItem("token")), subscription_id, status)
            .then((data: any) => {
                if (data.message === "ok") {
                    setModalConfirmation(false);
                    setModal(false);
                    setSuccessAlert(true);
                    setUpdating(false);
                    dispatch(updateStatus(Math.random().toString(36).slice(2)));
                } else {
                    setModalConfirmation(false);
                    setModal(false);
                    setDangerAlert(true);
                    setUpdating(false);
                }
            })
            .catch((err) => {
                setModalConfirmation(false);
                setModal(false);
                setDangerAlert(true);
                setUpdating(false);
            });
    };

    const getButtonToTransitionState = () => {
        if (campaignOnModal?.campaign_situation === "paused") return <></>
        switch (subscriptionOnModal?.status.name) {
            case statuses.PAUSED.original:
                return (
                    <>
                        <Button
                            color="primary"
                            style={{ float: "right" }}
                            onClick={() =>
                                transitionStateSubscriptionTo(statuses.STREAMER_AGREED.original)
                            }>
                            Reactivar
                        </Button>{" "}
                    </>
                );
            case statuses.ASSIGNED.original:
                return (
                    <>
                        <Button
                            color="primary"
                            style={{ float: "right" }}
                            onClick={() => transitionStateSubscriptionTo(statuses.PAUSED.original)}>
                            Pausar
                        </Button>{" "}
                    </>
                );
        }
        return <></>;
    };

    useEffect(() => {
        const FilteredCampaigns = props.campaigns ? props.campaigns.filter((campaign: any) => {
            const subscription = getSubscriptionByCampaign(campaign);
            if (subscription && (campaign?.campaign_situation !== "finished")) {
                return subscription
            } return false

        }).filter((el: any) => el !== undefined) : false
        setNoCampaigns(!FilteredCampaigns.length)
        const numberCampaigns = FilteredCampaigns ? FilteredCampaigns.length : 0;
        const quantityPages = ceil(numberCampaigns / recordsPerPage);
        setLastPage(quantityPages);
        const offset = (page - 1) * recordsPerPage;
        const limit = offset + recordsPerPage;

        setCampaignsCurrentPage(props.campaigns ? props.campaigns.slice(offset, limit) : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.campaigns, page, props.subscriptions]);

    const table = () => {
        return (
            <>
                <Table className="table table-hover mb-4 mt-4 " responsive color="white" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nombre campaña</th>
                            <th>Estado</th>
                            <th className="text-center">Views</th>
                            <th className="text-center">Ganancias</th>
                            <th className="text-center">Emisiones</th>
                            <th className="text-center">Fecha inicio</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ width: "100%" }}>
                        {Array.isArray(campaignsCurrentPage) ? (
                            campaignsCurrentPage.map((campaign: any, i: any) => {
                                const subscription = getSubscriptionByCampaign(campaign);
                                if (subscription && (campaign?.campaign_situation !== "finished")) {
                                    const status = campaign.campaign_situation === 'paused' ? ({ name: "operator-paused", original: "operator-paused" }) : statuses.getStatusByCode(
                                        subscription.status.name
                                    );
                                    return (
                                        <tr key={campaign.campaign_id} style={{ verticalAlign: "baseline" }}>
                                            <td
                                                onClick={() =>
                                                    handleDetails(campaign, subscription)
                                                }
                                                style={{ cursor: "pointer" }}>
                                                {campaign.campaign_image ? (
                                                    <img
                                                        alt=""
                                                        style={{ width: "6vw", height: "auto", objectFit: 'cover', minWidth: "75px", maxHeight: 80 }}
                                                        src={validURL(campaign.campaign_image) ? campaign.campaign_image : `${process.env.REACT_APP_BASE_URL}${campaign.campaign_image}`}></img>
                                                ) : (
                                                    <img
                                                        alt=""
                                                        style={{ width: "6vw", height: "auto", minWidth: "75px", maxHeight: 80 }}
                                                        src="https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"></img>
                                                )}{" "}
                                            </td>
                                            <td
                                                onClick={() =>
                                                    handleDetails(campaign, subscription)
                                                }
                                                style={{ cursor: "pointer" }}>
                                                {campaign.name}
                                            </td>
                                            <td>
                                                {new Date(campaign.start_date) > new Date() && status?.original === "assigned" ? (
                                                    <StatusCampaign code={"prepared"} name={campaign.name}></StatusCampaign>) : <StatusCampaign code={status?.original} name={campaign.name}></StatusCampaign>}
                                            </td>
                                            <td className="text-center">{subscription.views.toLocaleString("es-AR")}</td>
                                            <td className="text-center">
                                                {subscription.earnings.toLocaleString("es-AR") + " €"}
                                            </td>
                                            <td className="text-center">
                                                {subscription.emissions.toLocaleString("es-AR")}
                                            </td>
                                            <td className="text-center">
                                                {moment(campaign.start_date)
                                                    .startOf("day")
                                                    .format("DD/MM/YYYY")}
                                            </td>
                                            <td>
                                                <Button
                                                    outline
                                                    color="primary m-1"
                                                    style={{
                                                        // boxShadow: `0 0 7px 5px #00bfff`,
                                                        fontWeight: 800,
                                                    }}
                                                    onClick={() =>
                                                        handleDetails(campaign, subscription)
                                                    }>
                                                    Detalles
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                }
                                else return <></>
                            })
                        ) : (
                            <></>
                        )}
                    </tbody>
                </Table>
                <nav style={{ float: "right", margin: "1.5rem 0" }}>
                    <Pagination>
                        <PaginationItem
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{"<"}</PaginationLink>
                        </PaginationItem>
                        {firstPage === page ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(firstPage);
                                }}>
                                <PaginationLink>{firstPage}</PaginationLink>
                            </PaginationItem>
                        )}

                        {page - 1 <= firstPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page - 1);
                                }}>
                                <PaginationLink>{page - 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        <PaginationItem
                            active
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{page}</PaginationLink>
                        </PaginationItem>

                        {page + 1 >= lastPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page + 1);
                                }}>
                                <PaginationLink>{page + 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        {lastPage === page ? (
                            <></>
                        ) : (
                            <>
                                <PaginationItem
                                    onClick={() => {
                                        setPage(lastPage);
                                    }}>
                                    <PaginationLink>{lastPage}</PaginationLink>
                                </PaginationItem>
                            </>
                        )}

                        <PaginationItem
                            onClick={() => {
                                if (page < lastPage) {
                                    setPage(page + 1);
                                }
                            }}>
                            <PaginationLink next tag="button">
                                {">"}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem></PaginationItem>
                    </Pagination>
                </nav>
                <Modal
                    className="custom"
                    centered={true}
                    isOpen={modal}
                    toggle={() => {
                        if (!updating) setModal(false);
                    }}>
                    <div>
                        <ModalHeader style={{ display: "block", verticalAlign: "middle" }}>
                            <Row>
                                <Col sm={8}>
                                    <img
                                        alt=""
                                        width="24px"
                                        src={campaignOnModal?.creativities[0]?.thumbnail_img_url ?
                                            validCampaignImage(campaignOnModal?.creativities[0]?.thumbnail_img_url)
                                            : ''}
                                    ></img>{" "}
                                    {campaignOnModal?.name}{" "}
                                </Col>
                                <Col
                                    sm={4}
                                    style={{
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                    }}>

                                    {campaignOnModal?.campaign_situation === "paused" ? <StatusCampaign code={"operator-paused"}></StatusCampaign> : <>
                                        {new Date(campaignOnModal?.start_date) > new Date() && subscriptionOnModal?.status?.name === "assigned" ? (
                                            <StatusCampaign code={"prepared"}></StatusCampaign>) : <StatusCampaign code={subscriptionOnModal?.status?.name}></StatusCampaign>}</>}

                                    {/* <StatusCampaign code={subscriptionOnModal?.status?.name}></StatusCampaign> */}
                                </Col>
                            </Row>
                        </ModalHeader>
                        <ModalBody>
                            <DetailCampaign campaign={campaignOnModal} subscription={campaignOnModal?.campaign_situation === "paused" ? ({ name: "operator-paused", original: "operator-paused" }) : subscriptionOnModal} />
                            {/* <AddPositioner /> */}
                        </ModalBody>
                    </div>

                    <Modal
                        className="custom"
                        centered={true}
                        isOpen={modalConfirmation}
                        toggle={() => {
                            if (!updating) setModalConfirmation(false);
                        }}>
                        <ModalHeader>{modalConfirmationTitle}</ModalHeader>
                        <ModalBody>{modalConfirmationBody}</ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                disabled={updating}
                                onClick={() =>
                                    updateStatusSubscription(
                                        statusToTransition,
                                        subscriptionOnModal.id
                                    )
                                }>
                                {updating ? <Spinner size={"sm"} animation="border" /> : "Si"}
                            </Button>{" "}
                            <Button
                                color="secondary"
                                disabled={updating}
                                onClick={() => setModalConfirmation(false)}>
                                No
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <ModalFooter style={{ display: "block" }}>
                        <Button
                            outline
                            color="primary"
                            onClick={() =>
                                transitionStateSubscriptionTo(statuses.STREAMER_REFUSED.original)
                            }
                            style={{ float: "left" }}>
                            Dar de baja
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => setModal(false)}
                            style={{ float: "right" }}>
                            Cerrar
                        </Button>
                        {getButtonToTransitionState()}
                    </ModalFooter>
                </Modal>
            </>
        );
    };

    return (
        <React.Fragment>
            <Notifications active={successAlert} type={alertType} />
            <Notifications active={dangerAlert} type={"error"} />
            {/* <Notifications active={successAlert} type={"success"} />
            <Notifications active={dangerAlert} type={"error"} /> */}

            {campaignsCurrentPage !== false ? (
                noCampaigns ? <NoInfoCard WidgetTitle={"No tienes ninguna campaña activa!"} WidgetData={<><>Anímate a suscribirte a alguno de nuestros patrocinios.</><br />Tú decides el cuando, como y con quien!</>} /> : table()
            ) : (
                <div className="m-4" style={{ textAlign: "center" }}>
                    <Spinner animation="border" />
                </div>
            )}
        </React.Fragment>
    );
};

export default MyCampaignsTable;
