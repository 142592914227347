import { ceil } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    Table, Pagination, PaginationItem, PaginationLink, Spinner,
} from "reactstrap";
import NoInfoCard from "../NoInfoCard/NoInfoCard";










const WalletBillsTable = (props: any) => {
    const [firstPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [page, setPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [noBills, setNoBills] = useState(false)
    const [billsCurrentPage, setBillsCurrentPage] = useState(false);

    const statusParse = (status: any) => {

        var map = new Map();
        map.set('created', 'Solicitada');
        map.set('sent', 'En proceso');
        map.set('paid', 'Pagada');

        return map.get(status);
    };

    useEffect(() => {
        const numberActivities = props.bills ? props.bills.length : 0;
        const quantityPages = ceil(numberActivities / recordsPerPage);
        setLastPage(quantityPages === 0 ? 1 : quantityPages);
        const offset = (page - 1) * recordsPerPage;
        const limit = offset + recordsPerPage;

        setNoBills(!props?.bills?.length)
        setBillsCurrentPage(props.bills ? props.bills.slice(offset, limit) : []);
    }, [props.bills, page]);


    const table = () => {
        return (
            <>
                <Table responsive className="table table-hover mb-4 mt-4" color="white">
                    <thead>
                        <tr>
                            <th >Fecha</th>
                            <th className="text-center">Importe</th>
                            <th className="text-center">Estado</th>
                            <th className="text-center" style={{ minWidth: "120px" }} >Pdf</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(billsCurrentPage) ? (
                            billsCurrentPage.map((activity: any, i: any) => {
                                return (
                                    <tr key={i} style={{ verticalAlign: "baseline" }}>
                                        <td>
                                            {moment(activity.created_at)
                                                .startOf("day")
                                                .format("DD/MM/YYYY")}
                                        </td>
                                        <td className="text-center">{activity.total?.toLocaleString("es-AR")} €</td>
                                        <td className="text-center">{statusParse(activity.status)}</td>
                                        <td className="text-center">
                                            <a target="_blank" rel="noreferrer" href={activity.pdf_url}>
                                                <i className="fas fa-arrow-down" />
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </tbody>
                </Table>
                <nav style={{ float: "right", margin: "1.5rem 0" }}>
                    <Pagination>
                        <PaginationItem
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{"<"}</PaginationLink>
                        </PaginationItem>
                        {firstPage == page ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(firstPage);
                                }}>
                                <PaginationLink>{firstPage}</PaginationLink>
                            </PaginationItem>
                        )}

                        {page - 1 <= firstPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page - 1);
                                }}>
                                <PaginationLink>{page - 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        <PaginationItem
                            active
                            onClick={() => {
                                if (page > 1) {
                                    setPage(page - 1);
                                }
                            }}>
                            <PaginationLink>{page}</PaginationLink>
                        </PaginationItem>

                        {page + 1 >= lastPage ? (
                            <></>
                        ) : (
                            <PaginationItem
                                onClick={() => {
                                    setPage(page + 1);
                                }}>
                                <PaginationLink>{page + 1}</PaginationLink>
                            </PaginationItem>
                        )}

                        {lastPage == page ? (
                            <></>
                        ) : (
                            <>
                                <PaginationItem
                                    onClick={() => {
                                        setPage(lastPage);
                                    }}>
                                    <PaginationLink>{lastPage}</PaginationLink>
                                </PaginationItem>
                            </>
                        )}

                        <PaginationItem
                            onClick={() => {
                                if (page < lastPage) {
                                    setPage(page + 1);
                                }
                            }}>
                            <PaginationLink next tag="button">
                                {">"}
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem></PaginationItem>
                    </Pagination>
                </nav>
            </>
        );
    };

    return (
        <React.Fragment>
            {billsCurrentPage !== false ? (
                noBills ? <>
                    <NoInfoCard
                        WidgetTitle={"Todavía no has facturado campañas!"}
                        WidgetData={<>Anímate a suscribirte a alguno de nuestros patrocinios.<br />Tú decides el cuando, como y con quien!</>}
                    ></NoInfoCard>

                </> :
                    table()
            ) : (
                <div className="m-4" style={{ textAlign: "center" }}>
                    <Spinner animation="border" />
                </div>
            )}
        </React.Fragment>
    );
};

export default WalletBillsTable;
