import { ApiCore } from "src/helpers/api_helper";
import { getCampaigns, getSubscriptions } from "src/helpers/backend_helper";
import { createAsyncThunk } from "@reduxjs/toolkit";

const availableCampaignsStatus = ["streamer-pending", "streamer-requested", "streamer-refused"];

const filterCampaigns = async () => {
    const subscriptions: any = await getSubscriptions(
        new ApiCore(localStorage.getItem("token"))
    ).then((data: any) => {
        const subscriptionsResponse = Object.values(data.payload.streamers)[0];
        return subscriptionsResponse;
    });
    const data: any = await getCampaigns(new ApiCore(localStorage.getItem("token")));
    const allCampaigns: any = Object.values(data.payload.streamers)[0];
    const availableCampaigns = Array.isArray(allCampaigns)
        ? allCampaigns.filter((e: any) => {
            if (availableCampaignsStatus.includes(e.subscription_status) && e.campaign_situation !== "finished") {
                return true;
            }
            return false;
        })
        : [];

    const getSubscriptionByCampaign = (campaing: any) => {
        const subscriptionIdToFind = campaing.subscription_id;
        return subscriptions
            ? subscriptions.find((sub: any) => sub.id === subscriptionIdToFind)
            : null;
    };
    const FilteredCampaigns: any = availableCampaigns
        ? availableCampaigns
            .map((campaign: any) => {
                const subscription = getSubscriptionByCampaign(campaign);
                if (subscription) {
                    return subscription;
                }
                return null
            })
            .filter((el: any) => el !== undefined)
        : false;
    return { availableCampaigns, FilteredCampaigns };
};

export const getAvailableCampaigns = createAsyncThunk(
    "campaign/updateAvailableCampaigns",
    async () => {
        try {
            const response = filterCampaigns();
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getAllCampaigns = createAsyncThunk("campaign/getAllCampaigns", async () => {
    try {
        const data: any = await getCampaigns(new ApiCore(localStorage.getItem("token")));
        const allCampaigns: any = Object.values(data.payload.streamers)[0];
        return allCampaigns;
    } catch (error) {
        return error;
    }
});
