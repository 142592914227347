import { createSlice } from "@reduxjs/toolkit";


interface Status {
    status: string;
    viewers: number;
    handshake: number;
    liveStatus: string;
}
interface ProfileStateType {
    error?: string | object | null | undefined | unknown;
    success?: any;
    status: Status;
    modal: boolean;
    pfp: string;
}

function validURL(str: any) {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
}



const validCampaignImage = (image: string) => {
    return validURL(image)
        ? image
        : `${process.env.REACT_APP_BASE_URL}${image}`;
};


const getPfp = () => {
    const getProfile = localStorage.getItem("profile");
    if (getProfile) {
        const obj = JSON.parse(getProfile);
        const streamer = obj.streamers?.[0];
        if (streamer) {
            return validCampaignImage(streamer.thumbnail_img_url);
        }
    }
    return "";
};



export const initialState: ProfileStateType = {
    error: "",
    success: "",
    status: {
        status: "not connected",
        viewers: 0,
        handshake: 0,
        liveStatus: "",
    },
    modal: false,
    pfp: getPfp(),
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        profileSuccess(state, action) {
            state.success = action.payload;
        },
        profileError(state, action) {
            state.error = action.payload;
        },
        profileStatus(state, action) {
            state.status = action.payload;
        },
        profileModal(state, action) {
            state.modal = action.payload;
        },
        profilePic(state, action) {
            state.pfp = action.payload;
        },
    },
});

export const { profileSuccess, profileError, profileStatus, profileModal, profilePic } =
    profileSlice.actions;

export default profileSlice.reducer;
