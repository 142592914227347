import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import { selectGraphCode } from "src/slices/Statistics/reducer";
import LineChartGeneric from "./LineChartGeneric";

interface Props {
    analytics?: any;
}

const GraphicsStatistics = (props: Props) => {
    const dispatch = useDispatch();

    const { selectedGraphCode } = useSelector((state: any) => ({
        selectedGraphCode: state.statistics.selectGraphCode,
    }));

    const graphs = [
        {
            code: 1,
            name: "Evolución campañas suscritas (diaria)",
            yTitle: "Suscripciones",
            apiName: "subscriptions",
        },
        {
            code: 2,
            name: "Evolución views (diaria)",
            yTitle: "views",
            apiName: "views",
        },
        {
            code: 3,
            name: "Evolución ganancias (diaria)",
            yTitle: "Ganancias",
            apiName: "earnings",
        },
        {
            code: 4,
            name: "Evolución clicks (diaria)",
            yTitle: "Clicks",
            apiName: "clicks",
        },
        {
            code: 5,
            name: "Evolución emisiones (diaria)",
            yTitle: "Emisiones",
            apiName: "plays",
        },
    ];

    const selectedGraph = () => {
        const graphDataSelected =
            graphs.find(function (graph) {
                return selectedGraphCode == graph.code;
            }) ?? graphs[0];

        return (
            <LineChartGeneric
                name={graphDataSelected?.name}
                yTitle={graphDataSelected?.yTitle}
                dates={props?.analytics?.dates}
                data={props?.analytics[graphDataSelected?.apiName]}
            />
        );
    };

    useEffect(() => {
        selectedGraph();
    }, [selectedGraphCode]);

    const { layoutMode } = useSelector((state: any) => ({
        layoutMode: state.Layout.layoutMode,
    }));

    return (
        <React.Fragment>
            <Row className="statistics3" style={{ marginTop: "3rem" }}>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Input
                                type="select"
                                className="form-select mb-3 w-auto"
                                style={layoutMode === "dark" ? { color: "#fff" } : {}}
                                onChange={e => dispatch(selectGraphCode(e.currentTarget.value))}
                                defaultValue={selectedGraphCode}>
                                {graphs.map((graph, i) => {
                                    return (
                                        <option key={i} style={{ color: "#fff" }} value={graph.code}>
                                            {graph.name}&nbsp;&nbsp;&nbsp;
                                        </option>
                                    );
                                })}
                            </Input>

                            <div id="line_chart_zoomable" className="apex-charts" dir="ltr">
                                {selectedGraph()}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default GraphicsStatistics;
