
import { useEffect, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import UnsubscribeModal from "./UnsubscribeModal";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import {
    Col,
    Container,
    Row,
    //    InputGroup,
    Alert,
    //    ButtonGroup,
    Button,
    Spinner,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    //    ModalFooter
} from "reactstrap";
//import Select from "react-select";
import { Link } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
//import Flatpickr from "react-flatpickr";
import twitchIcon from "../../assets/images/small/twitch-icon.png";
import { AvField, AvForm } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//import logolight from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";

import { CardBody, Card } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";

import noUser from "../../assets/images/users/no-user.jpg";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import moment from "moment";
import {
    getStreamerProfile,
    jsonResponseFromAxiosResponse,
    postStreamerImage,
    postStreamerLeave,
    putStreamerProfile,
    updateBillingData,
    updateContactData,
} from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import { languagesIso } from "src/common/data/languagesIso";
import { countries } from "src/common/data/countries";
import TwitchSync from "src/components/Twitch/TwitchSync";
import { profileModal } from "src/slices/Auth/Profile/reducer";
import { validCampaignImage } from "../Campaigns";
import { profilePic } from "src/slices/Auth/Profile/reducer";
import PdfUpload from "src/components/Inputs/PdfUpload";
import WithDrawModal from "../../components/Wallet/WithdrawModal";
// import { logoutUser } from "src/slices/thunks";
registerLocale("es", es);

const vatIdentifiers: any = {
    "company": "CIF/NIF *",
    "freelance": "NIF *",
    "natural_person": "DNI *"
}



const UsertSetting = (props: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { pfp } = useSelector((state: any) => state.profile)
    const ref = useRef<any>()
    const [UnsubscribeIsOpen, setUnsubscribeIsOpen] = useState(false);


    // const [selectedFile, setSelectedFile] = useState<any>(null);
    const [disabledAccount, setDisabledAccount] = useState(false)

    const [fiscalEntity, setFiscalEntity] = useState<string>("natural_person");

    const [name, setname] = useState();
    // const [username, setusername] = useState();
    const [email, setemail] = useState<string>();
    const [photo, setphoto] = useState(pfp);
    const [saveButtonGeneralInfo, setSaveButtonGeneralInfo] = useState(true);
    const [saveButtonContactData, setSaveButtonContactData] = useState(true);
    const [saveButtonBillingData, setSaveButtonBillingData] = useState(true);
    const [updating,] = useState(false);
    const [updatingGeneralInfo, setUpdatingGeneralInfo] = useState(false);
    const [updatingBillingData, setUpdatingBillingData] = useState(false);
    const [updatingContactData, setUpdatingContactData] = useState(false);

    //Streamer Contact data
    const [streamerContactName, setStreamerContactName] = useState<string>("");
    const [streamerContactEmail, setStreamerContactEmail] = useState<string>("");
    const [streamerContactCountry, setStreamerContactCountry] = useState<string>("");
    const [streamerContactCity, setStreamerContactCity] = useState<string>("");
    const [streamerContactState, setStreamerContactState] = useState<string>("");
    const [streamerContactAddress, setStreamerContactAddress] = useState<string>("");
    const [streamerContactAddressExtra, setStreamerContactAddressExtra] = useState<string>("");
    const [streamerContactZipCode, setStreamerContactZipCode] = useState<string>("");
    const [streamerContactPhone, setStreamerContactPhone] = useState<string>("");

    const [streamerBirthdate, setStreamerBirthdate] = useState<Date>();
    const [streamerGender, setStreamerGender] = useState<string>("");
    const [streamerLanguage, setStreamerLanguage] = useState<string>("");
    const [streamerStatus, setStreamerStatus] = useState<string>("");
    const [streamerNationality, setStreamerNationality] = useState<string>("");
    const [streamerCurrency, setStreamerCurrency] = useState<string>("");
    const [streamerNotes, setStreamerNotes] = useState<string>("");
    const [streamerDiscord, setStreamerDiscord] = useState<string>("");

    //Streamer Billing data
    const [streamerBillingName, setStreamerBillingName] = useState<string>("");
    const [streamerBillingEmail, setStreamerBillingEmail] = useState<string>("");
    const [streamerBillingCountry, setStreamerBillingCountry] = useState<string>("");
    const [streamerBillingCity, setStreamerBillingCity] = useState<string>("");
    const [streamerBillingState, setStreamerBillingState] = useState<string>("");
    const [streamerBillingAddress, setStreamerBillingAddress] = useState<string>("");
    const [streamerBillingAddressExtra, setStreamerBillingAddressExtra] = useState<string>("");
    const [streamerBillingZipCode, setStreamerBillingZipCode] = useState<string>("");
    const [streamerBillingPhone, setStreamerBillingPhone] = useState<string>("");
    const [streamerBillingVat, setStreamerBillingVat] = useState<string>("");
    const [streamerBillingBankAccount, setStreamerBillingBankAccount] = useState<string>("");
    const [streamerBicCode, setStreamerBicCode] = useState<string>("")

    const [streamerId, setStreamerId] = useState<string>("");

    const [configurationTwitchModal, setConfigurationTwitchModal] = useState(false);
    const [pendingApprovalModal] = useState(false);

    const { success } = useSelector((state: any) => ({
        success: state.profile.success,
    }));

    const getStatusStreamerTranslation = (status: any) => {
        switch (status) {
            case "enabled":
                return "Activo";
            case "new":
                // setPendingApprovalModal(true);
                return "Pendiente de activación";
            default:
                return "";
        }
    };

    const configurateEmissionLink = () => {
        history.push("/emission/edit");
    };

    const unsubscribeUser = () => {
        postStreamerLeave(new ApiCore(localStorage.getItem('token')), { streamer_id: localStorage.getItem('streamer_id') }).then(_res => {
            if (!ref.current) return
            return ref.current.click()
        }).catch((err) => {
            alert("Error en peticion")
        }
        )
    };
    const toggle = () => setUnsubscribeIsOpen(!UnsubscribeIsOpen);

    const handleValidSubmitGeneralInfo = (event: any) => {
        event.preventDefault();
        if (!streamerBirthdate) return
        setUpdatingGeneralInfo(true);
        saveProfileGeneralInfo();
    };
    const handleValidSubmitBillingData = (event: any) => {
        event.preventDefault();
        setUpdatingBillingData(true);
        saveProfileBillingData();
    };
    const handleValidSubmitContactData = (event: any) => {
        event.preventDefault();
        setUpdatingContactData(true);
        saveProfileContactData();
    };

    const copyContactDataToBillingData = (checked: boolean) => {
        setStreamerBillingName(checked ? streamerContactName : "");
        setStreamerBillingEmail(checked ? streamerContactEmail : "");
        setStreamerBillingCountry(checked ? streamerContactCountry : "");
        setStreamerBillingCity(checked ? streamerContactCity : "");
        setStreamerBillingState(checked ? streamerContactState : "");
        setStreamerBillingAddress(checked ? streamerContactAddress : "");
        setStreamerBillingAddressExtra(checked ? streamerContactAddressExtra : "");
        setStreamerBillingZipCode(checked ? streamerContactZipCode : "");
        setStreamerBillingPhone(checked ? streamerContactPhone : "");
    };


    useEffect(() => {
        if (configurationTwitchModal || pendingApprovalModal) {
            dispatch(profileModal(true));
        } else {
            dispatch(profileModal(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationTwitchModal, pendingApprovalModal]);

    useEffect(() => {
        // Fuerzo a actualizar el profile
        getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
            const profileJson = jsonResponseFromAxiosResponse(data);
            localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
            localStorage.setItem("profile", JSON.stringify(profileJson.payload));
            setUpdatingContactData(false);
        });


        const getAuthUser = localStorage.getItem("authUser");
        if (getAuthUser) {
            const obj = JSON.parse(getAuthUser);
            if (obj) {
                setname(obj.name);
                // setusername(obj.username);
                setemail(obj.email);
                setphoto(obj.photo);
            }
        }

        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            if (streamer) {
                const contact = streamer.contact;
                const billing = streamer.invoice;
                const withdrawMethod = streamer.withdraw_method
                setStreamerContactName(contact?.fullname);
                setStreamerContactEmail(contact?.email);
                setStreamerContactCountry(contact?.country ?? "");
                setStreamerContactCity(contact?.city);
                setStreamerContactState(contact?.state);
                setStreamerContactAddress(contact?.address);
                setStreamerContactAddressExtra(contact?.address_extra);
                setStreamerContactZipCode(contact?.zip_code);
                setStreamerContactPhone(contact?.phone);
                setStreamerBillingName(billing?.legal_name);
                setStreamerBillingEmail(billing?.email);
                setStreamerBillingCountry(billing?.country);
                setStreamerBillingCity(billing?.city);
                setStreamerBillingState(billing?.state);
                setStreamerBillingAddress(billing?.address);
                setStreamerBillingAddressExtra(billing?.address_extra);
                setStreamerBillingZipCode(billing?.zip_code);
                setStreamerBillingPhone(billing?.phone);
                setStreamerBillingVat(billing?.vat_identifier);
                setStreamerBillingBankAccount(withdrawMethod?.account_number);
                setFiscalEntity(streamer?.invoice?.organization_type)
                setphoto(validCampaignImage(streamer.thumbnail_img_url))
                setStreamerBicCode(streamer?.withdraw_method?.bic_code)
                if (streamer.status.name === "closed-account") {
                    setDisabledAccount(true)
                }
                if (streamer.birthdate) {
                    setStreamerBirthdate(
                        new Date(moment(streamer.birthdate).startOf("day").format("MM/DD/YYYY"))
                    );
                }

                setStreamerGender(streamer.gender);
                setStreamerLanguage(streamer.language_iso);
                setStreamerStatus(getStatusStreamerTranslation(streamer.status?.name));
                setStreamerNationality(streamer.nationality);
                setStreamerCurrency(streamer.currency_iso);
                setStreamerNotes(streamer.notes);

                setStreamerId(streamer.id);
                setConfigurationTwitchModal(false);
            } else {
                setConfigurationTwitchModal(true);
            }
        } else {
            setConfigurationTwitchModal(true);
        }
    }, [success]);

    const saveProfileGeneralInfo = () => {
        putStreamerProfile(new ApiCore(localStorage.getItem("token")), {
            streamer_id: streamerId,

            birthdate: streamerBirthdate,
            gender: streamerGender,
            nationality: streamerNationality,
            language_iso: streamerLanguage,
            currency_iso: streamerCurrency,
            notes: streamerNotes,
        }).then((data: any) => {
            if (data.message === "ok") {
                getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                    const profileJson = jsonResponseFromAxiosResponse(data);
                    localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
                    localStorage.setItem("profile", JSON.stringify(profileJson.payload));
                    setUpdatingGeneralInfo(false);
                });
            } else {
            }
        });
    };

    const saveProfileBillingData = () => {
        updateBillingData(new ApiCore(localStorage.getItem("token")), {
            streamer_id: streamerId,
            legal_name: streamerBillingName,
            email: streamerBillingEmail,
            legal_identifier: streamerBillingVat,
            vat_identifier: streamerBillingVat,
            country: streamerBillingCountry,
            city: streamerBillingCity,
            state: streamerBillingState,
            address_extra: streamerBillingAddressExtra,
            address: streamerBillingAddress,
            zip_code: streamerBillingZipCode,
            phone: streamerBillingPhone,
            legal_residence_document: "",
            legal_residence_country: streamerBillingCountry,
            account_number: streamerBillingBankAccount,
            beneficiary: streamerBillingName,
            branch_address: "",
            branch_name: "",
            bic_code: streamerBicCode,
            organization_type: fiscalEntity
        }).then((data: any) => {
            if (data.message === "ok") {
                getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                    const profileJson = jsonResponseFromAxiosResponse(data);
                    localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
                    localStorage.setItem("profile", JSON.stringify(profileJson.payload));
                    setUpdatingBillingData(false);
                });
            } else {
            }
        });
    };

    const saveProfileContactData = () => {
        updateContactData(new ApiCore(localStorage.getItem("token")), {
            streamer_id: streamerId,

            address: streamerContactAddress,
            address_extra: streamerContactAddressExtra,
            zip_code: streamerContactZipCode,
            city: streamerContactCity,
            state: streamerContactState,
            country: streamerContactCountry,
            email: streamerContactEmail,
            phone: streamerContactPhone,
            fullname: streamerContactName,
        }).then((data: any) => {
            if (data.message === "ok") {
                getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                    const profileJson = jsonResponseFromAxiosResponse(data);
                    localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
                    localStorage.setItem("profile", JSON.stringify(profileJson.payload));
                    setUpdatingContactData(false);
                });
            } else {
            }
        });
    };


    const checkGeneralInfo = () => {
        const data = [streamerBirthdate, streamerGender, streamerNationality, streamerCurrency, streamerLanguage]
        return data.every(el => el && el !== "")
    }


    async function handleChangeProfilePic(e: any) {
        if (e.target.files.length) {
            const formData = new FormData();
            formData.append('image', e.target.files[0]);
            formData.append('streamer_id', localStorage.getItem("streamer_id") as string)
            try {
                const response: any = await postStreamerImage(new ApiCore(localStorage.getItem("token")), formData)
                setphoto(URL.createObjectURL(e.target.files[0]))
                dispatch(profilePic(URL.createObjectURL(e.target.files[0])))
                if (response.message === "ok") {
                    getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                        const profileJson = jsonResponseFromAxiosResponse(data);
                        localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
                        localStorage.setItem("profile", JSON.stringify(profileJson.payload));
                    });
                }

            } catch (error) {
                console.log("error", error)
            }
        }
        return
    }

    return (
        <div
            className={
                pendingApprovalModal || configurationTwitchModal
                    ? "page-content blur"
                    : "page-content"
            }>
            <MetaTags>
                <title>{props.t("Edit profile")}</title>
            </MetaTags>
            <UnsubscribeModal
                isOpen={UnsubscribeIsOpen}
                toggle={toggle}
                onConfirm={unsubscribeUser}
            />
            <Container fluid>
                {/* Render Breadcrumbs */}

                <a ref={ref as any} style={{ display: "none" }} href="/logout">_</a>
                <Breadcrumbs title={props.t("Profile")} breadcrumbItem={props.t("Edit profile")} />
                <Row>
                    <Row className="col-xxl-12 col-lg-12">
                        {streamerStatus !== "Activo" ? (
                            <Alert color="primary">
                                Completar el perfil para poder utilizar la aplicación completa. Una
                                vez que complete los datos deberá esperar a que validemos sus datos.
                            </Alert>
                        ) : (
                            <></>
                        )}
                    </Row>
                    <Row className="col-xxl-3 col-lg-4">
                        <Card>
                            <CardBody>
                                <div
                                    className="profile-bg position-relative overflow-hidden rounded-top"
                                    style={{ height: "150px" }}>
                                    <div className="bg-overlay bg-dark bg-gradient"></div>

                                    {/* <div className="position-relative p-3 text-end">
                                        <button type="button" className="btn btn-primary btn-sm"><i className="mdi mdi-pencil me-1"></i> Edit</button>
                                    </div> */}
                                </div>
                                <div className="text-center">
                                    <div className="position-relative d-inline-block mx-auto mt-n5 mb-4">
                                        <div className="avatar-lg">
                                            <img
                                                src={photo ?? noUser}
                                                alt=""
                                                className="rounded-circle img-thumbnail"
                                                referrerPolicy="no-referrer"
                                                style={{ minWidth: "100%", minHeight: "100%", objectFit: "cover" }}
                                            />
                                        </div>
                                        <article
                                            className="d-block position-absolute bottom-0 end-0">
                                            <div className="avatar-sm">
                                                <div className="avatar-title rounded-circle bg-light text-primary">
                                                    <input style={{ position: "absolute", width: "100%", left: 0, height: "100%", borderRadius: "50%", opacity: 0, zIndex: 1 }} type="file" accept="image/*"
                                                        onChange={handleChangeProfilePic} />
                                                    <i className="mdi mdi-pencil"></i>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    {/* <img src={imageUrl}></img> */}
                                    <h5 className="font-size-16 mb-1">{name}</h5>
                                    {/* <p className="text-muted mb-0">Full Stack Developer</p> */}
                                </div>
                                <hr className="mt-4" style={{ marginBottom: 0 }} />
                                <div>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                        <div className="mt-4">
                                            <p className="text-muted mb-1">Nombre :</p>
                                            <h5 className="font-size-14 text-truncate">{name}</h5>
                                        </div>

                                    </div>
                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Email :</p>
                                        <h5 className="font-size-14 text-truncate">{email}</h5>
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-muted mb-1">Estado de cuenta :</p>
                                        <h5 className="font-size-14 text-truncate">
                                            {streamerStatus}
                                        </h5>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Row>

                    <div className="col-xxl-9 col-lg-8">
                        <Card>
                            <CardBody>
                                <h5 className="card-title mb-4">Datos Streamer</h5>
                                <div className="card border shadow-none" style={{ marginBottom: "8rem" }}>
                                    <div className="card-header d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                    01
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">Información General</h5>
                                        </div>
                                    </div>
                                    <CardBody>
                                        <AvForm
                                            onValidSubmit={(e: any, v: any) => {
                                                handleValidSubmitGeneralInfo(e);
                                            }}>
                                            <div>
                                                <Input
                                                    hidden
                                                    name="streamer_id"
                                                    value={streamerId}></Input>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <label className={`${!streamerBirthdate ? "text-danger" : ""}`}>Fecha de nacimiento *</label>
                                                            <DatePicker
                                                                className="form-control d-block"
                                                                placeholderText="Seleccione la fecha de nacimiento"
                                                                locale="es"
                                                                name="birthdate"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={streamerBirthdate}
                                                                onChange={(
                                                                    newValue: any,
                                                                    e: any
                                                                ) => {
                                                                    e.target.name = "birthdate";
                                                                    e.target.value = newValue;
                                                                    setSaveButtonGeneralInfo(!newValue);
                                                                    setStreamerBirthdate(newValue);
                                                                }}
                                                            />
                                                            <div className="invalid-feedback" style={{ display: `${!streamerBirthdate ? "block" : "none"}` }}>Este campo es obligatorio</div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                type="select"
                                                                name="gender"
                                                                label="Género *"
                                                                value={streamerGender}
                                                                errorMessage="Este campo es obligatorio"
                                                                required
                                                                onChange={(e: any) => {
                                                                    setSaveButtonGeneralInfo(false);
                                                                    setStreamerGender(
                                                                        e.target.value
                                                                    );
                                                                }}>
                                                                <option value="">
                                                                    Elegir género
                                                                </option>
                                                                <option value={"female"}>
                                                                    Femenino
                                                                </option>
                                                                <option value={"male"}>
                                                                    Masculino
                                                                </option>
                                                                <option value={"other"}>
                                                                    Otro
                                                                </option>
                                                            </AvField>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="nationality"
                                                                label="País de nacimiento *"
                                                                type="select"
                                                                errorMessage="Este campo es obligatorio"
                                                                required
                                                                value={streamerNationality}
                                                                onChange={(e: any) => {
                                                                    setStreamerNationality(
                                                                        e.target.value
                                                                    );
                                                                    setSaveButtonGeneralInfo(false);
                                                                }}>
                                                                <option value="">
                                                                    Elegir país de nacimiento
                                                                </option>
                                                                {countries.map(
                                                                    (
                                                                        country: any,
                                                                        index: number
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                key={
                                                                                    country.name +
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    country.name
                                                                                }>
                                                                                {country.name === 'Spain' ? 'España' : country.name}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </AvField>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="language_iso"
                                                                label="Idioma de emisión *"
                                                                type="select"
                                                                errorMessage="Este campo es obligatorio"
                                                                required
                                                                placeholder="Ingresar idioma de emisión"
                                                                value={streamerLanguage}
                                                                onChange={(e: any) => {
                                                                    setStreamerLanguage(
                                                                        e.target.value
                                                                    );
                                                                    setSaveButtonGeneralInfo(false);
                                                                }}>
                                                                <option value="">
                                                                    Elegir idioma
                                                                </option>
                                                                {languagesIso.map(
                                                                    (language: any) => {
                                                                        return (
                                                                            <option
                                                                                key={language.code}
                                                                                value={
                                                                                    language.code
                                                                                }>
                                                                                {language.name}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </AvField>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="currency_iso"
                                                                label="Moneda *"
                                                                type="select"
                                                                required
                                                                errorMessage="Este campo es obligatorio"

                                                                placeholder="Ingresar moneda"
                                                                value={streamerCurrency}
                                                                onChange={(e: any) => {
                                                                    setStreamerCurrency(
                                                                        e.target.value
                                                                    );
                                                                    setSaveButtonGeneralInfo(false);
                                                                }}>
                                                                <option value="">
                                                                    Elegir moneda
                                                                </option>
                                                                <option value="USD">USD</option>
                                                                <option value="EUR">EUR</option>
                                                            </AvField>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="notes"
                                                                label="Notas"
                                                                type="text"
                                                                placeholder="Ingresar notas"
                                                                value={streamerNotes}
                                                                onChange={(e: any) => {
                                                                    setStreamerNotes(
                                                                        e.target.value
                                                                    );
                                                                    setSaveButtonGeneralInfo(false);
                                                                }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Button
                                                    color="primary"
                                                    style={{ float: "right" }}
                                                    type="submit"
                                                    disabled={
                                                        saveButtonGeneralInfo || updatingGeneralInfo || !checkGeneralInfo()
                                                    }>
                                                    {" "}
                                                    {updatingGeneralInfo ? (
                                                        <Spinner size={"sm"} animation="border" />
                                                    ) : (
                                                        "Actualizar"
                                                    )}
                                                </Button>
                                                {"  "}
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </div>

                                <div className="card border shadow-none" style={{ marginBottom: "8rem" }}>
                                    <div className="card-header d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                    02
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">Datos de contacto</h5>
                                        </div>
                                    </div>
                                    <CardBody>
                                        <AvForm
                                            onValidSubmit={(e: any, v: any) => {
                                                handleValidSubmitContactData(e);
                                            }}>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="fullname"
                                                            label="Nombre completo *"
                                                            type="text"
                                                            errorMessage="Este campo es obligatorio"
                                                            required
                                                            placeholder="Ingresar nombre completo"
                                                            value={streamerContactName}
                                                            onChange={(e: any) => {
                                                                setStreamerContactName(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="email"
                                                            label="Email *"
                                                            type="text"
                                                            required
                                                            errorMessage="Este campo es obligatorio"
                                                            placeholder="Ingresar email"
                                                            value={streamerContactEmail}
                                                            onChange={(e: any) => {
                                                                setStreamerContactEmail(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        {/* <AvField
                                                            name="country"
                                                            label="País"
                                                            type="text"
                                                            required
                                                            placeholder="Ingresar país"
                                                            value={streamerContactCountry}
                                                            onChange={(e: any) => {
                                                                setStreamerContactCountry(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        /> */}
                                                        <AvField
                                                            name="country"
                                                            label="País *"
                                                            type="select"
                                                            errorMessage="Este campo es obligatorio"
                                                            required
                                                            value={streamerContactCountry}

                                                            // onChange={(e: any) => {
                                                            //     setStreamerNationality(
                                                            //         e.target.value
                                                            //     );
                                                            //     setSaveButtonGeneralInfo(false);
                                                            // }}
                                                            onChange={(e: any) => {
                                                                setStreamerContactCountry(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        >
                                                            <option value="">
                                                                Ingresar país
                                                            </option>
                                                            {countries.map(
                                                                (
                                                                    country: any,
                                                                    index: number
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                country.name +
                                                                                index
                                                                            }
                                                                            value={
                                                                                country.name
                                                                            }>
                                                                            {country.name === 'Spain' ? 'España' : country.name}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="state"
                                                            label="Provincia *"
                                                            type="text"
                                                            required
                                                            errorMessage="Este campo es obligatorio"
                                                            placeholder="Ingresar provincia"
                                                            value={streamerContactState}
                                                            onChange={(e: any) => {
                                                                setStreamerContactState(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="city"
                                                            label="Ciudad *"
                                                            type="text"
                                                            required
                                                            errorMessage="Este campo es obligatorio"
                                                            placeholder="Ingresar ciudad"
                                                            value={streamerContactCity}
                                                            onChange={(e: any) => {
                                                                setStreamerContactCity(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="address"
                                                            label="Dirección *"
                                                            type="text"
                                                            required
                                                            errorMessage="Este campo es obligatorio"
                                                            placeholder="Ingresar dirección"
                                                            value={streamerContactAddress}
                                                            onChange={(e: any) => {
                                                                setStreamerContactAddress(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="address_extra"
                                                            label="Información adicional"
                                                            type="text"
                                                            placeholder="Ingresar información adicional de facturación"
                                                            value={streamerContactAddressExtra}
                                                            onChange={(e: any) => {
                                                                setStreamerContactAddressExtra(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="zip_code"
                                                            label="Código postal *"
                                                            type="text"
                                                            required
                                                            placeholder="Ingresar código postal"
                                                            errorMessage="Completar información"
                                                            value={streamerContactZipCode}
                                                            onChange={(e: any) => {
                                                                setStreamerContactZipCode(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="phone"
                                                            label="Teléfono *"
                                                            type="text"
                                                            required
                                                            placeholder="Ingresar teléfono"
                                                            errorMessage="Completar información"
                                                            value={streamerContactPhone}
                                                            onChange={(e: any) => {
                                                                setStreamerContactPhone(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="discord"
                                                            label="Cuenta Discord"
                                                            type="text"
                                                            value={streamerDiscord || ""}
                                                            placeholder="Ingresar cuenta Discord"
                                                            onChange={(e: any) => {
                                                                setStreamerDiscord(e.target.value);
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Button
                                                color="primary"
                                                style={{ float: "right" }}
                                                type="submit"
                                                disabled={
                                                    saveButtonContactData || updatingContactData
                                                }>
                                                {" "}
                                                {updatingContactData ? (
                                                    <Spinner size={"sm"} animation="border" />
                                                ) : (
                                                    "Actualizar"
                                                )}
                                            </Button>
                                            {"  "}
                                        </AvForm>
                                    </CardBody>
                                </div>

                                <div className="card border shadow-none" style={{ marginBottom: "8rem" }}>
                                    <div className="card-header d-flex align-items-center">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar-sm">
                                                <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                                                    03
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="card-title">Datos de Facturación</h5>
                                        </div>
                                    </div>
                                    <CardBody>
                                        <div className="form-check form-check-info font-size-16 mb-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="same-contact"
                                                onClick={e =>
                                                    copyContactDataToBillingData(
                                                        e.currentTarget.checked
                                                    )
                                                }
                                            />
                                            <label
                                                className="form-check-label font-size-14"
                                                htmlFor="same-contact">
                                                ¿Utilizar mismos datos que en contacto?
                                            </label>
                                        </div>
                                        <AvForm
                                            onValidSubmit={(e: any, v: any) => {
                                                handleValidSubmitBillingData(e);
                                            }}>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_legal_name"
                                                            label="Nombre completo *"
                                                            type="text"
                                                            errorMessage="Este campo es obligatorio"
                                                            required
                                                            placeholder="Ingresar nombre completo"
                                                            value={streamerBillingName}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingName(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_email"
                                                            label="Email *"
                                                            type="text"
                                                            errorMessage="Este campo es obligatorio"
                                                            required
                                                            placeholder="Ingresar email"
                                                            value={streamerBillingEmail}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingEmail(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            type="select"
                                                            name="select"
                                                            label="Tipo de entidad fiscal *"
                                                            required
                                                            errorMessage="Este campo es obligatorio"
                                                            value={fiscalEntity?? "" }
                                                            onChange={(e: any) => {
                                                                setFiscalEntity(e.target.value);
                                                                setSaveButtonBillingData(false);
                                                            }}>
                                                            <option value=""></option>
                                                            <option value="natural_person">Particular</option>
                                                            <option value="company">Empresa</option>
                                                            <option value="freelance">Autónomo</option>
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_vat_identifier"
                                                            label={vatIdentifiers[fiscalEntity] || "NIF *"
                                                            }
                                                            type="text"
                                                            errorMessage="Este campo es obligatorio"
                                                            required
                                                            placeholder="Ingresar documento"
                                                            value={streamerBillingVat}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingVat(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_bank_account"
                                                            label="IBAN *"
                                                            errorMessage="Este campo es obligatorio"
                                                            type="text"
                                                            required
                                                            placeholder="Ingresar código IBAN"
                                                            value={streamerBillingBankAccount}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingBankAccount(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="streamerBicCode"
                                                            label={"BIC/SWIFT "}
                                                            type="text"
                                                            placeholder=" "
                                                            errorMessage="Este campo es obligatorio"
                                                            value={streamerBicCode}
                                                            onChange={(e: any) => {
                                                                setStreamerBicCode(e.target.value);
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        {/* <AvField
                                                            name="billing_country"
                                                            label="País"
                                                            type="text"
                                                            required
                                                            placeholder="Ingresar país"
                                                            value={streamerBillingCountry}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingCountry(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        /> */}

                                                        <AvField
                                                            name="billing_country"
                                                            label="País *"
                                                            type="select"
                                                            required
                                                            value={streamerBillingCountry}
                                                            errorMessage="Este campo es obligatorio"

                                                            onChange={(e: any) => {
                                                                setStreamerBillingCountry(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        >
                                                            <option value="">
                                                                Ingresar país
                                                            </option>
                                                            {countries.map(
                                                                (
                                                                    country: any,
                                                                    index: number
                                                                ) => {
                                                                    return (
                                                                        <option
                                                                            key={
                                                                                country.name +
                                                                                index
                                                                            }
                                                                            value={
                                                                                country.name
                                                                            }>
                                                                            {country.name === 'Spain' ? 'España' : country.name}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_state"
                                                            label="Provincia *"
                                                            type="text"
                                                            required
                                                            errorMessage="Este campo es obligatorio"
                                                            placeholder="Ingresar provincia"
                                                            value={streamerBillingState}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingState(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_city"
                                                            label="Ciudad *"
                                                            type="text"
                                                            required
                                                            errorMessage="Este campo es obligatorio"
                                                            placeholder="Ingresar ciudad"
                                                            value={streamerBillingCity}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingCity(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_address"
                                                            label="Dirección *"
                                                            type="text"
                                                            required
                                                            errorMessage="Este campo es obligatorio"
                                                            placeholder="Ingresar dirección"
                                                            value={streamerBillingAddress}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingAddress(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_address_extra"
                                                            label="Información adicional"
                                                            type="text"
                                                            placeholder="Ingresar información adicional"
                                                            value={streamerBillingAddressExtra}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingAddressExtra(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_zip_code"
                                                            label="Código postal * "
                                                            type="text"
                                                            required
                                                            placeholder="Ingresar código postal"
                                                            errorMessage="Completar información"
                                                            value={streamerBillingZipCode}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingZipCode(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="billing_phone"
                                                            label="Teléfono *"
                                                            type="text"
                                                            required
                                                            placeholder="Ingresar teléfono"
                                                            errorMessage="Completar información"
                                                            value={streamerBillingPhone}
                                                            onChange={(e: any) => {
                                                                setStreamerBillingPhone(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonBillingData(false);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            {streamerBillingCountry !== "Spain" ? (
                                                <Row>
                                                    <PdfUpload />
                                                </Row>
                                            ) : <></>}
                                            <Button
                                                color="primary"
                                                style={{ float: "right", marginTop: "1rem" }}
                                                type="submit"
                                                disabled={
                                                    saveButtonBillingData || updatingBillingData
                                                }>
                                                {" "}
                                                {updatingBillingData ? (
                                                    <Spinner size={"sm"} animation="border" />
                                                ) : (
                                                    "Actualizar"
                                                )}
                                            </Button>
                                            {"  "}
                                        </AvForm>
                                    </CardBody>
                                </div>

                                <div className="text-end">
                                    <Button
                                        outline
                                        color="primary"
                                        disabled={updating}
                                        onClick={toggle}
                                        style={{ float: "left" }}
                                    >
                                        Dar de baja
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
                <Modal isOpen={configurationTwitchModal} centered={true}>
                    <ModalHeader className="d-flex justify-content-center">
                        <img alt="twitchIcon" src={twitchIcon} width="25px" height="25px"></img>{" "}
                        Conexion con Twitch
                    </ModalHeader>
                    <ModalBody>
                        <h4 className=" mb-1 text-center" style={{ color: "#FFFFFF" }}>
                            ¡Bienvenido a 014 Live!{" "}
                        </h4>
                        <p className=" mb-3" style={{ margin: "10px 0 10px 0", color: "#FFFFFF" }}>
                            Para poder disfrutar de todas nuestras campañas tienes que conectarte
                            con tu cuenta de Twitch. Después, podrás completar tu información
                            personal y de facturación y ver todas las campañas que te están
                            esperando. ¡Adelante!
                        </p>

                        <TwitchSync />
                    </ModalBody>
                </Modal>
                <Modal isOpen={disabledAccount} centered={true}>
                    <ModalBody>
                        {/* <h4 className=" mb-1 text-center" style={{ color: "#FFFFFF" }}>
                            ¡Bienvenido a 014 Live!{" "}
                        </h4> */}
                        <div style={{ textAlign: "center" }}>
                            <p className=" mb-3" style={{ margin: "10px 0 0 0", color: "#FFFFFF", }}>
                                Tu cuenta ha sido desactivada. <br />
                                Ponte en contacto con <span style={{ color: "#038edc" }}>help@014live.com</span>
                            </p>
                            <a href="/logout">
                                <Button style={{ paddingLeft: "2rem", paddingRight: "2rem" }} color="danger">Salir</Button>
                            </a>
                        </div>

                    </ModalBody>
                </Modal>
                <Modal isOpen={pendingApprovalModal} centered={true}>
                    <ModalHeader>
                        <Link to="/" className="d-block auth-logo text-center">
                            <img src={logoDark} alt="" height="40" className="auth-logo-dark" />
                        </Link>
                    </ModalHeader>
                    <ModalBody>
                        <h5 className="text-muted">
                            Tu usuario está en proceso de validación por 014 Live! en estos
                            momentos. Para poder disfrutar de todo el contenido que te ofrecemos en
                            esta sección debes de esperar a que te confirmemos el registro. En
                            breves recibirás un email con la confirmación de tu cuenta. Muchas
                            gracias por registrarte en 014 Live!
                        </h5>
                    </ModalBody>
                </Modal>
            </Container>
        </div>
    );
};

export default withTranslation()(withRouter(UsertSetting));
