import React from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router";


const Affiliates = (props: any) => {

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Affiliates")}</title>
                </MetaTags>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(withRouter(Affiliates));