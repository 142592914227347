import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import { dateFilter, setEndDate, setStartDate } from "src/slices/Statistics/reducer";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { daysAgo as daysAgofn } from "src/helpers/functions_helper";
registerLocale("es", es);

const FilterHistorical = (props: any) => {
  const [daysAgo, setDaysAgo] = useState(7)
  const [daysFilter, setDaysFilter] = useState("all")

  const [startDate, setStartDate] = useState(daysAgofn(30))
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    props.handleCustomDate(startDate, endDate)
  }, [startDate, endDate])

  const [showSinceUntilDate, setShowSinceUntilDate] = useState(false);
  const handleSendDate = (date: any | Date) => {
    if (date === "custom") {
      setShowSinceUntilDate(true)
      return
    }
    if (date === "all") {
      props.handleDate(date)
      return
    }
    if (typeof date == "string") {
      props.handleDate(daysAgofn(parseInt(date)))
    }
    // console.log(typeof date)
  }
  useEffect(() => {
    if (daysFilter == "custom") {
      setShowSinceUntilDate(true);
    } else {
      setShowSinceUntilDate(false);
    }
  }, [daysFilter]);
  return (
    <React.Fragment>
      <Row className="mb-4" style={{ display: "flex", justifyContent: "right" }}>
        <Col sm={4}>
          <Input
            type="select"
            className="form-select"
            onChange={e => (handleSendDate(e.currentTarget.value))}
            defaultValue={daysFilter}>
            <option value={"all"}>Todos</option>
            <option value={7}>7 días</option>
            <option value={15}>15 días</option>
            <option value={30}>30 días</option>
            <option value={"custom"}>Personalizado</option>
          </Input>
        </Col>
        {showSinceUntilDate ? (
          <>
            <Col sm={2}>
              <DatePicker
                className="form-control d-block"
                placeholderText="Desde"
                locale="es"
                name="since"
                dateFormat="dd/MM/yyyy"
                selected={new Date(startDate)}
                onChange={(newValue: any) => { setStartDate(newValue) }}
              />
            </Col>
            <Col sm={2}>
              <DatePicker
                className="form-control d-block"
                placeholderText="Hasta"
                locale="es"
                name="until"
                dateFormat="dd/MM/yyyy"
                selected={new Date(endDate)}
                minDate={new Date(startDate)}
                onChange={(newValue: any) => { setEndDate(newValue) }}
              />
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
    </React.Fragment>
  );
};

export default FilterHistorical;
