import React from "react";
import classnames from "classnames";
import "./slider.css";

type Props = {
    items: number;
    active: number;
    onClick: (active: number) => void;
    onBlur: () => void;
};

const Dots = React.memo<Props>(({ items, active, onClick, onBlur }) => {
    return (
        <ul className={"dotList"}>
            {Array.from(Array(items).keys()).map((dot: number) => (
                <li
                    key={dot}
                    className={classnames("dot", {
                        active: active === dot,
                    })}
                    onBlur={() => onBlur()}
                    onClick={() => onClick(dot)}
                />
            ))}
        </ul>
    );
});

export default Dots;
