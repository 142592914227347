import { AxiosResponse } from "axios";
import * as url from "./url_helper";
import { ApiCore, ApiAdxtream } from "./api_helper";

const jsonResponseFromAxiosResponse = (axiosResponse: AxiosResponse<any>) => {
    return JSON.parse(JSON.stringify(axiosResponse));
};
const stringResponseFromAxiosResponse = (axiosResponse: AxiosResponse<any>) => {
    return JSON.stringify(axiosResponse);
};

// Login Method
const postLogin = (api: ApiCore, data: any) => {
    return api.create(url.POST_LOGIN, data);
};

// Register Method
const postUserStreamer = (api: ApiCore, data: any) => {
    return api
        .create(url.POST_STREAMER_REGISTER, data)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

// Profile Method
const getStreamerProfile = (api: ApiCore) => {
    return api
        .get(url.GET_STREAMER_PROFILE)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.message;
        })
        .catch(err => {
            throw err;
        });
};

const putStreamerProfile = (api: ApiCore, data: any) => {
    return api
        .create(url.POST_PROFILE, data)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const updateStreamerPlayout = (api: ApiCore, data: any) => {
    return api
        .create(url.POST_PLAYOUT, data)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
}

const updateContactData = (api: ApiCore, data: any) => {
    return api
        .create(url.POST_CONTACT_PROFILE, data)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const updateBillingData = (api: ApiCore, data: any) => {
    return api
        .create(url.POST_BILLING_PROFILE, data)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const postStreamerImage = (api: ApiCore, data: any) => {
    return api
        .create(url.POST_STREAMER_IMAGE, data)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const getSubscriptions = (api: ApiCore) => {
    return api
        .get(url.GET_STREAMER_SUSCRIBER_CAMPAIGNS)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.message;
        })
        .catch(err => {
            throw err;
        });
};
const getCampaigns = (api: ApiCore) => {
    return api
        .get(url.GET_STREAMER_CAMPAIGNS)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};


export const getStreamerKpi = (api: ApiCore, id: string) => api
    .get(url.GET_STREAMER_KPI + `?streamer_id=${id}`)
    .then(response => {
        const responseJson = jsonResponseFromAxiosResponse(response);
        if (responseJson.message === "ok") {
            return response;
        }
        throw responseJson.payload;
    })
    .catch(err => {
        throw err;
    });


export const getStreamerInvoices = (api: ApiCore, id: string = "") => api
    .get(url.GET_WALLET_INVOICES + `?streamer_id=${id}`)
    .then(response => {
        const responseJson = jsonResponseFromAxiosResponse(response);
        if (responseJson.message === "ok") {
            return response;
        }
        throw responseJson.payload;
    })
    .catch(err => {
        throw err;
    });


export const postSubscribeToCampaign = (api: ApiCore, campaign_id: any) => {
    return api
        .create(url.POST_STREAMER_SUBSCRIBE_CAMPAIGN, {
            campaign_id: campaign_id,
            streamer_id: localStorage.getItem("streamer_id"),
        })
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const postSubscriptionStatus = (api: ApiCore, subscription_id: any, status: any) => {
    // console.log("eso es lo que se envia", { api, subscription_id, status });
    return api
        .create(url.POST_STREAMER_SUBSCRIBER_STATUS, {
            subscription_id: subscription_id,
            status: status,
        })
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const getWalletActivity = (api: ApiCore) => {
    return api
        .get(url.GET_WALLET_ACTIVITY)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.message;
        })
        .catch(err => {
            throw err;
        });
};

const getWalletBallance = (api: ApiCore) => {
    return api.get(url.GET_ACTUAL_BALLANCE).then(response => {
        const responseJson = jsonResponseFromAxiosResponse(response);
        if (responseJson.message === "ok") {
            return response;
        }
        throw responseJson.message;
    });
};

const postWithdrawBallance = (api: ApiCore, data: any) => {
    return api
        .create(url.POST_WITHDRAW_BALLANCE, data)
        .then((response: any) => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.message;
        })
        .catch((err: any) => {
            throw err;
        });
};

export const getWalletByCampaigns = (api: ApiCore) => {
    return api.get(url.GET_WALLET_BY_CAMPAIGN + "?streamer_id=" + localStorage.getItem("streamer_id") ??
        ""
    ).then((response: any) => {
        const responseJson = jsonResponseFromAxiosResponse(response);
        if (responseJson.message === "ok") {
            return response.payload;
        }
        throw responseJson.payload;
    }).catch(err => {
        throw err;
    })
}

const getStreamerStatistics = (api: ApiCore) => {
    return api
        .get(
            url.GET_STREAMER_STATISTICS + "?streamer_id=" + localStorage.getItem("streamer_id") ??
            ""
        )
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const getStreamerSummary = (api: ApiCore, start_date: string, end_Date: string) => {
    return api
        .get(
            `${url.GET_STREAMER_SUMMARY}?streamer_id=${localStorage.getItem(
                "streamer_id"
            )}&start_date=${start_date}&end_date=${end_Date}`
        )
        .then((response: any) => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response.payload;
            }
            throw responseJson.payload;
        })
        .catch((err: Error) => {
            throw err;
        });
};

const getStreamerDaily = (api: ApiCore, start_date: string, end_Date: string) => {
    return api
        .get(
            `${url.GET_STREAMER_DAILY}?streamer_id=${localStorage.getItem(
                "streamer_id"
            )}&start_date=${start_date}&end_date=${end_Date}`
        )
        .then((response: any) => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response.payload;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const getStreamerStatus = () => {
    const api = new ApiAdxtream();

    return api
        .get(
            url.GET_STREAMER_STATUS.replace(
                "{streamerID}",
                localStorage.getItem("streamer_id") ?? ""
            )
        )
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            return responseJson;
        })
        .catch(error => {
            console.log(error);
        });
};

const rememberPassword = (api: ApiCore, email: any) => {
    return api
        .get(url.GET_REMEMBER_PASSWORD + "?email=" + email)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            throw err;
        });
};

const forgotPassword = (api: ApiCore, email: any) => {
    return api
        .create(url.POST_FORGOT_PASSWORD, { email })
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err;
        });
};

const getUrlRedirectTwitchAuth = (api: ApiCore) => {
    return api
        .get(url.GET_URL_REDIRECT_TWITCH_AUTH)
        .then(response => {
            const responseJson = jsonResponseFromAxiosResponse(response);
            if (responseJson.message === "ok") {
                return response;
            }
            throw responseJson.payload;
        })
        .catch(err => {
            console.log("err twitch", err);

            throw err;
        });
};

export const postStreamerLeave = (api: ApiCore, data: any) => {
    return api.create(url.POST_STREAMER_LEAVE, data).then(res => res).catch(err => {
        console.log("Couldn't realize the petition", err)
    })
}


export {
    postLogin,
    postUserStreamer,
    getStreamerProfile,
    putStreamerProfile,
    postStreamerImage,
    getCampaigns,
    getSubscriptions,
    postSubscriptionStatus,
    getWalletActivity,
    getWalletBallance,
    postWithdrawBallance,
    getStreamerStatistics,
    getStreamerSummary,
    getStreamerDaily,
    rememberPassword,
    getStreamerStatus,
    getUrlRedirectTwitchAuth,
    jsonResponseFromAxiosResponse,
    stringResponseFromAxiosResponse,
    updateContactData,
    updateBillingData,
    updateStreamerPlayout,
    forgotPassword,
};
