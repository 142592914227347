interface  LanguageProps{
    code: string;
    name : string;
}

const languagesIso: Array<LanguageProps> = [
    {
        "code": "af",
        "name": "Afrikaans"
    },
    {
        "code": "sq",
        "name": "Albanian"
    },
    {
        "code": "ar-dz",
        "name": "Arabic (Algeria)"
    },
    {
        "code": "ar-bh",
        "name": "Arabic (Bahrain)"
    },
    {
        "code": "ar-eg",
        "name": "Arabic (Egypt)"
    },
    {
        "code": "ar-iq",
        "name": "Arabic (Iraq)"
    },
    {
        "code": "ar-jo",
        "name": "Arabic (Jordan)"
    },
    {
        "code": "ar-kw",
        "name": "Arabic (Kuwait)"
    },
    {
        "code": "ar-lb",
        "name": "Arabic (Lebanon)"
    },
    {
        "code": "ar-ly",
        "name": "Arabic (Libya)"
    },
    {
        "code": "ar-ma",
        "name": "Arabic (Morocco)"
    },
    {
        "code": "ar-om",
        "name": "Arabic (Oman)"
    },
    {
        "code": "ar-qa",
        "name": "Arabic (Qatar)"
    },
    {
        "code": "ar-sa",
        "name": "Arabic (Saudi Arabia)"
    },
    {
        "code": "ar-sy",
        "name": "Arabic (Syria)"
    },
    {
        "code": "ar-tn",
        "name": "Arabic (Tunisia)"
    },
    {
        "code": "ar-ae",
        "name": "Arabic (U.A.E.)"
    },
    {
        "code": "ar-ye",
        "name": "Arabic (Yemen)"
    },
    {
        "code": "eu",
        "name": "Basque"
    },
    {
        "code": "be",
        "name": "Belarusian"
    },
    {
        "code": "bg",
        "name": "Bulgarian"
    },
    {
        "code": "ca",
        "name": "Catalan"
    },
    {
        "code": "zh-hk",
        "name": "Chinese (Hong Kong)"
    },
    {
        "code": "zh-cn",
        "name": "Chinese (PRC)"
    },
    {
        "code": "zh-sg",
        "name": "Chinese (Singapore)"
    },
    {
        "code": "zh-tw",
        "name": "Chinese (Taiwan)"
    },
    {
        "code": "hr",
        "name": "Croatian"
    },
    {
        "code": "cs",
        "name": "Czech"
    },
    {
        "code": "da",
        "name": "Danish"
    },
    {
        "code": "nl-be",
        "name": "Dutch (Belgium)"
    },
    {
        "code": "nl",
        "name": "Dutch (Standard)"
    },
    {
        "code": "en",
        "name": "English"
    },
    {
        "code": "en-au",
        "name": "English (Australia)"
    },
    {
        "code": "en-bz",
        "name": "English (Belize)"
    },
    {
        "code": "en-ca",
        "name": "English (Canada)"
    },
    {
        "code": "en-ie",
        "name": "English (Ireland)"
    },
    {
        "code": "en-jm",
        "name": "English (Jamaica)"
    },
    {
        "code": "en-nz",
        "name": "English (New Zealand)"
    },
    {
        "code": "en-za",
        "name": "English (South Africa)"
    },
    {
        "code": "en-tt",
        "name": "English (Trinidad)"
    },
    {
        "code": "en-gb",
        "name": "English (United Kingdom)"
    },
    {
        "code": "en-us",
        "name": "English (United States)"
    },
    {
        "code": "et",
        "name": "Estonian"
    },
    {
        "code": "fo",
        "name": "Faeroese"
    },
    {
        "code": "fa",
        "name": "Farsi"
    },
    {
        "code": "fi",
        "name": "Finnish"
    },
    {
        "code": "fr-be",
        "name": "French (Belgium)"
    },
    {
        "code": "fr-ca",
        "name": "French (Canada)"
    },
    {
        "code": "fr-lu",
        "name": "French (Luxembourg)"
    },
    {
        "code": "fr",
        "name": "French (Standard)"
    },
    {
        "code": "fr-ch",
        "name": "French (Switzerland)"
    },
    {
        "code": "gd",
        "name": "Gaelic (Scotland)"
    },
    {
        "code": "de-at",
        "name": "German (Austria)"
    },
    {
        "code": "de-li",
        "name": "German (Liechtenstein)"
    },
    {
        "code": "de-lu",
        "name": "German (Luxembourg)"
    },
    {
        "code": "de",
        "name": "German (Standard)"
    },
    {
        "code": "de-ch",
        "name": "German (Switzerland)"
    },
    {
        "code": "el",
        "name": "Greek"
    },
    {
        "code": "he",
        "name": "Hebrew"
    },
    {
        "code": "hi",
        "name": "Hindi"
    },
    {
        "code": "hu",
        "name": "Hungarian"
    },
    {
        "code": "is",
        "name": "Icelandic"
    },
    {
        "code": "id",
        "name": "Indonesian"
    },
    {
        "code": "ga",
        "name": "Irish"
    },
    {
        "code": "it",
        "name": "Italian (Standard)"
    },
    {
        "code": "it-ch",
        "name": "Italian (Switzerland)"
    },
    {
        "code": "ja",
        "name": "Japanese"
    },
    {
        "code": "ko",
        "name": "Korean"
    },
    {
        "code": "ku",
        "name": "Kurdish"
    },
    {
        "code": "lv",
        "name": "Latvian"
    },
    {
        "code": "lt",
        "name": "Lithuanian"
    },
    {
        "code": "mk",
        "name": "Macedonian (FYROM)"
    },
    {
        "code": "ml",
        "name": "Malayalam"
    },
    {
        "code": "ms",
        "name": "Malaysian"
    },
    {
        "code": "mt",
        "name": "Maltese"
    },
    {
        "code": "no",
        "name": "Norwegian"
    },
    {
        "code": "nb",
        "name": "Norwegian (BokmÃ¥l)"
    },
    {
        "code": "nn",
        "name": "Norwegian (Nynorsk)"
    },
    {
        "code": "pl",
        "name": "Polish"
    },
    {
        "code": "pt-br",
        "name": "Portuguese (Brazil)"
    },
    {
        "code": "pt",
        "name": "Portuguese (Portugal)"
    },
    {
        "code": "pa",
        "name": "Punjabi"
    },
    {
        "code": "rm",
        "name": "Rhaeto-Romanic"
    },
    {
        "code": "ro",
        "name": "Romanian"
    },
    {
        "code": "ro-md",
        "name": "Romanian (Republic of Moldova)"
    },
    {
        "code": "ru",
        "name": "Russian"
    },
    {
        "code": "ru-md",
        "name": "Russian (Republic of Moldova)"
    },
    {
        "code": "sr",
        "name": "Serbian"
    },
    {
        "code": "sk",
        "name": "Slovak"
    },
    {
        "code": "sl",
        "name": "Slovenian"
    },
    {
        "code": "sb",
        "name": "Sorbian"
    },
    {
        "code": "es-ar",
        "name": "Spanish (Argentina)"
    },
    {
        "code": "es-bo",
        "name": "Spanish (Bolivia)"
    },
    {
        "code": "es-cl",
        "name": "Spanish (Chile)"
    },
    {
        "code": "es-co",
        "name": "Spanish (Colombia)"
    },
    {
        "code": "es-cr",
        "name": "Spanish (Costa Rica)"
    },
    {
        "code": "es-do",
        "name": "Spanish (Dominican Republic)"
    },
    {
        "code": "es-ec",
        "name": "Spanish (Ecuador)"
    },
    {
        "code": "es-sv",
        "name": "Spanish (El Salvador)"
    },
    {
        "code": "es-gt",
        "name": "Spanish (Guatemala)"
    },
    {
        "code": "es-hn",
        "name": "Spanish (Honduras)"
    },
    {
        "code": "es-mx",
        "name": "Spanish (Mexico)"
    },
    {
        "code": "es-ni",
        "name": "Spanish (Nicaragua)"
    },
    {
        "code": "es-pa",
        "name": "Spanish (Panama)"
    },
    {
        "code": "es-py",
        "name": "Spanish (Paraguay)"
    },
    {
        "code": "es-pe",
        "name": "Spanish (Peru)"
    },
    {
        "code": "es-pr",
        "name": "Spanish (Puerto Rico)"
    },
    {
        "code": "es",
        "name": "Spanish (Spain)"
    },
    {
        "code": "es-uy",
        "name": "Spanish (Uruguay)"
    },
    {
        "code": "es-ve",
        "name": "Spanish (Venezuela)"
    },
    {
        "code": "sv",
        "name": "Swedish"
    },
    {
        "code": "sv-fi",
        "name": "Swedish (Finland)"
    },
    {
        "code": "th",
        "name": "Thai"
    },
    {
        "code": "ts",
        "name": "Tsonga"
    },
    {
        "code": "tn",
        "name": "Tswana"
    },
    {
        "code": "tr",
        "name": "Turkish"
    },
    {
        "code": "ua",
        "name": "Ukrainian"
    },
    {
        "code": "ur",
        "name": "Urdu"
    },
    {
        "code": "ve",
        "name": "Venda"
    },
    {
        "code": "vi",
        "name": "Vietnamese"
    },
    {
        "code": "cy",
        "name": "Welsh"
    },
    {
        "code": "xh",
        "name": "Xhosa"
    },
    {
        "code": "ji",
        "name": "Yiddish"
    },
    {
        "code": "zu",
        "name": "Zulu"
    }
]

export { languagesIso };