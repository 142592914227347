import React from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import classnames from "classnames";
import {
    // Link, 
    NavLink
} from "react-router-dom";
import './styles.css'
import ScrollToTopButton from "src/components/HorizontalLayout/ScrollUp";

const Cookies = (props: any) => {

    return (
        <React.Fragment>
            <div className="page-content" style={{ overflowX: "hidden", margin: " 0 3rem", marginTop: "6rem" }}>
                <MetaTags>
                    <title>{props.t("Politica de Cookies")}</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="mx-n4 mt-n4">
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <div className="faq-bg position-relative p-5">
                                        <NavLink to={"/"}>
                                            <i style={{ fontSize: "4rem", color: "white", fill: "white", position: "absolute", top: 10, left: 10, zIndex: 999 }} color="white" className="uil uil-angle-left-b"></i>
                                        </NavLink>
                                        <div className="bg-overlay bg-dark bg-gradient"></div>
                                        <div className="row justify-content-center">
                                            <Col lg={5}>
                                                <div className="text-center text-white-50">
                                                    <h3 className="text-white shadow-lg">POLÍTICA DE COOKIES</h3>
                                                </div>
                                            </Col>
                                        </div>
                                    </div>

                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-n5">
                        <Row>

                            <Container fluid>
                                <Card>
                                    <ScrollToTopButton />

                                    <CardBody className="p-5">
                                        <b className="termsTextBold" style={{ fontSize: "1.2rem", marginBottom: "0.6rem" }}>Utilización de Cookies por la web: </b>
                                        <p className="termsText" style={{ margin: "1rem" }}>La Web "streamer.pdec.es" , (en adelante la “Web”) utiliza Cookies. Las Cookies son ficheros enviados a un navegador por medio de un servidor web para registrar las actividades del Usuario en una web determinada. La primera finalidad de las Cookies es la de facilitar al usuario un acceso más rápido a los servicios seleccionados. Además, las Cookies personalizan los servicios que ofrece la Web, facilitando y ofreciendo a cada usuario información que es de su interés o que puede ser de su interés, en atención al uso que realiza de los Servicios.</p>
                                        <p className="termsText" style={{ margin: "1rem" }}>La Web utiliza Cookies para personalizar y facilitar al máximo la navegación del usuario. Las Cookies se asocian únicamente a un usuario anónimo y su ordenador y no proporcionan referencias que permitan deducir datos personales del usuario. El usuario podrá configurar su navegador para que notifique y rechace la instalación las Cookies enviadas por la Web, sin que ello perjudique la posibilidad del usuario de acceder a los contenidos de dicha web. Sin embargo, le hacemos notar que, en todo caso, la calidad de funcionamiento de la página Web puede disminuir.</p>

                                        <p className="termsText" style={{ margin: "1rem" }}>Los usuarios registrados, que se registren o que hayan iniciado sesión, podrán beneficiarse de unos servicios más personalizados y orientados a su perfil, gracias a la combinación de los datos almacenados en las cookies con los datos personales utilizados en el momento de su registro. Dichos usuarios autorizan expresamente el uso de esta información con la finalidad indicada, sin perjuicio de su derecho a rechazar o deshabilitar el uso de cookies.</p>

                                        <p className="termsText" style={{ margin: "1rem" }}>Asimismo, la Web podrá saber todos los servicios solicitados por los usuarios, de forma que podrán facilitar u ofrecer información adecuada a los gustos y preferencias de cada usuario.</p>
                                        <section>
                                            <b className="termsTextBold" style={{ fontSize: "1.2rem", marginBottom: "0.6rem" }}>{"A) Tipología, finalidad y funcionamiento de las Cookies"}</b>
                                            <p className="termsText" style={{ margin: "1rem" }}>Las Cookies, en función de su permanencia, pueden dividirse en “Cookies de sesión” o “permanentes”. Las primeras expiran cuando el usuario cierra el navegador. Las segundas expiran en función de cuando se cumpla el objetivo para el que sirven (por ejemplo, para que el usuario se mantenga identificado en los Servicios) o bien cuando se borran manualmente.</p>
                                            <p className="termsText" style={{ margin: "1rem" }}>Adicionalmente, en función de su objetivo, las Cookies pueden clasificarse de la siguiente forma:</p>
                                            <ul>
                                                <li>
                                                    <b>Cookies de rendimiento:</b>
                                                    <p className="termsText" style={{ margin: "1rem" }}>
                                                        Este tipo de Cookie recuerda sus preferencias para las herramientas que se encuentran en los servicios, por lo que no tiene que volver a configurar el servicio cada vez que usted visita. A modo de ejemplo, en esta tipología se incluyen:
                                                        <br />
                                                        -	Ajustes de volumen de reproductores de vídeo o sonido.
                                                        <br />
                                                        - 	Las velocidades de transmisión de vídeo que sean compatibles con su navegador.
                                                    </p>
                                                </li>
                                                <li>
                                                    <b>Cookies de geolocalización:</b>
                                                    <p className="termsText" style={{ margin: "1rem" }}>
                                                        Estas Cookies son utilizadas para averiguar en qué país se encuentra cuando se solicita un servicio. Esta Cookie es totalmente anónima, y sólo se utiliza para ayudar a orientar el contenido a su ubicación.
                                                    </p>
                                                </li>
                                                <li>
                                                    <b>Cookies de registro:</b>
                                                    <div style={{ margin: "1rem", display: "flex", flexDirection: "column", gap: "1rem", color: "white" }}>

                                                        Las Cookies de registro se generan una vez que el usuario se ha registrado o posteriormente ha abierto su sesión, y se utilizan para identificarle en los servicios con los siguientes objetivos:

                                                        <span>

                                                            - 	Mantener al usuario identificado de forma que, si cierra un servicio, el navegador o el ordenador y en otro momento u otro día vuelve a entrar en dicho servicio, seguirá identificado, facilitando así su navegación sin tener que volver a identificarse. Esta funcionalidad se puede suprimir si el usuario pulsa la funcionalidad “cerrar sesión”, de forma que esta Cookie se elimina y la próxima vez que entre en el servicio el usuario tendrá que iniciar sesión para estar identificado.
                                                        </span>

                                                        <span>
                                                            - 	Comprobar si el usuario está autorizado para acceder a ciertos servicios.
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <b>Cookies analíticas:</b>
                                                    <div style={{ margin: "1rem", display: "flex", flexDirection: "column", gap: "1rem", color: "white" }}>
                                                        <span>Cada vez que un Usuario visita un servicio, una herramienta de un proveedor externo (Google Analytics, Comscore y similares que podrán añadirse a este listado en caso de que varíen en relación con los actuales) genera una Cookie analítica en el ordenador del usuario. Esta Cookie que sólo se genera en la visita, servirá en próximas visitas a los Servicios de la Web para identificar de forma anónima al visitante. Los objetivos principales que se persiguen son:</span>
                                                        <span>
                                                            -   Permitir la identificación anónima de los usuarios navegantes a través de la “Cookie” (identifica navegadores y dispositivos, no personas) y por lo tanto la contabilización aproximada del número de visitantes y su tendencia en el tiempo.
                                                        </span>
                                                        <span>
                                                            -	Identificar de forma anónima los contenidos más visitados y por lo tanto más atractivos para los usuarios.
                                                        </span>
                                                        <span>
                                                            -	Saber si el usuario que está accediendo es nuevo o repite visita.
                                                        </span>
                                                        <span>Importante: Salvo que el usuario decida registrarse en un servicio de la Web, la “Cookie” nunca irá asociada a ningún dato de carácter personal que pueda identificarle. Dichas Cookies sólo serán utilizadas con propósitos estadísticos que ayuden a la optimización de la experiencia de los Usuarios en el sitio.</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <b className="termsTextBold" style={{ fontSize: "1.2rem", marginBottom: "0.6rem" }}>Cookies de publicidad:</b>
                                            <p className="termsText" style={{ margin: "1rem" }}>
                                                Este tipo de “Cookies” permite ampliar la información de los anuncios mostrados a cada usuario anónimo en los Servicios de la Web. Entre otros, se almacena la duración o frecuencia de visualización de posiciones publicitarias, la interacción con las mismas, o los patrones de navegación y/o compartimientos del usuario ya que ayudan a conformar un perfil de interés publicitario. De este modo, permiten ofrecer publicidad afín a los intereses del usuario.
                                            </p>

                                            <b className="termsTextBold" style={{ fontSize: "1.2rem", marginBottom: "0.6rem" }}>Cookies publicitarias de terceros:</b>
                                            <p className="termsText" style={{ margin: "1rem" }}>
                                                Además de la publicidad gestionada por la Web en sus Servicios, la Web ofrece a sus anunciantes la opción de servir anuncios a través de terceros (“AdServers”). De este modo, estos terceros pueden almacenar Cookies enviadas desde los Servicios de la Web procedentes
                                                de los navegadores de los Usuarios, así como acceder a los datos que en    ellas se guardan.
                                            </p>
                                            <div>
                                                <p className="termsText" style={{ margin: "1rem" }}>
                                                    En función de qué entidad gestiona las cookies podemos clasificar las Cookies de esta web de la siguiente forma:
                                                </p>
                                                <ul>
                                                    <li>
                                                        <b className="termsTextBold" style={{ fontSize: "1.2rem", marginBottom: "0.6rem" }}>Cookies propias:</b>
                                                        <p className="termsText" style={{ margin: "1rem" }}>
                                                            Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario. Tienen como finalidad detectar si está identificado el usuario en el control de cookies y poder controlar si se ha realizado la aceptación de la política de cookies para dejar de mostrar el cuadro superior al objeto de facilitar la navegación.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <b className="termsTextBold" style={{ fontSize: "1.2rem", marginBottom: "0.6rem" }}>Cookies de terceros:</b>
                                                        <p className="termsText" style={{ margin: "1rem" }}>
                                                            son aquellas que se envían a su equipo web desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad, que trata la información obtenida a través de las mismas.
                                                        </p>
                                                        <p className="termsText" style={{ margin: "1rem" }}>
                                                            Por lo que respecta a las siguientes cookies de terceros, no se hace responsable del contenido y veracidad de las políticas de privacidad de los terceros incluidas en sus políticas de privacidad y/o de cookies:
                                                        </p>
                                                        <p className="termsText" style={{ margin: "1rem" }}>
                                                            - Google Analytics – Cookies analíticas. Es la herramienta de análisis de Google que se utiliza para: limitar el porcentaje de solicitudes, Duración: 90 días (_gat_gtag_UA_160236275_72); para distinguir a los usuarios, Duración 2 años (_ga); para distinguir a los usuarios, duración 24horas (_gid)
                                                        </p>

                                                    </li>
                                                </ul>
                                            </div>

                                        </section>
                                        <section>
                                            <b>{"B) Cómo configurar las cookies en el sitio Web"}</b>
                                            <b>{"(I) Cómo deshabilitar las Cookies en el centro de configuración de cookies"}</b>
                                            <p className="termsText"  >
                                                Puedes aceptar o rechazar el uso de cookies a través del centro de configuración de cookies que ponemos a tu disposición, y al que puedes acceder tanto a través del mensaje de información básica sobre cookies que aparece al visitar la Web por primera vez, como a través del siguiente enlace, que te dirigirá al centro de configuración de cookies:
                                            </p>
                                            <p className="termsText"  >
                                                En el centro de configuración de cookies podrás consentir el uso de cookies, así como retirar cualquier consentimiento para su uso ya otorgado, en cualquier momento y tantas veces como desees.
                                            </p>
                                            <p className="termsText"  >
                                                Ten en cuenta que, si aceptas las cookies de terceros, deberás eliminarlas, si así lo deseas, directamente a través de las opciones de gestión de cookies de tu navegador, tal y como explicamos en el siguiente punto.
                                            </p>
                                            <b>{"(II) Cómo deshabilitar las Cookies en los principales navegadores"}</b>
                                            <p className="termsText"  >
                                                Normalmente es posible dejar de aceptar las Cookies del navegador, o dejar de aceptar las Cookies de un Servicio en particular.
                                            </p>
                                            <p className="termsText"  >
                                                Todos los navegadores modernos permiten cambiar la configuración de Cookies. Estos ajustes normalmente se encuentran en las "opciones" o "preferencias" del menú de su navegador. Asimismo, puede configurar su navegador o su gestor de correo electrónico, así como instalar complementos gratuitos para evitar que se descarguen los Web Bugs al abrir un email.
                                            </p>
                                            <div>
                                                <p className="termsText"  >
                                                    La Web ofrece orientación al Usuario sobre los pasos para acceder al menú de configuración de las cookies y, en su caso, de la navegación privada en cada uno de los navegadores principales:
                                                </p>
                                                <p className="termsText"  >
                                                    {"- Internet Explorer: Herramientas - > Opciones de Internet - > Privacidad - > Configuración."} <br />
                                                    {"Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador."}
                                                </p>
                                                <p className="termsText"  >
                                                    {"- Firefox: Herramientas - > Opciones - > Privacidad - > Historial - > Configuración personalizada."}
                                                </p>
                                                <p className="termsText"  >
                                                    {"- Chrome: Configuración - > Mostrar opciones avanzadas - > Privacidad - > Configuración de contenido."}
                                                </p>
                                                <p className="termsText"  >{"- Safari: Preferencias - > Seguridad."} <br />
                                                    {"Para más información, puede consultar el soporte de Apple o la Ayuda del navegador."}</p>
                                            </div>
                                        </section>
                                        <section>
                                            <b>{"C) Qué base jurídica utilizamos para tratar tus datos personales"}</b>
                                            <p className="termsText"  >{"El tratamiento de tus datos personales es necesario para las finalidades indicadas anteriormente - apartado a) -, y su base jurídica es el consentimiento del usuario, que otorga cuando acepta su uso a través del botón habilitado a tales efectos. En todo caso, de conformidad con lo establecido en el apartado b) anterior, los usuarios pueden revocar dicho consentimiento en cualquier momento. "}</p>
                                        </section>
                                        <section>
                                            <b>{"D) Durante cuánto tiempo se conservarán las cookies"}</b>
                                            <p className="termsText"  >
                                                {"Los plazos de conservación de las cookies dependerán del tipo de cookie y de su titular. Puedes encontrar más información sobre estos plazos en el apartado a) de esta política. "}
                                            </p>
                                        </section>

                                        <section>
                                            <b>{"E) Cesiones y transferencias internacionales a terceros"}</b>
                                            <p className="termsText"  >Los usuarios pueden obtener información acerca de las transferencias a terceros países que, en su caso, realicen los terceros identificados en esta política de cookies en sus correspondientes políticas.
                                                Asimismo, tus datos podrán ser comunicados a autoridades competentes en el ejercicio de sus funciones.</p>
                                        </section>
                                        <section>
                                            <b>Actualizaciones y cambios en la política de cookies.</b>
                                            <p className="termsText">Esta Política de Cookies de la Web puede modificarse en función de las exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por ello se aconseja a los usuarios que la visiten periódicamente.
                                                Cuando se produzcan cambios significativos en esta Política de Cookies, se comunicarán a los usuarios bien mediante la Web o a través de correo electrónico a los usuarios registrados.
                                                Última actualización de la Política de Cookies: febrero de 2023</p>
                                        </section>
                                    </CardBody>
                                </Card>
                            </Container>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(withRouter(Cookies));