import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Card, Container } from "reactstrap";
import WalletActivityTable from "src/components/Wallet/WalletActivityTable";
import WalletFiltersTable, {
    ACTIVES_NAV,
    AVAILABLE_NAV,
    BILLS_NAV,
    PAYMENTS_NAV,
    WITHDRAW_NAV,
} from "src/components/Wallet/WalletFiltersTable";
import WidgetsWallet from "src/components/Wallet/WidgetsWallet";
import { ApiCore } from "src/helpers/api_helper";
import {
    getCampaigns,
    getStreamerInvoices,
    getSubscriptions,
    getWalletActivity,
    getWalletBallance,
} from "src/helpers/backend_helper";
import { walletTableFilterMode } from "src/slices/Wallet/reducer";
import WalletHeader from "src/components/Wallet/WalletHeader";
import Notifications from "src/components/Notifications/Notifications";
import AvailablesTable from "src/components/Wallet/AvailablesTable";
import WithdrawedTable from "src/components/Wallet/WithdrawedTable";
import ActivesTable from "src/components/Wallet/ActivesTable";
import WalletBillsTable from "src/components/Wallet/WalletBillsTable";
import { useLocation } from "react-router-dom";

const Wallet = (props: any) => {
    const dispatch = useDispatch();
    const [subscriptions, setSubscriptions] = useState<Array<any> | null | unknown>();
    const [campaigns, setCampaigns] = useState<Array<any> | null | unknown>();
    const [walletActivity, setWalletActivity] = useState<Array<any> | null | unknown>();
    const [invoices, setInvoices] = useState<Array<any> | null | unknown>([]);

    const search = useLocation().search
    const name = new URLSearchParams(search).get('tab');

    const [successAlert, setSuccessAlert] = useState(false);
    const [dangerAlert, setDangerAlert] = useState(false);
    const [availableBalance, setAvailableBalance] = useState(0);
    const [accumulatedBalance, setAccumulatedBalance] = useState(0);
    const [pendingBalance, setPendingBalance] = useState(0);

    const { tableFilterMode } = useSelector((state: any) => ({
        tableFilterMode: state.wallet.tableFilterMode,
    }));

    const { status } = useSelector((state: any) => ({
        status: state.wallet.status,
    }));
    const { dateFilter } = useSelector((state: any) => ({
        dateFilter: state.wallet.dateFilter,
    }));

    const getDateXDaysAgo = (numOfDays: number, date = new Date()) => {
        const daysAgo = new Date(date.getTime());

        daysAgo.setDate(date.getDate() - numOfDays);

        return daysAgo;
    };

    const applyDateFilter = (subscriptions: any) => {
        let filtered =
            dateFilter !== "all" && Array.isArray(subscriptions)
                ? subscriptions.filter((subscription: any) => {
                    const sinceDate = getDateXDaysAgo(dateFilter);
                    return sinceDate <= new Date(subscription.campaign.start_date);
                })
                : subscriptions;
        return filtered;
    };

    useEffect(() => {
        dispatch(walletTableFilterMode("Saldos Vivos"));
        if (name) dispatch(walletTableFilterMode(name));
    }, [dispatch, name]);

    useEffect(() => {
        getSubscriptions(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
            let subscriptionsResponse = Object.values(data.payload.streamers)[0];

            subscriptionsResponse = applyDateFilter(subscriptionsResponse);

            getCampaigns(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                const allCampaigns = Object.values(data.payload.streamers)[0];
                setCampaigns(allCampaigns);
                setSubscriptions(subscriptionsResponse);
            });
        });

        // getWalletActivity(new ApiCore(localStorage.getItem("token")))
        //     .then((data: any) => {
        //         let walletActivityResponse = Object.values(data.payload.streamers)[0];
        //         walletActivityResponse = applyDateFilter(walletActivityResponse);
        //         setWalletActivity(walletActivityResponse);
        //     })

        setWalletActivity([
            {
                entry_id: "a19880c4-e977-42f5-9c1d-3f9516cc6111",
                type: "Ganancia",
                streamer_id: "8379eaea-8236-4183-b674-844c99263b3b",
                stream_id: null,
                campaign_id: "97602a60-ee84-43b1-94f9-5090adeda6b2",
                currency: "EUR",
                amount: 750759.24,
                date: "2022-12-05T15:46:13.000000Z",
                description: "Campañas 1 - ganancia",
            },
            {
                entry_id: "a19880c4-e977-42f5-9c1d-3f9516cc6111",
                type: "Ganancia",
                streamer_id: "8379eaea-8236-4183-b674-844c99263b3b",
                stream_id: null,
                campaign_id: "97602a60-ee84-43b1-94f9-5090adeda6b2",
                currency: "EUR",
                amount: 750759.24,
                date: "2022-12-01T15:46:13.000000Z",
                description: "Campañas 2 - ganancia",
            },
        ]);


        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            getWalletActivity(new ApiCore(localStorage.getItem("token"))).then((res: any) => setWalletActivity(res.payload.streamers[localStorage.getItem('streamer_id') as string]))
            getWalletBallance(new ApiCore(localStorage.getItem("token"))).then((el: any) => {
                const wallet: any = Object.values(el.payload)[0];
                setPendingBalance(
                    wallet?.withdrawal_pending_balance[streamer.currency_iso] ||
                    wallet?.withdrawal_pending_balance["EUR"] ||
                    0
                );
                setAvailableBalance(wallet?.available_balance[streamer.currency_iso] || wallet?.available_balance["EUR"] || 0)
                setAccumulatedBalance(
                    wallet?.accumulated_balance[streamer.currency_iso] ||
                    wallet?.accumulated_balance["EUR"] ||
                    0
                );
            });
            getStreamerInvoices(new ApiCore(localStorage.getItem('token')), localStorage.getItem('streamer_id') || undefined).then((res: any) => {
                setInvoices(res.payload.data)
                // console.log(res)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter, status]);

    const tableMode = () => {
        switch (tableFilterMode) {
            case ACTIVES_NAV:
                return <ActivesTable campaigns={campaigns} subscriptions={subscriptions} />;
            case AVAILABLE_NAV:
                return <AvailablesTable campaigns={campaigns} subscriptions={subscriptions} />;
            case WITHDRAW_NAV:
                return <WithdrawedTable campaigns={campaigns} subscriptions={subscriptions} />;
            case PAYMENTS_NAV:
                return <WalletActivityTable activities={walletActivity} />;
            case BILLS_NAV:
                return <WalletBillsTable bills={invoices} />
            default:
                return <></>;
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Wallet")}</title>
                </MetaTags>
                <Container fluid>
                    <Notifications active={successAlert} type={"withdraw"} />
                    <Notifications active={dangerAlert} type={"errorWithdraw"} />
                    <WidgetsWallet
                        accumulatedBalance={accumulatedBalance}
                        pendingBalance={pendingBalance}
                        error={setDangerAlert}
                        success={setSuccessAlert}
                        availableBalance={availableBalance}
                    />
                    <WalletHeader
                        error={setDangerAlert}
                        success={setSuccessAlert}
                        availableBalance={availableBalance}
                    />
                    <Card style={{ marginTop: "3rem" }}>
                        <Container className="wallet3" style={{ minHeight: "400px" }} fluid>
                            <WalletFiltersTable />
                            {/* {tableFilterMode === "Campañas" ?
                                <WalletCampaignsTable campaigns={campaigns} subscriptions={subscriptions} /> :
                                <></>
                            }

                            {tableFilterMode === "Transacciones" ?
                                <WalletActivityTable activities={walletActivity} /> :
                                <></>
                            } */}
                            {tableMode()}
                        </Container>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(withRouter(Wallet));
