export const ASSIGNED = {
    original: "assigned",
    translation: "Activa",
    color: "badge-soft-success",
};

export const PREPARED = {
    original: "prepared",
    translation: "Suscrita",
    color: "badge-soft-primary",
};

export const STREAMER_AGREED = {
    original: "streamer-agreed",
    translation: "Pendiente confirmación",
    color: "badge-soft-warning",
};

export const PAUSED = {
    original: "streamer-paused",
    translation: "Pausada",
    color: "badge-soft-streamer",
};

export const STREAMER_PENDING = {
    original: "streamer-pending",
    translation: "Disponible",
    color: "badge-soft-success",
};

export const STREAMER_REQUESTED = {
    original: "streamer-requested",
    translation: "Requerida",
    color: "badge-soft-warning",
};

export const STREAMER_REFUSED = {
    original: "streamer-refused",
    translation: "Rechazada",
    color: "badge-soft-danger",
};

export const OPERATIONAL = {
    original: "operational",
    translation: "Operando",
    color: "badge-soft-info",
};

export const OPERATOR_PAUSED = {
    original: "operator-paused",
    translation: "Pausada",
    color: "badge-soft-streamer",
}

export const FINISHED = {
    original: "finished",
    translation: "Finalizada",
    color: "badge-soft-streamer",
}

export const getStatusByCode = (code: any) => {
    switch (code) {
        case PREPARED.original:
            return PREPARED;
        case ASSIGNED.original:
            return ASSIGNED;
        case STREAMER_AGREED.original:
            return STREAMER_AGREED;
        case PAUSED.original:
            return PAUSED;
        case STREAMER_PENDING.original:
            return STREAMER_PENDING;
        case STREAMER_REQUESTED.original:
            return STREAMER_REQUESTED;
        case STREAMER_REFUSED.original:
            return STREAMER_REFUSED;
        case OPERATIONAL.original:
            return OPERATIONAL;
        case OPERATOR_PAUSED.original:
            return OPERATOR_PAUSED;
        case FINISHED.original:
            return FINISHED;
    }
};
