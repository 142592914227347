import { createSlice } from "@reduxjs/toolkit";
import { dateFormatter, daysAgoFormatted } from "src/helpers/functions_helper";

interface StatisticsStateType {
    dateFilter: any;
    selectGraphCode: any;
    startDate: any;
    endDate: any;
}
export const initialState: StatisticsStateType = {
    dateFilter: 7,
    startDate: daysAgoFormatted(),
    endDate: dateFormatter(),
    selectGraphCode: 1, //codigo del grafico seleccionado
};

const statisticsSlice = createSlice({
    name: "statistics",
    initialState,
    reducers: {
        dateFilter(state, action) {
            if (action.payload === "custom") {
                state.dateFilter = "custom";
                return;
            } else {
                state.dateFilter = "none";
            }
            state.endDate = dateFormatter();
            state.startDate = daysAgoFormatted(action.payload);
        },
        selectGraphCode(state, action) {
            state.selectGraphCode = action.payload;
        },
        setStartDate(state, action) {
            state.startDate = dateFormatter((action.payload));
            
        },
        setEndDate(state, action) {
            state.endDate = dateFormatter(action.payload);
        },
    },
});

export const { dateFilter, selectGraphCode, setStartDate, setEndDate } = statisticsSlice.actions;

export default statisticsSlice.reducer;
