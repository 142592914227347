import { CampanasActivasIcon, CampanasDisponiblesIcon, RevenueIcon, SaldoDispIcon } from "src/assets/icons/Icons"
import { NewHomeWidgetCampaign } from "../Campaigns/NewHomeWidgetCampaign"
import GetData from "./GetData"


const HomeCards = () => {
  const { availableCampaigns, availableBalance, revenue, activeCampaigns } = GetData()
  return (<>
    <NewHomeWidgetCampaign icon={<SaldoDispIcon />} color="#405601" decimals={2} suffix="€" WidgetData={availableBalance} WidgetTitle={"Saldo disponible"} />
    <NewHomeWidgetCampaign icon={<RevenueIcon />} color="#668A03" decimals={2} suffix="€" WidgetData={revenue} WidgetTitle={"Saldo Acumulado"} />
    <NewHomeWidgetCampaign icon={<CampanasActivasIcon />} color="#8c004b" WidgetData={activeCampaigns} WidgetTitle={"Campañas activas"} />
    <NewHomeWidgetCampaign icon={<CampanasDisponiblesIcon />} color="#007aff" WidgetData={availableCampaigns} WidgetTitle={"Campañas disponibles"} />

  </>)
}


export default HomeCards