import { createSlice } from "@reduxjs/toolkit";

interface EmissionStateType {
    coordinates?: object,
}
export const initialState: EmissionStateType = {
    coordinates: {x: 0, y: 0},

};

const EmissionSlice = createSlice({
    name: "emission",
    initialState,
    reducers: {
        coordinatesChange(state, action) {
            state.coordinates = action.payload
        },
    },
});

export const {
    coordinatesChange,
} = EmissionSlice.actions

export default EmissionSlice.reducer;