import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import logolight from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";
import { AvForm, AvField } from "availity-reactstrap-validation";

const NewPassword = (props: any) => {
    const [password, setPassword] = useState();
    const equalToPassword = (value: any, ctx: any) => {
        if (ctx.password !== ctx.confirmPassword) {
            return "No coinciden las contraseña";
        }
        return true;
    }

    // handleValidSubmit
    const handleValidSubmit = (values: any) => {
        //
        console.log(password)
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>Nueva Contraseña</title>
            </MetaTags>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay bg-white"></div>
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={4}>
                                <div className="py-5">
                                    <div className="mb-4 mb-md-5">
                                        <Link to="/" className="d-block auth-logo text-center">
                                            <img src={logoDark} alt="" height="40" className="auth-logo-dark" />
                                            <img src={logolight} alt="" height="40" className="auth-logo-light" />
                                        </Link>
                                    </div>
                                    <div className="text-center mb-4">
                                        <h5 className="">Cambiar contraseña</h5>
                                    </div>
                                    <AvForm
                                        className="needs-validation custom-form mt-4 pt-2"
                                        onValidSubmit={(e: any, v: any) => {
                                            handleValidSubmit(v);
                                        }}
                                    >
                                        <div className="form-floating form-floating-custom mb-3 light">
                                            <AvField
                                                name="password"
                                                label="Nueva contraseña"
                                                type="password"
                                                required
                                                errorMessage="Completar información"
                                                onChange={(e: any) => { setPassword(e.target.value) }}
                                                placeholder="Ingresar nueva contraseña"
                                            />
                                        </div>

                                        <div className="form-floating form-floating-custom mb-3 light">
                                            <AvField
                                                name="confirmPassword"
                                                label="Confirmar nueva contraseña"
                                                type="password"
                                                required
                                                validate={{ myValidation: equalToPassword }}
                                                errorMessage="Completar información"
                                                placeholder="Confirmar nueva contraseña"
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-info w-100" type="submit">Cambiar contraseña</button>
                                        </div>
                                    </AvForm>

                                    <div className="mt-5 text-center">
                                        <p> Ya tenes cuenta? <Link to="/login" className="fw-medium text-decoration-underline"> Login </Link></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )

}

export default NewPassword;


export async function getServerSideProps(context: any) {
    const hola = context.params
    return { props: { hola } }
}