const TOUR_INDEX = {
    WELCOME: {
        INDEX: 0,
    },
    INFO_FORM: {
        INDEX: 1,
    },
    CONTACT_FORM: {
        INDEX: 2,
    },
    BILLING_FORM: {
        INDEX: 3,
    },
    ACCEPT: {
        INDEX: 4,
    },
    POSITION: {
        INDEX: 5,
    },
    CADENCE: {
        INDEX: 6,
    },
    BEGGINING: {
        INDEX: 7,
    },
    PERSONAL_DATA: {
        INDEX: 8,
    },
    KPIS: {
        INDEX: 9,
    },
    AVAILABLE_CAMPAIGNS: {
        INDEX: 10,
    },
    HOME_END: {
        INDEX: 11,
    },
    CAMPAIGN: {
        INDEX: 12,
    },
    CAMPAIGN_1: {
        INDEX: 13,
    },
    CAMPAIGN_2: {
        INDEX: 14,
    },
    CAMPAIGN_3: {
        INDEX: 15,
    },
    CAMPAIGN_4: {
        INDEX: 16,
    },
    CAMPAIGN_5: {
        INDEX: 17,
    },
    CAMPAIGN_END: {
        INDEX: 18,
    },
    WALLET: {
        INDEX: 19,
    },
    WALLET_1: {
        INDEX: 20,
    },
    WALLET_2: {
        INDEX: 21,
    },
    WALLET_3: {
        INDEX: 22,
    },
    WALLET_4: {
        INDEX: 23,
    },
    WALLET_5: {
        INDEX: 24,
    },
    WALLET_6: {
        INDEX: 25,
    },
    WALLET_7: {
        INDEX: 26,
    },
    WALLET_END: {
        INDEX: 27,
    },
    STATS: {
        INDEX: 28,
    },
    STATS_1: {
        INDEX: 29,
    },
    STATS_2: {
        INDEX: 30,
    },
    STATS_3: {
        INDEX: 31,
    },
    TOUR_END: {
        INDEX: 32,
    },
};


const ALT_TOUR_INDEX = {
    WELCOME: {
        INDEX: 0,
    },


    PERSONAL_DATA: {
        INDEX: 1,
    },
    KPIS: {
        INDEX: 2,
    },
    AVAILABLE_CAMPAIGNS: {
        INDEX: 3,
    },
    HOME_END: {
        INDEX: 4,
    },
    CAMPAIGN: {
        INDEX: 5,
    },
    CAMPAIGN_1: {
        INDEX: 6,
    },
    CAMPAIGN_2: {
        INDEX: 7,
    },
    CAMPAIGN_3: {
        INDEX: 8,
    },
    CAMPAIGN_4: {
        INDEX: 9,
    },
    CAMPAIGN_5: {
        INDEX: 10,
    },
    CAMPAIGN_END: {
        INDEX: 11,
    },
    WALLET: {
        INDEX: 12,
    },
    WALLET_1: {
        INDEX: 13,
    },
    WALLET_2: {
        INDEX: 14,
    },
    WALLET_3: {
        INDEX: 15,
    },
    WALLET_4: {
        INDEX: 16,
    },
    WALLET_5: {
        INDEX: 17,
    },
    WALLET_6: {
        INDEX: 18,
    },
    WALLET_7: {
        INDEX: 19,
    },
    WALLET_END: {
        INDEX: 20,
    },
    STATS: {
        INDEX: 21,
    },
    STATS_1: {
        INDEX: 22,
    },
    STATS_2: {
        INDEX: 23,
    },
    STATS_3: {
        INDEX: 24,
    },
    TOUR_END: {
        INDEX: 25,
    },
};

export { TOUR_INDEX, ALT_TOUR_INDEX };