import React from "react";
import { NavLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

// import { Container, Row, Col } from "reactstrap";

const Footer = () => {



  return (
    <React.Fragment>
      <footer className="footer" >
        <section>
        </section>
        <section className="footer-links">
          <NavLink target="_blank" to={"/terms-conditions"}>Terminos y Condiciones</NavLink>
          <NavLink target="_blank" to={"/cookies-policy"}>Politica de Cookies</NavLink>
          <NavLink target="_blank" to={"/privacy"}>Politica de Privacidad</NavLink>

          Contactar:
          <CopyToClipboard
            text={
              "streamer@014live.com"
            }>

            <div style={{ color: "#038edc", cursor: "pointer" }}>
              streamer@014live.com
            </div>


          </CopyToClipboard>
        </section>
        {}
      </footer>
    </React.Fragment>
  );
};

export default Footer;
