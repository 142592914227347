import { useEffect, useState } from "react"
import { getStreamerKpi, getStreamerStatistics, getWalletBallance } from "src/helpers/backend_helper"
import { ApiCore } from "src/helpers/api_helper"
import { useSelector } from "react-redux";




const GetData = () => {

  const { statusUpdated } = useSelector((state: any) => state.campaign)
  const [myCampaigns, setMyCampaigns] = useState(0)
  const [activeCampaigns, setActiveCampaigns] = useState(0)
  const [subscribed_campaigns, setSubscribed_campaigns] = useState(0)
  const [availableCampaigns, setAvailableCampaigns] = useState(0)
  const [allCampaigns, setAllCampaigns] = useState(0)
  const [availableBalance, setAvailableBalance] = useState(0)
  const [revenue, setRevenue] = useState(0)


  const update = () => {
    const token = localStorage.getItem('token')
    const id = localStorage.getItem('streamer_id')

    const getProfile = localStorage.getItem("profile");
    if (getProfile) {
      const obj = JSON.parse(getProfile);
      const streamer = obj.streamers?.[0];

      getWalletBallance(new ApiCore(localStorage.getItem("token"))).then((el: any) => {
        const wallet: any = Object.values(el.payload)[0]
        setAvailableBalance(wallet?.available_balance[streamer.currency_iso] || wallet?.available_balance["EUR"] || 0)

      })
      getStreamerStatistics(new ApiCore(token)).then((res: any) => {
        setRevenue(res?.payload?.earnings?.year_to_date_earnings[streamer.currency_iso] || res?.payload?.earnings?.year_to_date_earnings["EUR"] || 0)
      })
    }

    getStreamerKpi(new ApiCore(token), id as any).then(({ payload }: any) => {
      setAllCampaigns(payload.total_campaigns)
      setMyCampaigns(payload.active_campaigns)
      setActiveCampaigns(payload.active_campaigns)
      setAvailableCampaigns(payload.available_campaigns)
      setSubscribed_campaigns(payload.subscribed_campaigns)
    })


    // const myCampaings: any = getCampaigns(new ApiCore(token));
    // myCampaings.then((data: any) => {
    //   const allCampaigns: any = Object.values(data.payload.streamers)[0];
    //   const myCampaings: any = Array.isArray(allCampaigns) ? allCampaigns.filter((e: any) => {
    //     if (myCampaignsStatus.includes(e.subscription_status)) {
    //       return true;
    //     }
    //     return false;
    //   }) : []
    //   const availableCampaigns = Array.isArray(allCampaigns) ? allCampaigns.filter((e: any) => {
    //     if (availableCampaignsStatus.includes(e.subscription_status)) {
    //       return true;
    //     }
    //     return false;
    //   }) : []
    //   const activeCampaigns = Array.isArray(allCampaigns) ? allCampaigns.filter((e: any) => {
    //     if (e.subscription_status === activeCampaignsStatus) {
    //       return true;
    //     }
    //     return false;
    //   }) : []

    //   setAvailableCampaigns(availableCampaigns.length)
    //   setAllCampaigns(activeCampaigns.length)
    //   setMyCampaigns(myCampaings.length)
    // })
  }
  // const myCampaignsStatus = ["assigned", "streamer-agreed", "paused"];
  // const availableCampaignsStatus = ["streamer-pending", "streamer-requested", "streamer-refused"];
  // const activeCampaignsStatus = "assigned"
  useEffect(() => {
    update()
  }, [statusUpdated])
  useEffect(() => {
    update()
  }, [])

  return { myCampaigns, availableCampaigns, allCampaigns, availableBalance, revenue, activeCampaigns, subscribed_campaigns };
};

export default GetData;