import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, Card, CardBody, Label, Row } from 'reactstrap'
import EmissionConfigurationDraggable from 'src/components/Draggables/EmissionConfigurationDraggableMini'
import EmissionConfigurationDraggableMobile from 'src/components/Draggables/EmissionConfigurationDraggableMobile'

import { ApiCore } from 'src/helpers/api_helper'
import { updateStreamerPlayout } from 'src/helpers/backend_helper'
import { getProfile } from 'src/slices/thunks'

type Props = {}

const AddPositioner = (props: Props) => {


  const dispatch = useDispatch()
  const history = useHistory()
  const [streamerAdPositionX, setStreamerAdPositionX] = useState<number>();
  const [streamerAdPositionY, setStreamerAdPositionY] = useState<number>();
  const [visible, setVisible] = useState(false);
  const [streamerId, setStreamerId] = useState();
  const profile = localStorage.getItem("profile") ?? "";
  const [button, setButton] = useState('Guardar Posición')

  const { coordinates } = useSelector((state: any) => ({
    coordinates: state.emission.coordinates,
  }));

  const getBodyToUpdateProfile = () => {
    return {
      streamer_id: streamerId,
      ad_position_x: coordinates.x,
      ad_position_y: coordinates.y,
    };
  };

  useEffect(() => {
    setButton("Guardar posición")
  }, [coordinates])

  const getCoordinates = () => {
    const profile = localStorage.getItem("profile")
    dispatch(getProfile(history))
    if (profile) {
      const obj = JSON.parse(profile);
      const streamer = obj.streamers?.[0];
      setStreamerId(streamer?.id);
      setStreamerAdPositionX(streamer?.ad_position_x);
      setStreamerAdPositionY(streamer?.ad_position_y);
    }
  }

  const handleValidSubmit = (event: any) => {
    event.preventDefault();
    const updatePlayout = updateStreamerPlayout(
      new ApiCore(localStorage.getItem("token")),
      getBodyToUpdateProfile()
    );
    updatePlayout.then(res => {
      setButton("Posición actualizada")
      getCoordinates()
    })
  };

  useEffect(() => {
    if (profile) {
      const obj = JSON.parse(profile);
      const streamer = obj.streamers?.[0];
      setStreamerId(streamer?.id);
      setStreamerAdPositionX(streamer?.ad_position_x);
      setStreamerAdPositionY(streamer?.ad_position_y);
    }
  }, [profile]);

  return (
    <div>
      <div >
        <Label>Posición del anuncio</Label>
        <span className="form-control light d-flex align-items-center justify-content-between" style={{ display: "flex", alignItems: "center", gap: "1rem" }}>

          Ajustar la posición del anuncio para esta campaña
          {/* <input id='hiddenPosition' type="checkbox" checked={visible} onChange={() => setVisible((e) => !e)} /> */}
          <label className="switchChatLink">
            <input type="checkbox" checked={visible} onChange={() => setVisible((e) => !e)} />
            <span className="sliderChatLink roundChatLink"></span>
          </label>
        </span>
      </div>
      {visible &&
        <>
          <Row className="mt-3 mb-3" style={{ justifyContent: "left" }}>
            {/* <label>Posición de la publicidad</label> */}

            <EmissionConfigurationDraggable
              x={
                typeof streamerAdPositionX == "number"
                  ? streamerAdPositionX
                  : 0
              }
              y={
                typeof streamerAdPositionY == "number"
                  ? streamerAdPositionY
                  : 0
              }
            />
            <EmissionConfigurationDraggableMobile
              x={
                typeof streamerAdPositionX == "number"
                  ? streamerAdPositionX
                  : 0
              }
              y={
                typeof streamerAdPositionY == "number"
                  ? streamerAdPositionY
                  : 0
              } />
            <Button color='primary' style={{ width: "13rem", margin: " 1rem auto" }} onClick={handleValidSubmit}>{button}</Button>
          </Row>
        </>
      }

    </div>
  )
}

export default AddPositioner