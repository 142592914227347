import { Button, Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import { COLORS } from "src/utils/COLORS";
import Icon from "@ailibs/feather-react-ts";


interface Props {
    WidgetData?: any;
    WidgetTitle?: any;
}

const NoInfoCard = (props: Props) => {


    return (
        <div className="d-flex h-100 w-90 p-2 mb-3">
            <Card
                className="d-flex w-100 h-100 align-items-stretch"
                style={{
                    flex: 1,
                    height: "100%",
                    border: `1px solid #043a5a`,
                }}>
                <CardBody className="d-flex h-100 justify-content-center flex-column align-items-center text-center">
                    <CardTitle
                        className="mb-1 "
                        tag="h2"
                        style={{
                            textAlign: "center",
                            fontSize:
                                "min(max(16px, calc(1.5625rem + ((1vw - 9px) * 1.4706))), 35px)",
                        }}>
                        <div>

                            <Icon size={"5rem"} name="camera-off" />
                            <h3 style={{ marginTop: "1rem" }}>{props.WidgetTitle}</h3>
                        </div>
                    </CardTitle>
                    <div
                        className="d-flex align-items-center"
                        style={{ fontSize: "1rem", color: "#eaeaea", marginTop: "1rem", maxWidth: "600px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {props.WidgetData}
                        </div>
                    </div>
                    <Button
                        outline
                        color="primary m-1"
                    // style={{
                    //     boxShadow: `0 0 7px 5px #00bfff`,
                    // }}
                    >
                        Contacta con nosotros!
                    </Button>
                </CardBody>
            </Card>
        </div >
    );
};

export default NoInfoCard;
