import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import UserCard from "src/components/UserCard/UserCard";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Col, Container, Row, Button, Spinner, Card, CardBody } from "reactstrap";
import Slider from "src/components/Slider/Slider";

//Estilos
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "../../assets/scss/custom/pages/_home.scss";

import Notifications from "src/components/Notifications/Notifications";
import avatarImg from "src/assets/images/stockImages/twitchAvatar.png";

import { useDispatch, useSelector } from "react-redux";
import { getAvailableCampaigns } from "src/slices/Campaigns/thunk";
import CampaignCardFunction from "src/components/CampaignCard/CampaignCardFunction";
import NoInfoCard from "src/components/NoInfoCard/NoInfoCard";
import HomeCards from "src/components/Home/HomeCards";
import { fetchProfilePic } from "src/slices/thunks";

import { setRunTour } from "src/slices/Tour/reducer";

export function validURL(str: any) {
    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(str);
}

const Home = (props: any) => {
    const { pfp } = useSelector((state: any) => state.profile);
    const [successAlert, setSuccessAlert] = useState(false);
    const [dangerAlert, setDangerAlert] = useState(false);
    const [noCampaigns, setNoCampaigns] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { availableCampaigns, subscriptions } = useSelector((state: any) => state.campaign);
    const [loading, setLoading] = useState(true);
    const { statusUpdated } = useSelector((state: any) => ({
        statusUpdated: state.campaign.statusUpdated,
    }));

    // const windowWidth = window.innerWidth;
    const dispatch = useDispatch();
    const maxWidthResponsive = (size: number) => {
        if (size > 1400) {
            return 3;
        } else if (size > 1100) {
            return 3;
        } else if (size > 768) {
            return 2;
        } else {
            return 1;
        }
    };

    useEffect(() => {
        dispatch(fetchProfilePic());
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        dispatch(getAvailableCampaigns());
        setLoading(false);
    }, [statusUpdated, dispatch]);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);

        if (availableCampaigns) {
            setLoading(false);
            if (!availableCampaigns.length) {
                setNoCampaigns(true);
            }
        }
        return () => window.removeEventListener("resize", handleResize);
    }, [availableCampaigns]);


    useEffect(() => {
        
        const profile = localStorage.getItem('profile')
        if (!profile) return
        const streamer = JSON.parse(profile).streamers[0]
        
        if (!streamer.birthdate) {
            dispatch(setRunTour(true))
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const profile = JSON.parse(localStorage.getItem("profile") ?? "");

    const carrousel = () => {
        if (Array.isArray(availableCampaigns)) {
            if (availableCampaigns.length > 2) {
                return (
                    <Col sm={12}>
                        <Slider
                            slides={availableCampaigns}
                            visibleItemsNumber={maxWidthResponsive(windowWidth)}>
                            {(slide: any) => (
                                <CampaignCardFunction
                                    campaign={slide}
                                    subscriptions={subscriptions}
                                    setSuccessAlert={setSuccessAlert}
                                    setDangerAlert={setDangerAlert}
                                />
                            )}
                        </Slider>
                    </Col>
                );
            } else {
                return (
                    <section className="flex-carousel" style={{}}>
                        {availableCampaigns.map((el: any, i: any) => (
                            <CampaignCardFunction
                                campaign={el}
                                key={i}
                                subscriptions={subscriptions}
                                setSuccessAlert={setSuccessAlert}
                                setDangerAlert={setDangerAlert}
                            />
                        ))}
                    </section>
                );
            }
        }
        setLoading(true);
        return <></>;
        // return availableCampaigns.length > 2 ? (<Col sm={12}>
        //     <Slider
        //         slides={availableCampaigns}
        //         visibleItemsNumber={maxWidthResponsive(windowWidth)}>
        //         {(slide: any) => (
        //             <CampaignCardFunction
        //                 campaign={slide}
        //                 subscriptions={subscriptions}
        //                 setSuccessAlert={setSuccessAlert}
        //                 setDangerAlert={setDangerAlert}
        //             />
        //         )}
        //     </Slider>
        // </Col>) : (<section style={{ display: "flex", justifyContent: "center", gap: "1.8rem", flexWrap: "wrap" }}>
        //     {availableCampaigns.map((el: any, i: any) => <CampaignCardFunction
        //         campaign={el}
        //         key={i}
        //         subscriptions={subscriptions}
        //         setSuccessAlert={setSuccessAlert}
        //         setDangerAlert={setDangerAlert}
        //     />)}
        // </section>)
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Home")}</title>
                </MetaTags>
                <Container fluid className="home-page-land">
                    <Notifications active={successAlert} type={"success"} />
                    <Notifications active={dangerAlert} type={"error"} />
                    <Row style={{ display: "flex", justifyContent: "right" }}>
                        {/* <FiltersHomeStatistics /> */}
                    </Row>

                    <div className="container-widgets" style={{ paddingTop: 16 }}>
                        <div className="user-card step-1">
                            <UserCard
                                user={"hola" || profile?.user?.name}
                                username={profile?.user?.email}
                                avatar={!pfp.includes("null") ? pfp : avatarImg}
                                streamURL={
                                    process.env.REACT_APP_STREAM_URL +
                                    profile?.streamers[0]?.id
                                }
                            />
                        </div>

                        <div className="widgets step-2">
                            <HomeCards />
                        </div>
                    </div>
                    <Row className="p-2 d-flex align-items-center justify-content-center step-3" style={{
                        marginTop: "4rem",
                    }}>
                        <Card className="px-xl-5">
                            <CardBody>

                                <h2
                                    style={{
                                        fontWeight: 400,
                                        marginBottom: "2.5rem",
                                        fontSize: props?.titleFontSize || "",
                                    }}>
                                    Campañas disponibles
                                </h2>

                                {/* {loading ? <div className="d-flex  align-items-center justify-content-center row"><Spinner /></div> : noCampaigns ? <NoInfoCard WidgetTitle={"No tenemos patrocinios disponibles para ti en estos momentos."} WidgetData={<><p className="mb-0" >Seguimos trabajando para traerte las mejores campañas.</p><p >En cuanto tengamos campañas te mostraremos toda la información detallada aquí. Para cualquier duda, ponte en contacto con el equipo de 014 Livel</p></>} /> : (
                            <>
                            {carrousel()}
                            </>
                            )
                        } */}

                                {loading ? (
                                    <div className="d-flex  align-items-center justify-content-center row step-3">
                                        <Spinner />
                                    </div>
                                ) : noCampaigns ? (
                                    <NoInfoCard
                                        WidgetTitle={
                                            "No tenemos patrocinios disponibles para ti en estos momentos."
                                        }
                                        WidgetData={
                                            <>
                                                <p className="mb-0" style={{ color: "white" }}>
                                                    Seguimos trabajando para traerte las mejores campañas.
                                                </p>
                                                <p style={{ color: "white" }}>
                                                    En cuanto tengamos campañas te mostraremos toda la
                                                    información detallada aquí. Para cualquier duda, ponte
                                                    en contacto con el equipo de 014 Livel
                                                </p>
                                            </>
                                        }
                                    />
                                ) : (
                                    <>{carrousel()}</>
                                )}
                            </CardBody>
                        </Card>
                    </Row>
                    {!loading && !noCampaigns && (
                        <Row
                            className="mb-4 pr-6"
                            style={{
                                display: "flex",
                                justifyContent: "right",
                            }}>
                            <Col className="pr-6 ver-todas" sm={4}>
                                <Link to="/campaigns">
                                    <Button size={"lg"} outline color="primary">
                                        VER TODAS
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(withRouter(Home));
