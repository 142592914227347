import Icon from "@ailibs/feather-react-ts/dist/Icon";
import React, { useEffect, useState } from "react";
import "../../assets/scss/custom/components/_notifications.scss";

type Props = {
    active: boolean;
    type: any
}

const Notifications = (props: Props) => {


    const [classNameToast, setClassNameToast] = useState("toast-custom position-fixed " + props.type);
    const [classNameProgress, setClassNameProgress] = useState("progress-custom " + props.type);

    const closeNotification = () => {
        setClassNameToast("toast-custom position-fixed " + props.type);
        setClassNameProgress("progress-custom");
    }

    const getIcon = () => {
        if (props.type === "error" || props.type === "errorWithdraw") {
            return <Icon name="x-circle"></Icon>
        }
        return <div className="IconCheckContainer"><Icon name="check" className="checkIcon"></Icon></div>
        // switch (props.type) {
        //     case "success":
        //         return <div className="IconCheckContainer"><Icon name="check" className="checkIcon"></Icon></div>
        //     case "error":
        //         return <Icon name="x-circle"></Icon>
        // }
    }

    const getMessage = () => {
        switch (props.type) {
            case "withdraw":
                return <><b className="exito">¡Éxito!</b><p className="exito">Los fondos se retiraron exitosamente!</p></>
            case "success":
                return <><b className="exito">¡Gracias por suscribirte!</b><p className="exito">Hemos recibido tu solicitud y nuestro equipo lo verificará tan pronto sea posible.</p></>
            case "resume":
                return <><b className="exito">¡Enhorabuena!</b><p className="exito">Has vuelto a suscribirte a la campaña.</p></>
            case "streamer-paused":
                return <><b className="exito">¡Recibido!</b><p className="exito">Tu campaña ha sido pausada.</p></>
            case "streamer-refused":
                return <><b className="exito">¡Recibido!</b><p className="exito">Tu campaña ha sido dada de baja.</p></>
            case "error":
                return <><b className="errorText">¡Error!</b><p className="errorText">No hemos podido actualizar el estado de tu campaña.</p></>
            case "errorWithdraw":
                return <><b className="errorText">¡Error!</b><p className="errorText">No hemos podido retirar tus fondos.</p></>
        }
    }

    useEffect(() => {
        if (props.active) {
            setClassNameToast(classNameToast + " active");
            setClassNameProgress(classNameProgress + " active");

            setTimeout(() => {
                closeNotification()
            }, 5000);
        }

    }, [props.active])


    return (
        <React.Fragment>
            <div className={classNameToast}>
                <div className="toast-custom-content">
                    {getIcon()}
                    <div className="message-custom">
                        <span className="text text-1">{getMessage()}</span>
                    </div>
                </div>
                <div className="closeIconContainer">
                    <Icon className="closeIcon" name="x" onClick={closeNotification}></Icon>
                </div>
                <div className={classNameProgress}></div>
            </div>
        </React.Fragment>
    )

}

export default Notifications;