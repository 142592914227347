import axios from "axios";
import { ceil } from "lodash";
import React, { useEffect, useState } from "react";
import "../../assets/scss/custom/components/_available_campaigns.scss";
import {
    CardBody,
    Table,
    Col,
    Card,
    Button,
    Row,
    Input,
    Container,
    FormGroup,
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    CarouselItem,
    CarouselCaption,
    Carousel,
    CarouselIndicators,
    CarouselControl,
    CardTitle,
    CardText,
    CardSubtitle,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Alert
} from "reactstrap";
import * as statuses from "../../helpers/status_helper";
import { postSubscriptionStatus } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import { updateStatus } from "src/slices/Campaigns/reducer";
import { useDispatch, useSelector } from "react-redux";
import DetailCampaign from "./DetailCampaign";
import moment from "moment";
import { COLORS } from "src/utils/COLORS";
import StatusCampaign from "./StatusCampaign";
import Notifications from "../Notifications/Notifications";
import { validURL } from "src/pages/Home";
import { validCampaignImage } from "src/pages/Campaigns";
import NoInfoCard from "../NoInfoCard/NoInfoCard";
import { getAllCampaigns, getAvailableCampaigns } from "src/slices/Campaigns/thunk";
import SubscribeButton from "../CampaignCard/SubscribeButton";

const AvailableCampaignsTable = (props: any) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [page, setPage] = useState(1);
    const [firstPage, setFirstPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(12);

    const campaigns = useSelector((state: any) => state.campaign.availableCampaigns)
    const [susbcribeModal, setSusbcribeModal] = useState(false);

    const [campaignsCurrentPage, setCampaignsCurrentPage] = useState(false);
    const [noCampaigns, setNoCampaigns] = useState(false);
    const [campaignOnModal, setCampaignOnModal] = useState<any>();
    const [subscriptionOnModal, setSubscriptionOnModal] = useState<any>();
    const [updating, setUpdating] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [dangerAlert, setDangerAlert] = useState(false);

    const { statusUpdated } = useSelector((state: any) => ({
        statusUpdated: state.campaign.statusUpdated,
    }));

    useEffect(() => {
        if (successAlert) {
            setTimeout(() => {
                setSuccessAlert(false);
            }, 3000);
        }

        if (dangerAlert) {
            setTimeout(() => {
                setDangerAlert(false);
            }, 3000);
        }
    }, [successAlert, dangerAlert])

    useEffect(() => {
        dispatch(getAvailableCampaigns())
        dispatch(getAllCampaigns())
        setCampaignsCurrentPage(false);

    }, [statusUpdated])

    useEffect(() => {
        setUpdating(true)
        const FilteredCampaigns = props.campaigns ? props.campaigns.map((campaign: any) => {
            const subscription = getSubscriptionByCampaign(campaign);
            if (subscription && (campaign?.campaign_situation !== "finished")) {
                return subscription
            }
        }).filter((el: any) => el !== undefined) : false

        setNoCampaigns(!FilteredCampaigns.length)

        const numberCampaigns = props.campaigns ? props.campaigns.length : 0;
        const quantityPages = ceil(numberCampaigns / recordsPerPage);
        setLastPage(quantityPages);
        const offset = (page - 1) * recordsPerPage;
        const limit = offset + recordsPerPage;
        setCampaignsCurrentPage(props.campaigns ? props.campaigns.slice(offset, limit) : false);
        setUpdating(false)

    }, [campaigns, page, statusUpdated]);

    const getSubscriptionByCampaign = (campaing: any) => {
        const subscriptionIdToFind = campaing.subscription_id;
        return props.subscriptions
            ? props.subscriptions.find((sub: any) => sub.id == subscriptionIdToFind)
            : null;
    };

    const subscribeConfirmationModal = (subscription: any, campaign: any) => {
        setSusbcribeModal(true);
        setSubscriptionOnModal(subscription);
        setCampaignOnModal(campaign);
    };

    const updateStatusSubscription = (status: any, subscription_id: any) => {
        setUpdating(true);
        postSubscriptionStatus(new ApiCore(localStorage.getItem("token")), subscription_id, status)
            .then((data: any) => {
                if (data.message == "ok") {
                    setSusbcribeModal(false);
                    setModal(false);
                    setSuccessAlert(true);
                    setUpdating(false);
                    dispatch(updateStatus(Math.random().toString(36).slice(2)));
                } else {
                    setSusbcribeModal(false);
                    setModal(false);
                    setDangerAlert(true);
                    setUpdating(false);
                }
            })
            .catch(() => {
                setSusbcribeModal(false);
                setModal(false);
                setDangerAlert(true);
                setUpdating(false);
            });
    };

    const handleDetails = (campaign: any, subscription: any) => {
        setModal(true);
        setCampaignOnModal(campaign);
        setSubscriptionOnModal(subscription);
    };

    const itemData = Array.isArray(campaignsCurrentPage) ? (
        campaignsCurrentPage.map((campaign: any, i: any) => {
            const subscription = getSubscriptionByCampaign(campaign);
            if (subscription && subscription.status.name !== "streamer-agreed" && campaign.campaign_situation !== "finished") {
                const status = statuses.getStatusByCode(subscription.status.name);
                return (
                    <Col
                        xs={12}
                        sm={6}
                        lg={3}
                        key={crypto.randomUUID()}
                        style={{ marginBottom: "20px", maxWidth: 300, minHeight: "100%" }}
                    >
                        <Card
                            style={{
                                border: "1px solid rgba(156,59,199,0.82)",
                                // boxShadow: "0px 0px 7px 5px rgba(156,59,199,0.82)",
                            }}
                            className="available-campaign card-h-100 border">
                            {/* {campaingIdOver != campaign.campaign_id ? (
                                <>
                                    <span className="lines"></span>
                                    <span className="lines"></span>
                                    <span className="lines"></span>
                                    <span className="lines"></span>
                                </>
                            ) : (
                                <>
                                    <span className="move-lines"></span>
                                    <span className="move-lines"></span>
                                    <span className="move-lines"></span>
                                    <span className="move-lines"></span>
                                </>
                            )} */}
                            <div style={{ width: "100%", height: "auto", aspectRatio: "1.51/1", backgroundImage: `url(${campaign.campaign_image ? validCampaignImage(campaign.campaign_image) : "https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"})`, backgroundSize: "cover", backgroundPosition: "center" }}>

                            </div>
                            {/* {campaign.campaign_image ? (

                                <img

                                    src={campaign.campaign_image ? validCampaignImage(campaign.campaign_image) : "https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"}

                                ></img>
                            ) : (
                                <img src="https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"></img>
                            )} */}
                            <CardBody>
                                <CardTitle>
                                    <h4
                                        onClick={() => handleDetails(campaign, subscription)}
                                        style={{ cursor: "pointer" }}>
                                        {campaign.name}
                                    </h4>
                                    <StatusCampaign code={status?.original}></StatusCampaign>
                                </CardTitle>

                                <Row className="mt-4 d-flex">
                                    <Col
                                        xs={12}
                                        sm={6}
                                        className="justify-content-end mb-3"
                                        style={{ textAlign: "center" }}>
                                        <Label className="light">CPM</Label>
                                        <Input
                                            disabled={true}
                                            value={campaign.cpm_streamer.toLocaleString("es-AR") + " €"}
                                            style={{
                                                textAlign: "center",
                                                display: "block",
                                                minWidth: "auto",
                                                height: "80%",
                                                fontSize: "1.8rem",
                                                margin: "auto",
                                            }}
                                        />
                                    </Col>

                                    <Col
                                        sm={6}
                                        className="justify-content-end mb-3"
                                        style={{ textAlign: "center" }}>
                                        <div className="mb-1">
                                            <Label className="light">Fecha inicio</Label>
                                            <Input
                                                disabled={true}
                                                value={moment(campaign?.start_date)
                                                    .startOf("day")
                                                    .format("DD/MM/YYYY")}
                                                style={{
                                                    textAlign: "center",
                                                    display: "block",
                                                    maxWidth: "120px",
                                                    margin: "auto",
                                                }}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <Label className="light">Fecha fin</Label>
                                            <Input
                                                disabled={true}
                                                value={moment(campaign?.end_date)
                                                    .startOf("day")
                                                    .format("DD/MM/YYYY")}
                                                style={{
                                                    textAlign: "center",
                                                    display: "block",
                                                    maxWidth: "120px",
                                                    margin: "auto",
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>

                            <CardFooter style={{ border: "none", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                <Button
                                    outline
                                    color="primary m-1"
                                    style={{ float: "left", fontWeight: "600" }}
                                    onClick={() => handleDetails(campaign, subscription)}>
                                    Detalles
                                </Button>
                                {/* <Button
                                    color="green-light m-1"
                                    style={{
                                        float: "right",
                                        borderColor: COLORS.bootstrapGreen,
                                        fontWeight: "600",
                                        // boxShadow: `0px 0px 7px 5px #ff0bf9`,
                                    }}
                                    onClick={() =>
                                        subscribeConfirmationModal(subscription, campaign)
                                    }>
                                    Suscribirse
                                </Button> */}
                                <SubscribeButton color='green-light' style={{
                                    float: "right",
                                    borderColor: COLORS.bootstrapGreen,
                                    fontWeight: "600",
                                    // boxShadow: `0px 0px 7px 5px #ff0bf9`,
                                }} subscribeConfirmationModal={subscribeConfirmationModal} subscriptionOnModal={subscription} campaign={campaign} />

                                {"   "}
                            </CardFooter>
                        </Card>
                    </Col>
                );
            }
            return <></>
        })
    ) : (
        <></>
    );

    const pagination = () => {
        return (
            <nav>
                <Pagination style={{ float: "right", margin: "1.5rem 0" }}>
                    <PaginationItem
                        onClick={() => {
                            if (page > 1) {
                                setPage(page - 1);
                            }
                        }}>
                        <PaginationLink>{"<"}</PaginationLink>
                    </PaginationItem>
                    {firstPage == page ? (
                        <></>
                    ) : (
                        <PaginationItem
                            onClick={() => {
                                setPage(firstPage);
                            }}>
                            <PaginationLink>{firstPage}</PaginationLink>
                        </PaginationItem>
                    )}

                    {page - 1 <= firstPage ? (
                        <></>
                    ) : (
                        <PaginationItem
                            onClick={() => {
                                setPage(page - 1);
                            }}>
                            <PaginationLink>{page - 1}</PaginationLink>
                        </PaginationItem>
                    )}

                    <PaginationItem
                        active
                        onClick={() => {
                            if (page > 1) {
                                setPage(page - 1);
                            }
                        }}>
                        <PaginationLink>{page}</PaginationLink>
                    </PaginationItem>

                    {page + 1 >= lastPage ? (
                        <></>
                    ) : (
                        <PaginationItem
                            onClick={() => {
                                setPage(page + 1);
                            }}>
                            <PaginationLink>{page + 1}</PaginationLink>
                        </PaginationItem>
                    )}

                    {lastPage == page ? (
                        <></>
                    ) : (
                        <>
                            <PaginationItem
                                onClick={() => {
                                    setPage(lastPage);
                                }}>
                                <PaginationLink>{lastPage}</PaginationLink>
                            </PaginationItem>
                        </>
                    )}

                    <PaginationItem
                        onClick={() => {
                            if (page < lastPage) {
                                setPage(page + 1);
                            }
                        }}>
                        <PaginationLink next tag="button">
                            {">"}
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem></PaginationItem>
                </Pagination>
            </nav>
        );
    };

    return (
        <React.Fragment>
            <Notifications active={successAlert} type={"success"} />
            <Notifications active={dangerAlert} type={"error"} />

            {campaignsCurrentPage !== false ? (
                <Row className="available-campaigns-container"
                    style={{ marginTop: "30px", padding: "0" }}
                >
                    {noCampaigns ? <NoInfoCard WidgetTitle={"No tenemos patrocinios disponibles para ti en estos momentos."} WidgetData={<><p className="mb-0" style={{ color: "white" }}>Seguimos trabajando para traerte las mejores campañas.</p><p>En cuanto tengamos campañas te mostraremos toda la información detallada aquí. Para cualquier duda, ponte en contacto con el equipo de 014 Livel</p></>} /> : <>
                        {itemData}
                        {pagination()}
                    </>
                    }                </Row>
            ) : (
                <div className="m-4" style={{ textAlign: "center" }}>
                    <Spinner animation="border" />
                </div>
            )}

            <Modal
                className="custom"
                centered={true}
                isOpen={modal}
                toggle={() => {
                    if (!updating) setModal(false);
                }}>
                <div style={{ padding: "1rem" }}>

                    <ModalHeader style={{ display: "block", verticalAlign: "middle" }}>
                        <Row>
                            <Col sm={8}>
                                <img
                                    width="24px"
                                    // src={
                                    //     campaignOnModal?.creativities[0]?.thumbnail_img_url
                                    // }
                                    src={campaignOnModal?.creativities[0]?.thumbnail_img_url ?
                                        validCampaignImage(campaignOnModal?.creativities[0]?.thumbnail_img_url)
                                        : ''}

                                ></img>{" "}
                                {campaignOnModal?.name}{" "}
                            </Col>
                            <Col
                                sm={4}
                                style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    alignItems: "center",
                                }}>

                                <StatusCampaign code={subscriptionOnModal?.status?.name}></StatusCampaign>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <DetailCampaign campaign={campaignOnModal} subscription={subscriptionOnModal} />
                    <ModalFooter style={{ display: "block" }}>
                        <SubscribeButton color="primary"
                            style={{ float: "right" }} subscribeConfirmationModal={subscribeConfirmationModal} subscriptionOnModal={subscriptionOnModal} campaign={campaignOnModal} />
                        <Button
                            color="secondary"
                            onClick={() => setModal(false)}
                            style={{ float: "right" }}>
                            Cerrar
                        </Button>
                        {/* <Button
                            color="primary"
                            style={{ float: "right" }}
                            onClick={() =>
                                subscribeConfirmationModal(subscriptionOnModal, campaignOnModal)
                            }>
                            Suscribirse
                        </Button> */}
                    </ModalFooter>
                </div>

            </Modal>
            <Modal
                className="custom"
                isOpen={susbcribeModal}
                centered={true}
                toggle={() => {
                    if (!updating) setSusbcribeModal(false);
                }}>
                <ModalHeader>{campaignOnModal?.name}</ModalHeader>
                <ModalBody>
                    {"Vas a suscribirte a la campaña " + campaignOnModal?.name + ". ¿Estas seguro?"}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        disabled={updating}
                        onClick={() =>
                            updateStatusSubscription(
                                statuses.STREAMER_AGREED.original,
                                subscriptionOnModal.id
                            )
                        }>
                        {updating ? <Spinner size={"sm"} animation="border" /> : "Si"}
                    </Button>{" "}
                    <Button
                        color="secondary"
                        disabled={updating}
                        onClick={() => setSusbcribeModal(false)}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default AvailableCampaignsTable;
