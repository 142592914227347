import { useEffect, useState } from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { Col, Row, Button, Spinner } from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";

import { AvField, AvForm } from "availity-reactstrap-validation";

import { CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import {
    getStreamerProfile,
    jsonResponseFromAxiosResponse,
    updateContactData,
} from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import { countries } from "src/common/data/countries";
import { profileModal } from "src/slices/Auth/Profile/reducer";
import { validCampaignImage } from "src/pages/Campaigns";
import { every } from "lodash";
registerLocale("es", es);

const TourContact = (props: any) => {
    const dispatch = useDispatch();
    const { pfp } = useSelector((state: any) => state.profile);

    const [name, setname] = useState();
    const [username, setusername] = useState();
    const [email, setemail] = useState<string>();
    const [photo, setphoto] = useState(pfp);

    const [saveButtonContactData, setSaveButtonContactData] = useState(true);

    const [updatingContactData, setUpdatingContactData] = useState(false);

    //Streamer Contact data
    const [streamerContactName, setStreamerContactName] = useState<string>("");
    const [streamerContactEmail, setStreamerContactEmail] = useState<string>("");
    const [streamerContactCountry, setStreamerContactCountry] = useState<string>("");
    const [streamerContactCity, setStreamerContactCity] = useState<string>("");
    const [streamerContactState, setStreamerContactState] = useState<string>("");
    const [streamerContactAddress, setStreamerContactAddress] = useState<string>("");
    const [streamerContactAddressExtra, setStreamerContactAddressExtra] = useState<string>("");
    const [streamerContactZipCode, setStreamerContactZipCode] = useState<string>("");
    const [streamerContactPhone, setStreamerContactPhone] = useState<string>("");
    const [streamerDiscord, setStreamerDiscord] = useState<string>("");
    const [streamerId, setStreamerId] = useState<string>("");

    const [configurationTwitchModal, setConfigurationTwitchModal] = useState(false);
    const [pendingApprovalModal, setPendingApprovalModal] = useState(false);

    const { success } = useSelector((state: any) => ({
        success: state.profile.success,
    }));

    const handleValidSubmitContactData = (event: any) => {
        event.preventDefault();
        setUpdatingContactData(true);
        saveProfileContactData();
    };

    useEffect(() => {
        if (configurationTwitchModal || pendingApprovalModal) {
            dispatch(profileModal(true));
        } else {
            dispatch(profileModal(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationTwitchModal, pendingApprovalModal]);

    useEffect(() => {
        const getAuthUser = localStorage.getItem("authUser");
        if (getAuthUser) {
            const obj = JSON.parse(getAuthUser);
            if (obj) {
                setname(obj.name);
                setusername(obj.username);
                setemail(obj.email);
                setphoto(obj.photo);
            }
        }

        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            if (streamer) {
                const contact = streamer.contact;
                setStreamerContactName(contact?.fullname || '');
                setStreamerContactEmail(contact?.email);
                setStreamerContactCountry(contact?.country ?? "");
                setStreamerContactCity(contact?.city);
                setStreamerContactState(contact?.state);
                setStreamerContactAddress(contact?.address);
                setStreamerContactAddressExtra(contact?.address_extra);
                setStreamerContactZipCode(contact?.zip_code);
                setStreamerContactPhone(contact?.phone);
                setphoto(validCampaignImage(streamer.thumbnail_img_url));
                setStreamerId(streamer.id);
            } else {
                setConfigurationTwitchModal(true);
            }
        } else {
            setConfigurationTwitchModal(true);
        }
    }, [success]);

    const saveProfileContactData = () => {
        updateContactData(new ApiCore(localStorage.getItem("token")), {
            streamer_id: streamerId,

            address: streamerContactAddress,
            address_extra: streamerContactAddressExtra,
            zip_code: streamerContactZipCode,
            city: streamerContactCity,
            state: streamerContactState,
            country: streamerContactCountry,
            email: streamerContactEmail,
            phone: streamerContactPhone,
            fullname: streamerContactName,
        }).then((data: any) => {
            if (data.message === "ok") {
                getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                    const profileJson = jsonResponseFromAxiosResponse(data);
                    localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
                    localStorage.setItem("profile", JSON.stringify(profileJson.payload));
                    setUpdatingContactData(false);
                    props.disableStep(false)
                });
            } else {
            }
        });
    };

    const checkInputs = () => {
        const data = [streamerContactName, streamerContactEmail, streamerContactCountry, streamerContactState, streamerContactCity, streamerContactAddress]
        // props.disableStep(data.every(el => el && el !== " " && el !== ""))
        return data.every(el => el && el !== " " && el !== "")
    }
    useEffect(() => {
        if (!checkInputs()) props.disableStep(!checkInputs())
    }, [streamerContactName, streamerContactEmail, setStreamerContactCountry, streamerContactState, streamerContactCity, streamerContactAddress])


    useEffect(() => {
        const getAuthUser = localStorage.getItem("authUser");
        if (getAuthUser) {
            const obj = JSON.parse(getAuthUser);
            if (obj) {
                setname(obj.name);
                setusername(obj.username);
                setemail(obj.email);
                setphoto(obj.photo);
            }
        }

        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            if (streamer) {
                const contact = streamer.contact ?? {};
                const { fullname, email, country, state, city, address } = contact 
                setStreamerContactName(contact?.fullname);
                setStreamerContactEmail(contact?.email);
                setStreamerContactCountry(contact?.country ?? "");
                setStreamerContactCity(contact?.city);
                setStreamerContactState(contact?.state);
                setStreamerContactAddress(contact?.address);
                setStreamerContactAddressExtra(contact?.address_extra);
                setStreamerContactZipCode(contact?.zip_code);
                setStreamerContactPhone(contact?.phone);
                setphoto(validCampaignImage(streamer.thumbnail_img_url));
                setStreamerId(streamer.id);
                const data = [fullname, email, country, state, city, address].every(el => el && el !== " " && el !== "")
                props.disableStep(data)
            } else {
                setConfigurationTwitchModal(true);
            }
        } else {
            setConfigurationTwitchModal(true);
        }
    }, [])
    return (
        <div className="card border shadow-none">
            <div className="card-header d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm">
                        <div className="avatar-title rounded-circle bg-soft-primary text-primary" onClick={() => props.disableStep(true)}>
                            02
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 d-flex justify-content-start mt-2">
                    <h4 style={{ color: "#fff" }}>Datos de contacto</h4>
                </div>
            </div>
            <CardBody>
                <AvForm
                    onValidSubmit={(e: any, v: any) => {
                        handleValidSubmitContactData(e);
                    }}>
                    <Row>
                        <Col lg={6}>
                            <div className="mb-3">
                                <AvField
                                    name="fullname"
                                    label="Nombre completo *"
                                    type="text"
                                    required
                                    errorMessage="Este campo es obligatorio"
                                    placeholder="Ingresar nombre completo"
                                    value={streamerContactName}
                                    onChange={(e: any) => {
                                        setStreamerContactName(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="mb-3">
                                <AvField
                                    name="email"
                                    label="Email *"
                                    type="text"
                                    errorMessage="Este campo es obligatorio"
                                    required
                                    placeholder="Ingresar email"
                                    value={streamerContactEmail}
                                    onChange={(e: any) => {
                                        setStreamerContactEmail(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className="mb-3">
                                {/* <AvField
                                                            name="country"
                                                            label="País"
                                                            type="text"
                                                            required
                                                            placeholder="Ingresar país"
                                                            value={streamerContactCountry}
                                                            onChange={(e: any) => {
                                                                setStreamerContactCountry(
                                                                    e.target.value
                                                                );
                                                                setSaveButtonContactData(false);
                                                            }}
                                                        /> */}
                                <AvField
                                    name="country"
                                    label="País *"
                                    type="select"
                                    errorMessage="Este campo es obligatorio"
                                    required
                                    value={streamerContactCountry}
                                    // onChange={(e: any) => {
                                    //     setStreamerNationality(
                                    //         e.target.value
                                    //     );
                                    //     setSaveButtonGeneralInfo(false);
                                    // }}
                                    onChange={(e: any) => {
                                        setStreamerContactCountry(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}>
                                    <option value="">Ingresar país</option>
                                    {countries.map((country: any, index: number) => {
                                        return (
                                            <option key={country.name + index} value={country.name}>
                                                {country.name === 'Spain' ? 'España' : country.name}
                                            </option>
                                        );
                                    })}
                                </AvField>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="state"
                                    label="Provincia *"
                                    type="text"
                                    errorMessage="Este campo es obligatorio"
                                    required
                                    placeholder="Ingresar provincia"
                                    value={streamerContactState}
                                    onChange={(e: any) => {
                                        setStreamerContactState(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="city"
                                    label="Ciudad *"
                                    type="text"
                                    errorMessage="Este campo es obligatorio"
                                    required
                                    placeholder="Ingresar ciudad"
                                    value={streamerContactCity}
                                    onChange={(e: any) => {
                                        setStreamerContactCity(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <div className="mb-3">
                                <AvField
                                    name="address"
                                    label="Dirección *"
                                    type="text"
                                    errorMessage="Este campo es obligatorio"
                                    required
                                    placeholder="Ingresar dirección"
                                    value={streamerContactAddress}
                                    onChange={(e: any) => {
                                        setStreamerContactAddress(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="mb-3">
                                <AvField
                                    name="address_extra"
                                    label="Información adicional"
                                    type="text"
                                    placeholder="Ingresar información adicional de facturación"
                                    value={streamerContactAddressExtra}
                                    onChange={(e: any) => {
                                        setStreamerContactAddressExtra(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="zip_code"
                                    label="Código postal *"
                                    type="text"
                                    required
                                    placeholder="Ingresar código postal"
                                    errorMessage="Completar información"
                                    value={streamerContactZipCode}
                                    onChange={(e: any) => {
                                        setStreamerContactZipCode(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="phone"
                                    label="Teléfono *"
                                    type="text"
                                    placeholder="Ingresar teléfono"
                                    value={streamerContactPhone}
                                    onChange={(e: any) => {
                                        setStreamerContactPhone(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="mb-3">
                                <AvField
                                    name="discord"
                                    label="Cuenta Discord"
                                    type="text"
                                    placeholder="Ingresar cuenta Discord"
                                    onChange={(e: any) => {
                                        setStreamerDiscord(e.target.value);
                                        setSaveButtonContactData(false);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Button
                        color="primary"
                        style={{ float: "right" }}
                        type="submit"
                        disabled={saveButtonContactData || updatingContactData}>
                        {" "}
                        {updatingContactData ? (
                            <Spinner size={"sm"} animation="border" />
                        ) : (
                            "Actualizar"
                        )}
                    </Button>
                    {"  "}
                </AvForm>
            </CardBody>
        </div>
    );
};

export default TourContact;
