import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row } from "reactstrap";
import { campaignTableFilterMode } from "src/slices/Campaigns/reducer";
import classnames from "classnames";
import { ALT_TOUR_INDEX, TOUR_INDEX } from "../Tour/TourConfig";

const CampaignFiltersTable = (props: any) => {
    const { handleSearch } = props;
    const dispatch = useDispatch();
    const [myCampaignsCheck, setMyCampaignsCheck] = useState(true);
    const { tableFilterMode } = useSelector((state: any) => state.campaign)
    const [availableCampaigns, setAvailableCampaigns] = useState(false);
    const [campaignsHistorial, setCampaignsHistorial] = useState(false);
    const [search, setSearch] = useState("");
    const { runTour, currentStep } = useSelector((state: any) => ({
        runTour: state.tour.runTour,
        currentStep: state.tour.currentStep,
    }));
    const { altRunTour, altCurrentStep } = useSelector((state: any) => ({
        altRunTour: state.altTour.runTour,
        altCurrentStep: state.altTour.currentStep,
    }));
    const handleChange = (e: any) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        handleSearch(search);
    }, [search]);


    useEffect(() => {
        handleChangeCheck({ currentTarget: { attributes: { name: { value: tableFilterMode } } } },);
    }, [tableFilterMode])



    const handleChangeCheck = (e: any, status?: any) => {
        if (!status) {
            switch (e.currentTarget.attributes.name.value) {
                case "Mis campañas":
                    setMyCampaignsCheck(true);
                    setAvailableCampaigns(false);
                    setCampaignsHistorial(false);
                    dispatch(campaignTableFilterMode("Mis campañas"));
                    break;
                case "Campañas disponibles":
                    setMyCampaignsCheck(false);
                    setAvailableCampaigns(true);
                    setCampaignsHistorial(false);
                    dispatch(campaignTableFilterMode("Campañas disponibles"));
                    break;
                case "Histórico":
                    setMyCampaignsCheck(false);
                    setAvailableCampaigns(false);
                    setCampaignsHistorial(true);
                    dispatch(campaignTableFilterMode("Histórico"));
                    break;
            }
        }
    };

    useEffect(() => {
        if (runTour === true) {
            if (currentStep === TOUR_INDEX.CAMPAIGN_3.INDEX) {
                handleChangeCheck(
                    { currentTarget: { attributes: { name: { value: "Mis campañas" } } } },
                    false
                );
            }
            if (currentStep === TOUR_INDEX.CAMPAIGN_4.INDEX) {
                handleChangeCheck(
                    { currentTarget: { attributes: { name: { value: "Campañas disponibles" } } } },
                    false
                );
            }
            if (currentStep === TOUR_INDEX.CAMPAIGN_5.INDEX) {
                handleChangeCheck(
                    { currentTarget: { attributes: { name: { value: "Histórico" } } } },
                    false
                );
            }
        }
    }, [currentStep]);

    useEffect(() => {
        if (altRunTour === true) {
            if (altCurrentStep === ALT_TOUR_INDEX.CAMPAIGN_3.INDEX) {
                handleChangeCheck(
                    { currentTarget: { attributes: { name: { value: "Mis campañas" } } } },
                    false
                );
            }
            if (altCurrentStep === ALT_TOUR_INDEX.CAMPAIGN_4.INDEX) {
                handleChangeCheck(
                    { currentTarget: { attributes: { name: { value: "Campañas disponibles" } } } },
                    false
                );
            }
            if (altCurrentStep === ALT_TOUR_INDEX.CAMPAIGN_5.INDEX) {
                handleChangeCheck(
                    { currentTarget: { attributes: { name: { value: "Histórico" } } } },
                    false
                );
            }
        }
    }, [altCurrentStep]);
    return (
        <React.Fragment>
            <Row className="mt-3 mb-3 ">
                <Col md={8} style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex" }}>
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    name="Mis campañas"
                                    className={classnames({ active: myCampaignsCheck })}
                                    onClick={e => {
                                        handleChangeCheck(e, myCampaignsCheck);
                                    }}>
                                    <span>Mis campañas</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    name="Campañas disponibles"
                                    className={classnames({ active: availableCampaigns })}
                                    onClick={e => {
                                        handleChangeCheck(e, availableCampaigns);
                                    }}>
                                    <span>Campañas disponibles</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    name="Histórico"
                                    className={classnames({ active: campaignsHistorial })}
                                    onClick={e => {
                                        handleChangeCheck(e, campaignsHistorial);
                                    }}>
                                    <span>Histórico</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    {/* <Button color="primary">+ Administrar campañas</Button>{' '} */}
                </Col>
                <Col>
                    <Input
                        type="search"
                        name="search"
                        id="search"
                        placeholder="Buscar"
                        onChange={handleChange}
                        value={search}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CampaignFiltersTable;
