import {
    // registerUserSuccessful,
    registerUserFailed
} from "./reducer";

import { jsonResponseFromAxiosResponse, postUserStreamer } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
// import { loginSuccess } from "../Login/reducer";

export const registerUser = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        response = postUserStreamer(new ApiCore(), user);
        const data = await response;

        if (data) {
            const dataJson = jsonResponseFromAxiosResponse(data);
            if (dataJson.message === "ok") {
                history.push("/verify-mail");
            }
            // localStorage.setItem("token", dataJson.payload.token);
            // dispatch(registerUserSuccessful(data));
            // dispatch(loginSuccess(dataJson.message));
        }
    } catch (error) {
        dispatch(registerUserFailed(error));
    }
};
