import React, { useEffect, useState } from "react";
import {
  Button,
  //  CardBody, Col, Input, Modal, ModalFooter, Row, Spinner
} from "reactstrap";
// import { AvField, AvForm } from "availity-reactstrap-validation";
// import CountUp from "react-countup";
// import {
//   getStreamerProfile,
//   jsonResponseFromAxiosResponse,
//   postWithdrawBallance,
//   putStreamerProfile,
//   updateBillingData,
// } from "src/helpers/backend_helper";
// import { ApiCore } from "src/helpers/api_helper";
// import { COLORS } from "src/utils/COLORS";
// import { countries } from "src/common/data/countries";
// import PdfUpload from "../Inputs/PdfUpload";

import 'src/assets/scss/custom/pages/_wallet.scss'
import BillingDataModal from "../Wallet/BillingDataModal";



// const vatIdentifiers: any = {
//   "company": "CIF/NIF",
//   "freelance": "NIF",
//   "natural_person": "DNI"
// }


export default function SubscribeButton(props: any) {
  const [modalEditBillingData, setModalEditBillingData] = useState(false);
  // const [updating, setUpdating] = useState(false);
  // const [notEnoughisOpen, setNotEnoughIsOpen] = useState(false);
  // const [whithdrawIsOpen, setWithdrawIsOpen] = useState(false)

  //Streamer Billing data
  const [streamerBillingName, setStreamerBillingName] = useState<string>("");
  const [streamerBillingEmail, setStreamerBillingEmail] = useState<string>("");
  const [streamerBillingCountry, setStreamerBillingCountry] = useState<string>("");
  const [streamerBillingCity, setStreamerBillingCity] = useState<string>("");
  const [streamerBillingState, setStreamerBillingState] = useState<string>("");
  const [streamerBillingAddress, setStreamerBillingAddress] = useState<string>("");
  const [streamerBillingZipCode, setStreamerBillingZipCode] = useState<string>("");
  const [streamerBillingPhone, setStreamerBillingPhone] = useState<string>("");
  const [streamerBillingVat, setStreamerBillingVat] = useState<string>("");
  const [streamerBillingBankAccount, setStreamerBillingBankAccount] = useState<string>("");


  // const [fiscalEntity, setFiscalEntity] = useState("freelance");
  // const handleFiscalEntity = (event: any) => {
  //   // setFiscalEntity(event.target.value);
  // };



  const validateBillingFields = () => {
    const billingData = [
      streamerBillingName,
      streamerBillingEmail,
      streamerBillingCountry,
      streamerBillingCity,
      streamerBillingState,
      streamerBillingAddress,
      streamerBillingZipCode,
      streamerBillingPhone,
      streamerBillingVat,
      streamerBillingBankAccount,

    ];
    return billingData.every(variable => variable !== "");
  };

  useEffect(() => {
    const getProfile = localStorage.getItem("profile");
    if (getProfile) {
      const obj = JSON.parse(getProfile);
      const streamer = obj.streamers?.[0];
      const billing = streamer?.invoice;
      setStreamerBillingName(billing?.legal_name);
      setStreamerBillingEmail(billing?.email);
      setStreamerBillingCountry(billing?.country);
      setStreamerBillingCity(billing?.city);
      setStreamerBillingState(billing?.state);
      setStreamerBillingAddress(billing?.address);
      setStreamerBillingZipCode(billing?.zip_code);
      setStreamerBillingPhone(billing?.phone);
      setStreamerBillingVat(billing?.vat_identifier);
      setStreamerBillingBankAccount(streamer?.withdraw_method?.account_number)
      // setFiscalEntity(streamer?.invoice?.organization_type)
    }
  }, []);






  const handleClick = () => {
    if (!validateBillingFields()) {
      return setModalEditBillingData(true)
    }
    return props.subscribeConfirmationModal(props.subscriptionOnModal, props.campaign)
  }















  return <>
    <BillingDataModal open={modalEditBillingData} setOpen={setModalEditBillingData} />
    <Button
      color={props.color || "primary"}
      style={props.style}
      onClick={handleClick}
    >
      Suscribirse
    </Button>
  </>
}