import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Card, Container } from "reactstrap";
import CampaignFiltersTable from "src/components/Campaigns/CampaignFiltersTable";
import HistoricalCampaignsTable from "src/components/Campaigns/HistoricalCampaignsTable";
import MyCampaignsTable from "src/components/Campaigns/MyCampaignsTable";
import { ApiCore } from "src/helpers/api_helper";
import { getCampaigns, getSubscriptions } from "src/helpers/backend_helper";
import WidgetsCampaign from "src/components/Campaigns/WidgetsCampaing";
import AvailableCampaignsTable from "src/components/Campaigns/AvailableCampaignsTable";
import { campaignTableFilterMode } from "src/slices/Campaigns/reducer";
import "../../assets/scss/custom/pages/_campaigns.scss";
import { validURL } from "../Home";
import { TOUR_INDEX } from "src/components/Tour/TourConfig";
import { useLocation } from "react-router-dom";

export const validCampaignImage = (image: string) => {
    console.log('image', image)
    console.log('fabricada', `${process.env.REACT_APP_BASE_URL}${image}`)
    return validURL(image)
        ? image
        : `${process.env.REACT_APP_BASE_URL}${image}`;
};

const Campaigns = (props: any) => {
    const dispatch = useDispatch();
    const [myCampaigns, setMyCampaigns] = useState<Array<any> | null | unknown>();
    const [campaignsAvailable, setCampaignsAvailable] = useState<Array<any> | null | unknown>();
    const [campaignsHistorical, setCampaignsHistorical] = useState<Array<any> | null | unknown>();
    const [subscriptions, setSubscriptions] = useState<Array<any> | null | unknown>();
    // const [countMyCampaigns, setCountMyCampaigns] = useState(0);
    const search = useLocation().search
    const name = new URLSearchParams(search).get('tab');
    const [auxMyCampaigns, setAuxMyCampaigns] = useState<Array<any> | null | unknown>(null);
    const [auxCampaignsAvailable, setAuxCampaignsAvailable] = useState<Array<any> | null | unknown>(
        null
    );
    const [auxCampaignsHistorical, setAuxCampaignsHistorical] = useState<
        Array<any> | null | unknown
    >(null);
    const [noCampaignsDates, setNoCampaignsDates] = useState(false);
    const { statusUpdated } = useSelector((state: any) => ({
        statusUpdated: state.campaign.statusUpdated,
    }));
    const { runTour, currentStep } = useSelector((state: any) => ({
        runTour: state.tour.runTour,
        currentStep: state.tour.currentStep,
    }));
    const { tableFilterMode } = useSelector((state: any) => ({
        tableFilterMode: state.campaign.tableFilterMode,
    }));

    const myCampaignsStatus = ["assigned", "streamer-agreed", "paused", "streamer-paused", "operator-paused"];
    const availableCampaignsStatus = ["streamer-pending", "streamer-requested", "streamer-refused"];
    // const activeCampaignsStatus = "assigned";

    const handleSearch = (input: string) => {
        if (Array.isArray(auxMyCampaigns)) {
            const filteredCampaings = auxMyCampaigns.filter(el => {
                return el.name.toLowerCase().includes(input.toLowerCase());
            });
            setMyCampaigns(filteredCampaings);
        }
        if (Array.isArray(auxCampaignsAvailable)) {
            const filteredCampaings = auxCampaignsAvailable.filter(el => {
                return el.name.toLowerCase().includes(input.toLowerCase());
            });
            setCampaignsAvailable(filteredCampaings);
        }
        if (Array.isArray(auxCampaignsHistorical)) {
            const filteredCampaings = auxCampaignsHistorical.filter(el => {
                return el.name.toLowerCase().includes(input.toLowerCase());
            });
            setCampaignsHistorical(filteredCampaings);
        }
    };

    const handleDate = (dates: any) => {
        setNoCampaignsDates(false);

        if (Array.isArray(auxCampaignsHistorical)) {
            if (dates === "all") {
                setCampaignsHistorical(auxCampaignsHistorical);
                return;
            }
            const FilteredArray = auxCampaignsHistorical.filter((el: any) => {
                const elDate = new Date(el.start_date);
                if (elDate > dates) return el;
                else return false
            });
            if (!FilteredArray.length) {
                setNoCampaignsDates(true);
                return;
            }
            setCampaignsHistorical(FilteredArray);
        }
    };

    const handleCustomDate = (start_date: Date, end_date: Date) => {
        setNoCampaignsDates(false);
        if (Array.isArray(auxCampaignsHistorical)) {
            const FilteredArray = auxCampaignsHistorical.filter((el: any) => {
                const elDate = new Date(el.start_date);
                if (elDate > start_date && elDate < end_date) return el;
                else return false
            });
            if (!FilteredArray.length) {
                setNoCampaignsDates(true);
                return;
            }
            setCampaignsHistorical(FilteredArray);
        }
    };
    useEffect(() => {
        dispatch(campaignTableFilterMode("Mis campañas"));
        if (name) dispatch(campaignTableFilterMode(name));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const myCampaings = getCampaigns(new ApiCore(localStorage.getItem("token")));
        myCampaings.then((data: any) => {
            const allCampaigns: any = Object.values(data.payload.streamers)[0];
            setCampaignsHistorical(allCampaigns);
            const myCampaings: any = Array.isArray(allCampaigns)
                ? allCampaigns.filter((e: any) => {
                    if (myCampaignsStatus.includes(e.subscription_status) && e.campaign_situation !== "finished") {
                        return true;
                    }
                    return false;
                })
                : [];
            const availableCampaigns: any = Array.isArray(allCampaigns)
                ? allCampaigns.filter((e: any) => {
                    if (availableCampaignsStatus.includes(e.subscription_status)) {
                        return true;
                    }
                    return false;
                })
                : [];

            // const activeCampaigns = Array.isArray(allCampaigns)
            //     ? allCampaigns.filter((e: any) => {
            //         if (e.subscription_status === activeCampaignsStatus) {
            //             return true;
            //         }
            //         return false;
            //     })
            //     : [];
            getSubscriptions(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                const subscriptionsResponse = Object.values(data.payload.streamers)[0];
                setMyCampaigns(myCampaings);
                setAuxMyCampaigns(myCampaings);
                setCampaignsAvailable(availableCampaigns);
                setSubscriptions(subscriptionsResponse);
                setAuxCampaignsAvailable(availableCampaigns);
                setCampaignsHistorical(allCampaigns);
                setAuxCampaignsHistorical(allCampaigns);
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableFilterMode, statusUpdated]);

    // useEffect(() => {
    //     if (Array.isArray(subscriptions)) {
    //         subscriptions.forEach((subscription: any) => {
    //             if (availableCampaignsStatus.includes(subscription.status.name)) {
    //                 setCountCampaignsAvailable(countCampaignsAvailable + 1);
    //             } else if (myCampaignsStatus.includes(subscription.status.name)) {
    //                 setCountMyCampaigns(countMyCampaigns + 1);
    //             }
    //         });
    //     }
    // }, [subscriptions]);

    const renderSwitch = () => {
        switch (tableFilterMode) {
            case "Mis campañas":
                return <MyCampaignsTable campaigns={myCampaigns} subscriptions={subscriptions} />;

            case "Campañas disponibles":
                return (
                    <AvailableCampaignsTable
                        campaigns={campaignsAvailable}
                        subscriptions={subscriptions}
                    />
                );
            case "Histórico":
                return (
                    <HistoricalCampaignsTable
                        campaigns={campaignsHistorical}
                        subscriptions={subscriptions}
                        handleDate={handleDate}
                        handleCustomDate={handleCustomDate}
                        noCampaignsDates={noCampaignsDates}
                    />
                );
        }
    };

    if (runTour) {
        if (currentStep === TOUR_INDEX.CAMPAIGN_3.INDEX) {
            dispatch(campaignTableFilterMode("Mis campañas"));
        }
        if (currentStep === TOUR_INDEX.CAMPAIGN_4.INDEX) {
            dispatch(campaignTableFilterMode("Campañas disponibles"));
        }
        if (currentStep === TOUR_INDEX.CAMPAIGN_5.INDEX) {
            dispatch(campaignTableFilterMode("Histórico"));
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Campaigns")}</title>
                </MetaTags>
                <Container fluid>
                    <WidgetsCampaign
                        className="campaigns1"
                    />
                    <Card className="campaigns3" style={{ marginTop: "1rem" }}>
                        <Container fluid>
                            <CampaignFiltersTable handleSearch={handleSearch} />
                            {renderSwitch()}
                        </Container>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(withRouter(Campaigns));
