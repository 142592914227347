import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { walletTableFilterMode } from "src/slices/Wallet/reducer";
// import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import classnames from "classnames";
import { TOUR_INDEX, ALT_TOUR_INDEX } from "../Tour/TourConfig";

export const ACTIVES_NAV = "Saldos Vivos";
export const AVAILABLE_NAV = "Saldos Disponibles";
export const WITHDRAW_NAV = "Saldos Retirados";
export const BILLS_NAV = "Facturas";

export const PAYMENTS_NAV = "Transacciones";

const WalletFiltersTable = () => {
    const dispatch = useDispatch();
    const [activesCheck, setActivesCheck] = useState(true);
    const [availableCheck, setAvailableCheck] = useState(false);
    const { tableFilterMode } = useSelector((state: any) => (state.wallet))
    const [withdrawCheck, setWithdrawCheck] = useState(false);
    const [paymentsCheck, setPaymentsCheck] = useState(false);
    const [billsCheck, setBillsCheck] = useState(false);

    // const { daysFilter } = useSelector((state: any) => ({
    //     daysFilter: state.wallet.dateFilter,
    // }));
    const { runTour, currentStep } = useSelector((state: any) => ({
        runTour: state.tour.runTour,
        currentStep: state.tour.currentStep,
    }));

    const { altRunTour, altCurrentStep } = useSelector((state: any) => ({
        altRunTour: state.altTour.runTour,
        altCurrentStep: state.altTour.currentStep,
    }));
    const tabsSet = [setActivesCheck, setAvailableCheck, setWithdrawCheck, setPaymentsCheck, setBillsCheck];

    const handleCLick = (arr: Array<any>, set: any) => {
        arr.forEach(el => el(false));
        set(true);
    };

    useEffect(() => {
        // handleChangeCheck(tableFilterMode)

        handleChangeCheck({ currentTarget: { attributes: { name: { value: tableFilterMode } } } },);


    }, [tableFilterMode])

    const handleChangeCheck = (e: any, status?: any) => {
        if (!status) {
            switch (e.currentTarget.attributes.name.value) {
                case ACTIVES_NAV:
                    handleCLick(tabsSet, setActivesCheck);
                    dispatch(walletTableFilterMode(ACTIVES_NAV));
                    break;
                case AVAILABLE_NAV:
                    handleCLick(tabsSet, setAvailableCheck);
                    dispatch(walletTableFilterMode(AVAILABLE_NAV));
                    break;
                case WITHDRAW_NAV:
                    handleCLick(tabsSet, setWithdrawCheck);
                    dispatch(walletTableFilterMode(WITHDRAW_NAV));
                    break;
                case PAYMENTS_NAV:
                    handleCLick(tabsSet, setPaymentsCheck);
                    dispatch(walletTableFilterMode(PAYMENTS_NAV));
                    break;
                case BILLS_NAV:
                    handleCLick(tabsSet, setBillsCheck);
                    dispatch(walletTableFilterMode(BILLS_NAV));
                    break;
            }
        }
    };
    if (runTour) {
        if (currentStep === TOUR_INDEX.WALLET_3.INDEX) {
            console.log("ran", currentStep, altCurrentStep);
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: ACTIVES_NAV } } } },
                activesCheck
            );
        }
        if (currentStep === TOUR_INDEX.WALLET_4.INDEX) {
            console.log("ran", currentStep, altCurrentStep);
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: AVAILABLE_NAV } } } },
                availableCheck
            );
        }
        if (currentStep === TOUR_INDEX.WALLET_5.INDEX) {
            console.log("ran2", currentStep, altCurrentStep);
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: WITHDRAW_NAV } } } },
                withdrawCheck
            );
        }
        if (currentStep === TOUR_INDEX.WALLET_6.INDEX) {
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: PAYMENTS_NAV } } } },
                paymentsCheck
            );
        }
        if (currentStep === TOUR_INDEX.WALLET_7.INDEX) {
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: BILLS_NAV } } } },
                billsCheck
            );
        }

    }

    if (altRunTour) {
        if (altCurrentStep === ALT_TOUR_INDEX.WALLET_3.INDEX) {
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: ACTIVES_NAV } } } },
                activesCheck
            );
        }
        if (altCurrentStep === ALT_TOUR_INDEX.WALLET_4.INDEX) {
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: AVAILABLE_NAV } } } },
                availableCheck
            );
        }
        if (altCurrentStep === ALT_TOUR_INDEX.WALLET_5.INDEX) {
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: WITHDRAW_NAV } } } },
                withdrawCheck
            );
        }
        if (altCurrentStep === ALT_TOUR_INDEX.WALLET_6.INDEX) {
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: PAYMENTS_NAV } } } },
                paymentsCheck
            );
        }
        if (altCurrentStep === ALT_TOUR_INDEX.WALLET_7.INDEX) {
            handleChangeCheck(
                { currentTarget: { attributes: { name: { value: BILLS_NAV } } } },
                billsCheck
            );
        }

    }
    return (
        <React.Fragment>
            <Row className="mt-3 mb-3">
                <Col md={8} style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", overflow: "hidden" }}>
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    name={ACTIVES_NAV}
                                    className={classnames({ active: activesCheck })}
                                    onClick={e => {
                                        handleChangeCheck(e, activesCheck);
                                    }}>
                                    {/* <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                    </span> */}
                                    <span className="d-block">Saldos vivos</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    name={AVAILABLE_NAV}
                                    className={classnames({ active: availableCheck })}
                                    onClick={e => {
                                        handleChangeCheck(e, availableCheck);
                                    }}>
                                    {/* <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                    </span> */}
                                    <span className="">Saldos disponibles</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    name={WITHDRAW_NAV}
                                    className={classnames({ active: withdrawCheck })}
                                    onClick={e => {
                                        handleChangeCheck(e, withdrawCheck);
                                    }}>
                                    {/* <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                    </span> */}
                                    <span className="">Saldos retirados</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    name={PAYMENTS_NAV}
                                    className={classnames({ active: paymentsCheck })}
                                    onClick={e => {
                                        handleChangeCheck(e, paymentsCheck);
                                    }}>
                                    {/* <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                    </span> */}
                                    <span className="">Transacciones</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Nav tabs justified className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    name={BILLS_NAV}
                                    className={classnames({ active: billsCheck })}
                                    onClick={e => {
                                        handleChangeCheck(e, billsCheck);
                                    }}>
                                    {/* <span className="d-block d-sm-none">
                                    <i className="fas fa-home"></i>
                                    </span> */}
                                    <span className="">Facturas</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </Col>
                {/*<Col md={4}>
                    <Input 
                        type="select"
                        className="form-select"
                        onChange={(e) => dispatch(dateFilter(e.currentTarget.value))}
                        defaultValue={daysFilter}
                    >
                        <option value={7}>7 días</option>
                        <option value={30}>30 días</option>
                        <option value={365}>1 año</option>
                        <option value={"all"}>Todo</option>
                    </Input>
                </Col>*/}
            </Row>
        </React.Fragment>
    );
};

export default WalletFiltersTable;
