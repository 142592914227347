import React, { useEffect, useState } from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { Col, Row, Button, Spinner, Input } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import moment from "moment";
import {
    getStreamerProfile,
    jsonResponseFromAxiosResponse,
    putStreamerProfile,
} from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import { languagesIso } from "src/common/data/languagesIso";
import { countries } from "src/common/data/countries";
import { profileModal } from "src/slices/Auth/Profile/reducer";
registerLocale("es", es);

const TourInfo = (props: any) => {
    const dispatch = useDispatch();
    const { pfp } = useSelector((state: any) => state.profile);
    const [name, setname] = useState();
    const [username, setusername] = useState();
    const [email, setemail] = useState<string>();
    const [photo, setphoto] = useState(pfp);
    const [saveButtonGeneralInfo, setSaveButtonGeneralInfo] = useState(true);
    const [updatingGeneralInfo, setUpdatingGeneralInfo] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [streamerBirthdate, setStreamerBirthdate] = useState<Date>();
    const [streamerGender, setStreamerGender] = useState<string>("");
    const [streamerLanguage, setStreamerLanguage] = useState<string>("");
    const [streamerStatus, setStreamerStatus] = useState<string>("");
    const [streamerNationality, setStreamerNationality] = useState<string>("");
    const [streamerCurrency, setStreamerCurrency] = useState<string>("");
    const [streamerNotes, setStreamerNotes] = useState<string>("");
    const [streamerId, setStreamerId] = useState<string>("");
    const [configurationTwitchModal, setConfigurationTwitchModal] = useState(false);
    const [pendingApprovalModal, setPendingApprovalModal] = useState(false);
    const { success } = useSelector((state: any) => ({
        success: state.profile.success,
    }));

    const checkGeneralInfo = () => {
        const data = [streamerBirthdate, streamerGender, streamerNationality, streamerCurrency, streamerLanguage].every(el => el && el !== "")
        return data
    }

    const getStatusStreamerTranslation = (status: any) => {
        switch (status) {
            case "enabled":
                return "Activo";
            case "new":
                setPendingApprovalModal(true);
                return "Pendiente de activación";
            default:
                return "";
        }
    };

    const handleValidSubmitGeneralInfo = (event: any) => {
        event.preventDefault();
        setUpdatingGeneralInfo(true);
        saveProfileGeneralInfo();

    };

    useEffect(() => {
        if (configurationTwitchModal || pendingApprovalModal) {
            dispatch(profileModal(true));
        } else {
            dispatch(profileModal(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configurationTwitchModal, pendingApprovalModal]);

    useEffect(() => {
        const getAuthUser = localStorage.getItem("authUser");
        if (getAuthUser) {
            const obj = JSON.parse(getAuthUser);
            if (obj) {
                setname(obj.name);
                setusername(obj.username);
                setemail(obj.email);
                setphoto(obj.photo);
            }
        }

        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            if (streamer) {
                if (streamer.birthdate) {
                    setStreamerBirthdate(
                        new Date(moment(streamer.birthdate).startOf("day").format("MM/DD/YYYY"))
                    );
                }
                setStreamerGender(streamer.gender);
                setStreamerLanguage(streamer.language_iso);
                setStreamerStatus(getStatusStreamerTranslation(streamer.status?.name));
                setStreamerNationality(streamer.nationality);
                setStreamerCurrency(streamer.currency_iso);
                setStreamerNotes(streamer.notes);
                setStreamerId(streamer.id);
                const valid = [streamer.currency_iso, streamer.nationality, streamer.language_iso, streamer.birthdate, streamer.gender].every(el => el && el !== "")
                props.disableStep(!valid)
            } else {
                setConfigurationTwitchModal(true);
            }
        } else {
            setConfigurationTwitchModal(true);
        }

    }, [success]);

    useEffect(() => {
        const getProfile = localStorage.getItem("profile");
        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            if (streamer) {
                if (streamer.birthdate) {
                    setStreamerBirthdate(
                        new Date(moment(streamer.birthdate).startOf("day").format("MM/DD/YYYY"))
                    );
                }
                setStreamerGender(streamer.gender);
                setStreamerLanguage(streamer.language_iso);
                setStreamerStatus(getStatusStreamerTranslation(streamer.status?.name));
                setStreamerNationality(streamer.nationality);
                setStreamerCurrency(streamer.currency_iso);
                setStreamerNotes(streamer.notes);
                setStreamerId(streamer.id);
                const valid = [streamer.currency_iso, streamer.nationality, streamer.language_iso, streamer.birthdate, streamer.gender].every(el => el && el !== "")
                // console.log("llegue", !valid)
                props.disableStep(!valid)
            } else {
                setConfigurationTwitchModal(true);
            }
        }
        return () => props.disableStep(false)
    }, [])

    useEffect(() => {
        if (!checkGeneralInfo()) {
            props.disableStep(!checkGeneralInfo())
            return
        }
        // props.disableStep(!checkGeneralInfo())

    }, [streamerBirthdate, streamerGender, streamerNationality, streamerCurrency, streamerLanguage])

    const saveProfileGeneralInfo = () => {
        putStreamerProfile(new ApiCore(localStorage.getItem("token")), {
            streamer_id: streamerId,

            birthdate: streamerBirthdate,
            gender: streamerGender,
            nationality: streamerNationality,
            language_iso: streamerLanguage,
            currency_iso: streamerCurrency,
            notes: streamerNotes,
        }).then((data: any) => {
            if (data.message === "ok") {
                getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
                    const profileJson = jsonResponseFromAxiosResponse(data);
                    localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
                    localStorage.setItem("profile", JSON.stringify(profileJson.payload));
                    setUpdatingGeneralInfo(false);
                    props.disableStep(false)

                });
            } else {
            }
        });
    };



    useEffect(() => {

        // checkGeneralInfo()

        // props.disableStep(!checkGeneralInfo())

    }, [streamerBirthdate, streamerGender, streamerNationality, streamerCurrency, streamerLanguage])

    return (
        <div className="first-block card border shadow-none">
            <div className="card-header d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm">
                        <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 d-flex justify-content-start mt-2">
                    <h4 style={{ color: "#fff" }} >Información General</h4>
                </div>
            </div>
            <CardBody>
                <AvForm
                    onValidSubmit={(e: any, v: any) => {
                        handleValidSubmitGeneralInfo(e);
                    }}>
                    <div>
                        <Input hidden name="streamer_id" value={streamerId}></Input>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <label className={`${!streamerBirthdate ? "text-danger" : ""}`}>Fecha de nacimiento *</label>
                                    <DatePicker
                                        className="form-control d-block"
                                        placeholderText="Seleccione la fecha de nacimiento"
                                        locale="es"
                                        name="birthdate"
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        errorMessage="Este campo es obligatorio"
                                        selected={streamerBirthdate}
                                        onChange={(newValue: any, e: any) => {
                                            e.target.name = "birthdate";
                                            e.target.value = newValue;
                                            setSaveButtonGeneralInfo(false);
                                            setStreamerBirthdate(newValue);
                                        }}
                                    />
                                    <div className="invalid-feedback" style={{ display: `${!streamerBirthdate ? "block" : "none"}` }}>Este campo es obligatorio</div>

                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <AvField
                                        type="select"
                                        name="gender"
                                        label="Género *"
                                        value={streamerGender}
                                        required
                                        errorMessage="Este campo es obligatorio"

                                        onChange={(e: any) => {
                                            setSaveButtonGeneralInfo(false);
                                            setStreamerGender(e.target.value);
                                        }}>
                                        <option value="">Elegir género</option>
                                        <option value={"female"}>Femenino</option>
                                        <option value={"male"}>Masculino</option>
                                        <option value={"other"}>Otro</option>
                                    </AvField>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <AvField
                                        name="nationality"
                                        label="País de nacimiento *"
                                        type="select"
                                        required
                                        errorMessage="Este campo es obligatorio"

                                        value={streamerNationality}
                                        onChange={(e: any) => {
                                            setStreamerNationality(e.target.value);
                                            setSaveButtonGeneralInfo(false);
                                        }}>
                                        <option value="">Elegir país de nacimiento</option>
                                        {countries.map((country: any, index: number) => {
                                            return (
                                                <option
                                                    key={country.name + index}
                                                    value={country.name}>
                                                    {country.name === 'Spain' ? 'España' : country.name}
                                                </option>
                                            );
                                        })}
                                    </AvField>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <AvField
                                        name="language_iso"
                                        label="Idioma de emisión *"
                                        type="select"
                                        required
                                        errorMessage="Este campo es obligatorio"

                                        placeholder="Ingresar idioma de emisión"
                                        value={streamerLanguage}
                                        onChange={(e: any) => {
                                            setStreamerLanguage(e.target.value);
                                            setSaveButtonGeneralInfo(false);
                                        }}>
                                        <option value="">Elegir idioma</option>
                                        {languagesIso.map((language: any) => {
                                            return (
                                                <option key={language.code} value={language.code}>
                                                    {language.name}
                                                </option>
                                            );
                                        })}
                                    </AvField>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <AvField
                                        name="currency_iso"
                                        label="Moneda *"
                                        type="select"
                                        required
                                        errorMessage="Este campo es obligatorio"

                                        placeholder="Ingresar moneda"
                                        value={streamerCurrency}
                                        onChange={(e: any) => {
                                            setStreamerCurrency(e.target.value);
                                            setSaveButtonGeneralInfo(false);
                                        }}>
                                        <option value="">Elegir moneda</option>
                                        <option value="USD">USD</option>
                                        <option value="EUR">EUR</option>
                                    </AvField>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <AvField
                                        name="notes"
                                        label="Notas"
                                        type="text"
                                        placeholder="Ingresar notas"
                                        value={streamerNotes}
                                        onChange={(e: any) => {
                                            setStreamerNotes(e.target.value);
                                            setSaveButtonGeneralInfo(false);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Button
                            color="primary"
                            style={{ float: "right" }}
                            type="submit"
                            disabled={saveButtonGeneralInfo || updatingGeneralInfo || !checkGeneralInfo()}>
                            {" "}
                            {updatingGeneralInfo ? (
                                <Spinner size={"sm"} animation="border" />
                            ) : (
                                "Actualizar"
                            )}
                        </Button>
                        {"  "}
                    </div>
                </AvForm>
            </CardBody>
        </div>
    );
};

export default TourInfo;
