import campanasActivas from "./campañas_activas.png";
import campanasDisponibles from "./campañas_disponibles.png";
import campanasPropuestas from "./campañas_propuestas.png";
import campanasSuscritas from "./campañas_suscritas.png";
import clicksChat from "./clicks_chat.png";
import ctr from "./CTR.png";
import datosFacturacion from "./datos_facturacion.png";
import gananciasTotales from "./ganancias_totales.png";
import emisiones from "./emisiones.png";
// import revenue from "./saldo_proceso_pago.png";
import pendingBalance from './saldo_proceso_pago.png'
import tipoEntidadFiscal from "./Tipo-entidad.svg";
import saldoAcumulado from "./saldo_acumulado.png";
import saldoDisp from "./saldo_disponible.png";
import vieswMedios from "./VIEWERS_MEDIOS.png";
import views from "./views.png";


import brightnessMode from "./brightness-mode.svg";


export const CampanasDisponiblesIcon = (props: any) => {
    return <img alt="Icono" src={campanasDisponibles} width={props.size || 70} height={props.size || 70} {...props} />
}

export const CampanasPropuestasIcon = (props: any) => {
    return <img alt="Icono" src={campanasPropuestas} width={props.size || 70} height={props.size || 70} {...props} />
}

export const CampanasSuscritasIcon = (props: any) => {
    return <img alt="Icono" src={campanasSuscritas} width={props.size || 70} height={props.size || 70} {...props} />
}

export const CampanasActivasIcon = (props: any) => {
    return <img alt="Icono" src={campanasActivas} width={props.size || 70} height={props.size || 70} {...props} style={{ scale: "1.2" }} />
}

export const ClicksChatIcon = (props: any) => {
    return <img alt="Icono" src={clicksChat} width={props.size || 70} height={props.size || 70} {...props} />
}
export const CtrIcon = (props: any) => {
    return <img alt="Icono" src={ctr} width={props.size || 70} height={props.size || 70} {...props} />
}
export const DatosFacturacionIcon = (props: any) => {
    return <img alt="Icono" src={datosFacturacion} width={props.size || 70} height={props.size || 70} {...props} />
}
export const EmisionesIcon = (props: any) => {
    return <img alt="Icono" src={emisiones} width={props.size || 70} height={props.size || 70} {...props} />
}
export const GananciasTotalesIcon = (props: any) => {
    return <img alt="Icono" src={gananciasTotales} width={props.size || 70} height={props.size || 70} {...props} />
}
export const ViewsIcon = (props: any) => {
    return <img alt="Icono" src={views} width={props.size || 70} height={props.size || 70} {...props} />
}
export const VieswMediosIcon = (props: any) => {
    return <img alt="Icono" src={vieswMedios} width={props.size || 70} height={props.size || 70} {...props} />
}
export const TipoEntidadFiscalIcon = (props: any) => {
    return <img alt="Icono" src={tipoEntidadFiscal} width={props.size || 70} height={props.size || 70} {...props} />
}
export const SaldoDispIcon = (props: any) => {
    return <img alt="Icono" src={saldoDisp} width={props.size || 70} height={props.size || 70} {...props} />
}
export const SaldoAcumuladoIcon = (props: any) => {
    return <img alt="Icono" src={saldoAcumulado} width={props.size || 70} height={props.size || 70} {...props} />
}
export const RevenueIcon = (props: any) => {
    return <img alt="Icono" src={saldoAcumulado} width={props.size || 70} height={props.size || 70} {...props} />
}
export const BrightnessModeIcon = (props: any) => {
    return <img alt="Icono" src={brightnessMode} width={props.size || 70} height={props.size || 70} {...props} />
}

export const SaldoEnProcesoDePagoIcon = (props: any) => {
    return <img alt="Icono" src={pendingBalance} width={props.size || 70} height={props.size || 70} {...props} />
}



