import React from "react";
import {  Card, CardBody,  } from "reactstrap";
import Icon from "@ailibs/feather-react-ts/dist/Icon";
interface Props {
    title: string;
    style?: any;
    color?: string;
    data?: any;
    icon?:any;
    titleFontSize?:string
    children?: any;
}

const WidgetBoxWallet: React.FC<Props> = ({ title, style, children, color, data,icon,titleFontSize }) => {
    return (
        <>

            <Card style={{maxHeight:"122px",boxShadow:"0 0 2px 3px #022032" }} className=" on-hover shadow-none ">
                <CardBody style={{ padding: "0.4rem", display: "flex", flexWrap: "nowrap", alignItems: "center",justifyContent:"start",gap:"1rem" }}>
                    <div style={{
                        // background: `${color || "rgb(29, 161, 212)"}`,
                        width: "70px",
                        height: "auto",
                        display: "grid", placeContent: "center",
                        borderRadius: "5%",
                        aspectRatio: "1/1",
                        position: "relative",
                        overflow: "hidden"
                    }}>

                        {/* Circulos */}
                        {/* <section>
                            <div style={{ position: "absolute", right: "-25%", bottom: "-45%", zIndex: 0 }}>
                                <div style={{
                                    background: "transparent", width: "4em", height: "4em", borderRadius: "50%", position: "relative", boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)"
                                }}>
                                    <div style={{
                                        background: "transparent",
                                        width: "2.6em",
                                        height: "2.6em",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)"
                                    }}>
                                        <div style={{
                                            background: "transparent",
                                            width: "1em",
                                            height: "1em",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: "absolute", right: "-50%", top: "0", zIndex: 0 }}>
                                <div style={{
                                    background: "transparent", width: "4em", height: "4em", borderRadius: "50%", position: "relative", boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)"
                                }}>
                                    <div style={{
                                        background: "transparent",
                                        width: "2.6em",
                                        height: "2.6em",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)"
                                    }}>
                                        <div style={{
                                            background: "transparent",
                                            width: "1em",
                                            height: "1em",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)"
                                        }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                        {/*  */}
                            {icon ? icon :<Icon name="airplay" color="white"></Icon>}
                        {/* <div style={{ zIndex: 1 }}>
                        </div> */}
                    </div>
                    <main style={{display:"flex",flexDirection:"column",justifyContent:"space-around",flexGrow:1,alignItems:"center"}}>
                        <h6 style={{ fontSize:titleFontSize|| "1.2rem",marginBottom:0,fontWeight:300 }}>
                            {title}
                        </h6>
                        {data}
                        {children}
                    </main>
                </CardBody>
            </Card>

            {/* <Card style={style ? style : {}} className=" card-h-100  on-hover  mb-2 d-flex ">
                <CardBody className="d-flex justify-content-between w-100 h-100 flex-column">
                    <CardTitle
                        className="d-flex align-items-center"
                        style={{
                            borderBottom: `1px solid ${COLORS.PrimarySkyBlue}`,
                            paddingBottom: "6px",
                            fontSize: "20px",
                        }}
                        tag={"h4"}>
                        <div
                            className="flex-shrink-0 "
                            style={{
                                display: window.innerWidth > 1200 ? "inline-block" : "block",
                                marginRight: "6px",
                            }}>
                            <div className="avatar-sm">
                                <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                                    <i className="uil-star" />
                                </div>
                            </div>
                        </div>
                        {title.toUpperCase()}
                    </CardTitle>
                    {children}
                </CardBody>
            </Card> */}



        </>
    );
};

export default WidgetBoxWallet;


// const old = () => {
//     return (<Card
//         style={{
//             borderRadius: "0.4rem",
//             padding: 0,
//             maxHeight: "150px",
//             minWidth: "200px",
//             minHeight: "147px"
//         }}

//         className="widget border-primary">

//         <CardBody className="d-flex" style={{
//             color: "white",
//             borderRadius: "0.4rem",
//             height: "100%",
//             padding: 0, position: "relative", overflow: "hidden",
//             minWidth: 0,
//         }}>
//             <div style={{
//                 padding: "1rem",
//                 width: "70%",
//                 display: "flex",
//                 flexDirection: "column",
//                 paddingTop: "2rem",
//             }}>
//                 <h6 className="font-size-xs text-uppercase" style={{ color: "white" }}> {title.toUpperCase()}</h6>
//                 {data}
//                 <Row style={{ display: "flex" }}>
//                     {children}
                

//                 </Row>
//             </div>
//             <div style={{
//                 background: `${color || '#038edc'}`,
//                 height: "100%",
//                 width: "60%",
//                 flexBasis: "1 1 0",
//                 position: "absolute",
//                 right: "-30%",
//                 maxWidth: "200px",
//                 borderRadius: "100%",
//                 display: "flex",
//                 justifyContent: "start",
//                 alignItems: "center",
//                 paddingLeft: "1rem"
//             }}>
//                 <Icon name="bell" color="white" />
//             </div>
//         </CardBody>
//     </Card>)
// }