import React, { useEffect, useState } from "react";
import twitchIcon from "../../assets/images/small/twitch-icon.png";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Spinner } from "reactstrap";
import { getTokenTwitch, getUserTwitch, logoutTwitch, TwitchUser } from "src/helpers/twitch_helper";
import { useHistory } from "react-router";
import { ApiCore } from "src/helpers/api_helper";
import { getUrlRedirectTwitchAuth } from "src/helpers/backend_helper";

const TwitchSync = () => {
    const history = useHistory();
    const [loadingSyncTwitch, setLoadingSyncTwitch] = useState(false);
    // const [loadingLogoutTwitch, setLoadingLogoutTwitch] = useState(false);

    const [twitchAccessToken, setTwitchAccessToken] = useState<string | null>();
    const [twitchUser, setTwitchUser] = useState<TwitchUser | null>(null);

    const syncWithTwitch = () => {
        setLoadingSyncTwitch(true);

        const request = getUrlRedirectTwitchAuth(new ApiCore(localStorage.getItem("token")));
        request
            .then((res: any) => {
                window.location.href = res.payload.redirect;
            })
            .catch((res: any) => {
                console.log("error", res);
            })

    };

    // const desyncWithTwitch = () => {
    //     setLoadingLogoutTwitch(true);
    //     const fetchData = async () => logoutTwitch();
    //     fetchData().then(response => {
    //         sessionStorage.removeItem("twitchAccessToken");
    //         sessionStorage.removeItem("twitchUser");
    //         setLoadingLogoutTwitch(true);
    //         setTwitchUser(null);
    //         setTwitchAccessToken(null);
    //     });
    // };

    const setTwitchUserWithLocalSession = () => {
        const sessionTwitchUser = sessionStorage.getItem("twitchUser");
        if (sessionTwitchUser !== null) {
            setTwitchUser(JSON.parse(sessionTwitchUser));
        }
    };

    useEffect(() => {
        setTwitchUserWithLocalSession();
        if (history.location.hash !== "") {
            const searchParams = history.location.hash.replace("#", "?");
            const query = new URLSearchParams(searchParams);
            if (query.get("state") === "twitch") {
                const accessToken = query.get("access_token") ?? "";

                setTwitchAccessToken(accessToken);
                sessionStorage.setItem("twitchAccessToken", accessToken);

                // if (twitchAccessToken) {
                //     const fetchData = async () => getUserTwitch();
                //     fetchData().then((response: any) => {
                //         sessionStorage.setItem("twitchUser", JSON.stringify(response.data.data[0]));
                //         setTwitchUser(response.data.data[0]);
                //         history.replace({
                //             hash: "",
                //         });
                //     });
                // } 
                // else {
                //     const twitchUserSession = sessionStorage.getItem("twitchUser");
                //     if (twitchUserSession !== undefined && twitchUserSession !== null) {
                //         setTwitchUser(JSON.parse(twitchUserSession));
                //     }
                // }
            }
        }
    }, [twitchAccessToken]);

    // let userSynchronized = <></>;
    // if (twitchUser !== undefined && twitchUser !== null) {
    //     userSynchronized = (
    //         <Card style={{ marginTop: "5px" }}>
    //             <div style={{ justifyContent: "center", display: "flex", marginTop: "5px" }}>
    //                 <img
    //                     alt="Sample"
    //                     width="100px"
    //                     referrerPolicy="no-referrer"
    //                     src={twitchUser.profile_image_url}
    //                     style={{ borderRadius: "50%" }}
    //                 />
    //             </div>

    //             <CardBody>
    //                 <CardTitle tag="h5">{twitchUser.display_name}</CardTitle>
    //                 <CardSubtitle className="mt-1 mb-2 text-muted" tag="h6">
    //                     {twitchUser.email}
    //                 </CardSubtitle>
    //                 <CardText>Id: {twitchUser.id}</CardText>
    //                 <div style={{ justifyContent: "center", display: "flex" }}>
    //                     <Button onClick={desyncWithTwitch} style={{ backgroundColor: "#8652f6" }}>
    //                         <i className="fas fa-unlink"></i>
    //                         <span style={{ marginRight: "10px", marginLeft: "5px" }}>
    //                             Desasociar
    //                         </span>
    //                         {loadingLogoutTwitch ? (
    //                             <Spinner animation="border" size={"sm"} />
    //                         ) : (
    //                             <></>
    //                         )}
    //                     </Button>
    //                 </div>
    //             </CardBody>
    //         </Card>
    //     );
    // }

    return (
        <React.Fragment>
            <Button
                onClick={syncWithTwitch}
                style={{ backgroundColor: "#8652f6", width: "100%" }}
                disabled={twitchUser !== null ? true : false}>
                <img alt="" src={twitchIcon} width="25px" height="25px"></img>
                <span style={{ marginRight: "10px", marginLeft: "5px" }}>
                    Sincronizar con Twitch
                </span>
                {loadingSyncTwitch ? <Spinner animation="border" size={"sm"} /> : <></>}
            </Button>

            {/* {userSynchronized} */}
        </React.Fragment>
    );
};

export default TwitchSync;
