import React, { useEffect, useState } from "react";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import TourBilling from "./TourBilling";
import TourContact from "./TourContact";
import TourInfo from "./TourInfo";
import { useDispatch, useSelector } from "react-redux";
import { setRunTour, setStep } from "src/slices/Tour/reducer";
import { COLORS } from "src/utils/COLORS";
import AcceptCampaignSelect from "../EditProfile/AcceptCampaignSelect";
import EmissionConfigurationDraggable from "src/components/Draggables/EmissionConfigurationDraggable";
import TourConfiguration from "./TourConfiguration";
import TourDraggable from "./TourDraggable";
import { useHistory } from "react-router-dom";
import { putStreamerProfile, updateStreamerPlayout } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import { getProfile } from "src/slices/thunks";
import { Button, Card } from "reactstrap";
import RangeSlider from "react-bootstrap-range-slider";
import { toNumber } from "lodash";
import { TOUR_INDEX } from "./TourConfig";

const buttonStates = {
    editing: "Guardar posición",
    loading: "Guardando posición",
    success: "Posición guardada",
};

const possibleValues: number[] = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];





function Tour() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentStep2, setCurrentStep] = useState(0);
    const [disableClose, setDisableClose] = useState(false);
    const [disableStep, setDisableStep] = useState(false)
    const [button, setButtonState] = useState(buttonStates.editing);
    const [accept_subscriptions_automatically, setAccept_subscriptions_automatically] = useState(0);
    const [streamerAdPositionX, setStreamerAdPositionX] = useState<number>();
    const [streamerAdPositionY, setStreamerAdPositionY] = useState<number>();
    const [streamerAdCadenceMinutes, setStreamerAdCadenceMinutes] = useState(10);
    const [streamerId, setStreamerId] = useState();
    const { coordinates } = useSelector((state: any) => ({
        coordinates: state.emission.coordinates,
    }));
    const profileData = localStorage.getItem("profile") ?? "";
    const { runTour, currentStep } = useSelector((state: any) => ({
        runTour: state.tour.runTour,
        currentStep: state.tour.currentStep,
    }));
    const genericDiv = { width: "400px" };
    const titleStyle = { marginBottom: "20px", fontWeight: 500 };

    useEffect(() => {
        if (profileData) {
            const obj = JSON.parse(profileData);
            const streamer = obj.streamers?.[0];
            setAccept_subscriptions_automatically(streamer?.accept_subscriptions_automatically);
            setStreamerAdCadenceMinutes(
                streamer?.ad_cadence_secs == null ? 5 : streamer?.ad_cadence_secs / 60
            );
            setStreamerId(streamer?.id);
            setStreamerAdPositionX(streamer?.ad_position_x);
            setStreamerAdPositionY(streamer?.ad_position_y);
        }
    }, [profileData]);

    useEffect(() => {
        if (profileData && currentStep === TOUR_INDEX.ACCEPT.INDEX) {
            const obj = JSON.parse(profileData);
            const streamer = obj.streamers?.[0];
            if (
                streamer?.accept_subscriptions_automatically !== accept_subscriptions_automatically
            ) {
                updateAccept();
            }
        }
    }, [accept_subscriptions_automatically]);

    const updateAccept = () => {
        if (profileData) {
            const obj = JSON.parse(profileData);
            const streamer = obj.streamers?.[0];
            const data = {
                streamer_id: localStorage.getItem("streamer_id"),
                birthdate: streamer.birthdate,
                gender: streamer.gender,
                nationality: streamer.nationality,
                language_iso: streamer.language_iso,
                currency_iso: streamer.currency_iso,
                notes: streamer.notes,
                channel_topic: streamer.channel_topic,
                accept_subscriptions_automatically,
            };
            putStreamerProfile(new ApiCore(localStorage.getItem("token")), data)
                .then(res => {
                    dispatch(getProfile(history));
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };


    const handleJoyrideCallback = (data: any) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
        if(data.index === 0 && data.action === "next")  setDisableClose(true)
        if (data.type === "step:before") {
            setCurrentStep(data.index);
            dispatch(setStep(data.index));
        } else if (finishedStatuses.includes(status)) {
            dispatch(setRunTour(false));
        } else if (data.action === "close") {
            dispatch(setRunTour(false));
        } else if (data.index === TOUR_INDEX.ACCEPT.INDEX && data.action === "next") {
            updateStreamerPlayout(new ApiCore(localStorage.getItem("token")), {
                streamer_id: streamerId,
                ad_position_x: coordinates.x,
                ad_position_y: coordinates.y,
            });
        } else if (data.index === TOUR_INDEX.CADENCE.INDEX && data.action === "next") {
            updateStreamerPlayout(new ApiCore(localStorage.getItem("token")), {
                streamer_id: streamerId,
                ad_cadence_secs: streamerAdCadenceMinutes * 60,
            });
        } else if (data.index === TOUR_INDEX.BEGGINING.INDEX) {
            history.push("/");
        }
        else if (data.index === TOUR_INDEX.CAMPAIGN.INDEX && data.action === "prev") {
            history.push("/");
        } else if (data.index === TOUR_INDEX.CAMPAIGN.INDEX) {
            history.push("/campaigns");
        } else if (data.index === TOUR_INDEX.WALLET.INDEX && data.action === "prev") {
            history.push("/campaigns");
        } else if (data.index === TOUR_INDEX.WALLET.INDEX) {
            history.push("/wallet");
        } else if (data.index === TOUR_INDEX.STATS.INDEX && data.action === "prev") {
            history.push("/wallet");
        } else if (data.index === TOUR_INDEX.STATS.INDEX) {
            history.push("/statistics");
        } else if (data.type === "tour:end") {
            dispatch(setRunTour(false));
            dispatch(setStep(0));
        }
    };

    const getBodyToUpdateProfile = () => {
        return {
            streamer_id: streamerId,
            ad_cadence_secs: streamerAdCadenceMinutes * 60,
            ad_position_x: coordinates.x,
            ad_position_y: coordinates.y,
        };
    };

    const updateCoordinates = (event: any) => {
        event.preventDefault();

        if (profileData) updateAccept();

        setButtonState(buttonStates.loading);
        const updatePlayout = updateStreamerPlayout(
            new ApiCore(localStorage.getItem("token")),
            getBodyToUpdateProfile()
        );
        updatePlayout.then((data: any) => {
            setButtonState(buttonStates.success);
        });
    };

    return (
        <Joyride
            steps={[
                {
                    target: "body",
                    placement: "center",
                    disableOverlayClose: true,
                    disableCloseOnEsc: true,
                    hideCloseButton: true,
                    content: (
                        <div>
                            <h4 style={titleStyle}>BIENVENID@ a 014 LIVE!</h4>
                            <p>
                                Vamos a guiarte a través de la plataforma para que puedas ver, paso
                                a paso, todo el contenido que te espera en cada sección, y
                                configurar todos tus datos de perfil, facturación y emisiones.
                            </p>
                            <p>¡Te deseamos lo mejor en 014 LIVE!</p>
                        </div>
                    ),
                },

                {
                    target: "body",
                    placement: "center",
                    disableCloseOnEsc: true,
                    hideCloseButton: true,
                    content: (
                        <div>
                            <TourInfo disableStep={setDisableStep} />
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div>
                            <TourContact disableStep={setDisableStep} />
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div>
                            <TourBilling disableStep={setDisableStep} />
                            <p style={{ textAlign: "start", marginLeft: "10px" }}>
                                *Datos no obligatorios en el proceso de alta. Puedes introducirlos posteriormente cuando quieras en la sección de Editar Perfil
                            </p>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div>
                            <AcceptCampaignSelect
                                tour={true}
                                setValue={setAccept_subscriptions_automatically}
                                value={accept_subscriptions_automatically === 1? 1 : 0 }
                            />
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div>
                            <Card className="p-3" style={{ justifyContent: "center" }}>
                                <h4 style={{ color: "#FFF", marginBottom: "10px" }}>
                                    Posición de la publicidad
                                </h4>
                                <span>
                                    Indícanos en qué posición deseas que se emita la publicidad por defecto. Ten en cuenta que nunca ocupará más de un 15% del espacio total.
                                </span>
                                <EmissionConfigurationDraggable
                                    x={
                                        typeof streamerAdPositionX == "number"
                                            ? streamerAdPositionX
                                            : 0
                                    }
                                    y={
                                        typeof streamerAdPositionY == "number"
                                            ? streamerAdPositionY
                                            : 0
                                    }
                                />

                                <div className="d-flex justify-content-end px-5">
                                    <Button onClick={updateCoordinates} color="primary">
                                        {button}
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div>
                            <Card className="p-3" style={{ marginTop: "1rem" }}>
                                <h4 style={{ color: "#fff", marginBottom: "15px" }}>
                                    Cadencia de publicidad por minutos
                                </h4>
                                {/* <label>Cadencia de publicidad por minutos</label> */}

                                <div style={{ marginBottom: "1rem" }}>
                                    Define el tiempo mínimo que quieres entre patrocinios. Recomendamos 30 minutos. Nunca emitiremos patrocinios a frecuencia mayor de la indicada.
                                </div>
                                <div>
                                    <RangeSlider
                                        style={{ padding: "1rem" }}
                                        min={10}
                                        max={60}
                                        tooltip="off"
                                        step={5}
                                        value={toNumber(streamerAdCadenceMinutes)}
                                        onChange={(e: any) =>
                                            setStreamerAdCadenceMinutes(e.target.value)
                                        }
                                    />
                                    <ul
                                        className="d-flex w-100 justify-content-between list-unstyled"
                                        style={{ padding: "0 1rem" }}>
                                        {possibleValues.map((possibleValue: number) => (
                                            <li key={possibleValue}>{possibleValue}</li>
                                        ))}
                                    </ul>
                                </div>
                            </Card>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div style={{ width: "650px" }}>
                            <h4 style={titleStyle}> INICIO DE TUTORIAL</h4>
                            <p>
                                Gracias por introducir tus datos!
                                Ahora te vamos a mostrar como funciona la plataforma. Si ahora no te va bien, lo tienes disponible en tu perfil en cualquier momento.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".step-1",
                    placement: "right",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Datos personales</h4>
                            <p>
                                Estos son tus datos de cuenta y tu imagen de perfil. Estos datos los
                                puedes cambiar cuando quieras en la página de “editar perfil”.
                                Además, encontrarás el estado de tu url de emisión en tu OBS, para
                                saber si estás listo para emitir los anuncios en tus directos o no.
                                Si aparece en rojo es que no estás listo. Copia la url de emisión
                                (pulsando en el botón) y pégala en tu capa visible del OBS.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".step-2",
                    placement: "bottom",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>KPIs de campañas</h4>
                            <p className="text-start" style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                <span >
                                    De un vistazo tienes tus campañas activas, suscritas y disponibles.
                                </span>
                                <ul style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                    <li>
                                        Campañas activas: Son aquellos patrocinios a los que te has suscrito y que ya se están emitiendo
                                    </li>
                                    <li>
                                        Campañas suscritas: Son todos aquellos patrocinios a los que te has suscrito, independientemente de si ya se han iniciado o no
                                    </li>
                                    <li>
                                        Campañas disponibles: Patrocinios que tienes disponibles y a los que te puedes apuntar
                                    </li>
                                </ul>
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".step-3",
                    placement: "top",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Campañas disponibles</h4>
                            <p>
                                Campañas que tienes disponibles y a las que te puedes apuntar.
                                Pulsando en "Detalles” accederás a la información de la campaña. Si
                                quieres apuntarte, solo tienes que darle a “Suscribirse”, y en nada
                                nuestro equipo te confirmará que todo está ok.
                            </p>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div style={{ width: "650px" }}>
                            <h4 style={titleStyle}> Fin inicio</h4>
                            <p>
                                ¡Enhorabuena! Has finalizado la guía en nuestro Inicio, ahora avanzaremos hacia Campañas.
                            </p>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Campañas</h4>
                            <p>
                                Pasamos a la página de campañas, donde podrás ver todo lo
                                relacionado con tus campañas en 014 LIVE!. Capañas activas, campañas
                                disponibles, y como no, todo lo relacionado con tu histórico de
                                campañas.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".campaigns1",
                    placement: "bottom",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>KPIs de campañas</h4>
                            <p>
                                Principales datos sobre tus campañas, tanto activas como
                                disponibles. Se actualizan al momento. Ve crecer tus números desde
                                el minuto 1.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".campaigns2",
                    placement: "left",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>URL de emisión</h4>
                            <p>
                                Descubre si estás listo para emitir (cuadro en color verde), o si te
                                falta configurar todavía la url de emisión en tu OBS (color Rojo).
                                Si sales en rojo, revisa tu OBS y asegúrate que tienes la url de 014
                                LIVE! bien configurada en la capa visible. Si todavía no la tienes,
                                cópiala en el botón que ves aquí, y configúrala en tu OBS.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".campaigns3",
                    placement: "top",
                    content: (
                        <div style={{ width: "650px" }}>
                            <h4 style={titleStyle}>Mis campañas</h4>
                            <p>
                                Listado de las campañas en las que estás suscrito en este momento en 014 LIVE!. Saldrán todas las que se estén emitiendo, las pendientes de iniciar y las que hayas decidido pausar por un tiempo.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".campaigns3",
                    placement: "top",
                    content: (
                        <div style={{ width: "650px" }}>
                            <h4 style={titleStyle}>Campañas disponibles</h4>
                            <p>
                                Campañas que tienes disponibles y a las que te puedes apuntar.
                                Navega por los detalles, revisa las fechas de campaña y decide si te
                                quieres apuntar
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".campaigns3",
                    placement: "top",
                    content: (
                        <div style={{ width: "650px" }}>
                            <h4 style={titleStyle}>Histórico de campañas</h4>
                            <p>
                                Revisa las campañas que en algún momento has emitido con 014 LIVE! y
                                recuerda tu actividad con nosotros.
                            </p>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div style={{ width: "650px" }}>
                            <h4 style={titleStyle}>Fin campañas</h4>
                            <p>
                                ¡Enhorabuena! Has finalizado la guía en nuestra sescción Campañas, ahora avanzaremos hacia Billetera.
                            </p>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Página de Billetera, controla tus ingresos</h4>
                            <p>
                                Descubre cuánto has ganado con 014 LIVE!, cuánto puedes retirar y
                                cuáles han sido tus movimientos y traspasos con nosotros.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".wallet1",
                    placement: "bottom",
                    content: (
                        <div style={{ width: "700px" }}>
                            <h4 style={titleStyle}>Datos destacados</h4>
                            <p>Principales datos sobre tu actividad económica en la plataforma.</p>
                            <ul style={{ textAlign: "left" }}>
                                <li style={{ marginTop: "10px" }}>
                                    <span style={{ fontWeight: 500 }}>Saldo disponible:</span> Es el
                                    saldo que puedes cobrar ahora mismo. Pertenece a las campañas
                                    terminadas. Si pinchas en el botón de cobrar iniciarás el
                                    proceso de pago de tus campañas. IMPORTANTE: Debes tener un
                                    saldo mayor a 20€ así como todos los datos del bloque de
                                    facturación rellenos para poder retirar fondos.
                                </li>
                                <li style={{ marginTop: "10px" }}>
                                    <span style={{ fontWeight: 500 }}>Saldo acumulado:</span>{" "}
                                    Pertenece a lo que has generado hasta el momento y todavía no
                                    has retirado. Sería la suma de los saldos de las campañas
                                    terminadas y no cobradas y de los saldos de las campañas
                                    activas.
                                </li>
                                <li style={{ marginTop: "10px" }}>
                                    <span style={{ fontWeight: 500 }}>
                                        Saldo en proceso de pago:
                                    </span>{" "}
                                    Es el dinero que ya has solicitado cobrar y que ya está incluido
                                    en el sistema de pagos para que lo puedas disfrutar
                                    próximamente.
                                </li>
                                <li style={{ marginTop: "10px" }}>
                                    <span style={{ fontWeight: 500 }}>Datos de facturación:</span>{" "}
                                    Edita en cualquier momento tus datos de facturación.
                                </li>
                            </ul>
                        </div>
                    ),
                },
                {
                    target: ".wallet2",
                    placement: "bottom",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Información importante de billetera</h4>
                            <p>
                                Una breve información necesaria e interesante para operar
                                económicamente en la plataforma.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".wallet3",
                    placement: "top",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Saldos Vivos</h4>
                            <p>
                                Información relativa a las campañas activas y de las que todavía no
                                puedes retirar sus fondos.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".wallet3",
                    placement: "top",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Saldos disponibles</h4>
                            <p>
                                Conoce el detalle de todas las campañas que ya han terminado y
                                todavía no has cobrado. Cuando las cobres, pasarán a saldos
                                retirados.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".wallet3",
                    placement: "top",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Saldos retirados</h4>
                            <p>
                                Saldos de campañas que ya has cobrado. Analiza tu evolución como
                                streamer y como partner nuestro.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".wallet3",
                    placement: "top",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Transacciones</h4>
                            <p>
                                Listado de todos los movimientos de dinero que has tenido en nuestra plataforma. Verás todos los ingresos generados en tu billetera por cada emisión de anuncio, así como todas tus retiradas (dinero cobrado).
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".wallet3",
                    placement: "top",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Facturas</h4>
                            <p>
                                Aquí podrás consultar y descargarte cómodamente todas tus facturas
                            </p>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div style={{ width: "650px" }}>
                            <h4 style={titleStyle}> Fin billetera</h4>
                            <p>
                                ¡Enhorabuena! Has finalizado la guía en nuestra sección Billetera, ahora avanzaremos hacia Estadísticas.
                            </p>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Tus estadísticas con 014 LIVE!</h4>
                            <p>
                                Mantente informado en todo momento cómo es tu evolución en nuestra
                                plataforma gracias al panel de estadísticas. Filtra temporalmente
                                los datos y analízalos gráficamente para ver de un golpe visual
                                rápido toda tu actividad con nosotros. ¡Haz crecer rápidamente tus
                                datos!
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".statistics1",
                    placement: "bottom",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Filtro temporal de estadísticas</h4>
                            <p>
                                Presenta tus datos y las gráficas en función del tiempo que más te
                                interese. Elige entre las opciones que te damos o elige la fecha tú
                                mismo. Fácil, visual e intuitivo.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".statistics2",
                    placement: "bottom",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>KPIs de estadísticas</h4>
                            <p>
                                Principales datos de la actividad que llevas con nosotros. Se
                                actualizan de forma automática y puedes filtrarlos por tiempo, como
                                hemos visto en el paso anterior.
                            </p>
                        </div>
                    ),
                },
                {
                    target: ".statistics3",
                    placement: "top",
                    content: (
                        <div style={genericDiv}>
                            <h4 style={titleStyle}>Gráficas de seguimiento</h4>
                            <p>
                                Revisa visualmente cuáles son tus datos en la plataforma. Escoge la
                                métrica y analiza los datos visualmente en la escala de tiempo que
                                elijas. Observa como es tu comportamiento en 014 LIVE! de forma
                                rápida y muy visual.
                            </p>
                        </div>
                    ),
                },
                {
                    target: "body",
                    placement: "center",
                    content: (
                        <div style={{ width: "650px" }}>
                            <h4 style={titleStyle}> Fin de guía</h4>
                            <p>
                                Enhorabuena! Ya eres miembro de 014 Live! <br />
                                Recuerda que tienes disponible esta guía en tu perfil en cualquier momento
                            </p>
                        </div>
                    ),
                },
            ]}
            run={runTour}
            scrollToFirstStep
            hideCloseButton
            disableCloseOnEsc
            disableOverlayClose
            continuous={true}
            showSkipButton={disableClose}
            callback={handleJoyrideCallback}
            scrollOffset={250}
            locale={{
                back: "Atrás",
                close: "Cerrar",
                last: "Finalizar",
                next: "Siguiente",
                open: "Open the dialog",
                skip: "Cerrar guía",
            }}

            styles={{
                options: {
                    arrowColor: "rgba(3, 49, 77, 0.9)",
                    backgroundColor: "rgba(3, 49, 77, 0.9)",
                    overlayColor: "#000000d4",
                    primaryColor: "#000",
                    textColor: "#fff",
                    width: "auto",
                    zIndex: 1000,
                },
                buttonNext: {
                    backgroundColor: COLORS.bootstrapGreen,
                    padding: "8px 12px 8px 12px ",
                    // filter: `${disableStep ? "opacity(.2)" : "opacity(1)"} `,
                    display: `${disableStep ? "none" : "block"}`
                },
                buttonBack: {
                    backgroundColor: COLORS.bootstrapBlue,
                    borderRadius: "4px",
                    marginRight: "7px",
                    color: "#fff",
                    padding: "8px 12px 8px 12px ",
                    display: `${disableStep ? "none" : "block"}`

                },
                buttonSkip: {
                    backgroundColor: COLORS.bootstrapBlue,
                    borderRadius: "4px",
                    padding: "8px 12px 8px 12px ",
                    display: `${disableStep ? "none" : "block"}`

                },
                buttonClose: {
                    display: `${disableStep ? "none" : "block"}`
                }
            }}
        />
    );
}

export default Tour;
