import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import "../../assets/scss/custom/components/_draggable.scss";
import obsImage from 'src/assets/images/adPosition.png';

import { useDispatch } from 'react-redux';
import { coordinatesChange } from 'src/slices/Auth/Emission/reducer';
import { debuglog } from 'util';

interface EmissionConfigurationDraggableProps {
  x: number, y: number
}

const EmissionConfigurationDraggable = (props: EmissionConfigurationDraggableProps) => {

  const [activeDrags, setActiveDrags] = useState(0);
  const [deltaPositionX, setDeltaPositionX] = useState(0);
  const [deltaPositionY, setDeltaPositionY] = useState(0);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    if (!loading) return
    setDeltaPositionX(props.x / scale);
    setDeltaPositionY(props.y / scale);
    if (props.x || props.y) setLoading(false)
  }, [props.y, props.x])

  const handleDrag = (e: any, ui: any) => {
    setDeltaPositionX(deltaPositionX + ui.deltaX);
    setDeltaPositionY(deltaPositionY + ui.deltaY);
  };



  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
    dispatch(coordinatesChange({ x: (deltaPositionX * scale), y: (deltaPositionY * scale) }))

  };

  const scale = 4;

  const dragHandlers = { onStart: onStart, onStop: onStop };

  const imageSize = { width: 319, height: 180 };

  const boxSize = { width: 480 + imageSize.width + 20, height: 270 + imageSize.height + 20 }

  return (
    <div className="box" style={{ height: 'auto', width: '100%', maxWidth: '822px', position: 'relative', overflow: 'auto', padding: '0', marginTop: '15px', }}>

      <div className='navBarSim' style={{ width: 820 }}>
        <div></div>
        <span className='navText'>Desplaza la ilustración para indicar la posición, luego clica en Guardar Posición</span>
        <section className='dots'>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </section>
      </div>
      <div className="box" style={{ height: 'auto', width: '-webkit-fill-available', maxWidth: 'fit-content', position: 'relative', padding: '0', margin: '0px', }}>

        <div className='windowBg' style={{ height: `${boxSize.height}px`, width: `${boxSize.width}px` }}>
          {typeof deltaPositionX == 'number' && typeof deltaPositionY == 'number' ?
            <Draggable position={{ x: (deltaPositionX), y: (deltaPositionY) }} bounds="parent" onDrag={handleDrag} {...dragHandlers}>
              <div className="box" style={{ backgroundImage: `url(${obsImage})`, backgroundPosition: "center", backgroundSize: "contain" }}>
                <h5 className="font-size-16 dragText">x: {(deltaPositionX * scale).toFixed(0)} <br></br>y: {(deltaPositionY * scale).toFixed(0)}</h5>

              </div>
            </Draggable>
            :
            <></>
          }
        </div>
      </div>
    </div>//
  )
}


export default EmissionConfigurationDraggable