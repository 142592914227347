
import React, { useEffect, useState } from "react";
import { Button, CardBody, Col, Input, Modal, ModalFooter, Row, Spinner } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getStreamerProfile, jsonResponseFromAxiosResponse, updateBillingData, } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import { COLORS } from "src/utils/COLORS";
// import { badgeColor } from "../Campaigns/MyCampaignsTable";
import { countries } from "src/common/data/countries";
// import { WidgetStatusCard } from "../Campaigns/WidgetStatusCard";
// import { WidgetButtonCard } from "../Campaigns/WidgetCardButton";
// import Icon from "@ailibs/feather-react-ts/dist/Icon";
// import { DatosFacturacionIcon, SaldoAcumuladoIcon, SaldoDispIcon, TipoEntidadFiscalIcon } from "src/assets/icons/Icons";
// import { useSelector } from "react-redux";
import PdfUpload from "../Inputs/PdfUpload";
import { validate } from "src/slices/Wallet/reducer";
import { useDispatch } from "react-redux";

const vatIdentifiers: any = {
  "company": "CIF/NIF",
  "freelance": "NIF",
  "natural_person": "DNI"
}



const BillingDataModal = (props: any) => {


  const { open } = props
  const dispatch = useDispatch()

  // const [modalEditBillingData] = useState(false);
  const [updating, setUpdating] = useState(false);

  //Streamer Billing data
  const [streamerBillingName, setStreamerBillingName] = useState<string>("");
  const [streamerBillingEmail, setStreamerBillingEmail] = useState<string>("");
  const [streamerBillingCountry, setStreamerBillingCountry] = useState<string>("");
  const [streamerBillingCity, setStreamerBillingCity] = useState<string>("");
  const [streamerBillingState, setStreamerBillingState] = useState<string>("");
  const [streamerBillingAddress, setStreamerBillingAddress] = useState<string>("");
  const [streamerBillingAddressExtra, setStreamerBillingAddressExtra] = useState<string>("");
  const [streamerBillingZipCode, setStreamerBillingZipCode] = useState<string>("");
  const [streamerBillingPhone, setStreamerBillingPhone] = useState<string>("");
  const [streamerBillingVat, setStreamerBillingVat] = useState<string>("");
  const [streamerBillingBankAccount, setStreamerBillingBankAccount] = useState<string>("");
  const [streamerBicCode, setStreamerBicCode] = useState<string>("")
  const [streamerId, setStreamerId] = useState<string>("");

  const [hiddenLabel, setHiddenLabel] = useState(true)

  const [fiscalEntity, setFiscalEntity] = useState("natural_person");

  const handleEditBillingData = () => {
    props.setOpen(true);
  };

  useEffect(() => {
    if (open) return handleEditBillingData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const validateBillingFields = () => {
    const billingData = [
      streamerBillingName,
      streamerBillingEmail,
      streamerBillingCountry,
      streamerBillingCity,
      streamerBillingState,
      streamerBillingAddress,
      streamerBillingZipCode,
      streamerBillingPhone,
      streamerBillingVat,
      streamerBillingBankAccount,
    ];
    return billingData.every(variable => Boolean(variable));
  };

  useEffect(() => {

    const getProfile = localStorage.getItem("profile");
    if (getProfile) {
      const obj = JSON.parse(getProfile);
      const streamer = obj.streamers?.[0];
      const billing = streamer?.invoice;
      setStreamerBillingName(billing?.legal_name);
      setStreamerBillingEmail(billing?.email);
      setStreamerBillingCountry(billing?.country);
      setStreamerBillingCity(billing?.city);
      setStreamerBillingState(billing?.state);
      setStreamerBillingAddress(billing?.address);
      setStreamerBillingAddressExtra(billing?.address_extra);
      setStreamerBillingZipCode(billing?.zip_code);
      setStreamerBillingPhone(billing?.phone);
      setStreamerBillingVat(billing?.vat_identifier);
      setStreamerId(streamer?.id);
      setStreamerBillingBankAccount(streamer?.withdraw_method?.account_number)
      setStreamerBicCode(streamer?.withdraw_method?.bic_code)
      setFiscalEntity(streamer?.invoice?.organization_type)
    }
    setHiddenLabel(validateBillingFields())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const [formData, setFormData] = useState({
    streamer_id: streamerId,
    legal_name: streamerBillingName,
    email: streamerBillingEmail,
    legal_identifier: streamerBillingVat,
    vat_identifier: streamerBillingVat,
    country: streamerBillingCountry,
    city: streamerBillingCity,
    state: streamerBillingState,
    address_extra: streamerBillingAddressExtra,
    address: streamerBillingAddress,
    zip_code: streamerBillingZipCode,
    organization_type: fiscalEntity,
    phone: streamerBillingPhone,
    account_number: streamerBillingBankAccount,
  });

  // useEffect(() => {
  //   if (modalEditBillingData) {
  //     setFormData({
  //       streamer_id: streamerId,
  //       legal_name: streamerBillingName,
  //       email: streamerBillingEmail,
  //       legal_identifier: vatIdentifiers[fiscalEntity],
  //       vat_identifier: streamerBillingVat,
  //       country: streamerBillingCountry,
  //       city: streamerBillingCity,
  //       state: streamerBillingState,
  //       address_extra: streamerBillingAddressExtra,
  //       address: streamerBillingAddress,
  //       zip_code: streamerBillingZipCode,
  //       phone: streamerBillingPhone,
  //       account_number: streamerBillingBankAccount,
  //       organization_type: fiscalEntity

  //     });
  //   }
  // }, [modalEditBillingData]);

  const onChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormData(currentFormData => {
      const nextFormData = {
        ...currentFormData,
        [name]: value,
        streamer_id: streamerId,
      };

      return nextFormData;
    });
    setHiddenLabel(validateBillingFields())
  };

  const saveBillingData = () => {
    setUpdating(true);
    updateBillingData(new ApiCore(localStorage.getItem("token")), {
      streamer_id: streamerId,
      legal_name: streamerBillingName,
      benificiary: streamerBillingName,
      email: streamerBillingEmail,
      legal_identifier: streamerBillingVat,
      vat_identifier: streamerBillingVat,
      country: streamerBillingCountry,
      city: streamerBillingCity,
      state: streamerBillingState,
      address_extra: streamerBillingAddressExtra,
      address: streamerBillingAddress,
      zip_code: streamerBillingZipCode,
      phone: streamerBillingPhone,
      legal_residence_document: "",
      legal_residence_country: streamerBillingCountry,
      account_number: streamerBillingBankAccount,
      branch_address: "",
      branch_name: "",
      bic_code: streamerBicCode,
      organization_type: fiscalEntity
    }).then(
      (data: any) => {
        if (data.message === "ok") {
          dispatch(validate(`${Math.random()}`))
          getStreamerProfile(new ApiCore(localStorage.getItem("token"))).then(
            (data: any) => {
              const profileJson = jsonResponseFromAxiosResponse(data);
              localStorage.setItem(
                "authUser",
                JSON.stringify(profileJson.payload.user)
              );
              localStorage.setItem("profile", JSON.stringify(profileJson.payload));
              setUpdating(false);
              props.setOpen(false);
            }
          );
        } else {
        }
      }
    );
  };

  const modalBillingData = () => {
    return (
      <>
        <Modal
          className="custom"
          isOpen={props.open}
          centered={true}
          toggle={() => {
            if (!updating) props.setOpen(false);
          }}>
          <AvForm
            onValidSubmit={(e: any, v: any) => {
              saveBillingData();
            }}>
            <Input hidden name="streamer_id" value={streamerId} onChange={() => null}></Input>
            <div className="card shadow-none mb-3" style={{ border: "none" }}>
              <div className="card-header d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                      <i className="uil-file-info-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="card-title">Datos de Facturación</h5>{" "}
                </div>
              </div>
              <div className="mt-3 mx-4 ">
                <h6 style={{ color: COLORS.bootstrapRed }} hidden={hiddenLabel}>
                  Para poder retirar fondos debes complementar antes los datos de
                  facturación e información bancaria
                </h6>
              </div>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3 light">
                      <AvField
                        name="legal_name"
                        label="Nombre completo *"
                        type="text"
                        required
                        placeholder="Ingresar nombre completo"
                        errorMessage="Completar información"
                        value={streamerBillingName}
                        onChange={(e: any) => {
                          setStreamerBillingName(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 light">
                      <AvField
                        name="email"
                        label="Mail *"
                        type="text"
                        required
                        placeholder="Ingresar mail"
                        errorMessage="Completar información"
                        value={streamerBillingEmail}
                        onChange={(e: any) => {
                          setStreamerBillingEmail(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <div className="mb-3 light">
                      <AvField
                        type="select"
                        name="select"
                        label="Tipo de entidad fiscal *"
                        required
                        errorMessage="Este campo es obligatorio"
                        value={fiscalEntity?? "" }
                        onChange={(e: any) => {
                          setFiscalEntity(e.target.value);
                        }}>
                        <option value=""></option>
                        <option value="natural_person">Particular</option>
                        <option value="company">Empresa</option>
                        <option value="freelance">Autónomo</option>
                      </AvField>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3 light">
                      <AvField
                        name="vat_identifier"
                        label={vatIdentifiers[fiscalEntity] || "NIF *"}
                        type="text"
                        required
                        placeholder="Ingresar documento"
                        errorMessage="Completar información"
                        value={streamerBillingVat}
                        onChange={(e: any) => {
                          setStreamerBillingVat(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3 light">
                      <AvField
                        name="bank_account"
                        label="IBAN *"
                        type="text"
                        required
                        errorMessage="Completar información"
                        error={true}
                        placeholder="Ingresar código IBAN"
                        value={streamerBillingBankAccount}
                        onChange={(e: any) => {
                          setStreamerBillingBankAccount(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <div className="mb-3 light">
                      <AvField
                        name="streamerBicCode"
                        label="BIC/SWIFT "
                        type="text"
                        placeholder=" "
                        errorMessage="Completar información"
                        value={streamerBicCode}
                        onChange={(e: any) => {
                          
                          setStreamerBicCode(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3 light">
                      {/* <AvField
                                                name="country"
                                                label="País"
                                                type="text"
                                                required
                                                placeholder="Ingresar país"
                                                errorMessage="Completar información"
                                                value={streamerBillingCountry}
                                                onChange={(e: any) => {
                                                    setStreamerBillingCountry(e.target.value);
                                                    onChange(e);
                                                }}
                                            /> */}
                      <AvField
                        name="country"
                        label="País *"
                        type="select"
                        required
                        value={streamerBillingCountry}
                        errorMessage="Completar información"
                        onChange={(e: any) => {
                          setStreamerBillingCountry(e.target.value);
                          onChange(e);
                        }}
                      >
                        <option value="">
                          Ingresar país
                        </option>
                        {countries.map(
                          (
                            country: any,
                            index: number
                          ) => {
                            return (
                              <option
                                key={
                                  country.name +
                                  index
                                }
                                value={
                                  country.name
                                }>
                                {country.name === 'Spain' ? 'España' : country.name}
                              </option>
                            );
                          }
                        )}
                      </AvField>
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3 light">
                      <AvField
                        name="state"
                        label="Estado *"
                        type="text"
                        required
                        placeholder="Ingresar estado"
                        errorMessage="Completar información"
                        value={streamerBillingState}
                        onChange={(e: any) => {
                          setStreamerBillingState(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={4}>
                    <div className="mb-3 light">
                      <AvField
                        name="city"
                        label="Ciudad *"
                        type="text"
                        required
                        placeholder="Ingresar ciudad"
                        errorMessage="Completar información"
                        value={streamerBillingCity}
                        onChange={(e: any) => {
                          setStreamerBillingCity(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3 light">
                      <AvField
                        name="address"
                        label="Dirección *"
                        type="text"
                        required
                        placeholder="Ingresar dirección"
                        errorMessage="Completar información"
                        value={streamerBillingAddress}
                        onChange={(e: any) => {
                          setStreamerBillingAddress(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3 light">
                      <AvField
                        name="address_extra"
                        label="Información adicional"
                        type="text"
                        placeholder="Ingresar información adicional"
                        value={streamerBillingAddressExtra}
                        onChange={(e: any) => {
                          setStreamerBillingAddressExtra(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <div className="mb-3 light">
                      <AvField
                        name="zip_code"
                        label="Código postal *"
                        type="text"
                        required
                        placeholder="Ingresar código postal"
                        errorMessage="Completar información"
                        value={streamerBillingZipCode}
                        onChange={(e: any) => {
                          setStreamerBillingZipCode(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3 light">
                      <AvField
                        name="phone"
                        label="Teléfono *"
                        type="text"
                        required
                        placeholder="Ingresar teléfono"
                        errorMessage="Completar información"
                        value={streamerBillingPhone}
                        onChange={(e: any) => {
                          setStreamerBillingPhone(e.target.value);
                          onChange(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                {streamerBillingCountry !== "Spain" ? (
                  <Row>
                    <PdfUpload />
                  </Row>
                ) : <></>}

              </CardBody>
            </div>
            <ModalFooter>
              <Button color="primary" disabled={updating}>
                {updating ? <Spinner size={"sm"} animation="border" /> : "Guardar"}
              </Button>
              <Button
                color="secondary"
                disabled={updating}
                onClick={() => props.setOpen(false)}
                style={{ float: "right" }}>
                Cerrar
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </>
    );
  };





  return (
    <React.Fragment>
      {modalBillingData()}
    </React.Fragment>
  );
};

export default BillingDataModal;