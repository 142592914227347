import { createSlice } from "@reduxjs/toolkit";
import { getAllCampaigns, getAvailableCampaigns } from "./thunk";

interface CampaignStateType {
    tableFilterMode: any;
    statusUpdated: any;
    availableCampaigns: any;
    allCampaigns: any;
    subscriptions: any;
    auxAllCampaigns: any;
    // users: Array<any>,
    // userProfile: object,
    // error?: string | object | null | undefined | unknown,
    // user?: any,
    // history?: any
}
export const initialState: CampaignStateType = {
    tableFilterMode: "Mis campañas",
    statusUpdated: "",
    availableCampaigns: false,
    allCampaigns: false,
    subscriptions: false,
    auxAllCampaigns: false,
};

const campaignSlice = createSlice({
    name: "campaign",
    initialState,
    reducers: {
        campaignTableFilterMode(state, action) {
            state.tableFilterMode = action.payload;
        },
        updateStatus(state, action) {
            state.statusUpdated = action.payload;
        },
        updateAvailableCampaigns(state, action) {
            state.availableCampaigns = action.payload;
        },
        handleSearch(state, action) {
            if (Array.isArray(state.auxAllCampaigns)) {
                state.allCampaigns = state.auxAllCampaigns.filter((el: any) => {
                    return el.name.toLowerCase().includes(action.payload.toLowerCase());
                });
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(getAvailableCampaigns.fulfilled, (state: any, action: any) => {
            state.availableCampaigns = action.payload.availableCampaigns.filter(
                (el: any) => el.campaign_id !== "0dba1bcf-0b24-4e66-a269-531725651188" && (el?.campaign_situation !== "finished")
            );
            state.subscriptions = action.payload.FilteredCampaigns;
        });
        builder.addCase(getAllCampaigns.fulfilled, (state: any, action: any) => {
            state.allCampaigns = action.payload;
            state.auxAllCampaigns = action.payload;
        });
    },
});

export const { updateStatus, campaignTableFilterMode, updateAvailableCampaigns, handleSearch } =
    campaignSlice.actions;

export default campaignSlice.reducer;
