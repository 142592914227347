import React from "react";
import { Button, Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import { COLORS } from "src/utils/COLORS";
import placeholderAvatar from "src/assets/images/stockImages/twitchAvatar.png";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface Props {
    user: string;
    username: string;
    avatar?: string;
    streamURL: string;
}

export const statusData = (userStatus: "ONLINE" | "OFFLINE" | "DISABLED" | "UNKNOWN") => {
    switch (userStatus) {
        case "ONLINE":
            return {
                color: COLORS.bootstrapGreen,
                message: "Listo para emitir",
            };
        case "OFFLINE":
            return {
                color: COLORS.bootstrapRed,
                message: "No conectado",
            };
        case "DISABLED":
            return {
                color: COLORS.bootstrapGreen,
                message: "Listo para emitir",
            };
        default:
            return {
                color: COLORS.bootstrapGrey,
                message: "Listo para emitir",
            };
    }
};

const UserCard = (props: Props) => {

    const { status } = useSelector((state: any) => ({
        status: state.profile.status
    }));
    const statusDataInfo = statusData(status.status === "not connected" ? "OFFLINE" : (status.status === "connected" ? "ONLINE" : "UNKNOWN"));
    return (
        <div className="d-flex h-100 w-90 mb-3">
            <Card
                className="d-flex w-100 h-100 align-items-stretch"
                style={{
                    flex: 1,
                    height: "100%",
                    border: `1px solid ${statusDataInfo.color}`,
                    boxShadow: `0px 0px 7px 5px ${statusDataInfo.color}`,
                }}>
                <CardBody className="d-flex h-100 justify-content-between flex-column align-items-stretch">
                    <div className="d-flex flex-md-row justify-content-between h-100 gap-4"
                        style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexDirection: "row"
                        }}
                    >
                        <img
                            style={{
                                width: "min(max(6rem, calc(2.125rem + ((1vw - 9px) * 9.8039))), 150px)",
                                height: "min(max(6rem, calc(2.125rem + ((1vw - 9px) * 9.8039))), 150px)",
                                backgroundColor: "#fff",
                                borderRadius: "100%",
                                // border: "1px solid rgba(156,59,199,0.82)",
                                boxShadow: "0px 0px 2px 1px #5964E0",
                                aspectRatio: "1",
                                objectFit: "cover"
                            }}
                            src={props.avatar || placeholderAvatar}
                            alt="userAvatar"
                        />
                        <div className="d-flex w-100 h-100 flex-column justify-content-between align-items-end">
                            <div className="d-flex flex-column align-items-end">
                                <CardTitle
                                    className="mb-1 "
                                    tag="h2"
                                    style={{
                                        textAlign: "end",
                                        fontSize:
                                            "min(max(16px, calc(1.5625rem + ((1vw - 9px) * 1.4706))), 35px)",
                                        textTransform: "capitalize"
                                    }}>
                                    {props.user}
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-1 "
                                    tag="h5"
                                    style={{
                                        fontSize:
                                            "min(max(12px, calc(1.4rem + ((1vw - 9px) * 1.4706))), 20px)",
                                    }}>
                                    {props.username}
                                </CardSubtitle>

                                <div
                                    className="d-flex align-items-center h5"
                                    style={{ fontSize: "1rem", marginTop: "1rem" }}>
                                    <div
                                        style={{
                                            display: "inline-block",
                                            width: "20px",
                                            height: "20px",
                                            borderRadius: "100%",
                                            marginRight: "5px",
                                            backgroundColor: `${statusDataInfo.color}`,
                                        }}></div>
                                    {statusDataInfo.message}
                                </div>
                            </div>

                            {status.status === "connected" ?
                                <CardText
                                    className="d-flex mb-3"
                                    style={{
                                        textAlign: "end",
                                        fontSize: "1.2rem",
                                        fontWeight: 500,
                                        color: "#fff",
                                    }}>
                                    <span style={{ marginLeft: "3px" }}>
                                        {status.viewers} Personas viendote{" "}
                                    </span>
                                </CardText>
                                : <></>
                            }
                        </div>
                    </div>
                    <div className="d-flex flex-column mt-2  align-items-stretch">
                        <CopyToClipboard
                            text={props.streamURL}
                        >
                            <Button
                                style={{
                                    borderRadius: "6px ",
                                    fontWeight: 500,
                                    fontSize: "1.1rem",
                                    backgroundColor: `${statusDataInfo.color}`,
                                    marginLeft: "auto",
                                    maxWidth: "15rem",
                                    padding: "0.2rem 2rem"
                                }}>

                                {"URL emisión"}
                            </Button>
                        </CopyToClipboard>
                    </div>
                </CardBody>
            </Card>
        </div >
    );
};


export default UserCard;
