import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import 'src/assets/scss/custom/components/_chatLinks.scss'
import { ApiCore } from 'src/helpers/api_helper'
import { putStreamerProfile } from 'src/helpers/backend_helper'




const AllowLinksChat = () => {
  const [checked, setChecked] = useState(1)
  const profile = localStorage.getItem("profile") ?? "";


  const bodySend = (value: any) => {
    const profile = localStorage.getItem("profile")
    if (profile) {
      const obj = JSON.parse(profile);
      const streamer = obj.streamers?.[0];
      const data = {
        "streamer_id": streamer.id,
        "birthdate": streamer.birthdate,
        "gender": streamer.gender,
        "nationality": streamer.nationality,
        "language_iso": streamer.language_iso,
        "currency_iso": streamer.currency_iso,
        "notes": streamer.notes,
        "channel_topic": streamer.channel_topic,
        "accept_subscriptions_automatically": streamer?.accept_subscriptions_automatically,
        "allows_second_chat_link": value
      }
      putStreamerProfile(new ApiCore(localStorage.getItem("token")), data).then(res => {
        setChecked(value)
      }).catch(err => {
        console.log(err)
      })
      return data
    }
  }

  const handleChange = () => {
    checked ? bodySend(0) : bodySend(1)
  }
  useEffect(() => {
    if (profile) {
      const obj = JSON.parse(profile);
      const streamer = obj.streamers?.[0];
      setChecked(streamer.allows_second_chat_link)
    }
  }, [profile]);

  return (
    <div style={{ display: 'flex', flexDirection: "column", gridColumn: "1/-1" }}>
      <h5 className="font-size-16">Permitir enlaces adicionales en el chat</h5>
      <Card style={{ width: "fit-content" }}>
        <CardBody style={{ padding: 5 }} className=' light-1 p-2'>
          <div className='d-flex gap-2 align-items-center'>
            <span>Permitir mostrar un enlace de chat adicional para aumentar el CTR de la campaña</span>
            <label className="switchChatLink">
              <input type="checkbox" checked={Boolean(checked)} onChange={handleChange} />
              <span className="sliderChatLink roundChatLink"></span>
            </label>
          </div>
        </CardBody>
      </Card >
    </div>
  )
}

export default AllowLinksChat