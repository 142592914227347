import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const TermsAndConditions = (props: any) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  }

  return <Modal isOpen={props.open} toggle={props.toggleModal} className='custom'>
    <ModalHeader toggle={props.toggleModal}>TÉRMINOS Y CONDICIONES DE CONTRATACIÓN CON “014 LIVE!”</ModalHeader>
    <ModalBody>
      <b>1. Objeto. Definiciones.</b>
      <p>1.1. Los presentes términos y condiciones de contratación (en adelante, “Condiciones” o “Términos y Condiciones”) ha sido redactadas por la mercantil española 014 ON MEDIA, S.L., con domicilio social sito en Calle Alcalá 106 Planta 1ª de Madrid (C.P. 28009 – Madrid, España) con CIF B-42746198, en vigor; y que figura debidamente constituida e inscrita en el Registro Mercantil de Madrid al Tomo 41.337, Folio 180, Hoja M-732697, inscripción 1ª (en adelante, “014 ON”) con el fin de establecer la relación contractual que mantendrá existente con los Streamers que se hallen suscritos a su Plataforma Web denominada 014 LIVE!</p>
      <p>1.2. Definiciones:</p>
      <ul>
        <li>
          <p>
            (I) “Plataforma Web 014 LIVE!”: página web oficial o plataforma web, titularidad de 014 ON MEDIA, S.L., a través de la cual los Streamers podrán registrarse mediante la apertura de una cuenta individual y singularizada, a través de la cual se les facilitará la obtención de un servicio de difusión publicitario a favor de anunciantes o agencias de medios proporcionadas por 014 LIVE! (en adelante, referidos en conjunto como los “Anunciantes”), así como el otorgamiento de una licencia de uso de software por parte de 014 LIVE! en favor del Streamer.
          </p>
        </li>
        <li>
          <p>
            (II) “Streamer(s)”: se referirá́ a aquellas personas, físicas o jurídicas, que se hayan dado de alta en la Plataforma Web de 014 LIVE! y que cuenten con un canal abierto en alguna/s de la/s plataforma/s de emisión de videos en directo o de intercambio de videos, existentes en la actualidad (Twitch, YouTube, etc.), o bien, que puedan constituirse en el futuro. En ningún caso, las referencias a “Streamer” realizadas en las presentes Condiciones se entenderá́ que se realizan respecto a titulares de canales de ninguna plataforma en concreto.
          </p>
        </li>
        <li>
          <p>
            (III) “Servicio publicitario”: se referirá al servicio de difusión publicitaria por parte del Streamer a favor de anunciantes o agencias de medios proporcionadas por 014 LIVE!, así́ como el otorgamiento de una licencia de uso de software por parte de 014 LIVE! en favor del Streamer.
          </p>
        </li>
        <li>
          <p>
            (IV) “Anunciante(s)”: anunciantes o agencias de medios proporcionadas por 014 LIVE! y cuyos anuncios se incluirán durante el desarrollo y/o ejecución de la actividad del Streamer en la correspondiente plataforma.
          </p>
        </li>
        <li>
          <p>
            (V) “Contrato de difusión publicitaria”: según queda definido en el artículo 17 de la Ley 34/1988, de 11 de noviembre, General de Publicidad como aquel por el que, a cambio de una contraprestación fijada en tarifas preestablecidas, un medio se obliga en favor de un anunciante o agencia a permitir la utilización publicitaria de unidades de espacio o de tiempo disponibles y a desarrollar la actividad técnica necesaria para lograr el resultado publicitario. A estos efectos, el Streamer (por medio de su canal) será́ el medio y 014 LIVE! la agencia comercializadora a terceros (los Anunciantes) de las unidades de espacio o tiempo, pudiendo o no comercializarlas, y retribuyendo al Streamer las cantidades siguiendo las tarifas fijadas en la Condición 4.1 únicamente cuando se logre la retransmisión, es decir, cuando el Streamer muestre en su canal una pieza publicitaria proporcionada por 014 LIVE!.
          </p>
        </li>
      </ul>
      <p>1.3. Las presentes Condiciones se aplicarán estrictamente a la relación entre el Streamer y 014 LIVE!, teniendo por objeto:</p>
      <ul>
        <li>
          <p>
            (I) Regular la prestación de un servicio de difusión publicitaria por parte del Streamer a favor de anunciantes o agencias de medios proporcionadas por 014 LIVE! (en adelante, referidos en conjunto como los “Anunciantes”);
          </p>
        </li>
        <li>
          <p>

            (II) El otorgamiento de una licencia de uso de software por parte de 014 LIVE! en favor del Streamer. El intercambio de servicios que se realice entre el Streamer y 014 LIVE! como consecuencia del registro del Streamer en la Plataforma Web de 014 LIVE! se realizará exclusivamente por medios electrónicos y estrictamente en base a estas Condiciones.
          </p>
        </li>
      </ul>
      <p>1.4. Las presentes Condiciones se encuentran, a disposición del Streamer, de manera permanente en su apartado de Streamer del Sitio Web <a href="https://www.014live.com">https://www.014live.com</a>  de 014 LIVE!, en concreto en el siguiente enlace:  ********</p>
      <b>2. Operativa básica de la Plataforma Web “014 LIVE!”. Derechos de propiedad intelectual. Licencia de uso de software. </b>
      <p>2.1.	014 LIVE! proporcionará piezas publicitarias a los Streamers elaboradas por los Anunciantes con el fin de que aquellos las emitan en sus canales, lo que se llevará a cabo en la forma indicada en las presentes Condiciones. La propiedad intelectual de las piezas publicitarias pertenecerá, en todo caso, a los Anunciantes.</p>
      <p>2.2.	Con la aceptación de las presentes Condiciones, el Streamer no cede, concede ni otorga licencia o sublicencia sobre ningún derecho derivado del contenido que genere o sea de su propiedad, haya generado o pueda generar por medio de vídeos en directo u otros formatos en los que emita piezas publicitarias proporcionadas por 014 LIVE!, de modo que conserva todos los derechos de propiedad intelectual sobre el mismo. Con la aceptación de las presentes Condiciones, el Streamer no concede a 014 LIVE! ningún tipo de relación en exclusiva, siendo plenamente libre para contratar con terceros, anunciantes, marcas, plataformas, etc, la emisión de publicidad por medio de su canal.</p>
      <p>2.3.	La licencia de uso de software que 014 LIVE! otorga al Streamer tiene las siguientes características: </p>
      <ul>
        <li>
          <p>

            {"A) tiene carácter gratuito."}
          </p>
        </li>
        <li>
          <p>

            {"B) El Streamer no podrá copiar dicho software, ni tampoco realizar ningún tipo de ingeniería inversa a fin de descifrar el código fuente. "}
          </p>
        </li>
        <li><p>

          {"C) Imposibilidad de que el Streamer otorgue otras licencias y/o sublicencias a favor de terceros. "}
        </p>
        </li>
        <li>
          <p>

            {"D) el software licenciado está íntegramente desarrollado por 014 LIVE!, perteneciendo a éste la propiedad intelectual del mismo. "}
          </p>
        </li>
      </ul>
      <p>2.4.	Asimismo, 014 LIVE! podrá actualizar el software, en cualquier momento de la relación contractual y sin necesidad de preaviso a los Streamers y/o de recabar ningún consentimiento del Streamer, modificando funcionalidades de manera parcial o total. </p>
      <b>3. Requisitos para actuar en calidad de Streamer de 014 LIVE!.</b>
      <p>3.1.	Los Streamers de 014 LIVE! deberán cumplir preceptivamente los siguientes requisitos, a saber (todos ellos de carácter acumulativo y simultáneo):</p>
      <ul>
        <li>
          <p>
            {"A) Tener la edad legal igual o mayor a 18 años."}
          </p>
        </li>
        <li>
          <p>
            {"B) No tener suscrito ningún acuerdo o contrato de exclusividad para mostrar y/o vender anuncios con ningún tercero."}
          </p>
        </li>
        <li>
          <p>
            {"C) Cumplir estrictamente con la legislación en materia fiscal y de Seguridad Social vigente en cada momento."}
          </p>
        </li>
      </ul>
      <p>3.2.	Mediante la aceptación expresa y voluntaria de las presentes Condiciones el Streamer declara, de manera formal: </p>
      <p>
        3.2.1. 		Que cumple con el requisito mínimo de la edad legal igual o superior a 18 años. En el caso de falseamiento, error (voluntario o involuntario), irregularidad en la información o situación análoga emitida por parte del Streamer a 014 LIVE!, esta última no se hará responsable de los eventuales perjuicios dimanantes de dicha situación o de la declaración que se pudieran derivar. 014 LIVE! siempre podrá solicitar, tanto como requisito previo para el registro en su plataforma web como en cualquier momento de la relación contractual, que el Streamer le exhiba, o bien, que le proporcione copia de cuantos documentos originales se estimen oportunos a fin de acreditar el cumplimiento de este requisito.
      </p>
      <p>3.2.2.		Que cumple con la legislación en materia fiscal y de Seguridad Social. 014 LIVE! no se hará responsable de los perjuicios que de la falsedad, error (voluntario o involuntario), o irregularidad en la información o que de esta declaración se pudieran derivar. 014 LIVE! siempre podrá solicitar, tanto como requisito previo para el registro en su plataforma web como en cualquier momento de la relación contractual, que el Streamer le exhiba, o bien, que le proporcione copia de cuantos documentos originales se estimen oportunos a fin de acreditar el cumplimiento de este requisito. </p>
      <p>
        3.2.3.		Que no existe ningún tipo de relación laboral entre él y 014 LIVE!, siendo cada uno de ellos responsable de sus obligaciones tributarias y/o para con la Seguridad Social, así como todas las obligaciones de carácter administrativo y mercantil que imponga pueda imponer la legislación vigente en cada momento. Asimismo, el Streamer será el único responsable del contenido que publique por medio de su canal, sobre el cual tendrá pleno control editorial y de organización sus recursos propios y/o ajenos, así como la propiedad exclusiva del contenido por él creado.
      </p>
      <p>
        3.2.4.		Que no mantiene relación jurídica de ningún tipo por la cual quede sometido a una exclusiva para mostrar, vender o difundir anuncios en favor de ningún tercero, asimismo, declara que si, una vez registrado en 014 LIVE!, tiene constancia de que va celebrar algún tipo de acuerdo/contrato con terceros que impida el efectivo cumplimiento de estas Condiciones, lo comunicará a 014 LIVE!.
        En este último supuesto, 014 LIVE! tendrá todo el derecho de cancelación de la suscripción del Streamer, de manera automática y sin necesidad de previo aviso. 014 LIVE! no se hará responsable de los perjuicios que de la falsedad de esta declaración se pudieran derivar, ni de los de la falta de comunicación por parte del Streamer.
      </p>
      <b>4. Duración.</b>
      <p>4.1.	Mientras se mantenga vigente por parte del Streamer la suscripción en la Plataforma Web de 014 LIVE! – esto es, mediante la vigencia de la cuenta en estado de “abierta” – la duración de la relación contractual con el Streamer será de tipo indefinido. </p>
      <p>4.2	Derecho de cancelación/resolución de la cuenta: el Streamer, en cualquier momento de la relación contractual, una vez aceptados estos Términos y Condiciones legales, estará debidamente habilitado para poder cancelar su suscripción por medio del correspondiente apartado/sección habilitada en el Sitio Web o plataforma web de 014 LIVE!. La cancelación emitida por el Streamer tendrá efectos desde el mismo momento en que se emita por parte de este último. Cuando este cancele su suscripción en 014 LIVE!, cualquier eventual cantidad que conste como pendiente de abonar en favor del Streamer se procederá conforme a la Condición 7.2, siempre que éstas superen el importe mínimo de VEINTE EUROS (20,00.-€).</p>
      <b>5. Cesión.</b>
      <p>El Streamer no podrá ceder su posición jurídica respecto a 014 LIVE! a ningún tercero. 014 LIVE! no podrá ceder su posición jurídica respecto a 014 LIVE! a ningún tercero; en este sentido y respecto a 014 LIVE!, no tendrán la consideración de terceros las sociedades pertenecientes, participadas y/o controladas completa o parcialmente (cualquiera que sea su participación) por 014 ON MEDIA, S.L. </p>
      <b>6. Anunciantes y piezas publicitarias.</b>
      <p>6.1.	014 LIVE! proporcionará al Streamer distintas piezas publicitarias de aquellos Anunciantes que trabajen con 014 LIVE!. El Streamer estará habilitado para poder emitir dichas piezas publicitarias a través de su canal. </p>
      <div>
        <p>
          6.2.	El Streamer podrá seleccionar, de entre la lista que 014 LIVE! ponga a su disposición en la Plataforma Web, las piezas publicitarias que desea emitir en su canal. Para este proceso selectivo, el Streamer tendrá a su disposición dos modos de gestionar las piezas publicitarias que 014 LIVE! pone a su disposición:{" a) “Modo Automático” y/o b) “Modo Manual”,"} a saber:
        </p>
        <ul>
          <li>
            <p>
              {"a) Modo Automático: todas las piezas publicitarias que se encuentren a disposición el Streamer se mostrarán en su canal, a excepción de las piezas publicitarias +18, las cuales siempre tendrán que ser expresamente seleccionadas por el Streamer. "}
            </p>
          </li>
          <li>
            <p>
              {"b) Modo Manual: el Streamer podrá elegir qué concretas piezas publicitarias, entre las que hayan sido puestas a su disposición por parte de 014 LIVE!, desea el Streamer mostrar en su canal y cuales no; todas las piezas publicitarias que 014 LIVE! ponga a disposición del Streamer figuraran en el estado de “pendiente”, siendo el Streamer quien seleccione cuál (o cuáles) de estas piezas se mostrarán en su canal."}
            </p>
          </li>
        </ul>
      </div>
      <p>6.3.	El software licenciado por 014 LIVE! determina, de manera automatizada, si finalmente se muestra, o no, cada pieza publicitaria en cierto momento en el canal de Streamer. La elección por parte del Streamer de una pieza publicitaria, con independencia del modo manual o automático, no generará, por si solo y de forma automática, que se vaya a mostrar en el canal del Streamer. </p>
      <p>6.4.	En caso de que 014 LIVE! proporcione al Streamer piezas publicitarias con contenido para mayores de edad (“piezas publicitarias +18”), contenido destinado a adultos o de naturaleza similar, lo indicará claramente en su título. Comoquiera que el único que puede conocer el público objetivo de su canal es el Stremer, cuando un Streamer tenga cualquier indicio de que en su canal hay público menor de edad deberá mostrar piezas publicitarias +18, siendo únicamente responsable el Streamer de la emisión.<b>014 LIVE! no se hace responsable (y el Streamer así lo consiente expresamente mediante la aceptación de las presente Condiciones) de ningún perjuicio que pueda causar la emisión por parte de un Streamer en su canal de una pieza publicitaria +18, respecto a 014 LIVE!, al propio Streamer, el público del canal, el propio canal o la plataforma donde este se encuentre alojado.</b></p>
      <b>7. Tarifas en favor del Streamer en concepto de servicios. Facturacion y sistema de pago. Incumplimientos contractuales y sistema de subsanación. </b>
      <p>7.1. Sistema de Tarifas.</p>
      <p>El Streamer exclusivamente tendrá derecho a la percepción de las correspondientes tarifas prestablecidas en la Condición 4.1 presente si ha aceptado, de manera expresa y fehaciente, los términos y condiciones de 014 LIVE! para que éste le genere una factura válida por el importe total que desea retirar.</p>
      <p>Aceptados los anteriores términos y condiciones, si 014 LIVE! ha podido comercializar las unidades de espacio y tiempo que son objeto de la prestación del servicio de difusión publicitaria, introduciendo el Streamer en su canal alguna/s de la/s piezas publicitarias facilitadas por 014 LIVE!, la tarifa preestablecida a la que el Streamer tendrá derecho se corresponderá al siguiente esquema: </p>
      <p>El Streamer seleccionará al patrocinador/anunciante con el que quiera colaborar y se adherirá a cada concreta campaña del patrocinador/anunciante. El contenido de los patrocinadores se reproducirá en los directos que realice el Streamer, según las condiciones técnicas determinadas en las Condiciones 8 y 9 siguientes. </p>
      <p>El sistema de tarificación empleado en los streams/directos se realizará según el siguiente esquema:  </p>
      <ul>
        <li>
          <p>
            {'a) La práctica totalidad de las campañas tienen una tarifa determinada por cada 1.000 visualizaciones del anuncio llamada CPV o CPM, de manera que el Streamer será retribuido en función del número de visualizaciones que haya generado en sus streams. Las tarifas de cada concreta campaña (CPM o CPV) podrán variar de acuerdo con los acuerdos alcanzados con cada uno de los patrocinadores/anunciantes.'}
          </p>
        </li>
        <li>
          <p>
            {'b) En ocasiones excepcionales, para algunas campañas en concreto y previo aviso por parte de 014 LIVE!, la retribución actuará en función del número de clics que los espectadores hayan efectuado en cada directo y que se hayan generado en el link del chat, que en ese caso se denominará CPC.'}
          </p>
        </li>
      </ul>
      <b>Cuestiones que deberán cumplir los Streamers</b>
      <ul>
        <li>
          <p>
            {'    a) No se establecerá, en cualquiera de ambas modalidades retributivas, un mínimo de visualizaciones o clicks para obtener un derecho de retribución económica mínimo. '}
          </p>
        </li>
        <li>
          <p>
            {'b) Se aplicará a todas las campañas el criterio denominado “Complete Views”, es decir, que se tendrán en cuenta las visualizaciones obtenidas siempre que aquellos espectadores estén conectados durante el cien por cien (100%) del tiempo de emisión completo del anuncio. '}
          </p>
        </li>
        <li>
          <p>
            {'c) La capa correspondiente a 014 LIVE! deberá proyectarse encima de todas las demás eventuales capas en el software de streaming para que no queden cubiertas o que no sean visibles o se dificulte su visualización y/o lectura. Solo se retribuirá cuando sean perfectamente visibles.'}
          </p>
        </li>
        <li>
          <p>
            {'d) Si el anuncio no se visualiza correctamente en una concreta emisión o directo, el número de espectadores de esa emisión no se computarán en el derecho de retribución que dimane de esta emisión. '}
          </p>
        </li>
      </ul>
      <b>Condiciones de cálculo de las visualizaciones de los patrocinios</b>
      <p>El total de las visualizaciones de la emisión de un anuncio de un patrocinio son iguales al número de espectadores que estén observando el stream/directo en el momento en que se muestra la creatividad de un patrocinio. Cada espectador que esté viendo el stream computará como una visualización.
        Al Streamer se le asignará la misma cantidad de visualizaciones que la cantidad de espectadores que están viendo el directo y que hayan visto el 100% del tiempo de emisión del anuncio. </p>
      <b>Derecho del Streamer a la retirada de fondos</b>
      <p>El Streamer únicamente tendrá derecho a retirar los fondos generados por la efectiva emisión de cada pieza publicitaria proporcionada por 014 LIVE! si cumple los siguientes requisitos:</p>
      <ul>
        <li>
          <p>
            Haber hecho la solicitud de retirada de fondos.
          </p>
        </li>
        <li>
          <p>
            Tener un saldo disponible para la retirada en su apartado de Streamer de la Plataforma Web de 014 LIVE!, de al menos, VEINTE EUROS (20,00.-€).
          </p>
        </li>
      </ul>
      <p>7.2. Facturación y sistema de pago.</p>
      <p>El Streamer, mientras se mantenga como usuario dado de alta en la Plataforma Web de 014 LIVE!, tendrá acceso permanente a la misma y, en la misma, dispondrá de un apartado específico, diferenciado y perceptible de manera sencilla, que muestra el importe total que lleva acumulado, calculado de conformidad con a la Condición 4.1 anterior (sistema de tarifas prestablecidas), teniendo la opción de retirar este importe, siempre que cumpla con los requisitos del punto anterior (importe mínimo de Veinte euros). </p>
      <p>Mediante la aceptación expresa de las presentes Condiciones, el Streamer autoriza expresamente a que 014 LIVE! pueda emitir auto facturas por los importes resultado de aplicación de lo estipulado en la Condición 4.1 cada vez que el Streamer formule una petición de retirada de los importes acumulados, y ello con aplicación de los impuestos, tributos y demás condicionantes fiscales que correspondan según la legislación española vigente en cada momento. </p>
      <p>El procedimiento de emisión y tramitación del sistema de auto facturas, consistirá en el envío de estas autofacturas a la concreta dirección de correo electrónico que haya declarado formalmente por parte del Streamer. En su defecto, se entenderá con la dirección de correo electrónico que se haya registrado en la Plataforma Web de 014 LIVE!. </p>
      <p>El Streamer estará conforme con el importe de la autofactura emitida por 014 LIVE! desde el momento en el que se haya formulado dicha petición de retirada del importe acumulado hasta la fecha a través de la Plataforma Web de 014 LIVE!. Se abonará el importe de la autofactura en la cuenta facilitada al respecto por el Streamer dentro de los siguientes 10 días laborales desde la fecha de la petición formulada por el Streamer.</p>
      <p>014 LIVE! podrá solicitar al Streamer, cuando así lo estime oportuno, los correspondientes certificados de estar al corriente de todas las obligaciones tributarias y de la seguridad social como requisito para efectuar el pago correspondiente. En caso de que el Streamer no se encuentre al corriente de pago o no facilitare dichos certificados a 014 LIVE! en el plazo concedido por este al efecto, 014 LIVE! podrá denegar el pago de la autofactura y dar de baja de la plataforma al Streamer.</p>
      <p>014 LIVE! se reserva el derecho, en cualquier momento, a cancelar la cuenta del Streamer y, por tanto, resolver su relación con este, en caso de que exista incumplimiento de alguna/s de las presentes Condiciones, así como por cualquier otra razón que considere contraria a sus intereses comerciales, abonando las cantidades adeudadas hasta ese momento siempre y cuando se hayan generado de una manera lícita y acorde a estas Condiciones.</p>
      <p>7.3. Incumplimiento de la obligación de pago por parte de 014 LIVE!.</p>
      <p>En caso de que 014 LIVE! incumpla su obligación de pago conforme a lo estipulado en las anteriores condiciones, el Streamer deberá formalizar una previa petición de subsanación del incumplimiento de pago, dirigiéndose a la dirección de atención de 014 LIVE! siguiente streamers@014live.com  e instando la subsanación del defecto, irregularidad en el pago (total o parcial) en el plazo de 5 días hábiles. En el supuesto de que 014 LIVE! incumpliera con la subsanación del anterior defecto o incumplimiento, el Streamer tendrá derecho a cancelar su suscripción en la Plataforma Web de 014 LIVE! y reclamarle los importes que se le deban, siempre y cuando estos figuren en su apartado de Streamer en la Plataforma Web de 014 LIVE! y se hayan generado por el Streamer en cumplimiento de las presentes Condiciones Generales, y que se hayan generado de manera lícita y legal, acorde con la finalidad de la relación contractual. </p>
      <b>8. Características técnicas de la pieza publicitaria proporcionada por 014 LIVE!.</b>
      <p>
        Las piezas publicitarias que 014 LIVE! proporcione a los Streamers contarán con los siguientes formatos:
        • Vídeo de un máximo de treinta (30) segundos de duración con relación de aspecto 16:9 o 4:3, en ocasiones, con una imagen en el lateral acompañando el vídeo.
        • Animación ad hoc. Puede ser cualquier tipo de animación fuera de formato anterior, de la cual se informará al Streamer con antelación suficiente a que este la emita en su canal.
        • Imagen estática
      </p>
      <b>9. Ejecución técnica.</b>
      <p>9.1.	Para que el Streamer pueda emitir una pieza publicitaria proporcionada por 014 LIVE!, este, por medio del software licenciado, deberá tener acceso al Open Broadcaster Software (OBS), o cualquier otro software de características similares, del Streamer y al chat del canal del Streamer. Con la aceptación expresa de estas condiciones, el Streamer declara conocer este extremo y lo acepta y consiente expresamente. </p>
      <p>9.2.	El Streamer será el único responsable de asegurarse de que la pieza publicitaria proporcionada por 014 LIVE! se visualiza correctamente, realizando las siguientes acciones:</p>
      <ul>
        <li>
          <p>
            Colocar el overlay (capa de proyección de elementos que ofrecen herramientas de Streaming como el software OBS) ocupando la totalidad de la pantalla, por defecto con dimensiones 1920×1080, sin redimensionar la capa, a no ser que la finalidad sea ajustarlo a un tamaño de pantalla diferente.
          </p>
        </li>
        <li>
          <p>
            Colocar el overlay en la capa superior del OBS, o software similar utilizado por el Streamer, para asegurar que siempre sea visible por encima del resto.
          </p>
        </li>
        <li>
          <p>
            Realizar las pruebas que sean técnicamente pertinentes para asegurar su buen funcionamiento.
          </p>
        </li>
        <li>
          <p>
            Realizar el testeo de validación de tamaño. En el caso de que la forma en la que se muestra la pieza publicitaria en el canal de Streamer no sea validada por el software de 014 LIVE!, bien porque el tamaño no es el adecuado o bien porque algún elemento interrumpe su visualización, la emisión de dicha pieza publicitaria no se contabilizará para la aplicación de la tarifa de la Cláusula 4.1.
          </p>
        </li>
      </ul>
      <p>
        9.3.	El Streamer exonera a 014 LIVE! de cualquier tipo de responsabilidad por el mal uso de su canal, o por incumplimiento por su parte de las condiciones generales o cualesquiera otras normas o documentos que rijan la relación entre el Streamer y la plataforma en la cual es Streamer tiene alojado su canal (por medio del cual comparte vídeos) o los terceros que puedan tener relación tanco con el Streamer como con la plataforma donde se aloje el canal de Streamer.
      </p>
      <b>10. Atención al Streamer y Reclamaciones.</b>
      <p>10.1. Atención al Streamer.
        El Streamer cuenta con un servicio de atención e información a través de la Web, en el domicilio social de 014 LIVE! indicado en las presentes Condiciones y a través de correo electrónico en la dirección streamers@014live.com  </p>
      <p>10.2. Reclamaciones.
        Si el Streamer quiere presentar una reclamación deberá hacerlo en el plazo máximo de un (1) mes desde que conozca el hecho que la motiva, por escrito al domicilio social de 014 LIVE! o por correo electrónico a streamers@014live.com o a través de cualquier otro medio que 014 LIVE! ponga a su disposición.
        Recibida la reclamación, 014 LIVE! facilitará al Streamer el número de referencia o de tramitación de la misma. Presentada la reclamación, si el Streamer no recibe respuesta satisfactoria en el plazo de un (1) mes, se entenderá su reclamación denegada, quedando a salvo las vías previstas en la Condición 15ª.
      </p>
      <b>11.	Notificaciones.</b>
      <p>
        A efectos de notificaciones, el domicilio del Streamer será el que comunique a 014 LIVE! en el proceso de registro en su Plataforma Web y el de 014 LIVE! el que consta en las presente Condiciones legales.</p>
      <b>12. Datos del Streamer.</b>
      <p>Los datos personales del Streamer serán usados para nuestra relación y poder prestarle nuestros servicios. Dichos datos son necesarios para poder relacionarnos con usted, lo que nos permite el uso de su información personal dentro de la legalidad. Asimismo, también pueden ser usados para otras actividades, como enviarle publicidad o promocionar nuestras actividades.</p>
      <p>Solo el personal de nuestra entidad que esté debidamente autorizado podrá tener conocimiento de la información que le pedimos. Asimismo, podrán tener conocimiento de su información aquellas entidades que necesiten tener acceso a la misma para que podamos prestarle nuestros servicios. Igualmente, tendrán conocimiento de su información aquellas entidades públicas o privadas a las cuales estemos obligados a facilitar sus datos personales con motivo del cumplimiento de alguna ley.</p>
      <p>No está prevista la transferencia de su información personal fuera de la Unión Europea y del Espacio Económico Europeo.</p>
      <p>Conservaremos sus datos durante nuestra relación y mientras nos obliguen las leyes. Una vez finalizados los plazos legales aplicables, procederemos a eliminarlos de forma segura.</p>
      <p>En cualquier momento puede dirigirse a nosotros para saber qué información tenemos sobre usted, rectificarla si fuese incorrecta y eliminarla una vez finalizada nuestra relación, en el caso de que ello sea legalmente posible. También tiene derecho a solicitar el traspaso de su información a otra entidad (portabilidad). Para solicitar alguno de estos derechos, deberá realizar una solicitud escrita a nuestra dirección, junto con una fotocopia de su DNI, para poder identificarle: streamers@014live.com. Asimismo, tienen derecho a presentar una reclamación a la autoridad de control competente en el supuesto de que entiendan que su derecho a la protección de datos ha sido vulnerado.</p>
      <p>Más información en nuestra política de privacidad que puede consultar en la web https://www.014live.com.</p>
      <b>13. Modificación y Terminación.</b>
      <p>13.1.	Las presentes Condiciones podrán ser modificadas por 014 LIVE! en cualquier momento, comunicando dicha modificación al Streamer y teniendo este derecho a desvincularse de la Plataforma Web de 014 LIVE!, teniendo obligación 014 LIVE! a abonarle el importe acumulado sin límite mínimo de cantidad..</p>
      <p>13.2.	Las presente Condiciones dejarán de regir la relación entre el Streamer y 014 LIVE! en el momento en el que el Streamer comunique la baja en la Plataforma Web de 014 LIVE!, o cuando 014 LIVE! lo de baja por alguna/s de la/s causas recogidas en las presentes Condiciones.</p>
      <b>14. Exoneración de responsabilidades.</b>
      <p>014 LIVE! no puede garantizar la ausencia de fallos o interrupciones técnicas de su Plataforma Web: tampoco puede garantizar el incumplimiento por parte del Streamer de ningún compromiso que haya adquirido o adquiera con terceros, por tanto, mediante la aceptación de las presentes Condiciones, el Streamer exonera a 014 LIVE! de cualquier responsabilidad por estos hechos.</p>
      <b>15. Otras Condiciones Generales. </b>
      <p>15.1.	Las presentes Condiciones han sido expuestas con la debida antelación, de conformidad con la legislación vigente. Asimismo, 014 LIVE! pone a disposición del Streamer las presentes Condiciones en su Sitio Web, de forma que pueden ser almacenadas y reproducidas, cumpliendo así con el deber legal de información previa.</p>
      <p>15.2.	014 LIVE! se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y configuración del Sitio Web, las funcionalidades del mismo y/o los contenidos que en ella se incorporan. El Streamer reconoce de acepta expresamente que en cualquier momento 014 LIVE! pueda interrumpir, desactivar y/o cancelar el acceso y/o uso del Sitio Web, sin que 014 LIVE! sea responsable por ello.</p>
      <p>15.3.	En el caso de que cualquier disposición o disposiciones de estas Condiciones fueran consideradas nulas o inaplicables, en su totalidad o en parte, por cualquier Juzgado, Tribunal u órgano administrativo competente, dicha nulidad no afectará a las restantes disposiciones. En dicho caso, la estipulación o estipulaciones afectadas serán sustituidas por otra u otras que tengan los efectos más semejantes a los de las sustituidas, preservando la exención de responsabilidad de 014 LIVE! en los supuestos contemplados en las presentes Condiciones.</p>
      <b>16.	Legislación aplicable, resolución de controversias y jurisdicción competente.</b>
      <p>16.1.	La relación entre el Streamer y 014 LIVE! se regirá, en todos sus términos y condiciones, conforme a la vigente legislación española.</p>
      <p>16.2.	Según lo dispuesto en el Reglamento (UE) 524/2013 del Parlamento Europeo y del Consejo de 21 de mayo de 2013 sobre resolución de litigios en línea en materia de consumo y por el que se modifica el Reglamento (CE) 2006/2004 y la Directiva 2009/22/CE, 014 LIVE! pone a disposición de los Streamers del Sitio Web el siguiente enlace: http://ec.europa.eu/consumers/odr/.  </p>
      <p>16.3.	Mediante este enlace, los Streamers de 014 LIVE! podrán acceder a la Plataforma Europea de Resolución de Conflictos en línea (ODR). En caso de que el Streamer haya tenido un problema con la prestación del servicio online, podrá hacer uso de este medio para presentar cualquier reclamación en relación dicha prestación, así como optar por la solución extrajudicial del conflicto suscitado.</p>
      <p>Para presentar reclamaciones, el Streamer puede dirigirse por correo electrónico a la dirección streamers@014live.com o, de manera física, en la dirección indicada en el primer párrafo de las presentes Condiciones, comprometiéndonos 014 LIVE! a buscar en todo momento una solución amistosa del conflicto.</p>
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={props.toggleModal}>Cerrar</Button>
    </ModalFooter>
  </Modal>

}
export default TermsAndConditions