import moment from "moment";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Container } from "reactstrap";
import FiltersStatistics from "src/components/Statistics/FiltersStatistics";
import GraphicsStatistics from "src/components/Statistics/GraphicsStatistics";
import WidgetsStatistics from "src/components/Statistics/WidgetsStatistics";
import { ApiCore } from "src/helpers/api_helper";
import { getStreamerDaily, getStreamerSummary } from "src/helpers/backend_helper";

const Statistics = (props: any) => {
    // console.log(localStorage.getItem('streamer_id'))
    const [mounted, setMounted] = useState(false)
    useEffect(() => {
        setMounted(true)
    }, [])

    const { startDate, endDate } = useSelector((state: any) => ({
        startDate: state.statistics.startDate, endDate: state.statistics.endDate
    }))
    const [analytics, setanalytics] = useState({})
    const [dailyAnalytics, setdailyAnalytics] = useState({})
    
    useEffect(() => {
        if (!mounted) return
        getStreamerSummary(new ApiCore(localStorage.getItem("token")), moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss') , moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss') ).then((res: any): void => {
            setanalytics(res.analytics)
        }).catch((err: Error) => {
            console.log("summary error:", err)
        })
        getStreamerDaily(new ApiCore(localStorage.getItem("token")),moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss') ).then((res: any): void =>  {
            setdailyAnalytics(res.daily_analytics)
        }).catch((err: Error) => {
            console.log("couldn't fetch:", err)
        })
    }, [startDate, endDate, mounted])



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Statistics")}</title>
                </MetaTags>
                <Container fluid>
                    <FiltersStatistics />
                    <WidgetsStatistics analytics={analytics} />
                    {Object.keys(dailyAnalytics).length === 0 ?
                        <></> :
                        <GraphicsStatistics analytics={dailyAnalytics} />
                    }
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(withRouter(Statistics));