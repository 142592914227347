import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function NotEnoughModal({ isOpen, toggle }: { isOpen: boolean, toggle: () => void }) {
  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Saldo insuficiente</ModalHeader>
      <ModalBody>
        Recuerda que para poder cobrar necesitas un saldo mínimo de 20€. En cuanto llegues a esa cantidad podrás cobrar tu saldo disponible.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>Cerrar</Button>
      </ModalFooter>
    </Modal>
  );
}

export default NotEnoughModal;
