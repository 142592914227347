import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import avatarImg from "src/assets/images/stockImages/twitchAvatar.png";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter, useHistory } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import noUser from "../../../assets/images/users/no-user.jpg";

//redux
import { useDispatch, useSelector } from "react-redux";
import { validCampaignImage } from "src/pages/Campaigns";
import { changeLayoutModeAction } from "src/slices/Layout/reducer";
import { changelayoutMode } from "src/slices/thunks";
import { layoutTheme, layoutTypes } from "src/constants/layout";
import { setRunTour } from "src/slices/TourAlt/reducer";

const ProfileMenu = (props: any) => {
    const dispatch = useDispatch();

    const { success } = useSelector((state: any) => ({
        success: state.profile.success,
    }));
    const { pfp } = useSelector((state: any) => state.profile);
    const [menu, setMenu] = useState<boolean>(false);
    const [checkStream, setCheckStream] = useState<boolean>(false);
    const [username, setusername] = useState();
    const [name, setname] = useState();
    const [email, setemail] = useState<string>();
    const [photo, setphoto] = useState(pfp);
    const history = useHistory();
    const { layoutType, layoutMode } = useSelector((state: any) => ({
        layoutType: state.Layout.layoutType,
        layoutMode: state.Layout.layoutMode,
    }));

    const checkStreamStatus = () => {
        const getAuthUser = localStorage.getItem("profile");
        const streamer = getAuthUser && JSON.parse(getAuthUser).streamers[0];
        if (streamer) {
            const mainKeys = ["birthdate", "gender", "nationality", "language_iso", "currency_iso", "contact", "invoice", "withdraw_method"];
            const contactKeys = ["fullname", "email", "country", "city", "state", "address"];
            const invoiceKeys = ["legal_name", "email", "country", "city", "state", "address", "vat_identifier"];
            const withdrawMethodKeys = ["account_number"];
            
            const checkKeys = (object: any, keys: string[]): boolean => {
                for (const key of keys) {
                    if (!object[key]) {
                        return false;
                    }
                }
                return true;
            }  
            let allKeysPresent = true;
            for (const mainKey of mainKeys) {
                if (!streamer[mainKey]) {
                    allKeysPresent = false;
                } else if (mainKey === "contact" && !checkKeys(streamer["contact"], contactKeys)) {
                    allKeysPresent = false;
                } else if (mainKey === "invoice" && !checkKeys(streamer["invoice"], invoiceKeys)) {
                    allKeysPresent = false;
                } else if (mainKey === "withdraw_method" && !checkKeys(streamer["withdraw_method"], withdrawMethodKeys)) {
                    allKeysPresent = false;
                }
            }
            if (allKeysPresent) {
                setCheckStream(true)
            } else {
                setCheckStream(false)      
            }
        }
    }



    useEffect(() => {
        const getAuthUser = localStorage.getItem("authUser");
        if (getAuthUser) {
            const obj = JSON.parse(getAuthUser);
            if (obj) {
                setname(obj.name);
                setusername(obj.username);
                setemail(obj.email);
            }
        }
    }, [success]);

    useEffect(() => {
        checkStreamStatus()
    },[pfp])
    const handleStartTour = () => {
        history.push("/");
        dispatch(setRunTour(true));
    };
    const switcher = () => {
        dispatch(
            changelayoutMode(
                layoutMode == layoutTheme.DARKMODE ? layoutTheme.LIGHTMODE : layoutTheme.DARKMODE,
                layoutType
            )
        );
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle
                    className="btn header-item user text-start d-flex align-items-center"
                    id="page-header-user-dropdown"
                    tag="button">
                    <img
                        className="rounded-circle header-profile-user"
                        src={!pfp.includes("null") ? pfp : avatarImg}
                        alt="Header Avatar"
                        referrerPolicy="no-referrer"
                    />
                    <span className="ms-2 d-none d-sm-block user-item-desc">
                        <span className="user-name">Hola, {name}</span>
             
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
                    <div className="p-3 bg-primary border-bottom">
                        <h6 className="mb-0 text-white">{name ? name : "name"}</h6>
                        <p className="mb-0 text-white-50 fw-semibold">{email}</p>
                    </div>
                    <DropdownItem tag="a" href="/help">
                        <i className="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i>{" "}
                        <span className="align-middle">{props.t("Help")}</span>
                    </DropdownItem>
                    <DropdownItem
                        tag="a"
                        className="d-flex align-items-center"
                        href="/profile/edit">
                        <i className="mdi mdi-account text-muted font-size-16 align-middle me-1"></i>{" "}
                        <span className="align-middle"> {props.t("Edit profile")} </span>
                        {!checkStream &&  <span className="badge badge-soft-danger mx-1">Completar</span>}
                    </DropdownItem>
                    <DropdownItem
                        tag="a"
                        className="d-flex align-items-center"
                        href="/emission/edit">
                        <i className="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i>{" "}
                        <span className="align-middle"> {props.t("Configurate emission")} </span>
                        
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag="a" onClick={() => switcher()}>
                        <i
                            className={`uil ${localStorage.getItem("layoutMode") == layoutTheme.DARKMODE
                                ? "uil-sun"
                                : "uil-moon"
                                } text-muted font-size-16 align-middle me-1`}></i>{" "}
                        <span className="align-middle">
                            Cambiar a modo{" "}
                            {localStorage.getItem("layoutMode") == layoutTheme.DARKMODE
                                ? "claro"
                                : "oscuro"}
                        </span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag="a" onClick={() => handleStartTour()}>
                        <i className="mdi mdi-align-horizontal-center  text-muted font-size-16 align-middle me-1"></i>{" "}
                        <span className="align-middle">Iniciar visita guiada</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag="a" href="/logout">
                        <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{" "}
                        <span className="align-middle">{props.t("Logout")}</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default withTranslation()(withRouter(ProfileMenu));
