import React from "react";
import CountUp from "react-countup";
import { Col, Row } from "reactstrap";
import WidgetBoxStadistics from "./WidgetBoxStadistics";
import { NewWidgetsCampaignCard } from "../Campaigns/NewWidgetCampaignCard";
import {
    CampanasPropuestasIcon,
    CampanasSuscritasIcon,
    ClicksChatIcon,
    CtrIcon,
    EmisionesIcon,
    GananciasTotalesIcon,
    VieswMediosIcon,
    ViewsIcon,
} from "src/assets/icons/Icons";

interface Props {
    analytics?: any;
}

const WidgetsStatistics = (props: Props) => {
    const { analytics } = props;
    return (
        <React.Fragment>
            <Row className="statistics2">
                <Col sm="12">
                    <Row>
                        <Col>
                            {/* <WidgetBoxStadistics title={"Campañas propuestas"}>
                        <h2
                            className="mt-1 font-weight-bold d-flex align-items-center"
                            style={{ justifyContent: "center" }}>
                            <CountUp start={0} end={3} decimals={0} duration={0.5} />
                        </h2>
                    </WidgetBoxStadistics> */}
                            <NewWidgetsCampaignCard
                                icon={<CampanasPropuestasIcon />}
                                WidgetTitle={"Campañas propuestas"}
                                WidgetData={analytics?.period_subscriptions || 0}
                            />
                        </Col>
                        <Col>
                            {/* <WidgetBoxStadistics title={"Ganancias totales"}>
                        <h2
                            className="mt-1 font-weight-bold d-flex align-items-center"
                            style={{ justifyContent: "center" }}>
                            <CountUp
                                start={0}
                                end={1200}
                                decimals={2}
                                suffix={"€"}
                                duration={0.5}
                            />
                        </h2>
                    </WidgetBoxStadistics> */}
                            <NewWidgetsCampaignCard
                                icon={<GananciasTotalesIcon />}
                                decimals={2}
                                color="#9A5B5F"
                                suffix="€"
                                WidgetTitle={"Ganancias totales"}
                                WidgetData={analytics?.period_earnings?.EUR || 0}
                            />
                        </Col>
                        <Col>
                            {/* <WidgetBoxStadistics title={"Views"}>
                        <h2
                            className="mt-1 font-weight-bold d-flex align-items-center"
                            style={{ justifyContent: "center" }}>
                            <CountUp start={0} end={50000} duration={0.5} />
                        </h2>
                    </WidgetBoxStadistics> */}
                            <NewWidgetsCampaignCard
                                icon={<ViewsIcon />}
                                color="rgb(99, 58, 162)"
                                WidgetTitle={"Views"}
                                WidgetData={analytics?.period_views || 0}
                            />
                        </Col>
                        <Col>
                            {/* <WidgetBoxStadistics title={"Clicks en el chat"}>
                        <h2
                            className="mt-1 font-weight-bold d-flex align-items-center"
                            style={{ justifyContent: "center" }}>
                            <CountUp start={0} end={200} duration={0.5} />
                        </h2>
                    </WidgetBoxStadistics> */}
                            <NewWidgetsCampaignCard
                                icon={<ClicksChatIcon />}
                                color="#CF4D59"
                                WidgetTitle={"Clicks en el chat"}
                                WidgetData={analytics?.period_clicks || 0}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <WidgetBoxStadistics title={"Campañas suscritas"}>
                        <h2
                            className="mt-1 font-weight-bold d-flex align-items-center"
                            style={{ justifyContent: "center" }}>
                            <CountUp start={0} end={29} decimals={0} duration={0.5} />
                        </h2>
                    </WidgetBoxStadistics> */}
                            <NewWidgetsCampaignCard
                                icon={<CampanasSuscritasIcon />}
                                color="rgb(246, 107, 78) "
                                WidgetTitle={"Campañas suscritas"}
                                WidgetData={analytics?.period_subscriptions || 0}
                            />
                        </Col>

                        <Col>
                            {/* <WidgetBoxStadistics title={"Emisiones"}>
                        <h2
                            className="mt-1 font-weight-bold d-flex align-items-center"
                            style={{ justifyContent: "center" }}>
                            <CountUp start={0} end={157} duration={0.5} />
                        </h2>
                    </WidgetBoxStadistics> */}
                            <NewWidgetsCampaignCard
                                icon={<EmisionesIcon />}
                                color="#25425E"
                                WidgetTitle={"Emisiones"}
                                WidgetData={analytics?.period_plays || 0}
                            />
                        </Col>

                        <Col>
                            {/* <WidgetBoxStadistics title={"Viewers medios"}>
                        <h2
                            className="mt-1 font-weight-bold d-flex align-items-center"
                            style={{ justifyContent: "center" }}>
                            <CountUp start={0} end={309} duration={0.5} />
                        </h2>
                    </WidgetBoxStadistics> */}
                            <NewWidgetsCampaignCard
                                icon={<VieswMediosIcon />}
                                WidgetTitle={"Viewers medios"}
                                WidgetData={analytics?.period_avg_viewers_per_stream || 0}
                            />
                        </Col>

                        <Col>
                            {/* <WidgetBoxStadistics title={"CTR"}>
                        <h2
                            className="mt-1 font-weight-bold d-flex align-items-center"
                            style={{ justifyContent: "center" }}>
                            <CountUp start={0} end={4} suffix={"%"} duration={0.5} />
                        </h2>
                    </WidgetBoxStadistics> */}
                            <NewWidgetsCampaignCard
                                icon={<CtrIcon />}
                                color="#668a03"
                                suffix="%"
                                WidgetTitle={"CTR"}
                                WidgetData={analytics?.period_ctr || 0}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default WidgetsStatistics;
