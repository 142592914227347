import Icon from "@ailibs/feather-react-ts/dist/Icon";
import React from "react";
import CountUp from "react-countup";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from 'src/assets/styles/CardButton.module.css'
import { useSelector } from "react-redux";

interface Props {
  WidgetData?: any;
  WidgetTitle?: any;
  color?: string;
  status?: string;
  children?: any
  buttonIcon?: any;
  buttonLabel?: any
  style?: any;
  icon?: any;
  className?: string
}

const opacity = 0.1
//1366 * 768
export const WidgetButtonCard = (props: Props) => {
  const [copied, setCopied] = React.useState(false)
  const { color } = props;

  const {
    layoutMode
  } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode
  }));


  return (
    <Card className={`${styles.container} on-hover shadow-none ${props.className} w-header-button`} style={{ maxHeight: "122px", minHeight: "86px", ...props.style }} >
      <CardBody style={{ padding: "0.4rem", display: "flex", alignItems: "center" }}>
        <div className={styles.card} style={{ justifyContent: "space-between" }}>

          <div className={styles.cube} style={{
            // background: `${color ? color : "rgb(3, 142, 220)"}`,
            width: "70px",
            // height: "4em",
            alignSelf: "center",
            display: "grid", placeContent: "center",
            borderRadius: "5%",
            aspectRatio: "1/1",
            position: "relative",
            overflow: "hidden",
            flexShrink: 0,
          }}>
            {/* Circulos */}
            {/* <section>
              <div style={{ position: "absolute", right: "-25%", bottom: "-45%", zIndex: 0 }}>
                <div style={{
                  background: "transparent", width: "4em", height: "4em", borderRadius: "50%", position: "relative", boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,

                }}>
                  <div style={{
                    background: "transparent",
                    width: "2.6em",
                    height: "2.6em",
                    borderRadius: "50%",
                    position: "absolute",
                    boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)"
                  }}>
                    <div style={{
                      background: "transparent",
                      width: "1em",
                      height: "1em",
                      borderRadius: "50%",
                      position: "absolute",
                      boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)"
                    }}>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ position: "absolute", right: "-50%", top: "0", zIndex: 0 }}>
                <div style={{
                  background: "transparent", width: "4em", height: "4em", borderRadius: "50%", position: "relative", boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,

                }}>
                  <div style={{
                    background: "transparent",
                    width: "2.6em",
                    height: "2.6em",
                    borderRadius: "50%",
                    position: "absolute",
                    boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)"
                  }}>
                    <div style={{
                      background: "transparent",
                      width: "1em",
                      height: "1em",
                      borderRadius: "50%",
                      position: "absolute",
                      boxShadow: ` 0 0 0 8px rgba(230, 230, 230,${opacity})`,
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)"
                    }}>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}


            <div style={{ zIndex: 1 }}>
              {props.icon ? props.icon : <Icon name="airplay" color={`white`}></Icon>}
            </div>
          </div >

          <div className={styles.text} style={{ color: "#e2f8f9", fontSize: "1rem", }}>
            <h6 style={{ padding: 0, margin: 0, fontSize: "1.2rem", fontWeight: 300 }} className={styles.title}>{props.WidgetTitle || "Saldo disponible"}</h6>
            <h5 className="font-weight-bold mb-2 d-flex align-items-center">
              {props.WidgetData}
            </h5>
          </div>

          <div className={styles.separator}></div>
          <div className={styles.button} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {props.children}
            <section onClick={() => setCopied(true)} style={{ textAlign: "center", display: "flex", flexDirection: "column", gap: 9, justifyContent: "center", justifyItems: "center" }}>
              <div style={{ border: layoutMode == "dark" ? "1px solid white" : "1px solid #038edc", padding: "0.5em", borderRadius: "0.3em", display: "flex" }}>
                {/* <Icon name="crosshair" color={copied ? "brown" : "white"} /> */}
                {props.buttonIcon || <Icon name="crosshair" color={copied ? "brown" : "white"} />}
              </div>
              <p className="m-0" style={{ fontSize: "0.8em", fontWeight: 300 }}>{props.buttonLabel}</p>
            </section>

          </div>
        </div >

      </CardBody >
    </Card >
  );
};