import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";

import { Row, Col, Alert, Container, Spinner } from "reactstrap";
import 'src/assets/scss/custom/app.scss'
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Social Media Imports
// import { GoogleLogin } from "react-google-login";

// import config from "../../config";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { getProfile, loginUser } from "../../slices/thunks";

//import images
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import { apiError } from "src/slices/Auth/Login/reducer";
// import { gapi } from "gapi-script";
// import { useAuth0 } from "@auth0/auth0-react";
// import { sign } from "crypto";
// import { use } from "i18next";
// import { makeRedirectUri, useAuthRequest } from "expo-auth-session";




interface LoginProps {
  history: any;
}

const Login = ({ history }: LoginProps) => {
  const dispatch = useDispatch();

  const { error } = useSelector((state: any) => ({
    error: state.login.error
  }));

  const { user } = useSelector((state: any) => ({
    user: state.login.user
  }));

  const { profile } = useSelector((state: any) => ({
    profile: state.profile.success
  }));

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    if (profile || error) {
      setLoading(false);
    }

    if (user) {
      dispatch(getProfile(history));
    }

    if (profile) {
      history.push(JSON.parse(localStorage.getItem("profile") ?? '').streamers?.[0]?.status?.name === "enabled" ? "/" : "/profile/edit");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, profile, error])

  // handleValidSubmit
  const handleValidSubmit = (event: any, values: any) => {
    dispatch(loginUser(values, history));
  };


  return (
    <React.Fragment>
      <MetaTags>
        <title>Login</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>

                <div className="py-5">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="40" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="40" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5>Bienvenido!</h5>
                  </div>
                  <AvForm
                    onValidSubmit={(e: any, v: any) => {
                      dispatch(apiError(''));
                      setLoading(true);
                      handleValidSubmit(e, v);
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    <div className="form-floating form-floating-custom mb-3 light">
                      <AvField
                        name="email"
                        label="Mail"
                        className="form-control"
                        disabled={loading}
                        placeholder="Ingresar mail"
                        type="email"
                        errorMessage="Debe ingresar un mail"
                        required
                      />
                    </div>
                    <div className="form-floating form-floating-custom mb-3 light">
                      <AvField
                        name="password"
                        label="Contraseña"
                        type="password"
                        className="form-control"
                        disabled={loading}
                        autoComplete="on"
                        placeholder="Ingresar contraseña"
                        errorMessage="Debe ingresar una contraseña"
                        required
                      />
                    </div>
                    {/* <div className="form-check form-check-info font-size-16">
                      <input className="form-check-input" type="checkbox" id="remember-check" />
                      <label className="form-check-label font-size-14" htmlFor="remember-check">
                        Recordarme
                      </label>
                    </div> */}

                    <div className="mt-3">
                      <button className="btn btn-info w-100" type="submit" disabled={loading}>{loading ? <Spinner animation="border" size={"sm"} /> : "Ingresar"}</button>
                    </div>


                    <div className="mt-4 text-center"><Link className="text-muted" to="/forgot-password"><i className="mdi mdi-lock me-1"></i> Olvidaste tu contraseña?</Link></div>

                  </AvForm>

                  <div className="mt-5 text-center text-muted">
                    <p>No tenes cuenta? <Link to="/register" className="fw-medium text-decoration-underline"> Registrate </Link></p>
                  </div>


                  <div className="mt-3">
                    {/* <button className="btn btn-info w-100" onClick={() => loginWithRedirect()}>
                      {isLoading ? <Spinner animation="border" size={"sm"} /> : "Ingresar"}
                    </button> */}
                    {/* <button className="btn btn-info w-100" type="submit" disabled={loading}>{loading ? <Spinner animation="border"  size={"sm"}/> : "Ingresar"}</button> */}
                  </div>

                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <div className="text-center text-muted p-4">
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div></React.Fragment>
  );
};

export default withRouter(Login);