import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// import * as url from "./helpers/url_helper";

//redux
import { useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
// import { useAuth0 } from "@auth0/auth0-react";
import Tour from "./components/Tour/Tour";
import TourAlt from "./components/Tour/TourAlt";

const App = () => {
    const { layoutType } = useSelector((state: any) => ({
        layoutType: state.Layout.layoutType,
    }));

    // const {isLoading, isAuthenticated, loginWithRedirect} = useAuth0();

    // useEffect(() => {
    //   if(!isLoading && !isAuthenticated){
    //     loginWithRedirect();
    //   }else{

    //   }

    // }, [isAuthenticated, isLoading]) EN CASO DE NO QUERER LOGIN PROPIO Y USAR SIEMPRE EL DE AUTH0

    function getLayout() {
        let layoutCls: Object = VerticalLayout;
        switch (layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    const Layout = getLayout();
    return (
        <React.Fragment>
            <Router>
                <TourAlt />
                <Tour />
                <Switch>
                    {authRoutes.map((route, idx) => (
                        <Authmiddleware
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={false}
                            exact
                        />
                    ))}

                    {userRoutes.map((route: any, idx: number) => (
                        <Authmiddleware
                            path={route.path}
                            layout={Layout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={true}
                            isStreamerEnabledNeeded={route.path === "/profile/edit" ? false : true}
                            exact
                        />
                    ))}
                </Switch>
            </Router>
        </React.Fragment>
    );
};

export default App;
