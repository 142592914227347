import { createSlice } from "@reduxjs/toolkit";

const tourSlice = createSlice({
    name: "tour",
    initialState: {
        runTour: false,
        currentStep: 0,
    },
    reducers: {
        setRunTour(state, action) {
            state.runTour = action.payload;
        },
        setStep(state, action) {
            state.currentStep = action.payload;
        },
    },
});

export const { setRunTour, setStep } = tourSlice.actions;

export default tourSlice.reducer;
