import { Badge } from "reactstrap";
import React from "react";
import { ASSIGNED, PREPARED, getStatusByCode } from "src/helpers/status_helper";
import { badgeColor } from "./MyCampaignsTable";

const StatusCampaign = (props: any) => {
    const getBadgeByStatusCode = (code: any) => {
        const status = getStatusByCode(code);
        switch (status) {
            case PREPARED:
                return <Badge
                    className={status?.color}
                    style={{
                        fontSize: "revert",
                    }}
                >
                    {status?.translation}
                </Badge>
            case ASSIGNED:
                return <Badge
                    className={status?.color}
                    style={{
                        fontSize: "revert",
                        boxShadow: `0 0 5px 3px ${badgeColor(status?.original!)}`,
                    }}
                >
                    {status?.translation}
                </Badge>
            default:
                return <Badge
                    className={status?.color}
                    style={{
                        fontSize: "revert",
                    }}
                >
                    {status?.translation}
                </Badge>
        }
    }

    return (
        <React.Fragment>
            {getBadgeByStatusCode(props.code)}
        </React.Fragment>
    )

}

export default StatusCampaign;