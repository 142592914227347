import { Col, Container, Input, Label, ModalBody, Row, Spinner } from "reactstrap";
import * as statuses from "../../helpers/status_helper";
import moment from "moment";
import React, { useState } from "react";
import backgroundImg from "src/assets/images/no-video.webp";
import ReactPlayer from "react-player";
import "../../assets/scss/custom/pages/_detail-campaign.scss";
import "../../assets/scss/custom/components/_chatLinks.scss";
import { validCampaignImage } from "src/pages/Campaigns";
import { getSubscriptions } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import AllowLinksChat from "./AllowLinksChat";
import AddPositioner from "./AdPosition/AddPositioner";

function isImage(url: string) {
	return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}
export function shapeLocal(data: number) {
	return data?.toLocaleString("es-AR", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	// return `${data.toFixed(2)}`.replace(".", ",");
}

const DetailCampaign = (props: any) => {
	const [campaignOnModal, setCampaignOnModal] = useState<any>(props.campaign);
	const [subscriptionOnModal, setSubscriptionOnModal] = useState<any>(props.subscription || false);
	const [isPlaying, setIsPlaying] = useState(false);


	const getSubscriptionByCampaign = (campaing: any, subscriptions: any) => {
		const subscriptionIdToFind = campaing.subscription_id;
		return subscriptions
			? subscriptions.find((sub: any) => sub.id == subscriptionIdToFind)
			: null;
	};

	const handlePlay = () => {
		setIsPlaying(true);
	}

	const handleMouseDown = (event: any) => {
		event.preventDefault();
	}
	React.useEffect(() => {
		getSubscriptions(new ApiCore(localStorage.getItem("token"))).then((data: any) => {
			const subscriptionsResponse = Object.values(data.payload.streamers)[0]
			setSubscriptionOnModal(getSubscriptionByCampaign(props.campaign, subscriptionsResponse))
		});
	}, []);
	const getCampaignDetailsByStatus = () => {
		// console.log("sus", campaignOnModal, subscriptionOnModal)
		if (campaignOnModal.campaign_situation === "paused") {
			return (
				<div>
					<div
						className="container-details-1 "
						style={{
							borderBottom: "1px solid",
							borderBottomColor: "rgb(4, 58, 90)",
							marginTop: "20px",
						}}
					>
						<div
							className="data"
						>
							<Label>Ganancias</Label>
							<Input
								className="light"
								disabled={true}
								value={`${shapeLocal(subscriptionOnModal?.earnings)}€`}
							/>
						</div>
						<div
							className="data"
						>
							<Label>Views</Label>
							<Input
								className="light"
								disabled={true}
								value={shapeLocal(subscriptionOnModal?.views)}
							/>
						</div>
						<div
							className="data"
						>
							<Label>CTR</Label>
							<Input
								className="light"
								disabled={true}
								value={`${shapeLocal(subscriptionOnModal?.ctr)} %`}
							/>
						</div>
						<div
							className="data"
							style={{ textAlign: "center" }}>
							<Label>Nº de emisiones</Label>
							<Input
								className="light"
								disabled={true}
								value={shapeLocal(subscriptionOnModal?.emissions)}
							/>
						</div>
						<div
							className="data"
						>
							<Label>Views por emisión</Label>
							<Input
								className="light"
								disabled={true}
								value={shapeLocal(
									subscriptionOnModal?.average_views_per_stream
								)}
							/>
						</div>
						<div
							className="data"
						>
							<Label>Clicks en el chat</Label>
							<Input
								className="light"
								disabled={true}
								value={shapeLocal(subscriptionOnModal?.clicks)}
							/>
						</div>
						<div
							className="data"
							style={{ textAlign: "center" }}>
							<Label>CPM</Label>
							<Input
								className="light"
								disabled={true}
								value={`${shapeLocal(campaignOnModal?.cpm_streamer)}€`}
							/>
						</div>


						<div
							className="data"
							style={{ textAlign: "center" }}>
							<Label>Fecha inicio</Label>
							<Input
								className="light"
								disabled={true}
								value={moment(campaignOnModal?.start_date)
									.startOf("day")
									.format("DD/MM/YYYY")}
							/>
						</div>

						<div
							className="data"
							style={{ textAlign: "center" }}>
							<Label>Fecha fin</Label>
							<Input
								className="light"
								disabled={true}
								value={moment(campaignOnModal?.end_date)
									.startOf("day")
									.format("DD/MM/YYYY")}
							/>
						</div>
						<br />

						<div className="data-large mt-3">
							<Label>Descripción campaña / producto</Label>
							<span className="form-control light" role="textbox">{campaignOnModal?.description}</span>
							{/* <Input
									className="light" disabled={true}
									value={campaignOnModal?.description}
								/> */}
						</div>

						<div className="data-large">
							<Label>Mensaje del bot en el chat</Label>
							<span className="form-control light" role="textbox">{campaignOnModal?.chat_message}</span>
							{/* <Input
									className="light" disabled={true}
									value={campaignOnModal?.chat_message}
								/> */}
						</div>

						<div className="data-large">
							<Label>Detalles de emisión de campaña</Label>
							<span className="form-control light" role="textbox">{campaignOnModal?.profile_segmentation}</span>
							{/* <Input
									className="light" disabled={true}
									value={campaignOnModal?.profile_segmentation}
								/> */}
						</div>
						<div className="data-large">
							{/* <AllowLinksChat /> */}

							<AddPositioner />
						</div>
						{/* <Col sm={12} className="mb-3">
																<Label className="light">White list</Label>
																<Input
																		disabled={true}
																		value={campaignOnModal?.whitelist_categories?.join(",")}
																/>
														</Col>

														<Col sm={12} className="mb-3">
																<Label className="light">Black list</Label>
																<Input
																		disabled={true}
																		value={campaignOnModal?.blacklist_categories?.join(",")}
																/>
														</Col> */}
					</div>
				</div>
			);
		}
		switch (subscriptionOnModal.status?.name) {
			case statuses.ASSIGNED.original:
			case statuses.PAUSED.original:
				return (
					<div>
						<div
							className="container-details-1 "
							style={{
								borderBottom: "1px solid",
								borderBottomColor: "rgb(4, 58, 90)",
								marginTop: "20px",
							}}
						>
							<div
								className="data"
							>
								<Label>Ganancias</Label>
								<Input
									className="light"
									disabled={true}
									value={`${shapeLocal(subscriptionOnModal?.earnings)}€`}
								/>
							</div>
							<div
								className="data"
							>
								<Label>Views</Label>
								<Input
									className="light"
									disabled={true}
									value={shapeLocal(subscriptionOnModal?.views)}
								/>
							</div>
							<div
								className="data"
							>
								<Label>CTR</Label>
								<Input
									className="light"
									disabled={true}
									value={`${shapeLocal(subscriptionOnModal?.ctr)} %`}
								/>
							</div>
							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>Nº de emisiones</Label>
								<Input
									className="light"
									disabled={true}
									value={shapeLocal(subscriptionOnModal?.emissions)}
								/>
							</div>
							<div
								className="data"
							>
								<Label>Views por emisión</Label>
								<Input
									className="light"
									disabled={true}
									value={shapeLocal(
										subscriptionOnModal?.average_views_per_stream
									)}
								/>
							</div>
							<div
								className="data"
							>
								<Label>Clicks en el chat</Label>
								<Input
									className="light"
									disabled={true}
									value={shapeLocal(subscriptionOnModal?.clicks)}
								/>
							</div>
							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>CPM</Label>
								<Input
									className="light"
									disabled={true}
									value={`${shapeLocal(campaignOnModal?.cpm_streamer)}€`}
								/>
							</div>


							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>Fecha inicio</Label>
								<Input
									className="light"
									disabled={true}
									value={moment(campaignOnModal?.start_date)
										.startOf("day")
										.format("DD/MM/YYYY")}
								/>
							</div>

							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>Fecha fin</Label>
								<Input
									className="light"
									disabled={true}
									value={moment(campaignOnModal?.end_date)
										.startOf("day")
										.format("DD/MM/YYYY")}
								/>
							</div>
							<br />

							<div className="data-large mt-3">
								<Label>Descripción campaña / producto</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.description}</span>
								{/* <Input
									className="light" disabled={true}
									value={campaignOnModal?.description}
								/> */}
							</div>

							<div className="data-large">
								<Label>Mensaje del bot en el chat</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.chat_message}</span>
								{/* <Input
									className="light" disabled={true}
									value={campaignOnModal?.chat_message}
								/> */}
							</div>

							<div className="data-large">
								<Label>Detalles de emisión de campaña</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.profile_segmentation}</span>
								{/* <Input
									className="light" disabled={true}
									value={campaignOnModal?.profile_segmentation}
								/> */}
							</div>
							<div className="data-large">
								{/* <AllowLinksChat /> */}

								<AddPositioner />
							</div>
							{/* <Col sm={12} className="mb-3">
                                <Label className="light">White list</Label>
                                <Input
                                    disabled={true}
                                    value={campaignOnModal?.whitelist_categories?.join(",")}
                                />
                            </Col>

                            <Col sm={12} className="mb-3">
                                <Label className="light">Black list</Label>
                                <Input
                                    disabled={true}
                                    value={campaignOnModal?.blacklist_categories?.join(",")}
                                />
                            </Col> */}
						</div>
					</div>
				);
			case statuses.OPERATOR_PAUSED.original:
				return (
					<div>
						<div
							className="container-details-1 "
							style={{
								borderBottom: "1px solid",
								borderBottomColor: "rgb(4, 58, 90)",
								marginTop: "20px",
							}}
						>
							<div
								className="data"
							>
								<Label>Ganancias</Label>
								<Input
									className="light"
									disabled={true}
									value={`${shapeLocal(subscriptionOnModal?.earnings)}€`}
								/>
							</div>
							<div
								className="data"
							>
								<Label>Views</Label>
								<Input
									className="light"
									disabled={true}
									value={shapeLocal(subscriptionOnModal?.views)}
								/>
							</div>
							<div
								className="data"
							>
								<Label>CTR</Label>
								<Input
									className="light"
									disabled={true}
									value={`${shapeLocal(subscriptionOnModal?.ctr)} %`}
								/>
							</div>
							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>Nº de emisiones</Label>
								<Input
									className="light"
									disabled={true}
									value={shapeLocal(subscriptionOnModal?.emissions)}
								/>
							</div>
							<div
								className="data"
							>
								<Label>Views por emisión</Label>
								<Input
									className="light"
									disabled={true}
									value={shapeLocal(
										subscriptionOnModal?.average_views_per_stream
									)}
								/>
							</div>
							<div
								className="data"
							>
								<Label>Clicks en el chat</Label>
								<Input
									className="light"
									disabled={true}
									value={shapeLocal(subscriptionOnModal?.clicks)}
								/>
							</div>
							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>CPM</Label>
								<Input
									className="light"
									disabled={true}
									value={`${shapeLocal(campaignOnModal?.cpm_streamer)}€`}
								/>
							</div>


							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>Fecha inicio</Label>
								<Input
									className="light"
									disabled={true}
									value={moment(campaignOnModal?.start_date)
										.startOf("day")
										.format("DD/MM/YYYY")}
								/>
							</div>

							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>Fecha fin</Label>
								<Input
									className="light"
									disabled={true}
									value={moment(campaignOnModal?.end_date)
										.startOf("day")
										.format("DD/MM/YYYY")}
								/>
							</div>
							<br />

							<div className="data-large mt-3">
								<Label>Descripción campaña / producto</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.description}</span>
								{/* <Input
										className="light" disabled={true}
										value={campaignOnModal?.description}
									/> */}
							</div>

							<div className="data-large">
								<Label>Mensaje del bot en el chat</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.chat_message}</span>
								{/* <Input
										className="light" disabled={true}
										value={campaignOnModal?.chat_message}
									/> */}
							</div>

							<div className="data-large">
								<Label>Detalles de emisión de campaña</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.profile_segmentation}</span>
								{/* <Input
										className="light" disabled={true}
										value={campaignOnModal?.profile_segmentation}
									/> */}
							</div>
							<div className="data-large">
								{/* <AllowLinksChat /> */}

								<AddPositioner />
							</div>
							{/* <Col sm={12} className="mb-3">
																	<Label className="light">White list</Label>
																	<Input
																			disabled={true}
																			value={campaignOnModal?.whitelist_categories?.join(",")}
																	/>
															</Col>
	
															<Col sm={12} className="mb-3">
																	<Label className="light">Black list</Label>
																	<Input
																			disabled={true}
																			value={campaignOnModal?.blacklist_categories?.join(",")}
																	/>
															</Col> */}
						</div>
					</div>
				);
			case statuses.STREAMER_AGREED.original:
			case statuses.STREAMER_PENDING.original:
			case statuses.STREAMER_REFUSED.original:
				return (
					<>
						<div
							className="container-details-2"
							style={{
								borderBottom: "1px soli",
								borderBottomColor: "rgb(4, 58, 90)",
								marginTop: "20px",
								marginBottom: "20px",
							}}
						>
							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>CPM</Label>
								<Input
									className="light" disabled={true}
									value={`${shapeLocal(campaignOnModal?.cpm_streamer)}€`}
								/>
							</div>

							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>Fecha inicio</Label>
								<Input
									className="light" disabled={true}
									value={moment(campaignOnModal?.start_date)
										.startOf("day")
										.format("DD/MM/YYYY")}

								/>
							</div>

							<div
								className="data"
								style={{ textAlign: "center" }}>
								<Label>Fecha fin</Label>
								<Input
									className="light" disabled={true}
									value={moment(campaignOnModal?.end_date)
										.startOf("day")
										.format("DD/MM/YYYY")}
								/>
							</div>
							<div className="data-large mt-3">
								<Label>Descripción campaña / producto</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.description}</span>
							</div>

							<div className="data-large">
								<Label>Mensaje del bot en el chat</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.chat_message}</span>
								{/* <Input
									className="light" disabled={true}
									value={campaignOnModal?.chat_message}
								/> */}
							</div>


							<div className="data-large">

								<Label>Detalles de emisión de campaña</Label>
								<span className="form-control light" role="textbox">{campaignOnModal?.profile_segmentation}</span>
								{/* <Input
								className="light" disabled={true}
								value={campaignOnModal?.profile_segmentation}
							/> */}


							</div>
							{/* <AllowLinksChat /> */}
							{/* <div className="data-large">
                                <Label>White list</Label>
                                <span className="form-control light" role="textbox">{campaignOnModal?.whitelist_categories?.join(",")}</span>
																</div>
																
																<div className="data-large">
                                <Label>Black list</Label>
                                <span className="form-control light" role="textbox">{campaignOnModal?.blacklist_categories?.join(",")}</span>
                            </div> */}
						</div>
					</>
				);
			case statuses.OPERATIONAL.original:
				return <></>;
			case statuses.STREAMER_REQUESTED.original:
				return <></>;
		}
	};

	return (
		<ModalBody className="modal-card">
			<Row style={{ borderBottom: "1px solid", borderBottomColor: "rgb(4, 58, 90)", padding: "1rem" }}>
				<Col lg={12} className="mb-3" style={{ textAlign: "center" }}>
					{campaignOnModal?.campaign_image ? (
						<img width="80%"
							style={{ maxWidth: 390, maxHeight: 190, objectFit: "cover" }}
							alt="campaign img"
							// src={campaignOnModal?.campaign_image}
							src={validCampaignImage(campaignOnModal.campaign_image)}
						></img>
					) : (
						<img
							width="80%"
							alt="campaign img"
							src="https://tecnovortex.com/wp-content/uploads/2022/01/twich-streamer.jpg"></img>
					)}
				</Col>
			</Row>
			{subscriptionOnModal ? getCampaignDetailsByStatus() : <Container fluid style={{ aspectRatio: "3/1", display: "grid", placeItems: "center" }}><Spinner /></Container>}


			<Row className="mt-4">
				<Col xl={12} className="px-4">
					{campaignOnModal?.creativities[0]?.resource_url ?
						(isImage(campaignOnModal?.creativities[0]?.resource_url)
							?
							<img width="100%" alt="campaign img"
								src={validCampaignImage(campaignOnModal?.creativities[0]?.resource_url)}
							></img>
							:
							<div
								onMouseDown={handleMouseDown} onClick={handlePlay}
								style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0 }}
							>


								<ReactPlayer
									width={"100%"}
									height={"100%"}

									style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
									controls={true}
									playing={isPlaying}
									url={campaignOnModal?.creativities[0]?.resource_url}
									light={campaignOnModal?.creativities[0]?.thumbnail_img_url ? <img width="100%" style={{ maxHeight: "100%", objectFit: "cover" }}
										// src={campaignOnModal?.campaign_image}
										src={validCampaignImage(campaignOnModal?.creativities[0]?.thumbnail_img_url)}
										alt="campaign img"
									>
									</img> : ''}

								/></div>)
						:
						<div
							style={{
								height: "300px",
								backgroundImage: `url(${backgroundImg})`,
								color: "white",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<h4>Video de campaña aún no disponible</h4>
						</div>
					}
				</Col>
			</Row>
		</ModalBody>
	);
};

export default DetailCampaign;
