export const COLORS = {
    PrimarySkyBlue: "#038edc",
    bootstrapBlue: "#0275d8",
    bootstrapGreen: "rgba(16,209,0 ,0.82)",
    bootstrapRed: "#d9534f",
    bootstrapYellow: "#f0ad4e",
    bootstrapGrey: "#f7f7f7",
    basicPurple: "rgba(156,59,199,0.82)",
    badgeInfo: "#5fd0f3",
    badgeSuccess: "#51d28c",
    badgeStreamer: "#a231d7",
    badgeDanger: "#f34e4e",
    badgeWarning: "#f7cc53",
};
