import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Alert, CardBody, Button } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile } from "../../slices/thunks";

const UserProfile = () => {
  const dispatch = useDispatch();

  const { error, success } = useSelector((state: any) => ({
    error: state.profile.error,
    success: state.profile.success,
  }));

  const [email, setemail] = useState<string>("");
  const [name, setname] = useState<string>("");
  const [idx, setidx] = useState<number>(1);

  useEffect(() => {
    const authUser: any = localStorage.getItem("authUser");

    if (authUser) {
      const obj = JSON.parse(authUser);
      // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      //   if (obj.displayName) {
      //     setname(obj.displayName);
      //   } else {
      //     setname(obj.fullName);
      //   }
      //   setemail(obj.email);
      //   setidx(obj.uid);

      // } else
      if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        if (obj.username) {
          setname(obj.username);
          setemail(obj.email);
          setidx(obj.uid);
        } else {
          setname(obj.name);
        }
      }
    }

  }, [dispatch, success]);

  function handleValidSubmit(event: any, values: any) {
    dispatch(editProfile(values));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Dashonic - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashonic" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Actualizar datos del usuario</h4>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e: any, v: any) => {
                  handleValidSubmit(e, v);
                }}
              >
                <h4 className="card-title">Actualizar datos de generales del usuario</h4>
                <div className="form-group">
                  <CardBody>
                    <AvField
                      name="username"
                      label="Nombre"
                      value={name}
                      className="form-control mb-1"
                      placeholder="Ingresar nombre"
                      type="text"
                      required
                    />

                    <AvField
                      name="dateOfBirth"
                      label="Fecha de nacimiento"
                      type="text"
                      className="form-control mb-1"
                      validate={{ date: { format: 'DD/MM/YYYY' } }}
                      title="Use DD/MM/YYYY"
                      placeholder="Usar formato DD/MM/YYYY"
                      required
                    />

                    <AvField type="select" name="select" label="Género" className="form-control mb-1">
                      <option>Femenino</option>
                      <option>Masculino</option>
                      <option>Otro</option>
                    </AvField>

                    <AvField
                      name="country"
                      label="Pais"
                      type="text"
                      className="form-control mb-1"
                      required
                      placeholder="Ingresar pais"
                    />

                    <AvField
                      name="population"
                      label="Población"
                      type="text"
                      className="form-control mb-1"
                      required
                      placeholder="Ingresar población"
                    />

                    <AvField
                      name="broadcastLanguage"
                      label="Idioma de emisión"
                      type="text"
                      className="form-control mb-1"
                      required
                      placeholder="Ingresar idioma de emisión"
                    />
                  </CardBody>

                  <h4 className="card-title mt-4">Actualizar datos de facturación del usuario</h4>

                  <CardBody>
                    <div className="form-group mr-1">
                      <AvField
                        name="billingAddress"
                        label="Dirección de facturación"
                        type="text"
                        className="form-control mb-1"
                        required
                        placeholder="Ingresar dirección de facturación"
                      />

                      <AvField
                        name="vatAndNif"
                        label="VAT / NIF"
                        type="text"
                        className="form-control mb-1"
                        required
                        placeholder="Ingresar VAT / NIF"
                      />

                      <AvField
                        name="phoneNumber"
                        label="Teléfono *"
                        type="text"
                        className="form-control mb-1"
                        required
                        placeholder="Ingresar teléfono"
                      />

                    </div>
                  </CardBody>

                  <h4 className="card-title mt-4">Actualizar datos de las plataformas en las que emite</h4>

                  <CardBody>
                    <div className="form-group mr-1">
                      <AvField
                        name="twitchUser"
                        label="Cuenta de Twitch"
                        type="text"
                        className="form-control mb-1"
                        required
                        placeholder="Ingresar cuenta de Twitch"
                      />

                      <AvField
                        name="twitchName"
                        label="Nombre en Twitch"
                        type="text"
                        className="form-control mb-1"
                        required
                        placeholder="Ingresar nombre en Twitch"
                      />

                      <AvField
                        name="channelThematic"
                        label="Temática del canal"
                        type="text"
                        className="form-control mb-1"
                        required
                        placeholder="Ingresar temática del canal"
                      />


                      <AvField type="select" name="categoriesEmits" label="Categorías en las que emite" helpMessage="Ctrl + Click puede seleccionar mas de una opción" multiple>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </AvField>

                      <AvField
                        name="urlLive"
                        label="URL de emisión"
                        type="text"
                        className="form-control mb-1"
                        required
                        placeholder="Ingresar URL de emisión"
                      />

                    </div>
                  </CardBody>

                  <AvField name="idx" value={idx} type="hidden" />
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);