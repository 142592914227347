import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

interface AuthLayoutProps {
    component: any;
    layout: any;
    isAuthProtected: any;
    isStreamerEnabledNeeded?: boolean;
    path?: string;
    exact?: boolean;
    key?: number;
}

const Authmiddleware = ({
    component,
    layout,
    isAuthProtected,
    isStreamerEnabledNeeded,
    path,
    exact,
    key,
    ...rest
}: AuthLayoutProps) => {
    const Layout = layout;
    const Component = component;
    const { pathname } = useLocation()
    return (
        <Route
            {...rest}
            render={(props: any) => {
                if (isAuthProtected && !localStorage.getItem("authUser")) {
                    return (
                        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                    );
                }

                if (isStreamerEnabledNeeded) {
                    const getProfile = localStorage.getItem("profile");
                    if (
                        !getProfile ||
                        JSON.parse(getProfile).streamers?.[0]?.status?.name !== "enabled"
                    ) {
                        return (
                            <Redirect
                                to={{ pathname: "/profile/edit", state: { from: props.location } }}
                            />
                        );
                    }
                }


                // if (isStreamerEnabledNeeded) {

                //     if (pathname !== "/") {
                //         const profile = localStorage.getItem('profile')
                //         if (!profile) return
                //         const streamer = JSON.parse(profile).streamers[0]
                //         if (!streamer.birthdate) {

                //             return (
                //                 <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                //             )
                //         }

                //     }

                // }

                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );
};

export default Authmiddleware;
