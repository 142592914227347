interface StreamerStatusProps {
    status: string;
    translate: string;
    classBadge: string;
}

const streamerStatuses: Array<StreamerStatusProps> = [
    {
        status: "not connected",
        translate: "No conectado",
        classBadge: "badge-soft-danger",
    },
    {
        status: "connected",
        translate: "Conectado",
        classBadge: "badge-soft-success",
    },
];

export { streamerStatuses };
