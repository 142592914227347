import React from "react";
import ReactApexChart from "react-apexcharts";
import zoomableSeries from "../../common/data/zoomableSeries";
import moment from "moment";
import 'moment/locale/es'



const LineChartGeneric = (props: any) => {

  const getYAxis = () => {
    if (props.yTitle === "Ganancias") {
      return [
        {
          seriesName: "EUR",
          title: {
            text: "EUR"
          },
        },
        {
          seriesName: "USD",
          opposite: true,
          title: {
            text: "USD"
          }
        }
      ]
    } else {
      return {
        showAlways: !0,
        labels: {
          show: !0,
          formatter: function (val: any) {
            return (val / 1000000).toFixed(0);
          },
        },
        title: {
          text: props.yTitle,
          style: {
            fontWeight: 500,
          },
        },
      }
    }
  }



  const getSeries = () => {
    if (props.yTitle === "Ganancias") {
      const eurData = props.data.map((e: any) => { return e['EUR'] ?? 0 });
      // const usdData = props.data.map((e: any) => { return e['USD'] ?? 0 });

      return [
        {
          name: "EUR",
          data: eurData
        },
        // {
        //   name: "USD",
        //   data: usdData,
        // }
      ];
    } else {
      return [{
        name: props.yTitle,
        data: props.data
      }];
    }

  }

  const options = {
    chart: {
      defaultLocale: 'es',
      locales: [{
        name: 'es',
        options: {
          months: ['Enero', 'Febrero', 'Mar<o', 'Abril', 'May', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['En', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
          shortDays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
          toolbar: {
            download: 'Download SVG',
            selection: 'Selection',
            selectionZoom: 'Selection Zoom',
            zoomIn: 'Zoom In',
            zoomOut: 'Zoom Out',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      }],
      stacked: !1,
      zoom: {
        type: "x",
        enabled: false,
        autoScaleYaxis: !0
      },
      toolbar: {
        enabled: false,
        show: false
      }
    },
    colors: ["#038edc"],
    dataLabels: {
      enabled: !1
    },
    markers: {
      size: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      },
    },
    yaxis: {
      showAlways: !0,
      labels: {
        show: !0,
        formatter: function (val: any) {
          return val
        },
      },
      title: {
        text: props.yTitle,
        style: {
          fontWeight: 500,
        },
      },
    },
    xaxis: {
      type: 'category',
      categories: props.dates,
      location: "es",
      labels: {
        formatter: (value: any) => {
          const originalLocale = moment.locale();
          moment.locale('es');
          const date = moment(value);
          const format = date.year() === moment().year() ? "D MMM" : "D MMM 'YY";
          let formattedDate = date.format(format);
          const splitDate = formattedDate.split(' ');
          splitDate[1] = splitDate[1].charAt(0).toUpperCase() + splitDate[1].slice(1);
          formattedDate = splitDate.join(' ');
          moment.locale(originalLocale);
          return formattedDate;
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: [{
        formatter: function (y: any) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;

        }
      }, {
        formatter: function (y: any) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;

        }
      }]
    }
  };


  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={getSeries()}
        type="area"
        height={350}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default LineChartGeneric;
