import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { postWithdrawBallance } from "src/helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";
import "src/assets/scss/custom/pages/_wallet.scss";
// import { useSelector } from "react-redux";
import NotEnoughModal from "./NotEnoughModal";
import WithDrawModal from "./WithdrawModal";
import { useDispatch } from "react-redux";
import { statusUpdate } from "src/slices/Wallet/reducer";
import { NavLink } from "react-router-dom";
import BillingDataModal from "./BillingDataModal";

// const vatIdentifiers: any = {
//     company: "CIF/NIF",
//     freelance: "NIF",
//     natural_person: "DNI",
// };

const WalletHeader = (props: any) => {
    const dispatch = useDispatch();
    const [modalEditBillingData, setModalEditBillingData] = useState(false);
    const [notEnoughisOpen, setNotEnoughIsOpen] = useState(false);
    const [whithdrawIsOpen, setWithdrawIsOpen] = useState(false);
    const [withdrawIsDisableButton, setWithdrawIsDisableButton] = useState(false);

    //Streamer Billing data

    const [visibleText, setVisibleText] = useState<boolean>(false);

    // const [fiscalEntity, setFiscalEntity] = useState("freelance");
    // const handleFiscalEntity = (event: any) => {
    //     setFiscalEntity(event.target.value);
    // };

    // const handleEditBillingData = () => {
    //     setModalEditBillingData(true);
    // };

    // const validateBillingFields = () => {
    //     const getProfile = localStorage.getItem("profile");
    //     if (getProfile) {
    //         const obj = JSON.parse(getProfile);
    //         const streamer = obj.streamers?.[0];
    //         const billing = streamer?.invoice;
    //         const streamerBillingName = billing?.legal_name;
    //         const streamerBillingEmail = billing?.email;
    //         const streamerBillingCountry = billing?.country;
    //         const streamerBillingCity = billing?.city;
    //         const streamerBillingState = billing?.state;
    //         const streamerBillingAddress = billing?.address;
    //         const streamerBillingZipCode = billing?.zip_code;
    //         const streamerBillingPhone = billing?.phone;
    //         const streamerBillingVat = billing?.vat_identifier;
    //         const streamerBillingBankAccount = streamer?.withdraw_method?.account_number;
    //         const sFiscalEntity = streamer?.invoice?.organization_type;
    //         const streamerBicCode = streamer?.withdraw_method?.bic_code;
    //         const billingData = [
    //             streamerBillingName,
    //             streamerBillingEmail,
    //             streamerBillingCountry,
    //             streamerBillingCity,
    //             streamerBillingState,
    //             streamerBillingAddress,
    //             streamerBillingZipCode,
    //             streamerBillingPhone,
    //             streamerBillingVat,
    //             streamerBillingBankAccount,
    //             streamerBicCode,
    //             sFiscalEntity,
    //         ];
    //         return billingData.every(variable => variable !== "" && variable);
    //     }
    //     return false;
    // };

    const confirmarRetiro = async () => {
        // Bloqueamos el boton para que no se pueda seguir pulsando.
        setWithdrawIsDisableButton(true);
        const getProfile = localStorage.getItem("profile");

        if (getProfile) {
            const obj = JSON.parse(getProfile);
            const streamer = obj.streamers?.[0];
            const currency = streamer.currency_iso;
            try {
                await postWithdrawBallance(new ApiCore(localStorage.getItem("token")), {
                    streamer_id: localStorage.getItem("streamer_id"),
                    balance: `${props.availableBalance}`,
                    currency,
                });
                dispatch(statusUpdate(Math.random().toString(36).slice(2)));
                props.success(true);
            } catch (error) {
                props.error(true);
            }
        }
        toggle();
    };

    const toggle = () => setWithdrawIsOpen(!whithdrawIsOpen);
    const toggleNotEnough = () => setNotEnoughIsOpen(!notEnoughisOpen);


    const hiddenTextStyle = {
        display: `${visibleText ? "" : "none"}`,
    };

    return (
        <React.Fragment>
            <NotEnoughModal isOpen={notEnoughisOpen} toggle={toggleNotEnough} />
            <WithDrawModal
                isOpen={whithdrawIsOpen}
                isDisableConfirm={withdrawIsDisableButton}
                toggle={toggle}
                onConfirm={confirmarRetiro}
                availableAmount={props.availableBalance}
            />
            <Card className="wallet2">
                <CardBody style={{ position: "relative", overflow: "hidden" }}>
                    <div
                        style={{ marginTop: "1rem", gap: "1.2rem", fontSize: "1rem" }}
                        className="wallet-container-header ">
                        <div className="wallet-texts-1">
                            <p>Aquí en la billetera te mostramos todas tus ganancias.</p>
                            <p style={hiddenTextStyle as any}>
                                Puedes solicitar el pago en cualquier momento siempre y cuando
                                tengas un <b>mínimo de 20€ de saldo disponible.</b>
                            </p>
                            <span
                                onClick={() => setVisibleText(!visibleText)}
                                className={`VerMas1 ${!visibleText ? "vmvisible" : "noDisplay"}`}
                                style={{ color: "#09abfe", cursor: "pointer" }}>
                                Ver mas...
                            </span>
                            <p style={hiddenTextStyle as any}>
                                La diferencia entre el saldo acumulado y el saldo disponible son las
                                ganancias que ya has generado de las campañas que están activas en
                                estos momentos.
                            </p>
                            <p style={hiddenTextStyle as any}>
                                Una vez solicitado el pago, recibirás el dinero a más tardar en{" "}
                                <b> 10 días laborables</b>.
                            </p>
                            <span
                                onClick={() => setVisibleText(!visibleText)}
                                className={`VerMenos ${!visibleText ? "noDisplay" : "vmvisible"}`}
                                style={{ color: "#09abfe", cursor: "pointer" }}>
                                Ver menos...
                            </span>
                        </div>
                        <div className="wallet-texts-2">
                            <p>Todos nuestros pagos cumplen con la legislación fiscal vigente.</p>
                            <p style={hiddenTextStyle as any}>
                                Si resides fuera de España recuerda enviarnos el certificado de
                                residencia fiscal para evitar la retención del 15%.
                            </p>
                            <p style={hiddenTextStyle as any}>
                                Para más información visita las <NavLink to={"/help"}>FAQs</NavLink>{" "}
                                o escríbenos a streamers@014live.com
                            </p>
                        </div>
                        <span
                            onClick={() => setVisibleText(!visibleText)}
                            className={`VerMas2`}
                            style={{ color: "#09abfe", cursor: "pointer" }}>
                            {!visibleText ? "Ver mas..." : "Ver menos..."}
                        </span>

                        {/* <WidgetButtonCard
          className='wallet-box'
          color="#668A03"
          icon={<SaldoDispIcon />}
          buttonLabel="Cobrar"
          buttonIcon={<svg onClick={() => handleWhitdraw()} style={{ color: layoutMode == "dark" ? "white" : "#038edc", display: "grid", placeContent: "center" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-currency-euro" viewBox="0 0 16 16">
          <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
          </svg>}
          WidgetData={<CountUp start={0} end={props.availableBalance} decimals={3} suffix={"€"} formattingFn={(number) => `${number.toLocaleString("es-AR")}€`}
          duration={1} style={{ fontSize: "2rem", fontWeight: "300" }} />}
        /> */}
                    </div>
                    {/* Circulo */}
                    <section style={{ display: `${visibleText ? "none" : "block"}` }}>
                        <div
                            style={{
                                position: "absolute",
                                right: "-5.3rem",
                                bottom: "-3.1rem",
                                zIndex: 0,
                            }}>
                            <div
                                style={{
                                    background: "transparent",
                                    width: "8rem",
                                    height: "8rem",
                                    borderRadius: "50%",
                                    position: "relative",
                                    boxShadow: " 0 0 0 12px rgba(255, 255, 255, 0.09)",
                                }}>
                                <div
                                    style={{
                                        background: "transparent",
                                        width: "5.6rem",
                                        height: "5.6rem",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        boxShadow: " 0 0 0 12px rgba(255, 255, 255, 0.09)",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)",
                                    }}>
                                    <div
                                        style={{
                                            background: "transparent",
                                            width: "3rem",
                                            height: "3rem",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            boxShadow: " 0 0 0 14px rgba(255, 255, 255, 0.09)",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)",
                                        }}></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Dos circulos visibles en open */}
                    <>
                        <section style={{ display: `${visibleText ? "block" : "none"}` }}>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "-1.3rem",
                                    bottom: "-4rem",
                                    zIndex: 0,
                                }}>
                                <div
                                    style={{
                                        background: "transparent",
                                        width: "8rem",
                                        height: "8rem",
                                        borderRadius: "50%",
                                        position: "relative",
                                        boxShadow: " 0 0 0 12px rgba(255, 255, 255, 0.09)",
                                    }}>
                                    <div
                                        style={{
                                            background: "transparent",
                                            width: "5.6rem",
                                            height: "5.6rem",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            boxShadow: " 0 0 0 12px rgba(255, 255, 255, 0.09)",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)",
                                        }}>
                                        <div
                                            style={{
                                                background: "transparent",
                                                width: "3rem",
                                                height: "3rem",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                boxShadow: " 0 0 0 14px rgba(255, 255, 255, 0.09)",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%,-50%)",
                                            }}></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section style={{ display: `${visibleText ? "block" : "none"}` }}>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "-5.3rem",
                                    bottom: "4.5rem",
                                    zIndex: 0,
                                }}>
                                <div
                                    style={{
                                        background: "transparent",
                                        width: "8rem",
                                        height: "8rem",
                                        borderRadius: "50%",
                                        position: "relative",
                                        boxShadow: " 0 0 0 12px rgba(255, 255, 255, 0.09)",
                                    }}>
                                    <div
                                        style={{
                                            background: "transparent",
                                            width: "5.6rem",
                                            height: "5.6rem",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            boxShadow: " 0 0 0 12px rgba(255, 255, 255, 0.09)",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)",
                                        }}>
                                        <div
                                            style={{
                                                background: "transparent",
                                                width: "3rem",
                                                height: "3rem",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                boxShadow: " 0 0 0 14px rgba(255, 255, 255, 0.09)",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%,-50%)",
                                            }}></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "-50%",
                                    top: "0",
                                    zIndex: 0,
                                }}>
                                <div
                                    style={{
                                        background: "transparent",
                                        width: "4rem",
                                        height: "4rem",
                                        borderRadius: "50%",
                                        position: "relative",
                                        boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                                    }}>
                                    <div
                                        style={{
                                            background: "transparent",
                                            width: "2.6rem",
                                            height: "2.6rem",
                                            borderRadius: "50%",
                                            position: "absolute",
                                            boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%,-50%)",
                                        }}>
                                        <div
                                            style={{
                                                background: "transparent",
                                                width: "1rem",
                                                height: "1rem",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                boxShadow: " 0 0 0 8px rgba(255, 255, 255, 0.09)",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%,-50%)",
                                            }}></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                </CardBody>
            </Card>
            <BillingDataModal open={modalEditBillingData} setOpen={setModalEditBillingData} />
        </React.Fragment>
    );
};

export default WalletHeader;
