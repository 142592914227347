// action
import {
    apiError,
    loginSuccess,
    // profileUserSuccess
} from "./reducer";

import {
    postLogin,
    jsonResponseFromAxiosResponse,
} from "../../../helpers/backend_helper";
import { ApiCore } from "src/helpers/api_helper";

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        response = postLogin(new ApiCore(), {
            email: user.email,
            password: user.password
        });

        const data = await response;
        if (data) {
            const dataJson = jsonResponseFromAxiosResponse(data);
            localStorage.setItem("token", dataJson.payload.token);
            dispatch(loginSuccess(dataJson.message));
        }

    } catch (error) {
        dispatch(apiError(["Mail y/o contraseña invalidas"]));
    }
};

export const logoutUser = (history: any) => async (dispatch: any) => {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("profile");
        localStorage.removeItem("token");
        localStorage.removeItem("streamer_id");

        history.push("/login");
    } catch (error) {
        dispatch(apiError(error));
    }
};