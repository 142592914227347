import { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
// import { FaSun, FaMoon } from 'react-icons/fa';
import './SliderMode.css';
import { useDispatch, useSelector } from 'react-redux';
import { changelayoutMode } from "src/slices/thunks";
import { layoutTheme, layoutTypes } from "src/constants/layout";
//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter } from "react-router-dom";
const SunIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-brightness-low-fill" viewBox="0 0 16 16">
    <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8.5 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 11a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm5-5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm-11 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9.743-4.036a.5.5 0 1 1-.707-.707.5.5 0 0 1 .707.707zm-7.779 7.779a.5.5 0 1 1-.707-.707.5.5 0 0 1 .707.707zm7.072 0a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707zM3.757 4.464a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707z" />
  </svg>
}

const MoonIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-brightness-low-fill" viewBox="0 0 16 16">
    <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8.5 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 11a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm5-5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm-11 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9.743-4.036a.5.5 0 1 1-.707-.707.5.5 0 0 1 .707.707zm-7.779 7.779a.5.5 0 1 1-.707-.707.5.5 0 0 1 .707.707zm7.072 0a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707zM3.757 4.464a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707z" />
  </svg>
}




function Slider() {
  const {
    layoutType,
    layoutMode
  } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
    layoutMode: state.Layout.layoutMode
  }));
  const { layoutMode: theme } = useSelector((state: any) => state.Layout)
  const [isDarkMode, setIsDarkMode] = useState<boolean>(theme == "light");
  const [isSliderActive, setIsSliderActive] = useState<boolean>(false);
  const dispatch = useDispatch();
  const switcher = () => {
    dispatch(changelayoutMode(layoutMode == layoutTheme.DARKMODE ? layoutTheme.LIGHTMODE : layoutTheme.DARKMODE, layoutType))
  }


  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
    setIsSliderActive(true);
    setTimeout(() => {
      setIsSliderActive(false);
    }, 500);
  };
  useEffect(() => {
    setIsDarkMode(theme == "light")
  }, [theme])


  return (
    <>
      <label className="switch" >
        <input onClick={() => { setIsDarkMode(!isDarkMode) }} type="checkbox" checked={isDarkMode} onChange={() => switcher()} />
        <span className="slider"></span>
      </label>
    </>
  );
}

export default withTranslation()(withRouter(Slider));