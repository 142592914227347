import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import "../../assets/scss/custom/components/_draggable.scss";
import obsImage from 'src/assets/images/adPosition.png';
import { useDispatch } from 'react-redux';
import { coordinatesChange } from 'src/slices/Auth/Emission/reducer';

interface EmissionConfigurationDraggableProps {
  x: number, y: number
}

const EmissionConfigurationDraggable = (props: EmissionConfigurationDraggableProps) => {

  const [activeDrags, setActiveDrags] = useState(0);
  const [deltaPositionX, setDeltaPositionX] = useState(0);
  const [deltaPositionY, setDeltaPositionY] = useState(0);
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) return
    setDeltaPositionX(props.x / scale);
    setDeltaPositionY(props.y / scale);
    if (props.x || props.y) setLoading(false)
  }, [props.y, props.x])

  const handleDrag = (e: any, ui: any) => {
    setDeltaPositionX(deltaPositionX + ui.deltaX);
    setDeltaPositionY(deltaPositionY + ui.deltaY);
  };



  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
    dispatch(coordinatesChange({ x: (deltaPositionX * scale), y: (deltaPositionY * scale) }))
  };

  const scale = 10;

  const dragHandlers = { onStart: onStart, onStop: onStop };

  // const imageSize = { width: 319, height: 180 };

  // const boxSize = { width: 480 / 2 + imageSize.width + 20, height: 270 / 2 + imageSize.height + 20 }

  return (
    <>
      <div className="box mobile" style={{ height: 'auto', width: 'fit-content', position: 'relative', overflow: 'auto', padding: '0', margin: '15px 0 0 6px' }}>
        <div className='navBarSim' style={{ width: 334 }}>
          <span style={{ padding: "9px", fontSize: "12px" }}>Desplace la ilustración <br /> para modificar la posición, luego guarde</span>
        </div>


        <div className='windowBg' style={{ height: `${187}px`, width: `${334}px`, position: "relative" }}>
          {typeof deltaPositionX == 'number' && typeof deltaPositionY == 'number' ?
            <Draggable position={{ x: (deltaPositionX), y: (deltaPositionY) }} bounds="parent" onDrag={handleDrag} {...dragHandlers}>
              <div className="box" style={{ backgroundImage: `url(${obsImage})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", margin: "6px 10px ", backgroundSize: "cover", width: "122px", height: "67px" }}>
                <h5 className="font-size-12" style={{ marginTop: "-5px" }}>x: {(deltaPositionX * scale).toFixed(0)} <br></br>y: {(deltaPositionY * scale).toFixed(0)}</h5>

              </div>
            </Draggable>
            :
            <></>
          }
        </div>
      </div>
    </>
  )
}


export default EmissionConfigurationDraggable