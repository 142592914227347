import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer";

// import { postFakeForgetPwd, postJwtForgetPwd } from "../../../helpers/fakebackend_helper";
import { ApiCore } from "src/helpers/api_helper";
import {
    forgotPassword,
    jsonResponseFromAxiosResponse,
    // rememberPassword,
} from "src/helpers/backend_helper";

// const fireBaseBackend = getFirebaseBackend();

export const userForgetPassword = (email: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        response = forgotPassword(new ApiCore(), email);
        const data = await response;
        const dataJson = jsonResponseFromAxiosResponse(data);
        if (dataJson.message === "ok") {
            dispatch(
                userForgetPasswordSuccess(
                    "Correo electronico con la recuperación de la contraseña enviado"
                )
            );
        } else if (dataJson.message === "streamer not found") {
            dispatch(userForgetPasswordError("Mail no registrado"));
        } else {
            dispatch(userForgetPasswordError("No funciona la API"));
        }
    } catch (forgetError) {
        dispatch(userForgetPasswordError(forgetError));
    }
};
