import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


interface ModalRetirarProps {
  isOpen: boolean;
  isDisableConfirm: boolean;
  toggle: () => void;
  onConfirm: () => void;
  availableAmount: number;
}

function WithDrawModal({ isOpen, isDisableConfirm, toggle, onConfirm, availableAmount }: ModalRetirarProps) {

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Retirar Saldo Disponible</ModalHeader>
      <ModalBody>
        ¿Estás seguro de que quieres retirar {Number(availableAmount.toFixed(2)).toLocaleString('es-ES')}€?
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancelar</Button>{' '}
        <Button color="primary" disabled={isDisableConfirm} onClick={onConfirm}>Confirmar</Button>
      </ModalFooter>
    </Modal>
  );
}




export default WithDrawModal;