
// action
import { profileSuccess, profileError, profileStatus, profilePic } from "./reducer";

// import { postFakeProfile, postJwtProfile } from "../../../helpers/fakebackend_helper";
import { ApiCore } from "src/helpers/api_helper";
import {
    getStreamerProfile,
    getStreamerStatus,
    // getWalletBallance,
    jsonResponseFromAxiosResponse,
} from "src/helpers/backend_helper";
import { logoutUser } from "../Login/thunk";
import { validCampaignImage } from "src/helpers/functions_helper";

// const fireBaseBackend = getFirebaseBackend();

export const getProfile = (history: any) => async (dispatch: any) => {
    try {
        const response = getStreamerProfile(new ApiCore(localStorage.getItem("token")));
        const profile = await response;
        const profileJson = jsonResponseFromAxiosResponse(profile);
        const streamerId = profileJson.payload.streamers?.[0]?.id;
        localStorage.setItem("authUser", JSON.stringify(profileJson.payload.user));
        localStorage.setItem("profile", JSON.stringify(profileJson.payload));
        localStorage.setItem("streamer_id", streamerId);
        // console.log(profile);
        dispatch(profileSuccess(profileJson.message));
    } catch (error) {
        dispatch(logoutUser(history));
    }
};

export const getStatus = () => async (dispatch: any) => {
    const statusResponse = getStreamerStatus();
    const status = await statusResponse;
    const statusArray = {
        status: status?.message,
        viewers: status?.payload?.viewers,
        handshake: status?.payload?.handshake,
        liveStatus: status?.payload?.live_status,
    };
    dispatch(profileStatus(statusArray));
};

export const editProfile = (user: any) => async (dispatch: any) => {
    try {
        let response;

        // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        //     response = fireBaseBackend.editProfileAPI(user.username, user.idx);
        // } else 
        // if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
        //     response = postJwtProfile({
        //         username: user.username,
        //         idx: user.idx,
        //     });
        // } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
        //     response = postFakeProfile({
        //         username: user.username,
        //         idx: user.idx,
        //     });
        // }

        const data = await response;

        if (data) {
            dispatch(profileSuccess(data));
        }
    } catch (error) {
        dispatch(profileError(error));
    }
};

export const fetchProfilePic = () => async (dispatch: any) => {
    try {
        const response = getStreamerProfile(new ApiCore(localStorage.getItem("token")));
        const profile: any = await response;
        const image = profile.payload.streamers[0].thumbnail_img_url;
        if (image) {
            dispatch(profilePic(validCampaignImage(image)));
        }
    } catch (error) {
        throw new Error("Couldn't fetch pfp");
    }
};

export const resetProfileFlag = (user: any) => async (dispatch: any) => { };
